import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../reducers';
import * as fromPermissions from '../reducers/permissions.reducer';
import { Permission } from '../models';

@Directive({
  selector: '[permissions]'
})
export class PermissionsDirective implements OnInit, OnDestroy {
  @Input() set permissions(perm: Permission | Permission[]) {
    if (Array.isArray(perm)) {
      this._permissions = perm;
    } else {
      this._permissions = [perm];
    }
  }

  private _permissions: Permission[];
  private hasView = false;
  private permissionSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionSubscription = this.store
      .pipe(
        select(fromPermissions.getAllPermissions),
        map(permissions => this._permissions.find(permission => permissions[permission]))
      )
      .subscribe(hasPermission => (hasPermission ? this.createView() : this.destroyView()));
  }

  ngOnDestroy() {
    this.permissionSubscription.unsubscribe();
  }

  private createView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdr.markForCheck();
      this.hasView = true;
    }
  }

  private destroyView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
