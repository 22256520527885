import { NgModule } from '@angular/core';
import { FontAwesomeModule as faFontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFloppyDisk,
  faCircleCheck,
  faPen,
  faUser,
  faArrowRightFromBracket,
  faCaretDown,
  faCaretUp,
  faBasketShopping,
  faComment,
  faGrid,
  faLayerGroup,
  faBullseyePointer,
  faTrash,
  faScissors,
  faCopy,
  faPaste,
  faBringForward,
  faBringFront,
  faSendBack,
  faSendBackward,
  faArrowRotateLeft,
  faArrowRotateRight,
  faGear,
  faCircleInfo,
  faPlus,
  faMinus,
  faRotateLeft,
  faRotateRight,
  faAngleLeft,
  faAngleRight,
  faWandMagicSparkles,
  faXmark
} from '@fortawesome/pro-solid-svg-icons';

import { FaConfig } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [],
  declarations: [],
  exports: [faFontAwesomeModule]
})
export class FontAwesomeModule {
  constructor(library: FaIconLibrary, private faConfig: FaConfig) {
    library.addIcons(
      faFloppyDisk,
      faCircleCheck,
      faPen,
      faUser,
      faArrowRightFromBracket,
      faCaretDown,
      faCaretUp,
      faBasketShopping,
      faComment,
      faGrid,
      faLayerGroup,
      faBullseyePointer,
      faTrash,
      faScissors,
      faCopy,
      faPaste,
      faBringForward,
      faBringFront,
      faSendBack,
      faSendBackward,
      faArrowRotateLeft,
      faArrowRotateRight,
      faGear,
      faCircleInfo,
      faPlus,
      faMinus,
      faRotateLeft,
      faRotateRight,
      faAngleLeft,
      faAngleRight,
      faWandMagicSparkles,
      faXmark
    );

    faConfig.fixedWidth = true;
  }
}
