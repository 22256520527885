import { Injectable } from '@angular/core';
import { ValueJSON, BlockJSON, MarkJSON, TextJSON, Block, Point, Selection } from 'slate';
import { Editor } from 'slate-react';
import {
  Color,
  Font,
  Line,
  MarkTypes,
  Paragraph,
  TextSpan,
  InlineTextElement,
  EditorSelection,
  hasSpotUv
} from '../models';
import { uniq } from 'lodash-es';
import { getSelectionMarkValues } from '../../../react-text-editor/utils/inline-text.utils';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { BehaviorSubject, Subject } from 'rxjs';
import { coerceNumber } from '../utils/element.utils';

@Injectable()
export class TextEditorService {
  editor: Editor;
  flipHorizontal: boolean;
  flipVertical: boolean;
  fonts: Font[];

  inlineTextElement$ = new BehaviorSubject<InlineTextElement>(null);
  currentSelection$ = new Subject<EditorSelection>();

  updateInput(element: InlineTextElement) {
    this.inlineTextElement$.next(element);
  }

  get allLines(): Block[] {
    return this.allParagraphs.flatMap(
      par => par.nodes.filter((line: Block) => line.type === 'line').toArray() as Block[]
    );
  }

  get allParagraphs(): Block[] {
    return this.editor.value.document.nodes.toArray() as Block[];
  }

  get selectedLines(): Block[] {
    return this.editor.value.blocks.toArray();
  }

  /**
   * return unique paragraphs containing selected lines
   */
  get selectedParagraphs(): Block[] {
    return uniq(this.selectedLines.map(line => this.editor.value.document.getParent(line.key) as Block));
  }

  get linesInSelectedParagraphs(): Block[] {
    return this.selectedParagraphs.flatMap(paragraph => paragraph.nodes.toArray() as Block[]);
  }

  get isExpanded() {
    return this.editor.value.selection.isExpanded;
  }

  get isFocused() {
    return this.editor.value.selection.isFocused;
  }

  constructor(public store: Store<AppState>) {
    this.store.pipe(select(s => s.fontlibrary.fontlibrary)).subscribe(fonts => (this.fonts = fonts));
  }

  selectWholeText() {
    this.editor?.moveToRangeOfDocument();
  }

  hasMark = (type: string) => this.editor.value.activeMarks.some(mark => mark.type === type);

  hasBlock = (type: string) => this.editor.value.blocks.some(node => node.type === type);

  convertToEditorValue(
    text: Paragraph[],
    width: number,
    height: number,
    flipVertical: boolean,
    flipHorizontal: boolean,
    padding: number
  ): ValueJSON {
    return {
      object: 'value',
      document: {
        object: 'document',
        data: {
          width,
          height,
          padding,
          flipVertical,
          flipHorizontal
        },
        nodes: text.map(paragraph => this.createEditorParagraph(paragraph, width))
      }
    };
  }

  createEditorParagraph(paragraph: Paragraph, width: number): BlockJSON {
    return {
      object: 'block',
      type: 'paragraph',
      data: {
        isJustified: paragraph.isJustified
      },
      nodes: paragraph.lines.map(line => this.createEditorLine(line, paragraph, width))
    };
  }

  createEditorLine(line: Line, paragraph: Paragraph, width: number): BlockJSON {
    return {
      object: 'block',
      type: 'line',
      data: {
        y: line.y,
        lineHeight: paragraph.lineHeight,
        textAlign: paragraph.textAlign,
        width,
        wordSpacing: line.wordSpacing,
        currentWidth: line.currentWidth,
        previousWidth: line.previousWidth,
        initialFontsize: line.initialFontsize
      },
      nodes: line.textSpans.map(span => this.createEditorSpan(span))
    };
  }

  createEditorSpan(span: TextSpan): TextJSON {
    const marks: MarkJSON[] = [];
    Object.keys(span).forEach(mark => {
      const exceptions = mark === 'text' || mark === 'spanParts';
      // only add marks when mark has a not falsly value (so bold=false will not add a mark)
      // eslint-disable-next-line no-prototype-builtins
      if (span.hasOwnProperty(mark) && !exceptions && span[mark]) {
        const newMark = {
          type: mark,
          data: {}
        };

        // only add data when necessary
        if (typeof span[mark] !== 'boolean') {
          newMark.data[mark] = span[mark];
        }

        if (mark === MarkTypes.Font) {
          const font = this.fonts.find((f: Font) => f.name === span[mark]);
          newMark.data['isMonospace'] = font ? font.isMonospace : false;
        }
        marks.push(newMark);
      }
    });

    return {
      object: 'text',
      text: span.text,
      marks
    };
  }

  convertToInlineText(value: ValueJSON, shrinkToFit = false): Paragraph[] {
    return value.document.nodes
      .filter((paragraph: BlockJSON) => paragraph.type === 'paragraph' && this.paragraphHasLines(paragraph))
      .map((paragraph: BlockJSON) => this.createTextParagraph(paragraph, shrinkToFit));
  }

  paragraphHasLines(paragraph: BlockJSON) {
    // make sure children of paragraph are all lines, empty paragraph's contain textNodes without lines
    return paragraph.nodes.find((line: BlockJSON) => line.type === 'line');
  }

  createTextParagraph(paragraph: BlockJSON, shrinkToFit = false): Paragraph {
    const newParagraph = new Paragraph();
    const firstLine = paragraph.nodes[0] as BlockJSON;

    Object.assign(newParagraph, {
      textAlign: firstLine.data.textAlign,
      lineHeight: firstLine.data.lineHeight,
      isJustified: paragraph.data.isJustified,
      lines: paragraph.nodes
        .filter((line: BlockJSON) => line.type === 'line')
        .map((line: BlockJSON) => this.createTextLine(line, shrinkToFit))
    });

    return newParagraph;
  }

  createTextLine(line: BlockJSON, shrinkToFit = false): Line {
    const newLine = new Line();
    Object.assign(newLine, {
      y: coerceNumber(line.data.y),
      wordSpacing: line.data.wordSpacing,
      textSpans: line.nodes
        .filter((span: TextJSON) => span.object === 'text')
        .map((span: TextJSON) => this.createTextSpan(span))
    });

    // needs to be saved otherwise it gets lost during editing when eg width is changed
    if (shrinkToFit) {
      newLine.currentWidth = line.data.currentWidth;
      newLine.previousWidth = line.data.previousWidth;
      newLine.initialFontsize = line.data.initialFontsize;
    }
    return newLine;
  }

  createTextSpan(span: TextJSON): TextSpan {
    const textSpan = new TextSpan();
    textSpan.text = span.text;

    span.marks.forEach((mark: MarkJSON) => {
      const value = mark.data[mark.type];
      // if mark has data value is data, else value = true
      textSpan[mark.type] = value ? value : true;
    });

    return textSpan;
  }

  addMark(type: string, value: string | number) {
    const data = {};
    data[type] = value;
    this.editor.addMark({ type, data });
  }

  removeMark(type: string) {
    this.editor.value.marks.filter(mark => mark.type === type).map(mark => this.editor.removeMark(mark));
  }

  // returns the value of a mark that is present in all the characters in the current selection
  getSelectionActiveMarkValue(type: string) {
    return this.editor.value.activeMarks
      .filter(mark => mark.type === type)
      .map(mark => mark.data.get(type))
      .toArray()[0];
  }

  getSelectionMarkValues(type: string) {
    return getSelectionMarkValues(type, this.editor.value);
  }

  getAllMarkValues(type: string) {
    return this.editor.value.document
      .getMarksByType(type)
      .map(mark => mark.data.get(type))
      .toArray();
  }

  getDocumentHasOneMarkValue(type: string) {
    return this.getAllMarkValues(type).length === 1;
  }

  setNodeData(node: Block, value: object) {
    const data = Object.assign(node.data.toJS(), value);
    this.editor.setNodeByKey(node.key, { type: node.type, data });
  }

  updateColorAndFoilMarks(color: Color) {
    this.selectWholeText();
    this.removeMark(MarkTypes.Foil);
    this.removeMark(MarkTypes.SpotUv);
    if (color.foil) {
      this.addMark(MarkTypes.Foil, color.foil);
    }
    if (color.spotUv) {
      // value of 1 used instead of true, because Marks should be of type string or number
      this.addMark(MarkTypes.SpotUv, 1);
    }
    this.removeMark(MarkTypes.Color);
    this.addMark(MarkTypes.Color, color.colorValue);
  }

  convertToSlateSelection(selection: EditorSelection) {
    const anchor = new Point({ path: selection.start.path, offset: selection.start.offset });
    const focus = new Point({ path: selection.end.path, offset: selection.end.offset });
    return Selection.create({ anchor, focus, isFocused: selection.isFocused });
  }
}
