import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FontListComponent } from './font-list.component';
import { ConfigService } from '../../services';

@Component({
  selector: 'ed-font-list-mob',
  templateUrl: 'font-list-mob.component.html',
  styleUrls: ['font-list-mob.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontListMobComponent extends FontListComponent {
  fontNameShort = 'Ag';
  constructor(config: ConfigService) {
    super(config);
  }
}
