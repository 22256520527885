export const fontData = [
  {
    name: 'BlackJack'
  },
  {
    name: 'Clementine'
  },
  {
    name: 'Sugar Plums'
  },
  {
    name: 'A Little Pot',
    screen_title: 'Test Screentitle A Little Pot'
  },
  {
    name: 'Arial'
  },
  {
    name: 'Arial Black,'
  },
  {
    name: 'BombshellPro'
  },
  {
    name: 'BombshellPro Bold'
  },
  {
    name: 'Castellia'
  },
  {
    name: 'featherly handlettered'
  },
  {
    name: 'Indie Flower'
  },
  {
    name: 'Lemon Milk'
  },
  {
    name: 'Signature Collection'
  },
  {
    name: 'VARSITY REGULAR'
  },
  {
    name: 'Veneer'
  },
  {
    name: 'Wild Spirit'
  },
  {
    name: 'Zipty Do'
  }
];
