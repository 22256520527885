import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SaveErrorText, UpdateExistingDesignDialogText } from '../../models/text';
import { DESIGN_NAME_MAX_LENGTH } from '../../data/designs.data';
import * as AdminDesigner from '../../admin-designer/actions';
import { select, Store } from '@ngrx/store';
import * as fromSave from '../../save/reducer';
import { getDesignSet } from '../../selectors';
import { filter, first, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientError } from '../../models/client-error';
import * as fromAdminDesigner from '../../admin-designer/reducer';

@Component({
  selector: 'ed-update-existing-design-dialog',
  templateUrl: 'update-existing-design-dialog.component.html',
  styleUrls: ['../dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateExistingDesignDialogComponent implements OnInit {
  userDesignTitle$: Observable<string>;
  designTitle$: Observable<string>;
  designDescription$: Observable<string>;
  errors$: Observable<ClientError[]>;
  errors: ClientError[];
  readonly title = 'title';
  readonly description = 'description';

  constructor(
    private store: Store<fromSave.State>,
    private dialogRef: MatDialogRef<UpdateExistingDesignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: UpdateExistingDesignDialogText; errorText: SaveErrorText }
  ) {}

  ngOnInit() {
    const designSet$ = this.store.pipe(
      select(getDesignSet),
      filter(set => set.init)
    );
    this.userDesignTitle$ = designSet$.pipe(map(designSet => designSet.userCollectionTitle || designSet.title || ''));
    this.designTitle$ = designSet$.pipe(map(designSet => designSet.title || ''));
    this.designDescription$ = designSet$.pipe(map(designSet => designSet.description || ''));
    this.errors$ = this.store.pipe(
      select(fromAdminDesigner.getError),
      tap((errors: ClientError[]) => (this.errors = errors))
    );
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.resetErrors());
  }

  hasInputErrors(field: string): boolean {
    if (this.errors?.length) {
      return this.errors.some(error => error.field === field);
    }
  }

  resetErrors(): void {
    this.store.dispatch(new AdminDesigner.ResetValidationErrors());
  }

  saveTitleDescription(title: string, description: string) {
    const errors = [];

    if (!title) {
      errors.push({ message: this.data.errorText.titleRequired, code: '', field: this.title });
    } else if (title.length > DESIGN_NAME_MAX_LENGTH) {
      errors.push({ message: this.data.errorText.maximumLengthExceeded, code: '', field: this.title });
    }
    if (!description) {
      errors.push({ message: this.data.errorText.descriptionRequired, code: '', field: this.description });
    }
    if (errors.length) {
      this.store.dispatch(new AdminDesigner.SaveTitleDescriptionFailure(errors));
    } else {
      this.store.dispatch(new AdminDesigner.SaveTitleDescription(title, description, true));
      this.dialogRef.close();
    }
  }
}
