import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AppState } from '../../reducers';
import {
  ChangeFormatPreset,
  Design,
  EditorPermissions,
  KcDesign,
  PageElement,
  PresetPage,
  ScaleOptions,
  View
} from '../../models';
import { OutputService } from '../../services';
import { TransformService } from '../../save/transform.service';
import { ChangeFormatDialogText } from '../../models/text';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../../services/params.service';
import { DesignSetSubmit, DesignSubmit } from '../../models/design-submit';
import { SetDesign } from '../../actions/permission-actions';
import { getDesignSet } from '../../selectors';
import { DesignSet } from '../../models/design-set';
import { cloneDeep } from 'lodash-es';
import { getSpreads } from 'src/app/utils/spreads.utils';

@Component({
  selector: 'ed-change-format-dialog',
  templateUrl: 'change-format.component.html',
  styleUrls: ['../dialogs/modals.scss', 'change-format.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeFormatDialogComponent implements OnInit {
  environment = environment;
  scaleOptions = ScaleOptions;
  maxNumberOfPages = 30;
  oldPages: PageElement[] = [];
  nrOfPages = new FormControl<number>(undefined);
  maxNewPages: FormGroup[] = [];
  design: Design;
  designSet: DesignSet;
  selectedPreset = new FormControl<ChangeFormatPreset>(undefined);
  presets: ChangeFormatPreset[] = [];
  convertDesignUrl = '';

  constructor(
    private dialogRef: MatDialogRef<ChangeFormatDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { text: ChangeFormatDialogText; presets: ChangeFormatPreset[]; convertDesignUrl: string },
    private store: Store<AppState>,
    private output: OutputService,
    private transform: TransformService,
    private http: HttpClient,
    private paramsService: ParamsService
  ) {}

  ngOnInit() {
    this.store.pipe(select(getDesignSet), first()).subscribe(designSet => {
      this.designSet = designSet;
      this.design = designSet.activeDesign;

      // first clone design and set view to pages befor setting oldPages
      const clonedDesign = cloneDeep(this.design);
      clonedDesign.updateView(View.pages);
      this.oldPages = clonedDesign.pages;

      this.maxNewPages = this.createMaxPageArray();
      this.nrOfPages.setValue(this.oldPages.length);
    });
    this.presets = this.data.presets.map(this.convertSpecToPages);
    this.convertDesignUrl = this.data.convertDesignUrl;
  }

  get newPages() {
    return this.maxNewPages.slice(0, this.nrOfPages.value);
  }

  get preset() {
    return this.selectedPreset.value;
  }

  convertSpecToPages(preset: ChangeFormatPreset) {
    if (!preset.specs) {
      return preset;
    }

    const pages: Array<PresetPage> = [];
    preset.specs.sheets.forEach(sheet => {
      sheet.spreads.forEach(spread =>
        spread.forEach(page => {
          const id = parseInt(page.page.substr(1), 10);
          const idExists = pages.findIndex(p => p.id === id) > -1;
          // assumes pages in freeform template with same id have same dimensions
          if (!idExists) {
            pages.push({
              width: page.width,
              height: page.height,
              bleed: Math.max(page.bleed_bottom, page.bleed_left, page.bleed_top, page.bleed_right),
              id
            });
          }
        })
      );
    });
    // sort pages on id, because it matches old page json kc
    pages.sort((a, b) => a.id - b.id);
    return {
      ...preset,
      pages
    };
  }

  selectPreset() {
    if (this.preset) {
      this.nrOfPages.setValue(this.preset.pages.length);
      this.nrOfPages.disable();
      this.preset.pages.forEach((presetPage: PresetPage, index: number) => {
        const width = this.maxNewPages[index].get('width');
        const height = this.maxNewPages[index].get('height');
        const id = this.maxNewPages[index].get('id');
        const bleed = this.maxNewPages[index].get('bleed');
        width.setValue(presetPage.width);
        height.setValue(presetPage.height);
        bleed.setValue(presetPage.bleed);
        id.setValue(presetPage.id);
        width.disable();
        height.disable();
        bleed.disable();
      });
    } else {
      this.maxNewPages.forEach(page => page.enable());
      this.nrOfPages.enable();
    }
  }

  submit(): void {
    const newPagesData = this.newPages.map((page, index) => {
      const formData = page.getRawValue();
      const newPage = {
        old_key: formData.oldPageIndex + 1,
        new_key: index + 1,
        width: formData.width,
        height: formData.height,
        scale: ScaleOptions[formData.scale],
        bleed: formData.bleed
      };

      if (this.preset && this.preset.specs) {
        // use page id when freeform specs;
        newPage.new_key = formData.id;
      }

      return newPage;
    });

    this.http
      .post(
        this.convertDesignUrl,
        {
          new_pages: newPagesData,
          source_design_json: this.output.transformToKC(this.design),
          specs: this.preset ? this.preset.specs : undefined,
          fold: this.preset ? this.preset.fold : undefined
        },
        { params: this.paramsService.httpParams }
      )
      .subscribe((response: { converted_pages: KcDesign; permissions: EditorPermissions }) => {
        const designSubmit = new DesignSubmit();
        designSubmit.use_absolute_font_size = false;
        designSubmit.pages = response.converted_pages;
        designSubmit.permissions = response.permissions;
        if (this.designSet.version > 0) {
          const designSetSubmit = new DesignSetSubmit();
          designSetSubmit.version = this.designSet.version;
          designSubmit.design_id = this.designSet.designs[0].id;
          designSetSubmit.designs = [designSubmit];
          this.store.dispatch(new SetDesign(designSetSubmit));
        } else {
          this.store.dispatch(new SetDesign(designSubmit));
        }
        this.dialogRef.close(true);
      });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  createPageFormControl(page: PageElement, index: number): FormGroup {
    return new FormGroup({
      oldPageIndex: new FormControl(index),
      width: new FormControl(page.width),
      height: new FormControl(page.height),
      scale: new FormControl(this.scaleOptions.auto),
      bleed: new FormControl(page.bleed),
      id: new FormControl<number>(undefined)
    });
  }

  createMaxPageArray() {
    const extraPages = Math.max(this.maxNumberOfPages - this.oldPages.length, 0);
    const emptyPages = Array(extraPages)
      .fill(undefined)
      .map(() => this.createPageFormControl(this.oldPages[0], -1));
    const existingPages = this.oldPages.map((page, index) => this.createPageFormControl(page, index));
    return [...existingPages, ...emptyPages];
  }
}
