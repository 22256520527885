import { fabric } from "fabric";
import { cloneDeep } from "lodash-es";
import { isTinyObject } from "../../../utils/object.utils";

const size = 24;
const controlRadius = 6;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle fill="#0008" cx="12" cy="12" r="7" />
    <circle fill="#FFF" cx="12" cy="12" r="6" stroke="#0008" stroke-width="0.2" stroke-opacity="0.7" />
  </svg>
`);

let isSideDrag: boolean;
let isMouseDown: boolean;
let actionHandlerOffsetX: number;
let actionHandlerOffsetY: number;

type ControlType = "tl" | "tr" | "br" | "bl";

const controlTypes: Record<ControlType, ControlType> = {
  tl: "tl",
  tr: "tr",
  br: "br",
  bl: "bl"
};

const Controls: Record<ControlType, fabric.Control> = {
  tl: cloneDeep(fabric.Object.prototype.controls.tl),
  tr: cloneDeep(fabric.Object.prototype.controls.tr),
  br: cloneDeep(fabric.Object.prototype.controls.br),
  bl: cloneDeep(fabric.Object.prototype.controls.bl)
};

Object.keys(Controls).forEach((control: ControlType) => {
  Object.assign(
    fabric.Object.prototype.controls[control],
    createPartialControl(control)
  );
});

function createPartialControl(controlType: ControlType): Partial<fabric.Control> {
  return {
    sizeX: 2 * controlRadius,
    sizeY: 2 * controlRadius,
    cursorStyleHandler: customCursorStyleHandler(controlType),
    actionHandler: customActionHandler(controlType),
    mouseDownHandler,
    getVisibility,
    render: customRender(controlType)
  }
}

function mouseDownHandler(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
  isMouseDown = true;
  isSideDrag = false;
  return true;
}

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isTinyObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}


function customRender(controlType: ControlType) {
  return (ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) => {
    const fabricControl = fabricObject.controls[controlType];

    let isTopOrBottomSide = controlType === controlTypes.tl || controlType === controlTypes.br;
    let isBottomControl = controlType === controlTypes.bl || controlType === controlTypes.br;

    //Calculate object size depending on scale (width or height)
    const objectSize = (isTopOrBottomSide ? fabricObject.getScaledWidth() : fabricObject.getScaledHeight()) * (fabricObject.canvas?.getZoom() ?? 1);

    //Check if render was called for Side or Corner
    const isSideRender = isTopOrBottomSide ? fabricControl.x === 0 : fabricControl.y === 0;

    const isSideScalable = !(fabricObject.data.downLg || fabricObject instanceof fabric.Textbox);

    //Controller direction relative to object center (x or y)
    const direction = isBottomControl ? 1 : -1;

    const x = isSideScalable ? 0 : direction * 0.5, y = x;
    const offsetX = isSideScalable ? direction * controlRadius : 0, offsetY = offsetX;
    const sizeX = isSideScalable ? objectSize : 2 * controlRadius, sizeY = sizeX;

    Object.assign(fabricControl, isTopOrBottomSide
      ? { x, offsetX, sizeX }
      : { y, offsetY, sizeY }
    );

    const controlPoint = new fabric.Point(left, top);

    let renderLeft = left + (isSideRender && isTopOrBottomSide ? direction * (objectSize / 2 - controlRadius) : 0);
    let renderTop = top + (isSideRender && !isTopOrBottomSide ? direction * (objectSize / 2 - controlRadius) : 0);
    const renderPoint = new fabric.Point(renderLeft, renderTop);

    const { x: imgLeft, y: imgTop } = fabric.util.rotatePoint(renderPoint, controlPoint, fabric.util.degreesToRadians(fabricObject.angle));

    ctx.save();
    ctx.translate(imgLeft, imgTop);
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  }
}

function customActionHandler(controlType: ControlType) {
  return (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => {
    const actionHandler = Controls[controlType].actionHandler;

    if (!(transformData.target.data.downLg || transformData.target instanceof fabric.Textbox)) {
      const controlPoint = getControlPoint(transformData.target, controlType);

      //Check if first mouse down on control
      if (isMouseDown) {
        isMouseDown = false;

        isSideDrag = !isCursorOnControl(new fabric.Point(x, y), transformData.target, controlType, controlRadius);

        //Offset pointer position based on initial mousedown position
        actionHandlerOffsetX = x - controlPoint.x;
        actionHandlerOffsetY = y - controlPoint.y;
      }

      if (isSideDrag) {
        //Update x or y in order to scale only one axis
        if (controlType === controlTypes.tl || controlType === controlTypes.br) {
          x = controlPoint.x;
          y = y - actionHandlerOffsetY;
        } else {
          y = controlPoint.y;
          x = x - actionHandlerOffsetX;
        }
      }
    }

    return actionHandler(eventData, transformData, x, y);
  }
}

function customCursorStyleHandler(controlType: ControlType) {
  return (eventData: MouseEvent, control: fabric.Control, fabricObject: fabric.Object): string => {
    const cursorStyleHandler = Controls[controlType].cursorStyleHandler;
    const pointer = fabricObject.canvas.getPointer(eventData as Event);
    let controlDummy = control;

    if (!(fabricObject.data.downLg || fabricObject instanceof fabric.Textbox)) {
      //Use a dummy control to render cursor based on position
      controlDummy = Object.assign(new fabric.Control(), control);

      const origin = new fabric.Point(0, 0);
      const neutralCursorPosition = new fabric.Point(0, 1);
      let cursorAngle = controlType === controlTypes.tr || controlType === controlTypes.bl ? 90 : 0;

      if (isCursorOnControl(new fabric.Point(pointer.x, pointer.y), fabricObject, controlType, controlRadius)) {
        cursorAngle = controlType === controlTypes.tl || controlType === controlTypes.br ? -45 : 45;
      }

      const { x: left, y: top } = fabric.util.rotatePoint(neutralCursorPosition, origin, fabric.util.degreesToRadians(cursorAngle));
      controlDummy.x = left;
      controlDummy.y = top;
    }

    return cursorStyleHandler(eventData, controlDummy, fabricObject);
  }
}

function isCursorOnControl(pointerPosition: fabric.Point, object: fabric.Object, controlType: ControlType, threshold: number): boolean {
  const controlPoint = getControlPoint(object, controlType);
  const distance = Math.sqrt(
    Math.pow(controlPoint.x - pointerPosition.x, 2) +
    Math.pow(controlPoint.y - pointerPosition.y, 2)
  );

  return distance <= threshold;
}

function getControlPoint(object: fabric.Object, controlType: ControlType): fabric.Point {
  let toRotate: fabric.Point;

  switch (controlType) {
    case controlTypes.tl:
      toRotate = new fabric.Point(object.left, object.top);
      break;
    case controlTypes.br:
      toRotate = new fabric.Point(object.left + object.width, object.top + object.height);
      break;
    case controlTypes.tr:
      toRotate = new fabric.Point(object.left + object.width, object.top);
      break;
    case controlTypes.bl:
      toRotate = new fabric.Point(object.left, object.top + object.height);
      break;
  }

  return fabric.util.rotatePoint(toRotate, new fabric.Point(object.left, object.top), fabric.util.degreesToRadians(object.angle));
}