export const devPermissions = {
  setElementPermissions: {
    isLocked: true,
    isPrintable: true,
    isRecolorable: true,
    isResizable: true,
    isDuplicatable: true,
    isRemovable: true,
    isMovable: true,
    isRotatable: true,
    isCroppable: true,
    isReplaceable: true,
    isInstantReplaceable: true,
    hasInstantReplaceablePlaceholder: true,
    isUntargetable: true,
    isFlippable: true,
    isTopLayer: true,
    isFoilable: true,
    addChildElements: true,
    adjustTransparency: true,
    hasCoating: true,
    isSpotUvable: true,
    isCutThrough: true,
    cutThroughInverted: true,
    isKissCut: true,
    isCrease: true,
    isPerforation: true,
    isFillable: true,
    isOrderable: true,
    adjustTrim: true,
    changeMaterial: true,
    isHidden: true,
    useRichText: true,
    shrinkToFit: true,
    excludeFromPreviews: true,
    isVisibleOutsideCropArea: true,
    isExternallyEditable: true,
    fontChangeable: true,
    fontResizable: true,
    textEditable: true
  },
  functionPermissions: {
    saveDesignInCollectionOtherUser: true,
    importExportDesign: true,
    updateExistingDesign: true,
    submitDesignForReview: true,
    formatPages: true,
    hideSaveContinueDialog: true,
    canAddBox: true,
    showFoilSafetyMarginWarning: true,
    addImageAsPhotoFrame: true,
    hasDimensionsInputField: true,
    autoSave: true,
    showGuideLines: true,
    toggleSpreads: true,
    canAddTag: true,
    canAddTextInline: true,
    canAddLabels: true,
    canChangeBorderTrim: true,
    canChangeMaterial: true,
    canSave: true,
    objectDetectionUpload: true,
    canEditStyle: true,
    allowCrossSessionElementCopying: true,
    enableFoilableByDefault: true,
    spotUvPermissionForJpgByDefault: false,
    useChat: true,
    resizeText: true,
    showUpdateDialog: false,
    canSearchImageLibrary: true,
    canCreatePdf: true,
    canAddSpotUv: true,
    enableImageUpload: true,
    setCharacterLimit: true
  }
};

export const kcPermissions = {
  setElementPermissions: {
    isLocked: true,
    isPrintable: true,
    isRecolorable: true,
    isResizable: true,
    isDuplicatable: true,
    isRemovable: true,
    isMovable: true,
    isRotatable: true,
    isCroppable: true,
    isReplaceable: true,
    isInstantReplaceable: true,
    hasInstantReplaceablePlaceholder: true,
    isUntargetable: true,
    isFlippable: true,
    isTopLayer: true,
    isFoilable: true,
    addChildElements: true,
    adjustTransparency: true,
    hasCoating: true,
    isSpotUvable: true,
    isCutThrough: true,
    cutThroughInverted: true,
    isKissCut: true,
    isCrease: true,
    isPerforation: true,
    isFillable: true,
    isOrderable: true,
    adjustTrim: true,
    changeMaterial: true,
    isHidden: true,
    useRichText: true,
    shrinkToFit: true,
    excludeFromPreviews: true,
    isVisibleOutsideCropArea: true,
    isExternallyEditable: true,
    fontChangeable: true,
    fontResizable: true,
    textEditable: true
  },
  functionPermissions: {
    saveDesignInCollectionOtherUser: true,
    importExportDesign: true,
    updateExistingDesign: true,
    submitDesignForReview: true,
    formatPages: true,
    hideSaveContinueDialog: true,
    canAddBox: false,
    showFoilSafetyMarginWarning: false,
    addImageAsPhotoFrame: true,
    hasDimensionsInputField: true,
    autoSave: true,
    showGuideLines: true,
    toggleSpreads: true,
    canAddTag: true,
    canAddTextInline: true,
    canAddLabels: true,
    canChangeBorderTrim: true,
    canChangeMaterial: true,
    canSave: true,
    objectDetectionUpload: true,
    canEditStyle: false,
    allowCrossSessionElementCopying: true,
    enableFoilableByDefault: true,
    spotUvPermissionForJpgByDefault: false,
    useChat: true,
    resizeText: true,
    showUpdateDialog: true,
    canSearchImageLibrary: true,
    canCreatePdf: true,
    canAddSpotUv: true,
    enableImageUpload: true,
    setCharacterLimit: true
  }
};

export const moozePermissions = {
  setElementPermissions: {
    isLocked: true,
    isPrintable: true,
    isRecolorable: true,
    isResizable: true,
    isDuplicatable: true,
    isRemovable: true,
    isMovable: true,
    isRotatable: true,
    isCroppable: true,
    isReplaceable: true,
    isInstantReplaceable: true,
    hasInstantReplaceablePlaceholder: true,
    isUntargetable: true,
    isFlippable: true,
    isTopLayer: true,
    isFoilable: true,
    addChildElements: true,
    adjustTransparency: true,
    hasCoating: true,
    isSpotUvable: true,
    isCutThrough: true,
    cutThroughInverted: true,
    isKissCut: true,
    isCrease: true,
    isPerforation: true,
    isFillable: true,
    isOrderable: true,
    adjustTrim: true,
    changeMaterial: true,
    isHidden: true,
    useRichText: true,
    shrinkToFit: true,
    excludeFromPreviews: true,
    isVisibleOutsideCropArea: true,
    isExternallyEditable: true,
    fontChangeable: true,
    fontResizable: true,
    textEditable: true
  },
  functionPermissions: {
    saveDesignInCollectionOtherUser: true,
    importExportDesign: true,
    updateExistingDesign: true,
    submitDesignForReview: true,
    formatPages: true,
    hideSaveContinueDialog: true,
    canAddBox: false,
    showFoilSafetyMarginWarning: false,
    addImageAsPhotoFrame: true,
    hasDimensionsInputField: true,
    autoSave: true,
    showGuideLines: true,
    toggleSpreads: true,
    canAddTag: true,
    canAddTextInline: true,
    canAddLabels: true,
    canChangeBorderTrim: true,
    canChangeMaterial: true,
    canSave: true,
    objectDetectionUpload: true,
    canEditStyle: false,
    allowCrossSessionElementCopying: true,
    enableFoilableByDefault: true,
    spotUvPermissionForJpgByDefault: false,
    useChat: false,
    resizeText: true,
    showUpdateDialog: true,
    canSearchImageLibrary: true,
    canCreatePdf: true,
    canAddSpotUv: true,
    enableImageUpload: true,
    setCharacterLimit: true
  }
};
