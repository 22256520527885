import { Component, Output, EventEmitter, HostBinding, Input } from '@angular/core';
import { BackgroundPanelComponent } from './background-panel.component';

@Component({
  selector: 'ed-background-mob',
  templateUrl: 'background-mob.component.html',
  styleUrls: [
    './background-mob.component.scss',
    '../edit-mob.component.scss',
    '../../../shared/button/preview-button-mob.scss'
  ]
})
export class BackgroundMobComponent extends BackgroundPanelComponent {
  showBackgroundColorMenu = false;
  showTrimMenu = false;

  @Output() closeEditMode: EventEmitter<boolean> = new EventEmitter();

  @Input() backgroundIsLocked: boolean;

  @HostBinding('class.hide-edit-menu') displayEditMenu = false;

  openTrimMenu() {
    this.showTrimMenu = true;
  }

  closeEditMenu() {
    if (this.showBackgroundColorMenu) {
      this.showBackgroundColorMenu = false;
    } else if (this.showTrimMenu) {
      this.showTrimMenu = false;
    } else {
      this.closeEditMode.emit(false);
    }
  }
}
