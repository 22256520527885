import { Component, Input } from '@angular/core';
import * as CanvasActions from '../../../actions/canvas-actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-edit-box',
  templateUrl: './edit-box.component.html',
  styleUrls: ['./edit-box.component.scss', '../../nav/nav-buttons.component.scss']
})
export class EditBoxComponent {
  @Input() class = '';

  constructor(private store: Store<AppState>, public getTextService: GetTextService) {}

  addBox() {
    this.store.dispatch(new CanvasActions.AddBox());
  }
}
