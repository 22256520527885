import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  defaultPreviewImage,
  DialogItem,
  EditOptionDialogComponent,
  PreviewType
} from './edit-option-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { formatImageSource, Material, Trim } from '../../../../models';

@Component({
  selector: 'ed-edit-option-selector',
  templateUrl: 'edit-option-selector.component.html',
  styleUrls: ['edit-option-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditOptionSelectorComponent {
  dialogRef: MatDialogRef<EditOptionDialogComponent>;
  previewTypes = PreviewType;
  itemName = '';
  itemImg = '';
  itemType = '';
  _selectedItem: Trim | Material;

  @Input() title: string;
  @Input() items: DialogItem[];
  @Input() imageBase: string;

  @Input() set selectedItem(selectedItem: Trim | Material) {
    this._selectedItem = selectedItem;
    if (selectedItem) {
      this.itemName = selectedItem.name;
      this.itemImg = selectedItem.image;
      this.itemType = selectedItem.type;
    }
  }

  get selectedItem() {
    return this._selectedItem;
  }

  @Input() dataLayerIdPrefix = '';
  @Input() clippingImage: string;
  @Input() previewType = PreviewType.image;

  @Output() selectItem = new EventEmitter<DialogItem>();

  constructor(public dialog: MatDialog, public sanitizer: DomSanitizer) {}

  openDialog() {
    this.dialogRef = this.dialog.open(EditOptionDialogComponent, {
      width: '360px',
      position: { right: '20px' },
      backdropClass: 'transparent',
      data: {
        title: this.title,
        items: this.items,
        selectedItem: this.selectedItem,
        imageBase: this.imageBase,
        clippingImage: this.clippingImage,
        previewType: this.previewType,
        dataLayerIdPrefix: this.dataLayerIdPrefix
      }
    });

    this.dialogRef.componentInstance.selectItem.subscribe((item: DialogItem) => {
      this.selectItem.emit(item);
    });
  }

  getImage(itemImage: string) {
    const image = itemImage || defaultPreviewImage;
    return this.sanitizer.bypassSecurityTrustUrl(`${this.imageBase}${formatImageSource(image)}`);
  }
}
