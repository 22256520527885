import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { makeIdsUnique } from '../utils/generate-unique-svg-ids.utils';
import { CacheElement } from './cache.service';

// backend generates svg images in points
const PIXELS_PER_POINT = 1 + 1 / 3;

@Injectable()
export class SvgService {
  nameSpaceUrl = 'http://www.w3.org/2000/svg';

  constructor(@Inject(DOCUMENT) private _document: Document) { }

  uniqueIdCounter = 1;

  setAttributes(element: Element, attributes: object) {
    Object.keys(attributes).forEach(attr => element.setAttribute(attr, attributes[attr]));
  }

  serialize(element: Element) {
    return new XMLSerializer().serializeToString(element);
  }

  parseSVG(svg_string: string, element: CacheElement) {
    const svg_document = new DOMParser().parseFromString(svg_string, 'image/svg+xml');

    // recoloring of an svg in backend happens with a filter with id='alpha'
    const mask = svg_document.getElementById('alpha');
    if (mask) {
      // breaks with svg surfaces using stroke, because filter in svg uses 'objectBoundingBox'
      // and apparently stroke width is outside the bounding box
      mask.setAttribute('filterUnits', 'userSpaceOnUse');
    }

    const svg_node = svg_document.firstChild as SVGElement;

    const newHeight = Number(svg_node.attributes.getNamedItem('height').value.replace('pt', '')) * PIXELS_PER_POINT;

    const newWidth = Number(svg_node.attributes.getNamedItem('width').value.replace('pt', '')) * PIXELS_PER_POINT;

    // remove svg automatic aspect ratio, else svgs will jump around, because of rounding whole pixels
    svg_node.setAttribute('preserveAspectRatio', 'none');

    const hasEmbeddedImage = !!svg_document.getElementsByTagName('image').length;

    // make ids unique so if multiple inline text elements, ids in elements will reference own glyphs
    this.uniqueIdCounter = makeIdsUnique(svg_node, false, this.uniqueIdCounter);

    if (element.isInlineText()) {
      const viewBox = String(svg_node.attributes.getNamedItem('viewBox').value);
      const [x, y, width, height] = viewBox.split(' ').map(Number);
      const newViewBox = `${x - width * (element.getExtraWidthRatio() - 1) / 2} ${y - height * (element.getExtraHeightRatio() - 1) / 2} ${element.getExtraWidthRatio() * width} ${element.getExtraHeightRatio() * height}`;

      svg_node.setAttribute('viewBox', newViewBox);
    }

    const url = new Blob([this.serialize(svg_node)], { type: 'image/svg+xml' });

    return [url, newHeight, newWidth, !hasEmbeddedImage] as [Blob, number, number, boolean];
  }
}
