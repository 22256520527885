import { KcDesign } from './kc-page';
import { EditorPermissions } from './permissions';

export class DesignSubmit {
  save: boolean; // if a design should be save in collection of user
  design_id: string; // unique id for shop design
  design_title: string; // title of shop design
  design_description: string; // description of shop design
  is_proposed_design: boolean; // description of shop design
  user_design_id: number; // unique id of user design
  user_design_title: string; // unique title of user design
  email_user?: string; // only used when admin saves design in collection of other user
  overwrite_existing_design?: boolean; // used for admin and designer to overwrite a design
  make_copy?: boolean; // used to make a copy from existing user design

  use_absolute_font_size: boolean; // boolean to set the use of absolute fontsize
  pages: KcDesign;
  permissions?: EditorPermissions;
}

export class DesignTitleResponse {
  design_id: string;
  design_title: string;
  design_description: string;
}

export class DesignSetSubmit {
  version: number; // the version number of response
  designs: Array<DesignSubmit> = []; // array of design submits

  id: string; // id of the set. optional for now
  title: string;
  description: string;
  save: boolean;

  use_absolute_font_size: boolean; // boolean to set the use of absolute fontsize
  is_proposed: boolean; // description of shop design
  user_collection_id: number; // unique id of user design
  user_collection_title: string; // unique title of user design
  email_user?: string; // only used when admin saves design in collection of other user
  overwrite_existing_design?: boolean; // used for admin and designer to overwrite a design
  make_copy?: boolean; // used to make a copy from existing user design
}

// checks if backend response is old DesignSubmit or newDesignSetSubmit version 1
export const isDesignSetSubmit = (setOrNot: DesignSubmit | DesignSetSubmit): setOrNot is DesignSetSubmit => {
  return (setOrNot as DesignSetSubmit).version !== undefined && (setOrNot as DesignSetSubmit).version >= 1;
};
