import { CanvasElement, PageElement } from '../models';
import { DesignSet } from '../models/design-set';

export const fill = (
  parentDimCenter: number,
  parentDimFill: number,
  dimFill: number,
  dimCenter: number,
  bleed: number
) => {
  const offsetFill = -bleed;
  const newDimFill = 2 * bleed + parentDimFill;
  const newDimCenter = (newDimFill / dimCenter) * dimFill;
  const offsetCenter = (parentDimCenter - newDimCenter) / 2;
  return { offsetCenter, offsetFill, newDimCenter, newDimFill };
};

export const getFillHorizontal = (selectedElement: CanvasElement, parentElement: CanvasElement) => {
  const newDimensions = fill(
    parentElement.screenHeight,
    parentElement.screenWidth,
    selectedElement.height,
    selectedElement.width,
    parentElement.screenBleed
  );
  return {
    screenWidth: newDimensions.newDimFill,
    screenHeight: newDimensions.newDimCenter,
    screenX: newDimensions.offsetFill,
    screenY: newDimensions.offsetCenter
  };
};

export const getFillVertical = (selectedElement: CanvasElement, parentElement: CanvasElement) => {
  const newDimensions = fill(
    parentElement.screenWidth,
    parentElement.screenHeight,
    selectedElement.width,
    selectedElement.height,
    parentElement.screenBleed
  );
  return {
    screenWidth: newDimensions.newDimCenter,
    screenHeight: newDimensions.newDimFill,
    screenX: newDimensions.offsetCenter,
    screenY: newDimensions.offsetFill
  };
};

export const setFillDimensions = (parent: CanvasElement, element: CanvasElement) => {
  const parentRatio = parent.screenHeight / parent.screenWidth;
  const imageRatio = element.height / element.width;
  let fillDimensions;

  if (parentRatio >= imageRatio) {
    fillDimensions = getFillVertical(element, parent);
  } else {
    fillDimensions = getFillHorizontal(element, parent);
  }

  element.screenWidth = fillDimensions.screenWidth;
  element.screenHeight = fillDimensions.screenHeight;
  element.screenX = fillDimensions.screenX;
  element.screenY = fillDimensions.screenY;
};

export const calculateFillPage = (designDim: DesignSet, page: PageElement) => {
  return {
    x: (designDim.width - page.width) / 2,
    y: (designDim.height - page.height) / 2
  };
};

export const fillPage = (designDim: DesignSet, page: PageElement) => {
  const { x, y } = calculateFillPage(designDim, page);
  page.x = x;
  page.y = y;
};
