import { EditorAction } from '../action';
import { TRANSLATE } from './canvas.action.types';

export class Translate implements EditorAction {
  readonly type = TRANSLATE;
  readonly undoable = true;
  readonly save = true;

  constructor(
    public route: Array<number>,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public precise?: boolean
  ) {}
}
