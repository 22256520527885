import { MOVE_TO_FRONT } from './canvas.action.types';
import { EditorAction } from '../action';

export class MoveToFront implements EditorAction {
  readonly type = MOVE_TO_FRONT;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>) {}
}
