import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { CustomFormField } from '../../models';
import { CustomFormText } from 'src/app/models/text';
import { FormFieldType } from '../../models';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ed-custom-form-field',
  templateUrl: './custom-form-field.component.html',
  styleUrls: ['../dialogs/modals.scss']
})
export class CustomFormComponent implements OnInit, OnDestroy {
  submittedSubscription: Subscription;
  private _isValidated: boolean;
  formControl: AbstractControl;
  private _form: FormGroup;
  formFieldType = FormFieldType;

  @Input() submitted$: Observable<boolean>;
  @Input() showPasswordMismatch: boolean;
  @Input() field: CustomFormField;
  @Input() formText: CustomFormText;

  @Input() set form(form: FormGroup) {
    this.formControl = form.controls[this.field.name];
    this._form = form;
  }

  get form() {
    return this._form;
  }

  resetValidation() {
    this._isValidated = false;
  }

  ngOnInit() {
    this.submittedSubscription = this.submitted$.subscribe(value => {
      this._isValidated = value;
    });
  }

  get isInValid() {
    return (this._isValidated && this.showRequiredError) || this.showMinLengthError || this.showEmailError;
  }

  get isValid() {
    return this._isValidated && !this.isPristine && !this.isInValid;
  }

  get showRequiredError() {
    return this._isValidated && !this.isPristine && this.hasError('required');
  }

  get showMinLengthError() {
    return this._isValidated && this.hasError('minlength');
  }

  get showEmailError() {
    return this._isValidated && this.hasError('email');
  }

  get isPristine() {
    return this.formControl.pristine;
  }

  // form types checkboxes and radio buttons don't work inside form fields
  showFormField(field: CustomFormField) {
    return (
      field.type !== FormFieldType.Checkbox &&
      field.type !== FormFieldType.Radio &&
      field.type !== FormFieldType.Textbox
    );
  }

  hasError(name: string) {
    return this.formControl.hasError(name);
  }

  get hideField() {
    let hide = false;

    if (this.field.hideIfValue) {
      const targetControl = this._form.get(this.field.hideIfValue.key);
      if (targetControl) {
        hide = targetControl.value === this.field.hideIfValue.value;
      }
    }

    return hide;
  }

  get title() {
    return this.field.title + (this.field.required && (!this.field.hideIfValue || !this.hideField) ? ' *' : '');
  }

  ngOnDestroy() {
    this.submittedSubscription.unsubscribe();
  }
}
