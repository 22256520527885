import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DesignSet } from '../../../models/design-set';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-zoom',
  templateUrl: 'zoom.component.html',
  styleUrls: ['zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomComponent {
  menuOpened = false;

  defaultZoomValue = 100;

  zoomSteps: number[];

  public init = false;
  public relativeZoom: number;

  private _designSet: DesignSet;

  @Input() set designSet(designSet: DesignSet) {
    this._designSet = designSet;

    if (designSet.activeDesign) {
      const design = designSet.activeDesign;
      if (!this.init && designSet.init && design.visiblePage) {
        // enable change detection for this component only after initialization of design,
        // because initialisation of design is fast after loading and this causes ExpressionChangedAfterItHasBeenCheckedError
        this.init = true;
        this.changeDetectorRef.reattach();
      }

      if (design.visiblePage) {
        this.relativeZoom = 100;
        this.zoomSteps = design.visiblePage.relativeZoomSteps;
      }
    }
  }

  get designSet(): DesignSet {
    return this._designSet;
  }

  @Input() zoom: number;

  @Input() zoomToFit: number;

  @Output() zoomChange = new EventEmitter<number>();

  get displayZoom(): number {
    return Math.round(this.zoom / this.zoomToFit * 100);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public getTextService: GetTextService
  ) {
    this.changeDetectorRef.detach();
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  zoomPercentage(step: number) {
    this.zoomCanvas(step);
  }

  zoomCanvas(percentage: number) {
    if (percentage !== undefined) {
      this.zoom = percentage * this.zoomToFit / 100;
      this.zoomChange.emit(this.zoom);
    }
  }

  resetZoom() {
    this.zoomCanvas(this.defaultZoomValue);
  }
}
