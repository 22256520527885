import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { tap, map, switchMap, catchError, withLatestFrom, mergeMap } from 'rxjs/operators';
import {
  AdminDesignerActionTypes,
  SubmitSaveDesignOtherUser,
  SubmitDesignOtherUserSuccess,
  SubmitDesignOtherUserFailure,
  SaveTitleDescription,
  SaveTitleDescriptionSuccess,
  SaveTitleDescriptionFailure,
  SubmitSaveDesignForReview,
  SubmitDesignForReviewSuccess,
  SubmitSaveDesignForReviewFailure
} from './actions';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveService } from '../save/save.service';
import { CanvasActions } from '../actions';
import { Action } from '@ngrx/store';
import { GetTextService, OutputService } from '../services';
import { CategoryImage, Color, KcDesignData } from '../models';
import { UpdateDesignColors, UpdateDesignImages, UpdateFoilPermissionWasChanged } from '../actions/canvas-actions';
import { DesignSet } from '../models/design-set';
import { UpdateSpotUvForJpgPermissionWasChanged } from '../actions/canvas-actions/update-spotuv-permission-was-changed.action';

@Injectable()
export class AdminDesignerEffects {
  @Effect()
  saveTitleDescription$: Observable<Action> = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SaveTitleDescription),
    switchMap((action: SaveTitleDescription) =>
      this.saveService.saveTitleDescription(action.title, action.description, action.overwrite).pipe(
        map(data => new SaveTitleDescriptionSuccess(data)),
        catchError(error => of(new SaveTitleDescriptionFailure(error.error)))
      )
    )
  );

  @Effect()
  saveTitleSucces$: Observable<Action> = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SaveTitleDescriptionSuccess),
    map(
      (action: SaveTitleDescriptionSuccess) =>
        new CanvasActions.SetTitleDescription(
          action.data.design_title,
          action.data.design_description,
          action.data.design_id
        )
    ),
    tap(() =>
      this.snackBar.open(this.getTextService.text.toolBar.save.snackbar.titleOrDescriptionSaved, '', {
        panelClass: 'save-snack-bar',
        duration: 1000,
        horizontalPosition: 'left',
        verticalPosition: 'top'
      })
    )
  );

  @Effect()
  submitSaveDesignForReview$: Observable<Action> = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SubmitSaveDesignForReview),
    switchMap((action: SubmitSaveDesignForReview) =>
      this.saveService.saveDesignForReview(action.title, action.description, action.requestOverwrite).pipe(
        map(design => new SubmitDesignForReviewSuccess(design)),
        catchError(error => of(new SubmitSaveDesignForReviewFailure(error.error)))
      )
    )
  );

  @Effect()
  submitSaveDesignForReviewSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SubmitDesignForReviewSuccess),
    map(
      (action: SubmitDesignForReviewSuccess) =>
        new CanvasActions.SetTitleDescription(
          action.data.design_title,
          action.data.design_description,
          action.data.design_id,
          true
        )
    ),
    tap(() => {
      this.snackBar.open(this.getTextService.text.toolBar.save.snackbar.designSavedForProposal, '', {
        panelClass: 'save-snack-bar',
        duration: 1000,
        horizontalPosition: 'left',
        verticalPosition: 'top'
      });
      this.dialog.closeAll();
    })
  );

  /**
   * changes made to design in output during submit are saved to the store
   */
  @Effect()
  updateDesignAssets$: Observable<Action> = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SubmitDesignForReviewSuccess, AdminDesignerActionTypes.SaveTitleDescriptionSuccess),
    withLatestFrom(this.saveService.designSet$),
    mergeMap(([action, designSet]: [SubmitDesignForReviewSuccess | SaveTitleDescriptionSuccess, DesignSet]) => {
      const actions: Observable<
        | UpdateDesignColors
        | UpdateDesignImages
        | UpdateFoilPermissionWasChanged
        | UpdateSpotUvForJpgPermissionWasChanged
      >[] = designSet.designs.flatMap(design => {
        const designData = this.outputService.getDesignData(design, true);
        return [
          of(
            new UpdateDesignImages(
              design.setId,
              designData.design_images.map(i => new CategoryImage(i.name, i.sid, i.url))
            )
          ),
          of(
            new UpdateDesignColors(
              design.setId,
              designData.design_colors.map(c => new Color(c.name, c.color_value))
            )
          )
        ];
      });

      actions.push(of(new UpdateFoilPermissionWasChanged(this.outputService.enableFoilableByDefault)));
      actions.push(of(new UpdateSpotUvForJpgPermissionWasChanged(this.outputService.spotUvPermissionForJpgByDefault)));

      return actions;
    }),
    mergeMap(obs => obs)
  );

  @Effect()
  submitSaveInCollectionOtherUser$ = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SubmitSaveDesignOtherUser),
    switchMap((action: SubmitSaveDesignOtherUser) =>
      this.saveService.saveDesignInCollectionOtherUser(action.payload).pipe(
        map(() => new SubmitDesignOtherUserSuccess()),
        catchError(error => of(new SubmitDesignOtherUserFailure(error.error)))
      )
    )
  );

  @Effect({ dispatch: false })
  submitInCollectionOtherUserSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(AdminDesignerActionTypes.SubmitDesignOtherUserSuccess),
    tap(() => {
      this.snackBar.open(this.getTextService.text.toolBar.save.snackbar.designSavedInCustomerCollection, '', {
        panelClass: 'save-snack-bar',
        duration: 1000,
        horizontalPosition: 'left',
        verticalPosition: 'top'
      });
      this.dialog.closeAll();
    })
  );

  constructor(
    private saveService: SaveService,
    private getTextService: GetTextService,
    private outputService: OutputService,
    private dialog: MatDialog,
    private actions$: Actions,
    public snackBar: MatSnackBar
  ) {}
}
