import { EditorAction } from '../action';
import { CHANGE_BACKGROUND_IMAGE } from './canvas.action.types';

export class ChangeBackgroundImage implements EditorAction {
  readonly type = CHANGE_BACKGROUND_IMAGE;
  readonly undoable = true;
  readonly save = true;

  constructor(public width: number, public height: number, public sid?: string, public url?: string) {}
}
