import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ed-coating',
  template: '<div></div>',
  styleUrls: ['coating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoatingComponent {
  @Input() @HostBinding('style.margin-left.px') x: number;
  @Input() @HostBinding('style.margin-top.px') y: number;
  @Input() @HostBinding('style.width.px') width: number;
  @Input() @HostBinding('style.height.px') height: number;
  @Input() rotation: number;

  @HostBinding('style.-ms-transform')
  @HostBinding('style.-webkit-transform')
  @HostBinding('style.transform')
  get transform() {
    return `rotate(${this.rotation}deg)`;
  }
}
