import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CanvasElement, Design } from '../../models';
import { GetTextService } from '../../services';

@Component({
  selector: 'ed-element-settings-menu',
  templateUrl: 'element-settings-menu.component.html',
  styleUrls: ['element-settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementSettingsMenuComponent {
  @Input() selectedElement: CanvasElement;
  @Input() design: Design;
  @Input() showElementSettings: boolean;
  @Input() menuWidth: string;
  @Output() closeMenu = new EventEmitter();

  constructor(public getTextService: GetTextService) {}

  closeElementSettingsMenu() {
    this.closeMenu.emit();
  }
}
