import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Directive({
  selector: '[xcMediaIf]'
})
export class MediaIfDirective implements OnDestroy {
  private hasView = false;
  private matcher = new Subject<Media>();

  // TODO: Replace hard coded media queries with media queries from config or css variables
  private bootstrapBreakpoints = [
    { media: 'down-lg', breakpoint: '(max-width: 991.92px)' },
    { media: 'up-lg', breakpoint: '(min-width: 992px)' }
  ];

  private subscription = this.matcher
    .pipe(
      map(value => this.bootstrapBreakpoints.find(breakpoint => breakpoint.media === value).breakpoint),
      switchMap(breakpoint => {
        return this.breakpointObserver.observe(breakpoint);
      })
    )
    .subscribe(result => (result.matches ? this.createView() : this.destroyView()));

  @Input()
  set xcMediaIf(value: Media) {
    this.matcher.next(value);
  }

  constructor(
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public breakpointObserver: BreakpointObserver,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.template);
      this.changeRef.markForCheck();
      this.hasView = true;
    }
  }

  private destroyView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

/*export enum Media {
  upLg = 'up-lg',
  downLg = 'down-lg'
}*/

export type Media = 'up-lg' | 'down-lg';
