import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducer';
import { SharedModule } from '../shared/shared.module';
import { SaveDialogComponent } from './save-dialog.component';
import { SaveEffects } from './save.effects';
import { SaveService } from './save.service';
import { TransformService } from './transform.service';
import { CopyDialogComponent } from './copy-dialog.component';

@NgModule({
  imports: [SharedModule, StoreModule.forFeature('save', reducer), EffectsModule.forFeature([SaveEffects])],
  declarations: [SaveDialogComponent, CopyDialogComponent],
  providers: [SaveService, TransformService]
})
export class SaveModule {}
