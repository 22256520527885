import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Font, FontStatus } from '../../models';
import { ConfigService } from '../../services';

@Component({
  selector: 'ed-font-list',
  templateUrl: 'font-list.component.html',
  styleUrls: ['font-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * font list shows a lists of fonts from the image backend
 */
export class FontListComponent {
  @Input() allFontsText = '';
  @Input() usedFontsText = '';
  @Input() dataLayerIdPrefix: string;
  @Input() items: Font[];
  @Input() designFonts: Font[];

  @Input() useSampleImages = true;
  @Input() fontItemHeight = 33;
  @Input() selectedItemName: string;

  @Input() shortEditing: boolean;
  @Output() selectedItem = new EventEmitter<Font>();

  fontStatus = FontStatus;

  constructor(private config: ConfigService) {}

  onSelect(item: Font): void {
    if (item.name !== this.selectedItemName) {
      this.selectedItem.emit(item);
      this.selectedItemName = item.name;
    }
  }

  getImageSrc(font: Font) {
    return this.config.imgBase + font.imageSampleSource;
  }
}
