export const INIT = '[Canvas] Init';
export const SELECT = 'SELECT';
export const TRANSLATE = 'TRANSLATE';
export const RESIZE = 'RESIZE';
export const CROP = 'CROP';
export const TOGGLE_PAGE = 'TOGGLE_PAGE';
export const ROTATE = 'ROTATE';
export const ROTATE_PAGE = 'ROTATE_PAGE';
export const REMOVE_ELEMENT = 'REMOVE_ELEMENT';
export const CHANGE_FONT = 'CHANGE_FONT';
export const CHANGE_FONTSIZE = 'CHANGE_FONTSIZE';
export const CHANGE_TEXT = 'CHANGE_TEXT';
export const CHANGE_TEXT_INLINE = 'CHANGE_TEXT_INLINE';
export const RESIZE_TEXT_INLINE = 'RESIZE_TEXT_INLINE';
export const CHANGE_TEXT_VALUE_INLINE = 'CHANGE_TEXT_VALUE_INLINE';
export const ALIGN_TEXT = 'ALIGN_TEXT';
export const HIGHLIGHT_TEXTAREA = 'HIGHLIGHT_TEXTAREA';
export const ENLARGE_TEXTAREA = 'ENLARGE_TEXTAREA';
export const MOVE_STEP_FORWARD = 'MOVE_STEP_FORWARD';
export const MOVE_STEP_BACKWARD = 'MOVE_STEP_BACKWARD';
export const MOVE_TO_FRONT = 'MOVE_TO_FRONT';
export const MOVE_TO_BACK = 'MOVE_TO_BACK';
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_IMAGE_AS_PHOTOFRAME = 'ADD_IMAGE_AS_PHOTOFRAME';
export const ADD_TEXT = 'ADD_TEXT';
export const CHANGE_BACKGROUND_COLOR = 'CHANGE_BACKGROUND_COLOR';
export const CHANGE_COLOR = 'CHANGE_COLOR';
export const ADD_BACKGROUND_IMAGE = 'ADD_BACKGROUND_IMAGE';
export const CHANGE_BACKGROUND_IMAGE = 'CHANGE_BACKGROUND_IMAGE';
export const PASTE = 'PASTE';
export const CHANGE_TRANSPARENCY = 'CHANGE_TRANSPARENCY';
export const FLIP_HORIZONTAL = 'FLIP_HORIZONTAL';
export const FLIP_VERTICAL = 'FLIP_VERTICAL';
export const DESELECT = 'DESELECT';
export const TOGGLE_GRID = 'TOGGLE_GRID';
export const ACTIVATE_OVERLAY = 'ACTIVATE_OVERLAY';
export const DE_ACTIVATE_OVERLAY = 'DE_ACTIVATE_OVERLAY';
export const FILL_VERTICAL = 'FILL_VERTICAL';
export const FILL_HORIZONTAL = 'FILL_HORIZONTAL';
export const FILL_PAGE = 'FILL_PAGE';
export const INIT_DESIGN = 'INIT_DESIGN';
export const SET_USER_TITLE_AND_ID = 'SET_USER_TITLE_AND_ID';
export const SET_TITLE_DESCRIPTION = 'SET_TITLE_DESCRIPTION';
export const RESET_IMAGE_COLOR = 'RESET_IMAGE_COLOR';
export const ADD_CUSTOM_COLOR = 'ADD_CUSTOM_COLOR';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const TOGGLE_CROPPING_MODE = 'TOGGLE_CROPPING_MODE';
export const REPLACE_IMAGE = 'REPLACE_IMAGE';
export const ADD_BOX = 'ADD_BOX';
export const CHECK_SPECIAL_COLOR = 'CHECK_SPECIAL_COLOR';
export const UPDATE_ALL_SPECIAL_COLOR_ELEMENTS = 'UPDATE_ALL_SPECIAL_COLOR_ELEMENTS';
export const RESET_ALL_SPOT_UV_ELEMENTS = 'RESET_ALL_SPOT_UV_ELEMENTS';
export const OPEN_MULTIPLE_SPECIAL_COLORS_DIALOG = 'OPEN_MULTIPLE_SPECIAL_COLORS_DIALOG';
export const OPEN_SET_FOIL_SAFETY_DIALOG = 'OPEN_SET_FOIL_SAFETY_DIALOG';
export const TOGGLE_ELEMENT_PERMISSION = 'TOGGLE_ELEMENT_PERMISSION';
export const TOGGLE_ELEMENT_PRESET = 'TOGGLE_ELEMENT_PRESET';
export const TOGGLE_VIEW = 'TOGGLE_VIEW';
export const ADD_TAG = 'ADD_TAG';
export const CHECK_DOUBLE_BACKGROUND_IMAGE = 'CHECK_DOUBLE_BACKGROUND_IMAGE';
export const ADD_TEXT_INLINE = 'ADD_TEXT_INLINE';
export const SET_LABEL = 'SET_LABEL';
export const CONVERT_TO_INLINE_TEXT = 'CONVERT_TO_INLINE_TEXT';
export const SET_INLINE_TEXT_EDIT_MODE = 'SET_INLINE_TEXT_EDIT_MODE';
export const CHANGE_TRIM = 'CHANGE_TRIM';
export const SET_MATERIAL = 'SET_MATERIAL';
export const MOVE_PAGE = 'MOVE_PAGE';
export const INIT_VISIBLE_PAGE_IMAGES = 'INIT_PAGE_IMAGES';
export const UPDATE_DESIGN_IMAGES = 'UPDATE_DESIGN_IMAGES';
export const UPDATE_DESIGN_COLORS = 'UPDATE_DESIGN_COLORS';
export const UPDATE_FOIL_PERMISSION_WAS_CHANGED = 'UPDATE_FOIL_PERMISSION_WAS_CHANGED';
export const UPDATE_SPOTUV_FOR_JPG_PERMISSION_WAS_CHANGED = 'UPDATE_SPOTUV_FOR_JPG_PERMISSION_WAS_CHANGED';
export const TOGGLE_TARGETABLE_WHEN_TARGETABLE = 'TOGGLE_TARGETABLE_WHEN_TARGETABLE';
export const SET_LAYER_TYPE = 'SET_LAYER_TYPE';
export const SET_CHARACTER_LIMIT = 'SET_CHARACTER_LIMIT';
