import { ActionReducer } from '@ngrx/store';
import { UndoActionTypes, CanvasActionTypes } from '../../actions';
import { Design, Spread, View } from '../../models';
import { cloneDeep, isEqual } from 'lodash-es';
import { CanvasActions, UndoActions } from '../../actions';
import { UPDATE_IMAGE } from '../../actions/canvas-actions/canvas.action.types';
import { DesignSet } from '../../models/design-set';
import { fillPage } from '../../utils/fill.utils';

const MAX_HISTORY_RECORDS = 100;

interface HistoryStateEntry {
  spreads: Spread[];
  view: View;
  designSetId: number;
}

interface HistoryState {
  [setId: string]: HistoryStateEntry[];
}

export function undoMetaReducer(reducer: ActionReducer<DesignSet>): ActionReducer<DesignSet> {
  const previousState: HistoryState = {};
  const futureState: HistoryState = {};
  let previousUndoableState: Design;
  let previousActionUndoable = false;

  let addLastUndoAbleStateToHistoryFunction: () => void;
  let prevActionType: string;
  let addToHistoryTimeout: number;
  let addToHistoryFunctionCalledAlready = true;

  return (state: DesignSet, action: CanvasActions.All | UndoActions.All): DesignSet => {
    switch (action.type) {
      case UndoActionTypes.UNDO: {
        // to make sure last undoable state is not in timeout atm
        if (addLastUndoAbleStateToHistoryFunction) {
          addLastUndoAbleStateToHistoryFunction();
        }

        if (previousState[state.activeDesign.setId] && previousState[state.activeDesign.setId].length) {
          previousActionUndoable = true;
          return addState(state, previousState, futureState);
        }

        return state;
      }

      case UndoActionTypes.REDO: {
        // to make sure last undoable state is not in timeout atm
        if (addLastUndoAbleStateToHistoryFunction) {
          addLastUndoAbleStateToHistoryFunction();
        }

        if (futureState[state.activeDesign.setId] && futureState[state.activeDesign.setId].length) {
          previousActionUndoable = true;
          return addState(state, futureState, previousState);
        }

        return state;
      }

      case CanvasActionTypes.INIT_DESIGN: {
        const setId = state.designs.find(d => d.active).setId;
        previousActionUndoable = true;
        previousState[setId] = [];
        futureState[setId] = [];
        addToHistoryFunctionCalledAlready = true;
        return reducer(state, action);
      }

      case CanvasActionTypes.CONVERT_TO_INLINE_TEXT: {
        const setId = state.designs.find(d => d.active).setId;
        previousActionUndoable = true;
        previousState[setId] = [];
        futureState[setId] = [];
        addToHistoryFunctionCalledAlready = true;
        return reducer(state, action);
      }

      case CanvasActionTypes.UPDATE_IMAGE: {
        return reducer(state, action);
      }

      default: {
        if (previousActionUndoable) {
          previousUndoableState = state.activeDesign;
        }

        if (action.undoable && previousUndoableState) {
          // always clear timeout, to create a debounce effect
          clearTimeout(addToHistoryTimeout);

          if (prevActionType !== action.type || addToHistoryFunctionCalledAlready) {
            // if action is a different action then the one before this one
            // and add function is not yet addToHistoryFunctionCalledAlready in timeout, then call function
            if (addLastUndoAbleStateToHistoryFunction && !addToHistoryFunctionCalledAlready) {
              addLastUndoAbleStateToHistoryFunction();
            }

            const historyState: HistoryStateEntry = {
              spreads: updateParent(cloneDeep(previousUndoableState.spreads), undefined),
              view: previousUndoableState.view,
              designSetId: previousUndoableState.setId
            };

            addToHistoryFunctionCalledAlready = false;

            addLastUndoAbleStateToHistoryFunction = () => {
              // only add to history if not done before
              if (!addToHistoryFunctionCalledAlready) {
                // only add to history if something really changed in the state or there is no history yet
                const activeDesignPrevState = previousState[historyState.designSetId] || [];
                if (
                  !activeDesignPrevState.length ||
                  !isEqual(historyState, activeDesignPrevState[activeDesignPrevState.length - 1])
                ) {
                  if (historyState.spreads[0].pages[0].selectedElement) {
                    historyState.spreads[0].pages[0].selectedElement.deselectAll()
                  }

                  previousState[historyState.designSetId] = [...activeDesignPrevState, historyState].slice(
                    -MAX_HISTORY_RECORDS
                  );
                }

                // this function should only be addToHistoryFunctionCalledAlready once every action
                // (after timeout or when there is a differnt action)
                addToHistoryFunctionCalledAlready = true;
              }
            };
          }

          // set a timeout, so when same action is called multiple times after each other rapidly, it's only once added to history
          addToHistoryTimeout = setTimeout(addLastUndoAbleStateToHistoryFunction, 300);
          prevActionType = action.type;
        }

        return reducer(state, action);
      }
    }
  };
}

function updateParent(spreads: Spread[], parent: Design | undefined) {
  spreads.forEach(spread => {
    spread.spreadPage.parent = parent;
    spread.pages.forEach(page => (page.parent = parent));
  });
  return spreads;
}

function addState(set: DesignSet, popFromState: HistoryState, addToState: HistoryState) {
  const newSet = cloneDeep(set);
  newSet.activeDesign.spreads[0].pages[0].deselectAll()

  const activeDesign = newSet.activeDesign;

  addToState[activeDesign.setId] = [
    ...(addToState[activeDesign.setId] || []),
    {
      spreads: updateParent(activeDesign.spreads, undefined),
      view: activeDesign.view,
      designSetId: activeDesign.setId
    }
  ];

  const newDesign = popFromState[activeDesign.setId].pop();
  activeDesign.spreads = newDesign.spreads;
  activeDesign.view = newDesign.view;

  updateParent(activeDesign.spreads, activeDesign);

  activeDesign.updatePages();

  newSet.designs = [...newSet.designs.filter(d => activeDesign.setId !== d.setId), activeDesign].sort(
    (a, b) => a.setId - b.setId
  );

  fillPage(newSet, newSet.visiblePage);
  return newSet;
}
