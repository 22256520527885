import { EditorAction } from '../action';
import { UPDATE_IMAGE } from './canvas.action.types';
import { ImageElement, InlineTextElement, TextElement } from '../../models';

export class UpdateImageAction implements EditorAction {
  readonly type = UPDATE_IMAGE;
  readonly undoable = false;
  readonly save = false;

  constructor(
    public route: Array<number>,
    public element: ImageElement | TextElement | InlineTextElement,
    public imgSource: string,
    public isVectorImage: boolean
  ) {}
}
