import { SVG_VIEWBOX_SCALE } from "react-text-editor/models/text-editor.model";
import { InlineTextElementTextAlign } from "../models/inline-text-element-text-align";
import { TextboxTextAlign } from "../models/textbox-text-align";

const InlineTextElementTextAlignMap = new Map<InlineTextElementTextAlign, TextboxTextAlign>([
  ["start", "left"],
  ["middle", "center"],
  ["end", "right"]
]);

export const InlineTextElementUtils = new (class {
  getTextboxTextAlign(textAlign: InlineTextElementTextAlign, isJustified: boolean) {
    if (isJustified) {
      return "justify-left";
    }
    return InlineTextElementTextAlignMap.get(textAlign);
  }

  getTextboxFontSize(fontSize: number) {
    return fontSize / SVG_VIEWBOX_SCALE;
  }

  getTextboxFontWeight(bold: boolean) {
    if (bold) {
      return 'bold';
    }
    return 'normal';
  }

  getTextboxFontStyle(italic: boolean) {
    if (italic) {
      return 'italic';
    }
    return 'normal';
  }
});
