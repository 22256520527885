import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { TextState } from '../models/text';

@Injectable()
export class GetTextService {
  text: TextState;

  constructor(private store: Store<AppState>) {
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.text = config.text;
    });
  }
}
