import { fabric } from 'fabric';
import { isSmallObject } from '../../utils/object.utils';

const size = 40;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="930" height="440" viewBox="0 0 93 44" fill="none">
    <rect x="6" y="4" width="81" height="32" rx="16" fill="white" shape-rendering="geometricPrecision" stroke="#0008" stroke-width="1" stroke-opacity="0.7" />
    <rect x="46" y="10" width="1" height="20" fill="#CCCCCC" />
  </svg>
`);

const zoomInner = new fabric.Control({
  actionName: 'zoomInner',
  x: 0,
  y: .5,
  offsetX: -20,
  offsetY: 30,
  cursorStyle: 'pointer',
  visible: false,
  getVisibility,
  render,
});

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isSmallObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size * 2, size);
  ctx.restore();
}

fabric.Object.prototype.controls.zoomInner = zoomInner;
