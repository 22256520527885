import { EditorAction } from '../action';
import { SET_TITLE_DESCRIPTION } from './canvas.action.types';

export class SetTitleDescription implements EditorAction {
  readonly type = SET_TITLE_DESCRIPTION;
  readonly undoable = false;
  readonly save = false;

  constructor(public title: string, public description: string, public id: string, public isProposed = false) {}
}
