import { fabric } from "fabric";
import { BackgroundElement, BaseElement, BoxElement } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/box-element" {
  interface BoxElement { }
  interface BackgroundElement { }
}

BoxElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: BoxElement = this;

  if (!(element instanceof BoxElement)) {
    return null;
  }

  object ??= new fabric.Rect();

  if (!(object instanceof fabric.Rect)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

BoxElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: BoxElement = this;

  if (!(element instanceof BoxElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    opacity: 1e-323,
    lockMovementX: !element.permissions.isMovable,
    lockMovementY: !element.permissions.isMovable
  };
}

BoxElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: BoxElement = this;

  if (!(element instanceof BoxElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
    "tl": !!element.permissions.isResizable,
    "tr": !!element.permissions.isResizable,
    "br": !!element.permissions.isResizable,
    "bl": !!element.permissions.isResizable,
    "ml": !!element.permissions.isCroppable,
    "mr": !!element.permissions.isCroppable,
    "mt": !!element.permissions.isCroppable,
    "mb": !!element.permissions.isCroppable,
    "mtr": !!element.permissions.isRotatable,
    "remove": !!element.permissions.isRemovable,
  };
}

BackgroundElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: BackgroundElement = this;

  if (!(element instanceof BackgroundElement)) {
    return null;
  }

  object ??= new fabric.Rect();

  if (!(object instanceof fabric.Rect)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

BackgroundElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: BackgroundElement = this;

  if (!(element instanceof BackgroundElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    hoverCursor: "pointer",
    fill: element.color,
    hasBorders: false,
    lockMovementX: true,
    lockMovementY: true
  };
}

BackgroundElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: BackgroundElement = this;

  if (!(element instanceof BackgroundElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
  };
}
