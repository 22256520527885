import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToolbarComponent } from './toolbar.component';

@Component({
  selector: 'ed-toolbar-mob',
  templateUrl: 'toolbar-mob.component.html',
  styleUrls: ['toolbar-mob.component.scss', 'toolbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarMobComponent extends ToolbarComponent {}
