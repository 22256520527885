import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { AppState } from '../../reducers';
import * as Continue from '../../continue/actions';
import { GetTextService } from '../../services';
import { DesignSet } from '../../models/design-set';
import { Design } from '../../models';

@Component({
  selector: 'ed-nav',
  templateUrl: 'nav.component.html',
  styleUrls: ['nav.component.scss', 'nav-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit {
  continueUrl: string;
  closeUrl: string;
  dataLayerIdPrefix = 'nav-';

  @Input() designSet: DesignSet;

  constructor(public store: Store<AppState>, public getTextService: GetTextService) {}

  ngOnInit() {
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.continueUrl = config.continueUrl;
      this.closeUrl = config.closeUrl;
    });
  }
  goToUrl(url: string, retainParams = false) {
    this.store.dispatch(new Continue.Navigate(url, retainParams));
  }

  itemTrackBy(index: number, item: Design) {
    return item.setId;
  }
}
