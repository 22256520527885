import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AppState } from '../../../reducers';
import { CanvasActions } from '../../../actions';
import { ImageCategory } from '../../../models';
import { ImageData } from '../../../services/image-upload.service';
import { getAddImageAsPhotoFrame } from '../../../reducers/permissions.reducer';
import { getMainCategory } from '../../../image-library/reducer';
import { ImageLibraryType } from '../../../image-library/image-library';

@Component({
  selector: 'ed-image',
  templateUrl: 'image.component.html',
  styleUrls: ['image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy {
  mainCategory$: Observable<ImageCategory>;
  addImageAsPhotoFrame: boolean;
  permissionSubscription: Subscription;
  imageLibrarySavedLocation$: Observable<number[]>;

  constructor(protected store: Store<AppState>) {}

  ngOnInit() {
    this.imageLibrarySavedLocation$ = this.store.pipe(select(s => s.imagelibrary.savedLocation));
    this.mainCategory$ = this.store.pipe(select(getMainCategory(ImageLibraryType.image)));
    this.permissionSubscription = this.store
      .pipe(select(getAddImageAsPhotoFrame))
      .subscribe(addImageAsPhotoFrame => (this.addImageAsPhotoFrame = addImageAsPhotoFrame));
  }

  chooseImage(event: any) {
    if (this.addImageAsPhotoFrame) {
      this.store.dispatch(
        new CanvasActions.AddImageAsPhotoFrame(
          event.width,
          event.height,
          event.sid,
          event.url,
          undefined,
          undefined,
          event.isFoilable
        )
      );
    } else {
      this.store.dispatch(
        new CanvasActions.AddImage(
          event.width,
          event.height,
          event.sid,
          event.url,
          undefined,
          undefined,
          event.isFoilable
        )
      );
    }
  }

  uploadImageCallback() {
    return (event: ImageData) => {
      if (this.addImageAsPhotoFrame) {
        this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(event.width, event.height, event.sid));
      } else {
        this.store.dispatch(new CanvasActions.AddImage(event.width, event.height, event.sid));
      }
    };
  }

  ngOnDestroy() {
    this.permissionSubscription.unsubscribe();
  }
}
