import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { fakeBackendFactory } from '../app/services/fake-backend.service';
import { Environment } from './environment.interface';
import { devPermissions } from './environment.permissions';
import { storeDevToolsModule } from 'src/app/store-dev-tools.module';

export const environment: Environment = {
  production: false,
  providers: [{ provide: HTTP_INTERCEPTORS, useFactory: fakeBackendFactory(), multi: true }],
  serviceWorker: false,
  configFile: '/mock_backend/config',
  demoCanvas: true,
  allowWindowAlerts: false,
  guidelinesTestFeatures: true,
  ngrxRuntimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictStateSerializability: false,
    strictActionSerializability: false,
    strictActionWithinNgZone: true
  },
  appModuleImports: [storeDevToolsModule],
  googleTagManagerEditorID: 'GTM-MOCK-EDITOR',
  permissions: devPermissions
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
