import { PageElement } from './page-element';
import { ElementType } from './canvas';
import { doPolygonsIntersect } from '../utils/intersect.utils';
import { View } from './design';
import { cloneElement } from '../utils/element.utils';
import { elementsAreEqual } from '../utils/spreads.utils';

// orientation is rotation of the entire spread
// folds is an array of directions always pages.length - 1,
// and represents the direction in space the page after the fold moves, could be used for 3d rendering
// backSpread is the other side of the spread/paper
export class Spread {
  readonly id: number;
  readonly pages: PageElement[];
  readonly orientation: 0 | 90 | 180 | 270;
  readonly folds: Array<'front' | 'back'> = [];

  private showSpread = false;
  private _backSpread: Spread;
  set backSpread(spread: Spread) {
    // should do some clever assertion spreads match each other
    // folds should mirror (position and direction) same amount of pages
    // backSpread could be used for 3d rendering folded thumbs
    this._backSpread = spread;
    spread._backSpread = this;
  }

  get backSpread() {
    return this._backSpread;
  }

  // 1 page containing the whole spread,
  // when transforming old pages to spreads put them in box elements for now
  // some kind of lines on folds
  spreadPage: PageElement;
  type: View.userSpreads | View.productionSpreads | View.card;

  constructor(
    id: number,
    pages: PageElement[],
    folds: Array<'front' | 'back'> = [],
    label: Array<string> = [],
    orientation: 0 | 90 | 180 | 270 = 0
  ) {
    // could do more validations, like if direction right, height of all pages should be the same
    // and when top width should be the same
    if (pages.length - 1 !== folds.length) {
      throw new Error('folds should always be 1 less then pages, folds: ' + folds.length + ' pages: ' + pages.length);
    }
    this.id = id;
    this.pages = pages;
    this.folds = folds;
    this.orientation = orientation;

    this.spreadPage = new PageElement(id);
    this.spreadPage.label = label;
    this.spreadPage.width = pages.reduce((totalWidth, current) => totalWidth + current.width, 0);
    this.spreadPage.height = pages[0].height;
    this.spreadPage.parent = pages[0].parent;
  }

  pagesToSpread() {
    // for now create a spreadPage here from the pages
    // create page with right dimensions
    if (!this.showSpread) {
      this.showSpread = true;
      this.spreadPage.children = [];

      this.pages.reduce((totalWidth, current) => {
        // add background of old pages to box of page size
        current.children
          .filter(child => child.type === ElementType.background)
          .map(child => {
            const newChild = cloneElement(child);
            newChild.x = child.x + totalWidth;
            this.spreadPage.addElement(newChild, undefined, child.order);
          });
        return totalWidth + current.width;
      }, 0);

      this.pages.reduce((totalWidth, current) => {
        current.children
          .filter(child => child.type !== ElementType.background)
          .map(child => {
            const elementAlreadyExists = this.spreadPage.children.find(spreadChildElement =>
              elementsAreEqual(child, spreadChildElement, child.x + totalWidth)
            );

            if (!elementAlreadyExists) {
              const newChild = cloneElement(child);
              newChild.x = child.x + totalWidth;

              this.spreadPage.addElement(newChild, undefined, child.order);
            }
          });
        return totalWidth + current.width;
      }, 0);
    }
  }

  spreadToPages() {
    if (this.showSpread) {
      this.showSpread = false;
      this.pages.reduce((totalWidth, currentPage, index) => {
        currentPage.children = [];
        const pagePolygon = currentPage.safetyPolygon;
        this.spreadPage.children.map(spreadChildElement => {
          spreadChildElement.x = spreadChildElement.x - totalWidth;
          const elementsOverlap = doPolygonsIntersect(spreadChildElement.polygon, pagePolygon);
          if (elementsOverlap) {
            currentPage.addElement(cloneElement(spreadChildElement), undefined, spreadChildElement.order);
          }
          spreadChildElement.x = spreadChildElement.x + totalWidth;
        });
        return totalWidth + currentPage.width;
      }, 0);
    }
  }
}
