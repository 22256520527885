import { EditorAction } from '../action';
import { TOGGLE_PAGE } from './canvas.action.types';

export class TogglePage implements EditorAction {
  readonly type = TOGGLE_PAGE;
  readonly undoable = true;
  readonly save = false;

  constructor(public route: Array<number>) {}
}
