import { EditorAction } from '../action';
import { OPEN_SET_FOIL_SAFETY_DIALOG } from './canvas.action.types';

export class OpenSetFoilSafetyDialog implements EditorAction {
  readonly type = OPEN_SET_FOIL_SAFETY_DIALOG;
  readonly undoable = false;
  readonly save = false;

  constructor(public text: string) {}
}
