import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { UiActions } from '../../../actions';
import { Color, ImageElement, PhotoFrameElement } from '../../../models';
import { UploadComponent } from '../upload/upload.component';
import { EditImageComponent } from './edit-image.component';

export type editOptionName =
  | 'crop'
  | 'effect'
  | 'fill'
  | 'flippable'
  | 'order'
  | 'recolor'
  | 'replace'
  | 'rotate'
  | 'transparency'
  | 'zoom';

export class EditOption {
  public open = false;
  public enabled = true;

  constructor(public name: string) { }
}

export type EditOptions = { [E in editOptionName]: EditOption };

@Component({
  selector: 'ed-edit-image-mob',
  templateUrl: 'edit-image-mob.component.html',
  styleUrls: ['edit-image-mob.component.scss', '../edit-mob.component.scss']
})
export class EditImageMobComponent extends EditImageComponent {
  editOptions: EditOptions = {
    crop: new EditOption('crop'),
    effect: new EditOption('effect'),
    fill: new EditOption('fill'),
    flippable: new EditOption('flippable'),
    order: new EditOption('order'),
    recolor: new EditOption('recolor'),
    replace: new EditOption('replace'),
    rotate: new EditOption('rotate'),
    transparency: new EditOption('transparency'),
    zoom: new EditOption('zoom')
  };
  buttonCount: number;

  @Input()
  set element(element: ImageElement | PhotoFrameElement) {
    const imageElement = element.firstChild
      ? (element.firstChild as ImageElement)
      : element.isImage()
        ? (element as ImageElement)
        : undefined;

    this.editOptions.crop.enabled = element.isPhotoFrame() && element.combinedPermissions.isCroppable;
    this.editOptions.effect.enabled = imageElement.isJpg && element.permissions.isSpotUvable;
    this.editOptions.fill.enabled = element.combinedPermissions.isFillable;
    this.editOptions.flippable.enabled = element.combinedPermissions.isFlippable;
    this.editOptions.order.enabled = element.combinedPermissions.isOrderable;
    this.editOptions.recolor.enabled = element.combinedPermissions.isRecolorable;
    this.editOptions.replace.enabled = element.isPhotoFrame() && element.combinedPermissions.isReplaceable;
    this.editOptions.rotate.enabled = element.combinedPermissions.isRotatable;
    this.editOptions.transparency.enabled = element.combinedPermissions.adjustTransparency && !element.foilType;
    this.editOptions.zoom.enabled = element.combinedPermissions.isCroppable;

    // close all editoptions when selecting another element
    if (!this.element || element.id !== this._element.id) {
      this.closeAllEditOptions();
    }
    this._element = element;
    this.elementColor = new Color('', this.imageElement.color, this.imageElement.foilType, this.imageElement.spotUv);

    this.buttonCount = Object.keys(this.editOptions).filter(option => this.editOptions[option].enabled).length;
  }

  get element() {
    return this._element;
  }

  @Output() displayEditMenuButton: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.hide-edit-menu') hideEditMenu = false;

  openSettingsMenu() {
    this.store.dispatch(new UiActions.ToggleSettingsMenu(true));
  }

  get showAlternativeInputField() {
    return this.configService.showAlternativeInputField;
  }

  get showSetElementsAsFoilable() {
    return this.imageElement.isSvg;
  }

  openEditOption(editOption: EditOption) {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = name === editOption.name));
  }

  closeAllEditOptions() {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = false));
  }

  goOneLevelUp() {
    this.element.inCroppingMode ? this.toggleCroppingMode() : this.closeAllEditOptions();
  }

  clickUploadButton(uploadComponent: UploadComponent) {
    uploadComponent.clickFileInput();
  }

  get optionSelected() {
    return Object.keys(this.editOptions).some(name => this.editOptions[name].open);
  }
}
