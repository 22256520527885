import { EditorAction } from '../action';
import { CHANGE_TRIM } from './canvas.action.types';
import { Trim } from '../../models';

export class ChangeTrim implements EditorAction {
  readonly type = CHANGE_TRIM;
  readonly undoable = true;
  readonly save = true;

  constructor(public trim: Trim) {}
}
