import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { fabric } from 'fabric';
import { ICanvasDimensions } from 'fabric/fabric-impl';
import { cloneDeep, debounce } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { CanvasActions } from 'src/app/actions';
import { ImageLibraryType } from 'src/app/image-library/image-library';
import { CanvasElement, CoatingType, Design, EditorSelection, ElementType, Font, PageElement, View } from 'src/app/models';
import { DesignSet } from 'src/app/models/design-set';
import { AppState } from 'src/app/reducers';
import { getAddImageAsPhotoFrame } from 'src/app/reducers/permissions.reducer';
import { ConfigService, GetTextService, ImageUploadService } from 'src/app/services';
import { ImageData, UPLOAD_URL } from 'src/app/services/image-upload.service';
import { ContextMenuService } from 'src/app/shared/context-menu/context-menu.service';
import { ErrorDialogComponent } from 'src/app/shared/dialogs';
import { NAVBAR_HEIGHT, TOOLBAR_HEIGHT_GT_SM } from 'src/app/shared/layout-constants';
import { fileExtensions } from 'src/app/utils/element.utils';
import { GradientData, gradients } from 'src/app/utils/gradient.utils';
import { AlignGuidelines } from '../alignment-guidelines/aligning';
import * as FabricCanvasActions from "./actions";
import { X_COAT_ROUTE, X_CROP_ROUTE, X_CUT_THROUGH_ROUTE, X_REPLACE_CONTROL_ROUTE, X_ROUTE, X_SPECIAL_COLOR_ROUTE, X_TEMP_EXCLUDE_FROM_CUT_THROUGH, X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR, X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING, X_TEXT_IMAGE_ROUTE } from './fabric/constants/object-keys';
import { IMAGE_REPLACE_SVG } from './fabric/controls/image-replace.control';
import { CanvasIdService } from './services/canvas.service';
import * as CanvasElementUtils from './utils/canvas-element.utils';
import { FontUtils } from './utils/font.utils';
import * as GradientUtils from './utils/gradient.utils';
import { InlineTextElementUtils } from './utils/inline-text-element.utils';
import { calculatePosition, clearObjectTempKeys, onObjectTransformContainObject, onObjectTransformContainWithinObject, onObjectTransform_TransformObject, setTransformMatrix } from './utils/object-event.utils';
import { getAngle, setPositionAfterZooming } from './utils/object.utils';
import { TextboxUtils } from './utils/textbox.utils';

@Component({
  selector: 'ed-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  providers: [ImageUploadService]
})
export class CanvasComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('downLg', { static: false })
  downLg: ElementRef;

  @ViewChild('canvasContainer', { static: false })
  canvasContainer: ElementRef;

  private canvasId: string;

  @Input()
  isMainCanvas: boolean;

  @Input()
  isBehindMainCanvas: boolean;

  canvas: fabric.Canvas;

  private _canvasDimensions: ICanvasDimensions;

  @Input()
  set canvasDimensions(canvasDimensions: ICanvasDimensions) {
    this._canvasDimensions = canvasDimensions;

    if (this.canvas) {
      this.canvas.setDimensions(canvasDimensions);

      this.zoomCanvasToFitAfterRenderPage = true;
      this.zoomCanvasToFit();
    }
  }

  get canvasDimensions(): ICanvasDimensions {
    return this._canvasDimensions
  }

  init: boolean;

  private renderAbortController: AbortController;

  zoomCanvasToFitAfterRenderPage: boolean;

  private addImageAsPhotoFrame: boolean;

  private fontLibrary: Font[] = [];

  protected readonly unsubscribe$ = new Subject<void>();

  cutThroughBehindPages: PageElement[] = [];

  private keysPressed: [] = [];

  private alignGuidelines: AlignGuidelines;

  private keydownListener: (event: KeyboardEvent) => void;
  private keyupListener: (event: KeyboardEvent) => void;

  @Input()
  designSet: DesignSet;

  @Input()
  design: Design;

  private _page: PageElement;

  @Input()
  set page(page: PageElement) {
    let isViewChanged = this.page?.parent?.view !== page?.parent?.view;
    let isRouteChanged = this.page?.route?.toString() !== page?.route?.toString();

    this._page = page;
    this.cutThroughBehindPages = this.getCutThroughBehindPages(page);

    if (this.canvas) {
      if (isViewChanged || isRouteChanged) {
        this.canvas.clear();
        this.zoomCanvasToFitAfterRenderPage = true;
      } else if (!this.isMainCanvas && !this.isBehindMainCanvas) {
        this.zoomCanvasToFitAfterRenderPage = true;
      }

      this.debounceRenderPageAsync(page);
    }
  }

  get page(): PageElement {
    return this._page;
  }

  @Input()
  set zoom(zoom: number) {
    this.zoomCanvasToCenter(zoom);
  }

  get zoom(): number {
    return this.canvas.getZoom();
  }

  @Output()
  zoomChange = new EventEmitter<number>();

  get zoomToFit() {
    let pageWidth = this.page.visiblePageBoundingBox.width,
      pageHeight = this.page.visiblePageBoundingBox.height,
      marginLeft = this.designSet.marginLeft,
      marginRight = this.designSet.marginTop;

    if (!this.isMainCanvas && !this.isBehindMainCanvas) {
      pageWidth = this.page.width;
      pageHeight = this.page.height;
      marginLeft = 0;
      marginRight = 0;
    }

    return Math.min(
      (this.canvas.width - marginLeft * 2) / pageWidth,
      (this.canvas.height - marginRight * 2) / pageHeight,
      this.designSet.pixelsPerMm * 1.5
    );
  }

  @Input()
  set viewportTransform(viewportTransform: number[]) {
    if (this.canvas) {
      this.canvas.setViewportTransform(viewportTransform);
      this.viewportTransformChange.emit(viewportTransform);

      this.canvas.calcViewportBoundaries();
      this.canvas.requestRenderAll();
    }
  }

  get viewportTransform() {
    return this.canvas.viewportTransform;
  }

  @Output()
  viewportTransformChange = new EventEmitter<number[]>();

  get viewportBoundaries() {
    return this.canvas.calcViewportBoundaries();
  }

  get pageRelativeWidth(): number {
    return this.page.width * this.zoom;
  }

  get pageRelativeHeight(): number {
    return this.page.height * this.zoom;
  }

  get pageRelativeLeft(): number {
    return (-(this.viewportBoundaries?.tl?.x ?? 0) + (this.canvas.width - this.page.width) * 0.5) * this.zoom;
  }

  get pageRelativeTop(): number {
    return (-(this.viewportBoundaries?.tl?.y ?? 0) + (this.canvas.height - this.page.height) * 0.5) * this.zoom;
  }

  get pageRelativeRight(): number {
    return (-(this.viewportBoundaries?.tl?.x ?? 0) + (this.canvas.width + this.page.width) * 0.5) * this.zoom;
  }

  get pageRelativeBottom(): number {
    return (-(this.viewportBoundaries?.tl?.y ?? 0) + (this.canvas.height + this.page.height) * 0.5) * this.zoom;
  }

  get pageRelativeCenterX(): number {
    return (-(this.viewportBoundaries?.tl?.x ?? 0) + this.canvas.width * 0.5) * this.zoom;
  }

  get pageRelativeCenterY(): number {
    return (-(this.viewportBoundaries?.tl?.y ?? 0) + this.canvas.height * 0.5) * this.zoom;
  }

  get pageInvertedRelativeRight(): number {
    return ((this.viewportBoundaries?.br?.x ?? 0) - (this.canvas.width + this.page.width) * 0.5) * this.zoom;
  }

  get pageInvertedRelativeBottom(): number {
    return ((this.viewportBoundaries?.br?.y ?? 0) - (this.canvas.height + this.page.height) * 0.5) * this.zoom;
  }

  get gridCellSize(): number {
    return this.configService.gridSize;
  }

  get rainbowBackgroundUrl(): string {
    return this.configService.rainbowBackgroundUrl;
  }

  @Output()
  objectModifying = new EventEmitter<fabric.Object>();

  @Output()
  objectModified = new EventEmitter<fabric.Object>();

  constructor(
    private readonly canvasIdService: CanvasIdService,
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly contextMenuService: ContextMenuService,
    private readonly imageUploadService: ImageUploadService,
    private readonly getTextService: GetTextService,
    private readonly dialog: MatDialog,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly configService: ConfigService
  ) {
    this.setupPermissionSubscriptions();
    this.setupFontLibrarySubscriptions();
    this.setupFabricCanvasActionSubscriptions();

    this.keydownListener = (event: KeyboardEvent) => this.onCanvasKeyDown(event);
    this.keyupListener = (event: KeyboardEvent) => this.onCanvasKeyUp(event);

    this.objectModifying.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((object) => this.handleOnObjectModifying(object));

    // Prevent default context menu when right click on object
    const body = this.document.getElementsByTagName('body')?.[0];
    body?.addEventListener('contextmenu', (e: any) => e.srcElement.className.includes("context-menu-dialog") && e.preventDefault());
  }

  ngOnInit(): void {
    this.canvasId = this.canvasIdService.generate();
  }

  ngAfterViewInit(): void {
    // Setup canvas
    let canvasElement = document.createElement('canvas');
    canvasElement.id = this.canvasId;

    let nativeCanvasContainer = this.canvasContainer.nativeElement;
    nativeCanvasContainer.append(canvasElement);

    let canvas = new fabric.Canvas(canvasElement.id, {
      renderOnAddRemove: false,
      altActionKey: "none",
      uniScaleKey: "none",
      selection: false,
      preserveObjectStacking: true,
      stopContextMenu: true,
      fireRightClick: true,
    });

    if (this.isMainCanvas) {
      this.setupCanvasAlignGuidelines(canvas);

      this.setupCanvasTextEvents(canvas);
      this.setupCanvasObjectEvents(canvas);
      this.setupCanvasObjectCustomEvents(canvas);

      this.setupCanvasSelection(canvas);

      if (this.downLg) {
        this.addCanvasTouchEventListeners(canvas);
        this.setupOnCanvasTouchEventClick(canvas);
        this.setupOnCanvasTouchEventPinchPan(canvas);
      } else {
        this.setupOnCanvasMouseEventClick(canvas);
        this.setupOnCanvasMouseEventZoom(canvas);
        this.setupOnCanvasMouseEventDrag(canvas);

        this.setupOnCanvasMouseEventRenderBorders(canvas);
        this.setupOnCanvasMouseEventRenderReplaceControl(canvas);
        this.setupOnCanvasMouseEventUpsertImage(canvas);
      }

      this.setupDocumentListeners(document);
    }

    // Init canvas
    this.canvas = canvas;
    this.init = true;

    // Trigger canvas update
    this.canvasDimensions = this.canvasDimensions;
    this.page = this.page;
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.keydownListener);
    document.removeEventListener('keyup', this.keyupListener);

    this.removeCanvasTouchEventListeners(this.canvas);
    this.canvasIdService.remove(this.canvasId);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //#region Setup subscriptions

  private setupPermissionSubscriptions() {
    this.store.pipe(
      select(getAddImageAsPhotoFrame),
      takeUntil(this.unsubscribe$),
    ).subscribe((addImageAsPhotoFrame) => {
      this.addImageAsPhotoFrame = addImageAsPhotoFrame;
    });
  }

  private setupFontLibrarySubscriptions() {
    this.store.pipe(
      select(s => s.fontlibrary.fontlibrary),
      takeUntil(this.unsubscribe$),
    ).subscribe((fontLibrary) => {
      this.fontLibrary = fontLibrary;

      if (this.page) {
        this.debounceRenderPageOnFontLoadAsync(this.page)
      }
    });
  }

  private setupFabricCanvasActionSubscriptions() {
    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_FAMILY),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontFamily }: FabricCanvasActions.ChangeTextboxFontFamily) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      let element = this.findElement(object[X_ROUTE]);
      if (!(element.isInlineText())) {
        return;
      }

      this.removeSpecialColorObject(object[X_ROUTE]);

      object.data.element.text[0].lines[0].textSpans[0].font = fontFamily;

      object.set({ fontFamily: FontUtils.getFontFamily(object.data.fontLibrary, fontFamily) });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_SIZE),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontSize }: FabricCanvasActions.ChangeTextboxFontSize) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ fontSize });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_STYLE),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontStyle }: FabricCanvasActions.ChangeTextboxFontStyle) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ fontStyle });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_FONT_WEIGHT),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, fontWeight }: FabricCanvasActions.ChangeTextboxFontWeight) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ fontWeight });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_LINE_HEIGHT),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, lineHeight }: FabricCanvasActions.ChangeTextboxLineHeight) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ lineHeight });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_TEXT_ALIGN),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, textAlign }: FabricCanvasActions.ChangeTextboxTextAlign) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ textAlign });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_TEXTBOX_UNDERLINE),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, underline }: FabricCanvasActions.ChangeTextboxUnderline) => {
      let object = this.findObject(route);
      if (!(object instanceof fabric.Textbox)) {
        return;
      }

      object.set({ underline });
      this.onCanvasTextChanged({ target: object });
    });

    this.actions.pipe(
      ofType(FabricCanvasActions.types.CHANGE_PHOTOFRAME_ZOOM),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ route, zoomLevel }: FabricCanvasActions.ChangePhotoFrameZoom) => {
      let zoomScale: number;

      let object = this.findObject(route);

      if (!object)
        return;

      let element = this.findElement(route);

      if (!element.isPhotoFrame())
        return;

      let childElement = element.firstChild;

      zoomLevel = Math.min(Math.max(zoomLevel, childElement.minZoom), childElement.maxZoom)

      if (childElement.isImage())
        zoomScale = zoomLevel / childElement.zoomLevel

      if (!zoomScale || zoomScale === 1)
        return;

      //@ts-expect-error
      this.onObjectZoomInner({ transform: { target: object, 'zoomScale': zoomScale } });
    });
  }

  private handleOnObjectModifying(object: fabric.Object) {
    this.setObjectDirty(object);
    this.setObjectDirty(this.findSpecialColorObject(object[X_ROUTE], "rect"));
    this.setObjectDirty(this.findSpecialColorObject(object[X_ROUTE], "image"));
  }

  private setObjectDirty(object: fabric.Object) {
    if (!object || object.dirty) return;

    object.set({ dirty: true });
    this.setObjectDirty(object.clipPath);

    if (object instanceof fabric.Group) {
      object.getObjects().forEach((o) => this.setObjectDirty(o));
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) return;

    element.children
      .flatMap((e) => [
        this.findObject(e.route),
        this.findTextImageObject(e.route),
      ])
      .filter((o) => o)
      .forEach((o) => this.setObjectDirty(o));
  }

  //#endregion

  //#region Setup (canvas)

  private setupCanvasAlignGuidelines(canvas: fabric.Canvas) {
    this.alignGuidelines = new AlignGuidelines({
      canvas,
      highlightObjectOptions: {
        strokeColor: '#8B3DFF',
      },
      aligningOptions: {
        strokeColor: '#D53AC5',
      },
      ignoreCanvasObjTypes: [
        { key: 'elementType', value: ElementType.image },
        { key: 'elementType', value: ElementType.background },
        { key: 'elementType', value: ElementType.backgroundImage },
      ],
      ignoreSelectedObjTypes: [
        { key: 'elementType', value: ElementType.image }
      ]
    });

    this.alignGuidelines.init();
  }

  private setupCanvasTextEvents(canvas: fabric.Canvas) {
    canvas.on('text:editing:entered', (event: fabric.IEvent<MouseEvent>) => this.onCanvasTextEditingEntered(event));
    canvas.on('text:editing:exited', (event: fabric.IEvent<MouseEvent>) => this.onCanvasTextEditingExited(event));
    canvas.on('text:changed', (event: fabric.IEvent<KeyboardEvent>) => this.onCanvasTextChanged(event));
  }

  private setupCanvasObjectEvents(canvas: fabric.Canvas) {
    canvas.on('object:moving', (event: fabric.IEvent<MouseEvent>) => this.onObjectMoving(event));
    canvas.on('object:rotating', (event: fabric.IEvent<MouseEvent>) => this.onObjectRotating(event));
    canvas.on('object:scaling', (event: fabric.IEvent<MouseEvent>) => this.onObjectScaling(event));
    canvas.on('object:resizing', (event: fabric.IEvent<MouseEvent>) => this.onObjectScaling(event));
    canvas.on('object:removed', (event: fabric.IEvent<MouseEvent>) => this.onCanvasObjectRemoved(event));
    canvas.on('object:modified', (event: fabric.IEvent<MouseEvent>) => {
      this.onObjectModified(event);

      // Clear objects temp keys
      canvas.getObjects().forEach((object) => clearObjectTempKeys(object));
    });
  }

  private setupCanvasObjectCustomEvents(canvas: fabric.Canvas) {
    canvas.on('object:remove', (event: fabric.IEvent<MouseEvent>) => this.onObjectRemove(event));
    canvas.on('object:zoomInnerMinus', (event: fabric.IEvent<MouseEvent>) => this.onObjectZoomInner(event));
    canvas.on('object:zoomInnerPlus', (event: fabric.IEvent<MouseEvent>) => this.onObjectZoomInner(event));
    canvas.on('object:before:imageReplace', (event: fabric.IEvent<MouseEvent>) => this.onObjectBeforeImageReplace(event));
    canvas.on('object:imageReplace', (event: fabric.IEvent<MouseEvent>) => this.onObjectImageReplace(event));
  }

  private setupDocumentListeners(document: Document) {
    document.addEventListener('keydown', this.keydownListener);
    document.addEventListener('keyup', this.keyupListener);
  }

  private setupCanvasSelection(canvas: fabric.Canvas) {
    canvas.on('selection:created', (event: fabric.IEvent<MouseEvent>) => {
      const selectedTarget = event.selected?.[0];
      if (selectedTarget) {
        this.onObjectSelected(Object.assign(event, { target: selectedTarget }));
      }
    });

    canvas.on('selection:updated', (event: fabric.IEvent<MouseEvent>) => {
      const deselectedTarget = event.deselected?.[0];
      if (deselectedTarget) {
        this.onObjectDeselected(Object.assign(event, { target: deselectedTarget }));
      }

      const selectedTarget = event.selected?.[0];
      if (selectedTarget) {
        const route: number[] = selectedTarget?.[X_ROUTE];
        if (!route) return;

        const element = this.findElement(route);
        if (!element || element.isBackground()) return;

        this.onObjectSelected(Object.assign(event, { target: selectedTarget }));
      }
    });

    canvas.on('selection:cleared', async (event: fabric.IEvent<MouseEvent>) => {
      const deselectedTarget = event.deselected?.[0];
      if (deselectedTarget) {
        this.onObjectDeselected(Object.assign(event, { target: deselectedTarget }));
      }
    });
  }

  private setupOnCanvasTouchEventClick(canvas: fabric.Canvas) {
    let touchDownTargetCorner: boolean;
    let touchDownTime: number;

    canvas.on("mouse:down:before", (event: fabric.IEvent<MouseEvent>) => {
      touchDownTime = event.e.timeStamp;

      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      touchDownTargetCorner = !!target?._findTargetCorner(event.pointer);
    });

    canvas.on('mouse:up', (event: fabric.IEvent<MouseEvent>) => {
      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      const touchDuration = event.e.timeStamp - touchDownTime;

      if (canvas.getActiveObject() === target) {
        const element = this.findElement(route);
        if (!element || !element.selected) return;

        switch (event.button) {
          case 1: {
            if (!touchDownTargetCorner && touchDuration < 150) {
              if (target instanceof fabric.Textbox && target.editable) {
                target.enterEditing(event.e);
              } else {
                canvas.discardActiveObject(event.e);
              }
            }
            break;
          }
        }
      } else {
        if (target.selectable && touchDuration < 150) {
          //@ts-expect-error
          canvas.setActiveObject(target, event);
        }
      }

      touchDownTime = undefined;
      touchDownTargetCorner = undefined;
    });
  }

  private setupOnCanvasMouseEventClick(canvas: fabric.Canvas) {
    let mouseDownActiveObject: fabric.Object;
    let mouseDownTargetCorner: boolean;
    let mouseDownTime: number;

    canvas.on("mouse:down:before", (event: fabric.IEvent<MouseEvent>) => {
      mouseDownTime = event.e.timeStamp;

      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      mouseDownActiveObject = canvas.getActiveObject();
      mouseDownTargetCorner = !!target?._findTargetCorner(event.pointer);
    });

    canvas.on('mouse:up', (event: fabric.IEvent<MouseEvent>) => {
      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      const clickDuration = event.e.timeStamp - mouseDownTime;

      const element = this.findElement(route);
      if (!element) return;

      switch (event.button) {
        case 1: {
          if (!element.selected || element.isText() || element.isInlineText()) return;

          if (!mouseDownTargetCorner && target === mouseDownActiveObject && clickDuration < 150) {
            canvas.discardActiveObject(event.e);
          }
          break;
        }
        case 3: {
          if (target.selectable) {
            canvas.setActiveObject(target);
            this.contextMenuService.openContextMenu(event.pointer.x, event.pointer.y, element);
          }
          break;
        }
      }

      mouseDownTargetCorner = undefined;
      mouseDownTime = undefined;
      mouseDownActiveObject = undefined;
    });
  }

  private setupOnCanvasTouchEventPinchPan(canvas: fabric.Canvas) {
    const touchPoints: fabric.Point[] = [];

    let activeObject: fabric.Object;
    let activeObjectOptions: Partial<fabric.IObjectOptions>;

    canvas.on("touch:move", (event: fabric.IEvent<TouchEvent>) => {
      const prevTouchPoints = touchPoints.slice(0);

      switch (event.e.touches.length) {
        case 1: {
          touchPoints[0] = new fabric.Point(event.e.touches[0].clientX, event.e.touches[0].clientY);

          if (
            prevTouchPoints.length &&
            this.zoom > this.zoomToFit &&
            (
              !event.target ||
              event.target !== canvas.getActiveObject() ||
              this.findElement(event.target[X_ROUTE])?.isBackgroundElement()
            )
          ) {
            const viewportTransform = canvas.viewportTransform.slice(0);
            const { x, y } = touchPoints[0].subtract(prevTouchPoints[0]);

            viewportTransform[4] += x;
            viewportTransform[5] += y;

            this.setCanvasViewportTransform(viewportTransform, false);
          }
          break;
        }

        case 2: {
          touchPoints[0] = new fabric.Point(event.e.touches[0].clientX, event.e.touches[0].clientY);
          touchPoints[1] = new fabric.Point(event.e.touches[1].clientX, event.e.touches[1].clientY);

          if (prevTouchPoints.length) {
            if (!activeObject && canvas.getActiveObject()) {
              // Temp store object and object options
              activeObject = canvas.getActiveObject();
              activeObjectOptions = {
                lockMovementX: activeObject.lockMovementX,
                lockMovementY: activeObject.lockMovementY,
              };

              // Disable object movement
              activeObject.set({
                lockMovementX: true,
                lockMovementY: true,
              });
            }

            // Calculate zoom
            const zoomPointBefore = touchPoints[0].midPointFrom(touchPoints[1]), viewportTransform = canvas.viewportTransform.slice(0);
            const zoomDistance = prevTouchPoints[0].distanceFrom(prevTouchPoints[1]) - touchPoints[0].distanceFrom(touchPoints[1]);
            const zoomMin = this.zoomToFit * this.page.minZoom;
            const zoomMax = this.zoomToFit * this.page.maxZoom;
            const zoom = Math.min(Math.max(canvas.getZoom() * .999 ** (((zoomDistance) / (zoomMin * 2))), zoomMin), zoomMax);

            // Set zoom
            viewportTransform[0] = zoom;
            viewportTransform[3] = zoom;

            // Calculate pinch offset
            const zoomPointAfter = fabric.util.transformPoint(fabric.util.transformPoint(zoomPointBefore, fabric.util.invertTransform(this.viewportTransform)), viewportTransform);
            const pinchOffset = zoomPointBefore.subtract(zoomPointAfter);

            // Calculate pan offset
            const prevMidPoint = prevTouchPoints[0].midPointFrom(prevTouchPoints[1]);
            const nextMidPoint = touchPoints[0].midPointFrom(touchPoints[1]);
            const panOffset = nextMidPoint.subtract(prevMidPoint);

            // Set drag
            viewportTransform[4] += pinchOffset.x + panOffset.x;
            viewportTransform[5] += pinchOffset.y + panOffset.y;

            this.setCanvasViewportTransform(viewportTransform, false, false);
          }
          break;
        }
      }
    });

    canvas.on("touch:end", (event: fabric.IEvent<TouchEvent>) => {
      // Restore object options
      activeObject?.set(activeObjectOptions);

      // Clear temp values
      activeObject = null;
      activeObjectOptions = null;
      touchPoints.length = event.e.touches.length;

      if (!touchPoints.length) {
        const viewportTransform = canvas.viewportTransform.slice(0);
        viewportTransform[0] = viewportTransform[3] = Math.max(this.zoom, this.zoomToFit);

        this.setCanvasViewportTransform(viewportTransform);
        this.setCanvasViewportTransform();
      }
    });
  }

  private setupOnCanvasMouseEventZoom(canvas: fabric.Canvas) {
    canvas.on('mouse:wheel', (event: fabric.IEvent<WheelEvent>) => {
      let delta = event.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;

      let zoomPoint = new fabric.Point(event.e.offsetX, event.e.offsetY);
      this.zoomCanvasToPoint(zoomPoint, zoom);

      event.e?.preventDefault();
      event.e?.stopPropagation();
    });
  }

  private setupOnCanvasMouseEventDrag(canvas: fabric.Canvas) {
    const touchPoints: fabric.Point[] = [];

    canvas.on("mouse:move", (event: fabric.IEvent<TouchEvent | MouseEvent>) => {
      if ("MouseEvent" in window && event.e instanceof MouseEvent) {
        const prevTouchPoints = touchPoints.slice(0);

        switch (event.e.buttons) {
          case 1: {
            touchPoints[0] = new fabric.Point(event.e.clientX, event.e.clientY);

            if (
              prevTouchPoints.length &&
              this.zoom > this.zoomToFit &&
              (
                !event.target ||
                this.findElement(event.target[X_ROUTE])?.isBackgroundElement()
              )
            ) {
              const viewportTransform = canvas.viewportTransform.slice(0);
              const { x, y } = touchPoints[0].subtract(prevTouchPoints[0]);

              viewportTransform[4] += x;
              viewportTransform[5] += y;

              this.setCanvasViewportTransform(viewportTransform, false);
            }
            break;
          }
        }
      }
    });

    canvas.on("mouse:up", (event: fabric.IEvent<TouchEvent | MouseEvent>) => {
      if ("MouseEvent" in window && event.e instanceof MouseEvent) {
        touchPoints.length = 0;

        this.setCanvasViewportTransform();
      }
    });
  }

  private setupOnCanvasMouseEventRenderBorders(canvas: fabric.Canvas) {
    const that = this;
    let prevDragoverTarget: fabric.Object;
    let showControlsAfterRenderObjects: fabric.Object[] = [];

    canvas.on('after:render', (e: fabric.IEvent<MouseEvent>) => {
      for (let obj of showControlsAfterRenderObjects) {
        renderBorders(obj);
      }

      showControlsAfterRenderObjects = [];
    });


    canvas.on('mouse:over', (e: fabric.IEvent<MouseEvent>) => {
      if (!e.target) return;

      showControlsAfterRenderObjects.push(e.target);
      renderBorders(e.target);
    });

    canvas.on('mouse:wheel', (e: fabric.IEvent<MouseEvent>) => {
      if (!e.target) return;

      showControlsAfterRenderObjects.push(e.target);
    });

    canvas.on('mouse:out', (e: fabric.IEvent<MouseEvent>) => {
      prevDragoverTarget = undefined;
      showControlsAfterRenderObjects = [];
      canvas.renderTop();
    });

    canvas.on('dragover', (e: fabric.IEvent<MouseEvent>) => {
      let target = e.target;
      if (!target || target !== prevDragoverTarget) {
        canvas.renderTop();
      }

      if (target) {
        let element = this.findElement(target[X_ROUTE]);
        if (element && element.isPhotoFrame() && target !== prevDragoverTarget) {
          renderBorders(e.target);
        }
      }

      prevDragoverTarget = target;
    });

    canvas.on('dragleave', (e: fabric.IEvent<MouseEvent>) => {
      canvas.renderTop();
    });

    function renderBorders(object: fabric.Object | undefined) {
      //If target object is a replace control object, replace it with actual object
      if (object?.[X_REPLACE_CONTROL_ROUTE]) {
        object = that.findObject(object[X_REPLACE_CONTROL_ROUTE]);
      }

      let route: number[] = object?.[X_ROUTE];
      if (!route) return;

      let element = that.findElement(route);
      if (!element || element.selected || element.isBackground() || element.isBackgroundChild) return;

      if (object && object.selectable) {
        // @ts-expect-error
        object._renderControls(canvas.contextTop, { hasControls: false });

        if (element.isBoxChild) {
          let parentObject = that.findObject(element.parent.route);
          // @ts-expect-error
          parentObject._renderControls(canvas.contextTop, { hasControls: false });
        }

        // @ts-expect-error
        canvas.contextTopDirty = true;
      }
    }
  }

  private setupOnCanvasMouseEventRenderReplaceControl(canvas: fabric.Canvas) {
    canvas.on('mouse:over', (e: fabric.IEvent<MouseEvent>) => {
      let target = e.target;
      if (target) {
        this.createReplaceControlObject(target);
      }
    });

    canvas.on('mouse:out', (e: fabric.IEvent<MouseEvent>) => {
      // @ts-expect-error
      let nextTarget = e.nextTarget;
      let target = e.target;

      if (
        !target ||
        !target[X_ROUTE] ||
        !nextTarget ||
        !nextTarget[X_REPLACE_CONTROL_ROUTE] ||
        !(nextTarget[X_REPLACE_CONTROL_ROUTE].toString() === target[X_ROUTE].toString())
      ) {
        this.deleteReplaceControlObjects();
      }
    });

    canvas.on("before:render", (e: fabric.IEvent<MouseEvent>) => {
      canvas.getObjects()
        .filter((o) => o[X_REPLACE_CONTROL_ROUTE])
        .forEach((o) => {
          let size = 36 / this.canvas.getZoom();
          let { nLeft, nTop, angle, width, height, centerPoint } = o.data;
          let rPos = calculatePosition(nLeft - size / 2, nTop - size / 2, angle, centerPoint);

          o.set({
            left: rPos.x,
            top: rPos.y,
            scaleX: size / width,
            scaleY: size / height,
            angle: angle,
          });
          o.setCoords();
        });
    });
  }

  private setupOnCanvasMouseEventUpsertImage(canvas: fabric.Canvas) {
    canvas.on('drop', (e: fabric.IEvent<MouseEvent>) => {
      // @ts-expect-error
      let dataTransfer = e.e?.dataTransfer;
      let serializedData = dataTransfer?.getData('text');
      if (!serializedData) {
        return;
      }

      let data = JSON.parse(serializedData);
      let { sid, url, height, width, isFoilable, isSvg, libraryType, droppable } = data;

      if (droppable !== 'droppable') {
        return;
      }

      e.e.preventDefault();

      switch (libraryType) {
        // Upsert: background image
        case ImageLibraryType.background: {
          let backgroundElement = this.page.children.find((el) => el.isBackground());
          let hasBackgroundImageElement = backgroundElement?.children.some((el) => el.isBackgroundImage());

          if (hasBackgroundImageElement) {
            this.store.dispatch(new CanvasActions.ChangeBackgroundImage(width, height, sid, url));
          } else {
            this.store.dispatch(new CanvasActions.AddBackgroundImage(width, height, sid, url));
          }
          break;
        }

        // Upsert: photoFrame image, image
        case ImageLibraryType.image: {
          let hoverObject = canvas.findTarget(e.e, true);
          let hoverElement = this.findElement(hoverObject?.[X_ROUTE]);

          if (!hoverElement || !(hoverElement.isImage() || hoverElement.isPhotoFrame()) || hoverElement.parent?.isBackground()) {
            let x = this.pageRelativeLeft ? (e.e.clientX - this.pageRelativeLeft) / this.zoom : null;
            let y = this.pageRelativeTop ? (e.e.clientY - (this.pageRelativeTop + NAVBAR_HEIGHT + TOOLBAR_HEIGHT_GT_SM)) / this.zoom : null;

            if (this.addImageAsPhotoFrame) {
              this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(width, height, sid, url, x, y, isFoilable));
            } else {
              this.store.dispatch(new CanvasActions.AddImage(width, height, sid, url, x, y, isFoilable));
            }
          } else {
            if (!isSvg && !!hoverElement.foilType) {
              this.openFoilErrorDialog();
            } else {
              let canBeFoilable = isFoilable || hoverElement.permissions.isFoilable;
              this.store.dispatch(new CanvasActions.ReplaceImage(hoverElement.route, width, height, width, height, sid, url, canBeFoilable));
            }
          }
          break;
        }
      }
    });
  }

  //#endregion

  //#region Replace control (object)

  private findReplaceControlObject(route: number[]) {
    if (!route) return null;
    return this.canvas.getObjects().find((o) => o[X_REPLACE_CONTROL_ROUTE]?.toString() === route.toString());
  }

  private async createReplaceControlObject(object: fabric.Object) {
    if (!this.isMainCanvas) return;

    let route: number[] = object?.[X_ROUTE];
    if (!route) return;

    let element = this.findElement(route);
    if (
      !element ||
      element.selected ||
      !element.isPhotoFrame() ||
      !element.firstChild ||
      element.firstChild.isVectorImage ||
      !element.permissions.isInstantReplaceable
    ) return;

    let existingReplaceControlObject = this.findReplaceControlObject(element.route);
    let replaceControlObject = existingReplaceControlObject ?? await new Promise((resolve: (result: fabric.Object) => void) => {
      fabric.loadSVGFromString(IMAGE_REPLACE_SVG, (results) => {
        let result = new fabric.Group(results, {
          hasBorders: false,
          hasControls: false,
          hoverCursor: "pointer",
          // @ts-expect-error
          activeOn: "up",
          [X_REPLACE_CONTROL_ROUTE]: element.route,
        });

        let objectCenterPoint = object.getCenterPoint();
        let nPos = calculatePosition(objectCenterPoint.x, objectCenterPoint.y, -object.angle, objectCenterPoint);

        result.set({
          data: {
            nLeft: nPos.x,
            nTop: nPos.y,
            width: result.width,
            height: result.height,
            angle: object.angle,
            centerPoint: objectCenterPoint,
          },
        });

        result.on("selected", (event: fabric.IEvent<MouseEvent>) => {
          let nextEvent = Object.assign(cloneDeep(event), { target: object });
          this.onObjectSelected(nextEvent);
          this.onObjectBeforeImageReplace(nextEvent);
        });

        resolve(result);
      });
    });

    let size = 36 / this.canvas.getZoom();
    let { nLeft, nTop, angle, width, height, centerPoint } = replaceControlObject.data;
    let rPos = calculatePosition(nLeft - size / 2, nTop - size / 2, angle, centerPoint);

    replaceControlObject.set({
      left: rPos.x,
      top: rPos.y,
      scaleX: size / width,
      scaleY: size / height,
      angle: angle,
    });
    replaceControlObject.setCoords();

    if (!existingReplaceControlObject) {
      let index = this.canvas.getObjects().length;
      this.canvas.insertAt(replaceControlObject, index, true);
    } else {
      let index = this.canvas.getObjects().length - 1;
      this.canvas.moveTo(replaceControlObject, index);
    }

    this.canvas.requestRenderAll();
  }

  private deleteReplaceControlObjects() {
    let replaceControlObjects = this.canvas.getObjects()
      .filter((o) => {
        let route = o[X_REPLACE_CONTROL_ROUTE];
        if (!route) return false;

        let element = this.findElement(o[X_REPLACE_CONTROL_ROUTE]);
        return !element || !element.permissions.hasInstantReplaceablePlaceholder;
      });

    if (replaceControlObjects.length) {
      this.canvas.remove(...replaceControlObjects);
      this.canvas.requestRenderAll();
    }
  }

  //#endregion

  protected upsertGroupObjects(group: fabric.Group, objects: fabric.Object[]) {
    let existingObjects = group.getObjects();
    for (let object of existingObjects) {
      group.remove(object);
    }

    for (let object of objects) {
      group.addWithUpdate(object);
    }
  }

  private findElement(route: number[]) {
    if (!route) return null;
    return ((this.page?.route?.toString() === route.toString() && this.page) || this.page?.getElement(route.slice(0, -1))) as CanvasElement;
  }

  private findObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_ROUTE]?.toString() === route.toString());
  }

  private findObjectIndex(object: fabric.Object) {
    if (!object) return -1;
    return this.canvas?.getObjects()?.indexOf(object) ?? -1;
  }

  private findCropObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_CROP_ROUTE]?.toString() === route.toString());
  }

  private findCoatObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_COAT_ROUTE]?.toString() === route.toString());
  }

  private findTextImageObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_TEXT_IMAGE_ROUTE]?.toString() === route.toString());
  }

  private removeCropObject(route: number[]) {
    if (!route) {
      return;
    }

    let object = this.findCropObject(route);
    if (object) {
      this.canvas.remove(object);
    }
  }

  private removeCoatObject(route: number[]) {
    if (!route) {
      return;
    }

    let object = this.findCoatObject(route);
    if (object) {
      this.canvas.remove(object);
    }
  }

  private removeTextImageObject(route: number[]) {
    if (!route) {
      return;
    }

    let object = this.findTextImageObject(route);
    if (object) {
      this.canvas.remove(object);
    }
  }

  private hideCropObject(route: number[]) {
    if (!route) {
      return;
    }

    let object = this.findCropObject(route);
    if (object) {
      object.set({
        opacity: 1e-323
      });
    }
  }

  //#region Render

  private debounceRenderPageAsync = debounce(this.renderPageAsync, 100);
  private debounceRenderPageOnFontLoadAsync = debounce((page: PageElement) => {
    fabric.util.clearFabricFontCache();
    this.renderPageAsync(page);
  }, 200);

  private async renderPageAsync(page: PageElement) {
    this.renderAbortController?.abort();
    this.renderAbortController = new AbortController();

    let abortSignal = this.renderAbortController.signal;

    this.removeElementsFromCanvas();
    if (abortSignal.aborted) return;

    let pageIndex = this.findObjectIndex(this.findObject(page.route));
    pageIndex === -1 && (pageIndex = 0);

    await this.addElementToCanvasAsync(page, abortSignal, pageIndex);
    if (abortSignal.aborted) return;

    await this.addPageCutThroughToCanvasAsync(page, abortSignal);
    if (abortSignal.aborted) return;

    await this.addPageSpecialColorToCanvasAsync(page, abortSignal);
    if (abortSignal.aborted) return;

    this.addPageViewSeparatorToCanvas(page);

    if (this.isMainCanvas) {
      this.addPageInstantReplaceControlsToCanvasAsync(page);
      this.addPageGridToCanvas(page);
      this.setPageSelectedElementToCanvas(page);
    }

    if (this.zoomCanvasToFitAfterRenderPage) {
      this.zoomCanvasToFitAfterRenderPage = false;
      this.zoomCanvasToFit();
    }

    this.canvas.requestRenderAll();
  }

  private setPageSelectedElementToCanvas(page: PageElement) {
    const element = page.selectedElement;
    const object = this.findObject(element?.route);

    if (object) {
      this.canvas.setActiveObject(object);
    } else {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        this.canvas.discardActiveObject();
      }
    }
  }

  private async addPageInstantReplaceControlsToCanvasAsync(page: PageElement) {
    for (const element of page.children.filter((el) => !!el.permissions.hasInstantReplaceablePlaceholder)) {
      const object = this.findObject(element.route);
      await this.createReplaceControlObject(object);
    }
  }

  private getCutThroughBehindPages(page: PageElement): PageElement[] {
    let cutThroughElements = page?.children.filter(p => p.isCutThrough || p.isCutThroughInverted);
    if (!cutThroughElements?.length || !page?.pageBehindId) {
      return [];
    }

    let design = page.parent;
    let designPages = design.pages;

    let behindPage = designPages.find(p => p.id == page.pageBehindId);
    return [...this.getCutThroughBehindPages(behindPage), behindPage];
  }

  private async addElementToCanvasAsync(element: CanvasElement, abortSignal?: AbortSignal, index: number = 0) {
    const that = this;

    const existingObject = this.findObject(element.route);
    const object = await this.createObjectAsync(element, existingObject);

    if (!object || abortSignal?.aborted) return -1;

    object.data = { ...object.data, downLg: this.downLg };

    setObjectOptions();
    setObjectSelectionOptions();
    setObjectClipPath();
    setObjectTransformMatrix();

    if (!existingObject) {
      this.canvas.insertAt(object, index++, false);
    } else {
      this.canvas.moveTo(object, index++);
    }

    // Create coat object
    this.createCoatObject(element, object);

    // Create crop objects
    if (element.isBackgroundImage() && element.selected) {
      index++;
      await this.upsertCropObjectAsync(element, object);
    }

    if (element.isPhotoFrameChild && element.parent.selected) {
      index++;
      await this.upsertCropObjectAsync(element, object);
      this.hideCropObject(element.route);
    }

    // Create text image object
    if (element.isInlineText() && !element.selected) {
      index++;
      await this.createTextImageObjectAsync(element, object);
    }

    // Add child elements to canvas
    for (const childElement of element.children) {
      const nextIndex = await this.addElementToCanvasAsync(childElement, abortSignal, index);
      nextIndex !== -1 && (index = nextIndex);
    }

    return index;

    function setObjectOptions() {
      object.set({
        evented: (
          element.isClickable &&
          !(element.permissions.isUntargetable && !that.designSet.untargetableElementsTargetable) &&
          !element.isPhotoFrameChild
        ),
        // @ts-expect-error
        activeOn: that.downLg ? undefined : "down",
        [X_ROUTE]: element.route,
      });

      if (element.isBackgroundElement() && (that.isMainCanvas || that.isBehindMainCanvas)) {
        object.set({
          shadow: new fabric.Shadow({
            color: "#00000080",
            offsetX: 1 / that.zoomToFit,
            offsetY: 1 / that.zoomToFit,
            blur: 3 / that.zoomToFit,
          }),
        });
      }
    }

    function setObjectSelectionOptions() {
      object.set({
        cornerSize: 12,
        transparentCorners: false,
        borderColor: '#8B3DFF',
        borderScaleFactor: 1,
        borderOpacityWhenMoving: 1,
        cornerColor: '#FFF',
        cornerStrokeColor: '#0008',
        cornerStyle: 'circle',
      });

      if (object instanceof fabric.Textbox) {
        object.set({
          editingBorderColor: '#8B3DFF',
        });
      }
    }

    function setObjectClipPath() {
      if (element.isPage()) {
        return;
      }

      let clipPath: fabric.Object;

      if (
        !element.permissions.isVisibleOutsideCropArea &&
        !(element.selected && element.isInlineText()) &&
        !((element.isCutThrough || element.isCutThroughInverted) && element.parent.isPage())
      ) {
        const parentObject = that.findObject(element.parent.route);
        parentObject?.set({ absolutePositioned: true });

        clipPath = parentObject;
      }

      object.set({ clipPath });
    }

    function setObjectTransformMatrix() {
      if (element.isPhotoFrameChild || element.isBoxChild) {
        const parentObject = that.findObject(element.parent.route);
        setTransformMatrix(object, parentObject);
      }
    }
  }

  private async createObjectAsync(element: CanvasElement, object?: fabric.Object) {
    const data = {
      fontLibrary: this.fontLibrary,
    };

    return await element.createObjectAsync(object, data);
  }

  //#endregion

  //#region Cut through

  private removeCutThroughObject(route: number[]) {
    const that = this;

    const element = this.findElement(route);
    if (!element) return;

    const object = this.findObject(route);
    if (!object) return;

    if (!object[X_TEMP_EXCLUDE_FROM_CUT_THROUGH]) {
      object[X_TEMP_EXCLUDE_FROM_CUT_THROUGH] = true;

      if (element.isPhotoFrameChild) {
        object.opacity = element.opacity || 1e-323;
      }

      const pageRoute = route?.slice(route.length - 2);
      const pageObject = this.findObject(pageRoute);
      if (!pageObject) return;

      const cutThroughClipPath = this.findCutThroughObject(route, "clipPath");
      removeObject(cutThroughClipPath);

      const cutThroughClipPathInverted = this.findCutThroughObject(route, "clipPathInverted");
      removeObject(cutThroughClipPathInverted);

      function removeObject(clipPath: fabric.Object) {
        if (!(clipPath instanceof fabric.Group)) {
          return;
        }

        const objectToRemove = that.findCutThroughObject(route);

        if (clipPath.getObjects().includes(objectToRemove)) {
          clipPath.remove(objectToRemove);

          that.setObjectDirty(pageObject);
          pageObject.canvas?.requestRenderAll();
        }
      }
    }
  }

  private findCutThroughObject(route: number[], type: "object" | "clipPath" | "clipPathInverted" = "object") {
    const that = this;

    switch (type) {
      case "object":
        return findCutThroughObject();
      case "clipPath":
        return findCutThroughClipPath();
      case "clipPathInverted":
        return findCutThroughClipPathInverted();
      default:
        return null;
    }

    function findCutThroughClipPath() {
      const pageRoute = route?.slice(route.length - 2);
      const pageObject = that.findObject(pageRoute);

      let result: fabric.Group;

      if (pageObject && pageObject.clipPath instanceof fabric.Group) {
        if (!pageObject.clipPath.inverted) {
          result = pageObject.clipPath;
        }
      }

      return result;
    }

    function findCutThroughClipPathInverted() {
      const pageRoute = route?.slice(route.length - 2);
      const pageObject = that.findObject(pageRoute);

      let result: fabric.Group;

      if (pageObject && pageObject.clipPath instanceof fabric.Group) {
        if (pageObject.clipPath.inverted) {
          result = pageObject.clipPath;
        }

        if (pageObject.clipPath.clipPath instanceof fabric.Group) {
          if (pageObject.clipPath.clipPath.inverted) {
            result = pageObject.clipPath.clipPath;
          }
        }
      }

      return result;
    }

    function findCutThroughObject() {
      let object: fabric.Object;

      object ??= findObject(findCutThroughClipPath());
      object ??= findObject(findCutThroughClipPathInverted());

      return object;

      function findObject(cutThroughClipPath: fabric.Object) {
        if (!(cutThroughClipPath instanceof fabric.Group)) {
          return null;
        }

        return cutThroughClipPath
          .getObjects()
          .flatMap((o) => [o, o.clipPath])
          .filter((o) => o)
          .find((o) => o[X_CUT_THROUGH_ROUTE].toString() === route.toString());
      }
    }
  }

  private async addPageCutThroughToCanvasAsync(pageElement: PageElement, abortSignal: AbortSignal) {
    const that = this;

    const pageObject = this.findObject(pageElement?.route);
    if (!pageObject) return;

    // Create cut through
    const { cutThroughClipPath } = await createCutThroughAsync(false);
    if (abortSignal?.aborted) return;

    const { cutThroughClipPath: cutThroughClipPathInverted } = await createCutThroughAsync(true);
    if (abortSignal?.aborted) return;

    // Remove (existing) cut through from canvas
    const canvasObjects = this.canvas.getObjects();
    const canvasObjectsToRemove = canvasObjects.filter((object) => object[X_CUT_THROUGH_ROUTE]?.toString() === pageElement.route.toString());
    this.canvas.remove(...canvasObjectsToRemove);

    // Add cut through to canvas
    const pageClipPath = cutThroughClipPath?.set({
      clipPath: cutThroughClipPathInverted?.set({
        absolutePositioned: true,
      }),
    }) || cutThroughClipPathInverted?.set({
      absolutePositioned: true,
    });

    if (pageClipPath) {
      const pageIndex = this.findObjectIndex(pageObject);
      const pageClipPathIndex = pageIndex + (!pageObject.clipPath ? 0 : -1);

      this.setObjectDirty(pageClipPath);
      this.canvas.insertAt(pageClipPath, pageClipPathIndex, !!pageObject.clipPath);

      if (pageClipPath.clipPath) {
        this.setObjectDirty(pageClipPath.clipPath);
        this.canvas.insertAt(pageClipPath.clipPath, pageClipPathIndex + (!pageObject.clipPath ? 0 : -1), !!pageObject.clipPath);
      }
    }

    // Set cut through as page clip path
    pageObject.set({
      clipPath: pageClipPath?.set({
        absolutePositioned: true,
      }),
    });

    async function createCutThroughAsync(inverted: boolean) {
      let cutThroughClipPath: fabric.Object;

      const objects: fabric.Object[] = [
        ...await createObjectsAsync(pageElement.getCutThroughElements(!inverted), false),
        ...await createObjectsAsync(pageElement.backSidePage?.getCutThroughElements(!inverted) ?? [], true),
      ];

      if (objects.length) {
        cutThroughClipPath = that.findCutThroughObject(pageElement.route, !inverted ? "clipPath" : "clipPathInverted");
        cutThroughClipPath ??= new fabric.Group([], {
          opacity: 1e-323,
          inverted,
          evented: false,
          // @ts-expect-error
          [X_CUT_THROUGH_ROUTE]: pageElement.route,
        });

        if (cutThroughClipPath instanceof fabric.Group) {
          cutThroughClipPath.remove(...cutThroughClipPath.getObjects());

          for (const object of objects) {
            cutThroughClipPath.addWithUpdate(object);
          }
        }
      }

      return { cutThroughClipPath };
    }

    async function createObjectsAsync(elements: CanvasElement[], backSide: boolean) {
      const objects: fabric.Object[] = [];

      for (const element of elements) {
        const object = await createObjectAsync(element, backSide);
        if (!object) continue;

        if (element.children.length) {
          for (const childElement of element.children) {
            const childObject = await createObjectAsync(childElement, backSide);
            if (!childObject) continue;

            childObject.set({
              clipPath: object.set({
                opacity: 1e-323,
                absolutePositioned: true,
              }),
            });
            objects.push(childObject);
          }
        } else {
          objects.push(object);
        }
      }

      return objects;
    }

    async function createObjectAsync(element: CanvasElement, backSide: boolean) {
      let object: fabric.Object;

      const originalObject = that.findObject(element.route);
      if (originalObject) {
        if (!originalObject[X_TEMP_EXCLUDE_FROM_CUT_THROUGH]) {
          const existingObject = that.findCutThroughObject(element.route);
          if (existingObject) {
            object = await that.createObjectAsync(element, existingObject);
          } else {
            object = await new Promise((resolve: (object: fabric.Object) => void) => originalObject.clone(resolve));
          }
        }
      } else {
        object = await that.createObjectAsync(element);
      }

      if (object) {
        setObjectOptions(element, object);

        if (backSide) {
          setBackSideObjectOptions(element, object);
        }
      }

      return object;

      function setObjectOptions(element: CanvasElement, object: fabric.Object) {
        object.set({
          opacity: element.opacity || 1e-323,
          clipPath: undefined,
          // @ts-expect-error
          [X_CUT_THROUGH_ROUTE]: element.route,
        });
      }

      function setBackSideObjectOptions(element: CanvasElement, object: fabric.Object) {
        const angle = (360 - getAngle(element.screenRotation)) % 360;
        const { x: left, y: top } = fabric.util.rotatePoint(
          calculatePoint(),
          calculateOrigin(),
          fabric.util.degreesToRadians(angle)
        );

        object.set({
          left,
          top,
          angle,
          flipX: !object.flipX,
        });

        object.setCoords();

        function calculatePoint(): fabric.Point {
          const offsetLeft = calculateOffsetLeft();

          return new fabric.Point(
            -element.designX + element.pageX * 2 - element.width + element.pageWidth - offsetLeft,
            element.designY
          );
        }

        function calculateOrigin(): fabric.Point {
          const offsetLeft = calculateOffsetLeft();

          if (element.isBoxChild || element.isPhotoFrameChild) {
            return new fabric.Point(
              -element.parent.designX + element.pageX * 2 - element.parent.width * 0.5 + element.pageWidth - offsetLeft,
              element.parent.designY + element.parent.height * 0.5
            );
          }

          return new fabric.Point(
            -element.designX + element.pageX * 2 - element.width * 0.5 + element.pageWidth - offsetLeft * 2,
            element.designY + element.height * 0.5
          )
        }

        function calculateOffsetLeft() {
          let offsetLeft = 0;

          if (element.page.width !== element.page.backSidePage.width) {
            offsetLeft = Math.min(element.page.width, element.page.backSidePage.width) * 0.5;
          }

          return offsetLeft;
        }
      }
    }
  }

  //#endregion

  //#region Special color

  private removeSpecialColorObject(route: number[]) {
    const that = this;

    const element = this.findElement(route);
    if (!element) return;

    const object = this.findObject(route);
    if (!object) return;

    if (!object[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR]) {
      object[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR] = true;

      const pageRoute = route?.slice(route.length - 2);
      const pageObject = this.findObject(pageRoute);
      if (!pageObject) return;

      const specialColorClipPath = this.findSpecialColorObject(route, "clipPath");
      removeObject(specialColorClipPath);

      function removeObject(clipPath: fabric.Object) {
        if (!(clipPath instanceof fabric.Group)) {
          return;
        }

        const objectToRemove = that.findSpecialColorObject(route);

        if (clipPath.getObjects().includes(objectToRemove)) {
          clipPath.remove(objectToRemove);

          const specialColorRect = that.findSpecialColorObject(route, "rect");
          that.setObjectDirty(specialColorRect);

          const specialColorImage = that.findSpecialColorObject(route, "image");
          that.setObjectDirty(specialColorImage);

          pageObject.canvas?.requestRenderAll();
        }
      }
    }
  }

  private findSpecialColorObject(route: number[], type: "object" | "rect" | "image" | "clipPath" = "object") {
    const that = this;

    switch (type) {
      case "object":
        return findObject();
      case "rect":
        return findRect();
      case "image":
        return findImage();
      case "clipPath":
        return findClipPath();
      default:
        return null;
    }

    function findRect() {
      const pageRoute = route?.slice(route.length - 2);
      if (!pageRoute) {
        return null;
      }

      return that.canvas
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE]?.toString() === pageRoute.toString() && o instanceof fabric.Rect);
    }

    function findImage() {
      const pageRoute = route?.slice(route.length - 2);
      if (!pageRoute) {
        return null;
      }

      return that.canvas
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE]?.toString() === pageRoute.toString() && o instanceof fabric.Image);
    }

    function findClipPath() {
      const pageRoute = route?.slice(route.length - 2);
      if (!pageRoute) {
        return null;
      }

      return that.canvas
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE]?.toString() === pageRoute?.toString() && o instanceof fabric.Group);
    }

    function findObject() {
      const clipPath = findClipPath();
      if (!(clipPath instanceof fabric.Group)) {
        return null;
      }

      return clipPath
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE].toString() === route.toString());
    }
  }

  private async addPageSpecialColorToCanvasAsync(pageElement: PageElement, abortSignal: AbortSignal) {
    const that = this;

    const pageObject = this.findObject(pageElement.route);
    if (!pageObject) return;

    // Create special color
    const { clipPath, rect, image } = await createSpecialColorAsync();
    if (abortSignal?.aborted) return;

    // Remove (existing) special color from canvas
    const canvasObjects = this.canvas.getObjects();
    const canvasObjectsToRemove = canvasObjects.filter((object) => object[X_SPECIAL_COLOR_ROUTE]?.toString() === pageElement.route.toString());
    this.canvas.remove(...canvasObjectsToRemove);

    // Add special color to canvas
    if (clipPath) {
      this.setObjectDirty(clipPath);
      this.canvas.add(clipPath);

      if (rect) {
        this.setObjectDirty(rect);
        this.canvas.add(rect);
      }

      if (image) {
        this.setObjectDirty(image);
        this.canvas.add(image);
      }
    }

    async function createSpecialColorAsync() {
      let clipPath: fabric.Object;
      let rect: fabric.Object;
      let image: fabric.Object;

      const elements: CanvasElement[] = [];
      let gradient: GradientData;

      const foilElements = pageElement.getFoilElements();
      if (foilElements.length) {
        elements.push(...foilElements);

        const foilType = pageElement.getFoilType();
        gradient = gradients[foilType];
      } else {
        const spotUvElements = pageElement.getSpotUvElements();
        if (spotUvElements.length) {
          elements.push(...spotUvElements);
          gradient = gradients.spotUv;
        }
      }

      const objects: fabric.Object[] = [
        ...await createObjectsAsync(elements),
      ];

      if (objects.length && gradient) {
        clipPath = that.findSpecialColorObject(pageElement.route, "clipPath");
        clipPath ??= new fabric.Group([], {
          opacity: 1e-323,
          evented: false,
          // @ts-expect-error
          [X_SPECIAL_COLOR_ROUTE]: pageElement.route,
        });

        clipPath.set({
          clipPath: pageObject.set({
            absolutePositioned: true,
          }),
        });

        if (clipPath instanceof fabric.Group) {
          clipPath.remove(...clipPath.getObjects());

          for (const object of objects) {
            clipPath.addWithUpdate(object);
          }
        }

        switch (gradient.id) {
          case gradients.rainbow.id: {
            const rainbowPatternURL = that.rainbowBackgroundUrl;

            image = that.findSpecialColorObject(pageElement.route, "image");
            image ??= await new Promise((resolve: (image: fabric.Image) => void) => fabric.Image.fromURL(rainbowPatternURL, (image: fabric.Image) => {
              image.set({
                left: pageObject.left,
                top: pageObject.top,
                scaleX: pageObject.width / image.width,
                scaleY: pageObject.height / image.height,
                evented: false,
                // @ts-expect-error
                [X_SPECIAL_COLOR_ROUTE]: pageElement.route,
              });
              resolve(image);
            }, {
              crossOrigin: "anonymous",
            }));

            image.set({
              clipPath: clipPath.set({
                absolutePositioned: true,
              }),
            });
            break;
          }

          default: {
            rect = that.findSpecialColorObject(pageElement.route, "rect");
            rect ??= new fabric.Rect({
              left: pageObject.left,
              top: pageObject.top,
              width: pageObject.width,
              height: pageObject.height,
              evented: false,
              // @ts-expect-error
              [X_SPECIAL_COLOR_ROUTE]: pageElement.route,
            });

            rect.set({
              fill: GradientUtils.createGradient(rect.width * 1.25, rect.height * 1.25, gradient),
              clipPath: clipPath.set({
                absolutePositioned: true,
              }),
            });
          }
        }
      }

      return { clipPath, rect, image };
    }

    async function createObjectsAsync(elements: CanvasElement[]) {
      const objects: fabric.Object[] = [];

      for (const element of elements) {
        const object = await createObjectAsync(element);
        if (!object) continue;

        if (element.children.length) {
          for (const childElement of element.children) {
            const childObject = await createObjectAsync(childElement);
            if (!childObject) continue;

            childObject.set({
              clipPath: object.set({
                opacity: 1e-323,
                absolutePositioned: true,
              }),
            });
            objects.push(childObject);
          }
        } else {
          objects.push(object);
        }
      }

      return objects;
    }

    async function createObjectAsync(element: CanvasElement) {
      let object: fabric.Object;

      const originalObject = that.findObject(element.route);
      if (!originalObject || originalObject[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR]) {
        return null;
      }

      const existingObject = that.findSpecialColorObject(element.route);

      if (existingObject) {
        if (element.isInlineText()) {
          const textImageObject = that.findTextImageObject(element.route);
          if (textImageObject) {
            object = await element.createImageAsync(existingObject);
          }
        } else {
          object = await that.createObjectAsync(element, existingObject);
        }
      } else {
        if (element.isInlineText()) {
          const textImageObject = that.findTextImageObject(element.route);
          if (textImageObject) {
            object = await new Promise((resolve: (object: fabric.Object) => void) => textImageObject.clone(resolve));
          }
        } else {
          object = await new Promise((resolve: (object: fabric.Object) => void) => originalObject.clone(resolve));
        }
      }

      if (object) {
        setObjectOptions(element, object);
      }

      return object;

      function setObjectOptions(element: CanvasElement, object: fabric.Object) {
        object.set({
          clipPath: undefined,
          // @ts-expect-error
          [X_SPECIAL_COLOR_ROUTE]: element.route,
        });
      }
    }
  }

  //#endregion

  //#region Canvas events

  private onCanvasTextEditingEntered(event: fabric.IEvent<MouseEvent>) {
    this.canvas.preserveObjectStacking = false;

    const target: fabric.Object = event?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isInlineText() && element.editFullRange) {
      target.hasControls = true;
      this.store.dispatch(new CanvasActions.SetInlineTextEditMode(route, false));
    }
  }

  private onCanvasTextEditingExited(event: fabric.IEvent<MouseEvent>) {
    this.canvas.preserveObjectStacking = true;

    const target: fabric.Object = event?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isInlineText() && !element.editFullRange) {
      this.store.dispatch(new CanvasActions.SetInlineTextEditMode(route, true));
    }
  }

  private onCanvasTextChanged(event: fabric.IEvent<KeyboardEvent> | { target: fabric.Object }) {
    const target: fabric.Object = event?.target;
    if (!(target instanceof fabric.Textbox)) return;

    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    // Set shrink to fit
    if (element.permissions.shrinkToFit && !target.text.match(/[\r\n]/)) {
      while (target._textLines.length > 1) {
        target.set({
          fontSize: target.fontSize - 1,
        });
      }
    }

    const specialColorObject = this.findSpecialColorObject(element.route);
    if (specialColorObject instanceof fabric.Textbox) {
      specialColorObject.set({ text: target.text });
      this.canvas.requestRenderAll();
    }

    if (element.isText()) {
      this.store.dispatch(new CanvasActions.ChangeFontsize(route, target.fontSize));
      this.store.dispatch(new CanvasActions.ChangeText(route, target.text));

      const { x, y, width, height } = this.getObjectTransform(event.target);
      this.store.dispatch(new CanvasActions.Translate(route, width, height, x, y, true));
    }

    if (element.isInlineText()) {
      const { x, y, width, height } = this.getObjectTransform(event.target);
      const text = TextboxUtils.getInlineTextElementText(target);
      this.store.dispatch(new CanvasActions.ChangeTextInline(route, text, width, height, x, y));
    }
  }

  private onCanvasObjectRemoved(event: fabric.IEvent<MouseEvent>) {
    let target = event.target;
    let route: number[] = target[X_ROUTE];
    if (!route) return;

    this.removeCoatObject(route);
    this.removeCropObject(route);
    this.removeTextImageObject(route);
    this.removeCutThroughObject(route);
    this.removeSpecialColorObject(route);
  }

  private onObjectBeforeImageReplace(e: fabric.IEvent<MouseEvent>) {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = function (event: any) {
      let file = event.target.files[0];
      let fileExtension = file.name.split('.').slice(-1)[0].toLowerCase();

      let isValidFileExtension = fileExtensions[fileExtension];
      if (isValidFileExtension) {
        Object.assign(e, { file });
      }

      e.target.canvas.fire('object:imageReplace', e);
      e.target.fire('imageReplace', e);
    };
    input.click();
  }

  private onObjectImageReplace(event: fabric.IEvent<MouseEvent>) {
    let route: number[] = event.target[X_ROUTE];
    if (!route) return;

    // @ts-expect-error
    let file: File = event.file;
    if (file) {
      this.uploadImage(route, file);
    } else {
      this.uploadImageError();
    }
  }

  private uploadImage(route: number[], file: File) {
    this.imageUploadService.uploadUrl = UPLOAD_URL;
    this.imageUploadService.uploadImage(file, (imageData: ImageData) => {
      this.store.dispatch(new CanvasActions.ReplaceImage(
        route,
        imageData.width,
        imageData.height,
        imageData.width,
        imageData.height,
        imageData.sid,
        '',
        false
      ));
    });
  }

  private uploadImageError() {
    this.dialog.open(ErrorDialogComponent, {
      width: '250px',
      data: {
        title: this.getTextService.text.dialog.upload.error.title,
        message: this.getTextService.text.dialog.upload.error.text,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  //#endregion

  //#region Object events

  private async onObjectSelected(event: fabric.IEvent<MouseEvent>) {
    const target = event.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isInlineText()) {
      target.set({
        opacity: element.opacity || 1e-323
      });

      this.removeTextImageObject(element.route);
      this.removeSpecialColorObject(element.route);
      target[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR] = false;

      this.onCanvasTextChanged({ target })
    }

    this.deleteReplaceControlObjects();

    this.store.dispatch(new CanvasActions.Select(route));
  }

  private async onObjectDeselected(event: fabric.IEvent<MouseEvent>) {
    const target = event.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    this.store.dispatch(new CanvasActions.Deselect());
  }

  private onObjectMoving(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    this.setupObjectBeforeModifying(target);

    if (element.parent.isPage()) {
      target.set({ clipPath: undefined });
    }

    if (element.isBox()) {
      for (const childElement of element.children) {
        onObjectTransform_TransformObject(event, this.findObject(childElement.route));
      }
    }

    if (element.isPhotoFrame()) {
      onObjectTransform_TransformObject(event, this.findObject(element.firstChild.route));
    }

    if (element.isPhotoFrameChild) {
      onObjectTransformContainObject(event, this.findObject(element.parent.route));
      this.upsertCropObjectAsync(element, this.findObject(element.route));
    }

    if (element.isBackgroundImage()) {
      this.upsertCropObjectAsync(element, this.findObject(element.route));
    }

    onObjectTransform_TransformObject(event, this.findCoatObject(element.route));

    if (!(event instanceof KeyboardEvent) || event.repeat) {
      this.objectModifying.emit(target);
    }
  }

  private onObjectRotating(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    this.setupObjectBeforeModifying(target);

    if (element.parent.isPage()) {
      target.set({ clipPath: undefined });
    }

    if (element.isBox()) {
      for (const childElement of element.children) {
        onObjectTransform_TransformObject(event, this.findObject(childElement.route));
      }
    }

    if (element.isPhotoFrame()) {
      onObjectTransform_TransformObject(event, this.findObject(element.firstChild.route));
    }

    if (element.isBackgroundImage()) {
      this.upsertCropObjectAsync(element, this.findObject(element.route));
    }

    onObjectTransform_TransformObject(event, this.findCoatObject(element.route));

    this.objectModifying.emit(target);
  }

  private async onObjectScaling(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    this.setupObjectBeforeModifying(target);

    if (element.parent.isPage()) {
      target.set({ clipPath: undefined });
    }

    if (element.isPhotoFrame()) {
      switch (event.transform.action) {
        case 'scale': {
          onObjectTransform_TransformObject(event, this.findObject(element.firstChild.route));
          break;
        }

        case 'scaleX':
        case 'scaleY': {
          for (const childElement of element.children) {
            onObjectTransformContainWithinObject(event, this.findObject(childElement.route));
            await this.upsertCropObjectAsync(childElement, this.findObject(childElement.route));
          }
          break;
        }
      }
    }

    if (element.isBackgroundImage()) {
      this.upsertCropObjectAsync(element, this.findObject(element.route));
    }

    onObjectTransform_TransformObject(event, this.findCoatObject(element.route));

    this.objectModifying.emit(target);
  }

  private setupObjectBeforeModifying(object: fabric.Object) {
    if (!object || object[X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING]) {
      return;
    }

    object[X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING] = true;

    const element = this.findElement(object[X_ROUTE]);
    if (!element) return;

    for (const route of [element.route, ...element.children.map(({ route }) => route)]) {
      this.removeCutThroughObject(route);
      this.removeSpecialColorObject(route);

      const object = this.findObject(route);
      object?.bringToFront();
    }
  }

  private onObjectModified(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    switch (event.action) {
      case 'drag':
        this.onObjectMove(event);
        break;
      case 'rotate':
        this.onObjectRotate(event);
        break;
      case 'resizing':
      case 'scale':
        if (element.isBox()) {
          this.onObjectCrop(event);
          break;
        }

        this.onObjectScale(event);
        break;
      case 'scaleX':
      case 'scaleY':
        this.onObjectCrop(event);
        break;
    }

    if (element.isPhotoFrame()) {
      for (const childElement of element.children) {
        this.hideCropObject(childElement.route);
      }
    }
  }

  private onObjectMove(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;

    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    const { x, y, width, height } = this.getObjectTransform(target);

    this.store.dispatch(new CanvasActions.Translate(route, width, height, x, y, true));
    this.objectModified.emit(target);
  }

  private onObjectRotate(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    const { x, y, width, height, rotation } = this.getObjectTransform(target);

    this.store.dispatch(new CanvasActions.Rotate(route, width, height, x, y, rotation));
    this.objectModified.emit(target);
  }

  private onObjectCrop(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    const { x, y, width, height } = this.getObjectTransform(target);

    this.store.dispatch(new CanvasActions.Crop(route, width, height, x, y));
    this.objectModified.emit(target);
  }

  private onObjectScale(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (target instanceof fabric.Textbox) {
      if (element.isInlineText()) {

        for (let paragraph of element.text) {
          for (let line of paragraph.lines) {
            for (let textSpan of line.textSpans) {
              textSpan.fontSize *= target.scaleX;
            }
          }
        }

        target.set({
          fontSize: Number.parseInt((target.fontSize * target.scaleX).toFixed(0), 10),
          width: target.width * target.scaleX,
          height: target.height * target.scaleY,
          scaleX: 1,
          scaleY: 1,
        });
      }
    }

    const { x, y, width, height } = this.getObjectTransform(target);
    this.store.dispatch(new CanvasActions.Resize(route, width, height, x, y));

    if (target instanceof fabric.Textbox) {
      if (element.isInlineText()) {
        const text = TextboxUtils.getInlineTextElementText(target);
        this.store.dispatch(new CanvasActions.ResizeTextInline(element.route, text, width, height, x, y, new EditorSelection()));
      }
    }

    this.objectModified.emit(target);
  }

  private getObjectTransform(object: fabric.Object) {
    const route: number[] = object?.[X_ROUTE];
    if (!route) return null;

    const element = this.findElement(route);
    if (!element) return null;

    const left = object.left;
    const top = object.top;

    const width = object.width * object.scaleX;
    const height = object.height * object.scaleY;

    if (element.isBoxChild || element.isPhotoFrameChild) {
      const parentElement = element.parent;
      const parentObject = this.findObject(parentElement.route);

      const rotation = CanvasElementUtils.getRotation((object.angle - parentObject.angle) % 360);
      const { x, y } = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(
          parentElement.designX + (parentObject.width * parentObject.scaleX) / 2,
          parentElement.designY + (parentObject.height * parentObject.scaleY) / 2
        ),
        fabric.util.degreesToRadians(-parentObject.angle - ((object.angle - parentObject.angle) % 360))
      );

      let correctedWidth = Math.max(width, element.parent.width);
      let correctedHeight = Math.max(height, element.parent.height);

      return { route, x: x - element.parent.designX, y: y - element.parent.designY, width: correctedWidth, height: correctedHeight, rotation };
    } else {
      const rotation = CanvasElementUtils.getRotation(object.angle);
      const { x, y } = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(left - (width) / 2, top - height / 2),
        fabric.util.degreesToRadians(object.angle)
      );
      return { route, x: x - element.pageX, y: y - element.pageY, width, height: height, rotation };
    }
  }

  private onObjectRemove(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    this.store.dispatch(new CanvasActions.RemoveElement(route));
  }

  private onObjectZoomInner(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    const zoomScale = event.transform['zoomScale'] ?? 0;
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    this.setupObjectBeforeModifying(target);

    target.setCoords();

    for (const childElement of element.children) {
      const childObject = this.findObject(childElement.route);
      if (childObject && childElement.isImage()) {
        const prevChildOptions = getOptions(childObject);
        setPositionAfterZooming(target, childObject, zoomScale, childElement.maxZoom / 100);

        if (childElement.isPhotoFrameChild) {
          const nextChildOptions = getOptions(childObject);

          this.findCropObject(childElement.route)?.set(nextChildOptions)?.setCoords();
          this.findCoatObject(childElement.route)?.set(nextChildOptions)?.setCoords();

          const specialColorObject = this.findSpecialColorObject(childElement.route);
          specialColorObject?.set({
            ...nextChildOptions,
            left: specialColorObject.left + (prevChildOptions.left - nextChildOptions.left),
            top: specialColorObject.top + (prevChildOptions.top - nextChildOptions.top),
          });
        }

        // @ts-expect-error
        this.onObjectScale({ transform: { target: childObject } });
      }
    }

    target.canvas?.fire('object:modified', { target: target });
    target.fire('modified', { target });

    this.setObjectDirty(this.findSpecialColorObject(route, "rect"));
    this.setObjectDirty(this.findSpecialColorObject(route, "image"));

    this.canvas.requestRenderAll();

    function getOptions(object: fabric.Object) {
      return {
        left: object.left,
        top: object.top,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
      };
    }
  }

  //#endregion

  //#region Text Image object

  private async createTextImageObjectAsync(element: CanvasElement, object: fabric.Object, abortSignal?: AbortSignal) {
    const that = this;
    if (!element || !element.isInlineText()) return;

    let existingTextImageObject = this.findTextImageObject(element.route);
    let textImageObject = await element.createImageAsync(existingTextImageObject);

    if (!textImageObject || abortSignal?.aborted) return;

    textImageObject.set({
      clipPath: object.clipPath,
      evented: false,
      //@ts-expect-error
      [X_TEXT_IMAGE_ROUTE]: element.route
    });

    let isAddedToCanvas = !!this.findTextImageObject(element.route);
    let index = this.findObjectIndex(object);

    if (!isAddedToCanvas) {
      this.canvas.insertAt(textImageObject, index, false);
    } else {
      textImageObject.moveTo(index);
    }
  }

  //#endregion

  //#region Crop object

  private async upsertCropObjectAsync(element: CanvasElement, object: fabric.Object, abortSignal?: AbortSignal) {
    if (
      !(element.isBackgroundImage() && element.selected) &&
      !(element.isPhotoFrameChild && element.parent.selected)
    ) {
      return;
    }

    if (!object || abortSignal?.aborted) {
      return;
    }

    const existingCropObject = this.findCropObject(element.route);
    const cropObject = existingCropObject ?? await new Promise((resolve: (object: fabric.Object) => void) => object.clone((cropObject: fabric.Object) => {
      cropObject.set({
        clipPath: undefined,
        evented: false,
        //@ts-expect-error
        [X_CROP_ROUTE]: element.route,
      });

      resolve(cropObject);
    }));

    if (!cropObject || abortSignal?.aborted) {
      return;
    }

    cropObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      flipX: object.flipX,
      flipY: object.flipY,
      angle: object.angle,
      opacity: .5,
    });

    cropObject.setCoords();
    setTransformMatrix(cropObject, object);

    const index = this.findObjectIndex(object);
    if (!existingCropObject) {
      object.canvas?.insertAt(cropObject, index, false);
    } else {
      object.canvas?.moveTo(cropObject, index);
    }
  }

  //#endregion

  //#region Coat object

  private createCoatObject(element: CanvasElement, object: fabric.Object) {
    if (!CanvasElementUtils.hasCoating(element)) return;

    let backgroundElement = this.page.children.find((e) => e.isBackgroundElement());
    if (!backgroundElement || backgroundElement.children.length) return;

    let backgroundObject = this.findObject(backgroundElement.route);
    let backgroundObjectIndex = this.findObjectIndex(backgroundObject);
    if (backgroundObjectIndex === -1) return;

    let existingCoatObject = this.findCoatObject(element.route);
    let coatObject = existingCoatObject ?? new fabric.Rect();
    if (!coatObject) return;

    coatObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      angle: object.angle,
      fill: CoatingType,
      opacity: 1,
      clipPath: this.findObject(element.parent.route)?.set({
        absolutePositioned: true,
      }),
      evented: false,
      // @ts-expect-error
      [X_COAT_ROUTE]: element.route,
    });

    coatObject.setCoords();
    setTransformMatrix(coatObject, object);

    let isAddedToCanvas = !!this.findCoatObject(element.route);
    let index = backgroundObjectIndex + 1;

    if (!isAddedToCanvas) {
      this.canvas.insertAt(coatObject, index, false);
    } else {
      coatObject.moveTo(index);
    }
  }

  //#endregion

  //#region Zoom

  private zoomCanvasToFit() {
    const zoomToFit = this.zoomToFit;
    this.zoomCanvasToCenter(zoomToFit);
  }

  private zoomCanvasToCenter(zoom: number) {
    if (this.canvas) {
      const canvasCenter = this.canvas.getCenter();
      const centerPoint = new fabric.Point(canvasCenter.left, canvasCenter.top);
      this.zoomCanvasToPoint(centerPoint, zoom);
    }
  }

  private zoomCanvasToPoint(point: fabric.Point, zoom: number, hasLowerBoundaries: boolean = true, hasHigherBoundaries: boolean = true) {
    if (this.canvas && this.page) {
      zoom = Math.max(zoom, this.zoomToFit * this.page.minZoom);
      zoom = Math.min(zoom, this.zoomToFit * this.page.maxZoom);

      this.canvas.zoomToPoint(point, zoom);
      this.zoomChange.emit(zoom);

      const viewportTransform = this.canvas.viewportTransform.slice(0);
      this.setCanvasViewportTransform(viewportTransform, hasLowerBoundaries, hasHigherBoundaries);
    }
  }

  private setCanvasViewportTransform(
    viewportTransform: number[] = this.canvas.viewportTransform.slice(0),
    hasLowerBoundaries: boolean = true,
    hasHigherBoundaries: boolean = true
  ) {
    const zoom = this.zoom;
    const zoomToFit = this.zoomToFit;

    if (zoom <= zoomToFit) {
      if (hasLowerBoundaries) {
        let pageLeft = this.page.designX + this.page.visiblePageBoundingBox.x,
          pageTop = this.page.designY + this.page.visiblePageBoundingBox.y,
          pageWidth = this.page.visiblePageBoundingBox.width,
          pageHeight = this.page.visiblePageBoundingBox.height;

        if (!this.isMainCanvas && !this.isBehindMainCanvas) {
          pageLeft -= this.page.visiblePageBoundingBox.x;
          pageTop -= this.page.visiblePageBoundingBox.y;
          pageWidth = this.page.width;
          pageHeight = this.page.height;
        }

        viewportTransform[4] = this.canvas.width * 0.5 - (pageLeft + pageWidth * 0.5) * zoom;
        viewportTransform[5] = this.canvas.height * 0.5 - (pageTop + pageHeight * 0.5) * zoom;
      }
    } else {
      if (hasHigherBoundaries) {
        const maxTranslateX = (this.canvas.width * 0.5 - this.page.designX) * zoom;
        const minTranslateX = -((zoom - 1 && this.canvas.width * (zoom - 1)) + this.page.width * 0.5 * zoom);
        viewportTransform[4] = Math.max(Math.min(viewportTransform[4], maxTranslateX), minTranslateX);

        const maxTranslateY = (this.canvas.height * 0.5 - this.page.designY) * zoom;
        const minTranslateY = -((zoom - 1 && this.canvas.height * (zoom - 1)) + this.page.height * 0.5 * zoom);
        viewportTransform[5] = Math.max(Math.min(viewportTransform[5], maxTranslateY), minTranslateY);
      }
    }

    this.canvas.setViewportTransform(viewportTransform);
    this.viewportTransformChange.emit(viewportTransform);

    this.canvas.calcViewportBoundaries();
    this.canvas.requestRenderAll();
  }

  //#endregion

  //#region Canvas container

  private onCanvasKeyDown(event: KeyboardEvent) {
    const that = this;

    this.keysPressed[event.key] = true;

    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) return;

    const element = this.findElement(activeObject?.[X_ROUTE]);
    if (!element) return;

    const STEP = event.shiftKey ? 10 : 1;
    let arrowMovement = false;

    if (activeObject instanceof fabric.Textbox && (event.ctrlKey || event.metaKey)) {
      switch (event.key.toLowerCase()) {
        case "b":
          event.preventDefault()
          const nextFontWeight = InlineTextElementUtils.getTextboxFontWeight(!TextboxUtils.getInlineTextElementBold(activeObject));
          this.store.dispatch(new FabricCanvasActions.ChangeTextboxFontWeight(element.route, nextFontWeight));
          break;
        case "u":
          event.preventDefault()
          const nextUnderline = !activeObject.underline
          this.store.dispatch(new FabricCanvasActions.ChangeTextboxUnderline(element.route, nextUnderline));
          break;
        case "i":
          event.preventDefault()
          const nextFontStyle = InlineTextElementUtils.getTextboxFontStyle(!TextboxUtils.getInlineTextElementItalic(activeObject));
          this.store.dispatch(new FabricCanvasActions.ChangeTextboxFontStyle(element.route, nextFontStyle));
          break;
      }
    }

    function addPositionOffset(axis: 'left' | 'top', value: number) {
      that.setupObjectBeforeModifying(activeObject);
      activeObject.set({ [axis]: activeObject[axis] + value });
      activeObject.setCoords();
      arrowMovement = true;
    }

    if (!event.ctrlKey && !event.metaKey && !event.altKey) {
      switch (event.key) {
        case 'ArrowLeft':
          addPositionOffset('left', -STEP);
          break;
        case 'ArrowUp':
          addPositionOffset('top', -STEP);
          break;
        case 'ArrowRight':
          addPositionOffset('left', STEP);
          break;
        case 'ArrowDown':
          addPositionOffset('top', STEP);
          break;
      }
    }

    if (arrowMovement) {
      this.alignGuidelines.disableMagnetism();

      // @ts-expect-error
      this.onObjectMoving(Object.assign({}, event, { transform: { target: activeObject } }));
      //@ts-expect-error
      this.onObjectMove({ transform: { target: activeObject } });


      activeObject.canvas?.fire('object:modified', { target: activeObject });
      activeObject.fire('modified', { target: activeObject });

      const options = Object.assign({}, event, { transform: { target: activeObject } });
      // @ts-expect-error
      this.alignGuidelines.onCanvasObjectMoving(options);
      this.canvas.requestRenderAll();
    }
  }

  private onCanvasKeyUp(event: KeyboardEvent) {
    if (['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].some(key => this.keysPressed[key])) {
      this.alignGuidelines.enableMagnetism();
    }

    delete this.keysPressed[event.key];
  }

  private openFoilErrorDialog() {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        title: this.getTextService.text.dialog.dropFoilError.title,
        message: this.getTextService.text.dialog.dropFoilError.message,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  //#endregion

  private addPageGridToCanvas(page: PageElement) {
    if (!this.designSet.showGrid) {
      return;
    }

    const that = this;

    let gridLines: fabric.Line[] = [];
    let cellSize = Math.round(page.width / this.gridCellSize);

    gridLines.push(...getGridLines());
    gridLines.push(...getMedianLines());

    this.canvas.add(...gridLines);

    function getGridLines() {
      let gridLines: fabric.Line[] = [];
      let lineOptions: fabric.ILineOptions = {
        stroke: 'rgba(67, 191, 254, .8)',
        strokeWidth: 1 / that.zoomToFit,
        strokeUniform: true,
        evented: false,
      };

      let x = page.x;
      while (x <= page.x + page.width) {
        let horizontalLine = new fabric.Line([
          x, page.y,
          x, page.y + page.height
        ],
          lineOptions);
        //@ts-expect-error
        horizontalLine.elementType = ElementType.background;
        gridLines.push(horizontalLine);
        x += cellSize;
      }

      let y = page.y;
      while (y <= page.y + page.height) {
        let verticalLine = new fabric.Line([
          page.x, y,
          page.x + page.width, y
        ], lineOptions);
        //@ts-expect-error
        verticalLine.elementType = ElementType.background;
        gridLines.push(verticalLine);
        y += cellSize;
      }

      return gridLines;
    }

    function getMedianLines() {
      let medianLines: fabric.Line[] = [];
      let lineOptions: fabric.ILineOptions = {
        stroke: 'rgba(255, 28, 65, .8)',
        strokeWidth: 1 / that.zoomToFit,
        strokeUniform: true,
        evented: false,
      };

      let horizontalLine = new fabric.Line([
        page.x, page.y + page.height / 2,
        page.x + page.width, page.y + page.height / 2
      ], lineOptions);

      //@ts-expect-error
      horizontalLine.elementType = ElementType.background;

      medianLines.push(horizontalLine);

      let verticalLine = new fabric.Line([
        page.x + page.width / 2, page.y,
        page.x + page.width / 2, page.y + page.height
      ], lineOptions);

      //@ts-expect-error
      verticalLine.elementType = ElementType.background;

      medianLines.push(verticalLine);

      return medianLines;
    }
  }

  private addPageViewSeparatorToCanvas(page: PageElement) {
    let design = page?.parent;
    if (!design || design.view !== View.userSpreads && design.view !== View.card) {
      return;
    }

    let spread = design.spreads.find(spread => spread.spreadPage.route.toString() == page.route.toString());
    let spreadPages = spread?.pages;
    spreadPages = spreadPages.slice(0, spreadPages.length - 1);

    let separatorLines: fabric.Line[] = [];
    let width: number;

    for (let spreadPage of spreadPages) {
      width ??= spreadPage.width;

      let separatorLine = new fabric.Line([
        page.x + width, page.y,
        page.x + width, page.y + page.height
      ], {
        stroke: 'rgba(128, 128, 128, 0.5)',
        strokeWidth: 1 / this.zoomToFit,
        strokeUniform: true,
        evented: false
      });
      //@ts-expect-error
      separatorLine.elementType = ElementType.background;
      separatorLines.push(separatorLine);
      width += spreadPage.width;
    }

    this.canvas.add(...separatorLines);
  }

  private removeElementsFromCanvas() {
    const canvasObjects = this.canvas.getObjects();
    const canvasObjectsToRemove = canvasObjects
      .filter((object) => {
        const route: number[] =
          object[X_ROUTE] ||
          object[X_CROP_ROUTE] ||
          object[X_COAT_ROUTE] ||
          object[X_TEXT_IMAGE_ROUTE] ||
          object[X_CUT_THROUGH_ROUTE] || // skip remove
          object[X_SPECIAL_COLOR_ROUTE]; // skip remove

        const element = this.findElement(route);
        if (!element) return true;

        return (
          // Remove crop
          (
            object[X_CROP_ROUTE] &&
            (
              element.isPhotoFrameChild && (!element.parent.selected || (object instanceof fabric.Image && element.imgSource !== object.getSrc())) ||
              element.isBackground() && !element.selected
            )
          ) ||
          // Remove coat
          (
            object[X_COAT_ROUTE] &&
            !CanvasElementUtils.hasCoating(element)
          ) ||
          // Remove text image
          (
            object[X_TEXT_IMAGE_ROUTE] &&
            element.selected
          )
        );
      });

    this.canvas.remove(...canvasObjectsToRemove);
  }

  trackById(index: number, item: CanvasElement) {
    return `${index}-${item.id}`;
  }

  //#region Touch event listeners

  private touchstart: (e: TouchEvent) => void;
  private touchmove: (e: TouchEvent) => void;
  private touchend: (e: TouchEvent) => void;
  private touchcancel: (e: TouchEvent) => void;

  private addCanvasTouchEventListeners(canvas: fabric.Canvas) {
    const upperCanvas = canvas.getElement().parentElement.getElementsByClassName("upper-canvas")[0];
    upperCanvas.addEventListener("touchstart", (this.touchstart = (e: TouchEvent) => canvas.fire("touch:start", getOptions(e))));
    upperCanvas.addEventListener("touchmove", (this.touchmove = (e: TouchEvent) => canvas.fire("touch:move", getOptions(e))));
    upperCanvas.addEventListener("touchend", (this.touchend = (e: TouchEvent) => canvas.fire("touch:end", getOptions(e))));
    upperCanvas.addEventListener("touchcancel", (this.touchcancel = (e: TouchEvent) => canvas.fire("touch:cancel", getOptions(e))));

    function getOptions(e: TouchEvent): fabric.IEvent<TouchEvent> {
      return {
        e,
        target: canvas.findTarget(e, false),
        pointer: getPointer(),
        absolutePointer: getAbsolutePointer(),
      };

      function getPointer() {
        const { x, y } = canvas.getPointer(e, true);
        return new fabric.Point(x, y);
      }

      function getAbsolutePointer() {
        const { x, y } = canvas.getPointer(e);
        return new fabric.Point(x, y);
      }
    }
  }

  private removeCanvasTouchEventListeners(canvas: fabric.Canvas) {
    const upperCanvas = canvas.getElement().parentElement.getElementsByClassName("upper-canvas")[0];
    upperCanvas.removeEventListener("touchstart", this.touchstart);
    upperCanvas.removeEventListener("touchmove", this.touchmove);
    upperCanvas.removeEventListener("touchend", this.touchend);
    upperCanvas.removeEventListener("touchcancel", this.touchcancel);
  }

  //#endregion
}
