import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeItem, ThemeItemNames, ThemeTypes } from '../../data/theme.data';
import { BrightColors, DarkColors, LightColors } from '../../utils/color-utils';

@Component({
  selector: 'ed-style-edit-option',
  templateUrl: 'style-edit-option.component.html',
  styleUrls: ['style-edit-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StyleEditOptionComponent {
  @Input() data: ThemeItem;
  @Input() borderRadius = false;
  @Output() changeThemeValue = new EventEmitter<ThemeItem>();

  themeTypes = ThemeTypes;

  showSlider = false;
  showPicker = false;

  changeColor(data: ThemeItem, value: string) {
    this.data.value = value;
    this.changeThemeValue.emit(data);
  }

  changeRadius(data: ThemeItem, value: string) {
    this.data.value = value;
    this.changeThemeValue.emit(data);
  }

  toggleSlider() {
    this.showSlider = !this.showSlider;
  }

  togglePicker() {
    this.showPicker = !this.showPicker;
  }

  getColorScheme(name: ThemeItemNames) {
    switch (name) {
      case ThemeItemNames.colorTextDark:
        return DarkColors;
      case ThemeItemNames.colorTextLight:
        return LightColors;
      default:
        return BrightColors;
    }
  }

  getDisableInput(name: ThemeItemNames) {
    return name === ThemeItemNames.colorTextDark || name === ThemeItemNames.colorTextLight;
  }
}
