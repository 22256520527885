import { Inject, Injectable } from '@angular/core';
import {
  CssPrefixes,
  CssVariables,
  DARKEN_COLOR_PERCENTAGE,
  LIGHTEN_COLOR_PERCENTAGE,
  THEME_ITEMS,
  ThemeItem,
  ThemeItemNames,
  ThemeTypes
} from '../data/theme.data';
import { isLightOrDarkColor } from '../utils/theme.utils';
import { colorShade, hexToRgb } from '../utils/color-utils';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ThemeService {
  json = '';
  themeItems = THEME_ITEMS;

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  setCssVariables(
    themeData: CssVariables,
    toolbarColorStart: string,
    toolbarColorEnd: string,
    toolBarTextColor: string
  ) {
    const themeVariables = Object.assign({}, themeData);
    themeVariables[ThemeItemNames.colorBrandPrimary] = toolbarColorStart;
    themeVariables[ThemeItemNames.colorBrandSecondary] = toolbarColorEnd;
    themeVariables[ThemeItemNames.colorToolbarText] = toolBarTextColor;
    this.updateThemeItems(themeVariables);
  }

  setCssVariable(prefix: string, value: string) {
    this._document.documentElement.style.setProperty(prefix, value);
  }

  updateThemeItems(themeData: CssVariables) {
    Object.keys(themeData).forEach(name => this.setThemeItem(name, themeData[name]));
    this.setCssVariablesToDocument();
  }

  updateThemeItem(name: string, value: string) {
    this.setThemeItem(name, value);
    this.setCssVariablesToDocument();
  }

  setThemeItem(name: string, value: string) {
    const existingThemeItem = this.themeItems.find(cssVar => cssVar.name === name);
    if (existingThemeItem) {
      existingThemeItem.value = value;
    }
  }

  setCssVariablesToDocument() {
    const cssVariables = this.getCssVariablesFromEditableVariables();
    Object.keys(cssVariables).forEach(name => this.setCssVariable(name, cssVariables[name]));
  }

  getToolbarCssVariables() {
    const toolBarCssVars = {};
    const startColor = this.themeItems.find(variable => variable.name === ThemeItemNames.colorBrandPrimary).value;
    const endColor = this.themeItems.find(variable => variable.name === ThemeItemNames.colorBrandSecondary).value;
    const textColor = this.themeItems.find(variable => variable.name === ThemeItemNames.colorToolbarText).value;
    toolBarCssVars[CssPrefixes.colorToolbarStart] = startColor;
    toolBarCssVars[CssPrefixes.colorToolbarEnd] = endColor;
    toolBarCssVars[CssPrefixes.colorToolbarText] = textColor;
    return toolBarCssVars;
  }

  changeToolbarTextColor(textColor: ThemeItem) {
    this.themeItems.find(variable => variable.name === ThemeItemNames.colorToolbarText).basedOn = textColor.name;
    this.updateThemeItem(ThemeItemNames.colorToolbarText, textColor.value);
  }

  getTextColor(color: string) {
    const colorName = isLightOrDarkColor(hexToRgb(color));
    return this.themeItems.find(editableVariable => editableVariable.name === colorName).value;
  }

  getCssVarsFromThemeItems() {
    const themeVariables: CssVariables = {};
    this.themeItems.forEach(editableVariable => (themeVariables[editableVariable.name] = editableVariable.value));
    return themeVariables;
  }

  getCssVariablesFromEditableVariables() {
    const cssVariables: CssVariables = {};

    this.themeItems.forEach(themeItem => {
      if (themeItem.type === ThemeTypes.color && themeItem.name.includes('Base')) {
        cssVariables[themeItem.cssPrefix] = themeItem.value;
        cssVariables[themeItem.cssPrefix + '-light'] = colorShade(themeItem.value, LIGHTEN_COLOR_PERCENTAGE);
        cssVariables[themeItem.cssPrefix + '-dark'] = colorShade(themeItem.value, DARKEN_COLOR_PERCENTAGE);
        cssVariables[themeItem.cssPrefix + '-text'] = this.getTextColor(themeItem.value);
      } else {
        cssVariables[themeItem.cssPrefix] = themeItem.value;
      }
    });

    return {
      ...cssVariables,
      ...this.getToolbarCssVariables()
    };
  }

  exportCssVariablesAsJson() {
    this.json = JSON.stringify(this.getCssVarsFromThemeItems());
  }

  importCssVariablesFromJson(importJson: string) {
    this.updateThemeItems(JSON.parse(importJson));
  }

  getJson() {
    return this.json;
  }
}
