import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ed-label',
  templateUrl: 'label.component.html',
  styleUrls: ['label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent {
  @Input() pageLabel: Array<string>;
  @Input() spreadView: boolean;
  @Input() canAddLabel: boolean;
  @Input() pageWidth: number;

  @Output() newLabel: EventEmitter<Label> = new EventEmitter();

  public minWidth = 100;
  public idPrefix = 'dynamic-input-';

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument) {}

  setLabel(index?: number) {
    const element = this._document.getElementById(this.idPrefix + index);
    const newValue = element.innerText;
    this.newLabel.emit({ value: newValue, index });
  }

  onBlur(index?: number) {
    this.setLabel(index);
  }

  onKeyDown(event: KeyboardEvent, index?: number) {
    if (event.key === 'Enter') {
      this.setLabel(index);
    }
  }
}

export class Label {
  value: string;
  index: number;
}
