import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Design, PageElement } from '../../../../models';
import { DesignSet } from '../../../../models/design-set';

@Component({
  selector: 'ed-page-menu',
  templateUrl: 'page-menu.component.html'
})
export class PageMenuComponent {
  @Input() pages: Array<PageElement>;
  @Input() designSet: DesignSet;
  @Input() offsetTop: number;

  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onMouseClick(ev: MouseEvent) {
    if (ev.clientY < this.offsetTop) {
      this.closeMenu.emit();
    }
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(ev: TouchEvent) {
    if (ev.touches[0].clientY < this.offsetTop) {
      this.closeMenu.emit();
    }
  }

  itemTrackBy(index: number, item: Design) {
    return item.setId;
  }
}
