import { EditorAction } from '../action';
import { DE_ACTIVATE_OVERLAY } from './canvas.action.types';

export class DeActivateOverlay implements EditorAction {
  readonly type = DE_ACTIVATE_OVERLAY;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>) {}
}
