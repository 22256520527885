import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../models/user';

@Component({
  selector: 'ed-basket',
  templateUrl: 'basket.component.html',
  styleUrls: ['basket.component.scss', '../toolbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasketComponent {
  @Input() user: User;
  @Input() basketText: string;

  @Output() goToUrl = new EventEmitter();

  onClick() {
    this.goToUrl.emit();
  }
}
