import { fabric } from "fabric";
import { cloneDeep, round } from "lodash-es";
import { SVG_VIEWBOX_SCALE } from "react-text-editor/models/text-editor.model";
import { DEFAULT_INLINE_TEXT_ASCENDER, DEFAULT_INLINE_TEXT_DESCENDER, Line, Paragraph } from "src/app/models";
import { InlineTextElementTextAlign } from "../models/inline-text-element-text-align";
import { TextboxData as InlineTextTextboxData } from "../models/prototypes/inline-text-element.prototype";
import { TextboxTextAlign } from "../models/textbox-text-align";
import { FontUtils } from "./font.utils";

const TextboxTextAlignMap = new Map<TextboxTextAlign, InlineTextElementTextAlign>([
  ['left', 'start'],
  ['center', 'middle'],
  ['right', 'end'],
  ['justify-left', 'start'],
]);

export const TextboxUtils = new (class {
  getInlineTextElementText(object: fabric.Textbox) {
    if (!(object instanceof fabric.Textbox)) {
      return [];
    }

    let data: InlineTextTextboxData = object.data;

    let font = FontUtils.getFont(data.fontLibrary, object.fontFamily);
    let ascender = font?.ascender ?? DEFAULT_INLINE_TEXT_ASCENDER;
    let descender = font?.descender ?? DEFAULT_INLINE_TEXT_DESCENDER;

    let firstParagraph = data.element.text[0];
    let firstLine = firstParagraph.lines[0];
    let firstSpan = firstLine.textSpans[0];

    let unwrappedTextLines = object._unwrappedTextLines.map((utl) => utl.join(''));
    let wrappedTextLines = object._textLines.map((tl) => tl.join(''));

    let paragraphs: Paragraph[] = [];
    let wrappedIndex = 0;
    let y = data.element.padding;

    for (let i = 0; i < unwrappedTextLines.length; i++) {
      let unwrappedTextLine = unwrappedTextLines[i];
      let lines: Line[] = [];
      let isEmptyLine = unwrappedTextLine === "";

      for (let j = wrappedIndex; j < wrappedTextLines.length; j++) {
        let wrappedTextLine = "";

        if (!isEmptyLine) {
          if (!unwrappedTextLine) continue;

          wrappedTextLine = wrappedTextLines[j];
          if (!wrappedTextLine) continue;

          unwrappedTextLine = unwrappedTextLine.substring(wrappedTextLine.length);
          while (unwrappedTextLine[0] == " ") {
            wrappedTextLine += " ";
            unwrappedTextLine = unwrappedTextLine.substring(1);
          }
        }

        // Create textSpan
        let textSpan = Object.assign(cloneDeep(firstSpan), {
          text: wrappedTextLine,
          fontSize: this.getInlineTextElementFontSize(object),
          color: object.fill,
          bold: this.getInlineTextElementBold(object),
          italic: this.getInlineTextElementItalic(object),
          underlined: object.underline,
          opacity: object.opacity,
          spanParts: [],
        });

        // Line y
        y += (
          + object.fontSize * ascender * object.lineHeight
          + (wrappedIndex > 0 ? object.fontSize * descender * object.lineHeight : 0)
        ) * SVG_VIEWBOX_SCALE;

        // Create line
        let line = Object.assign(cloneDeep(firstLine), {
          textSpans: [textSpan],
          y: round(y, 6),
        });

        lines.push(line);
        wrappedIndex++;

        if (isEmptyLine) break;
      }

      // Create paragraph
      let paragraph = Object.assign(cloneDeep(firstParagraph), {
        textAlign: this.getInlineTextElementTextAlign(object),
        lineHeight: object.lineHeight,
        isJustified: this.getInlineTextElementIsJustified(object),
        lines,
      });

      paragraphs.push(paragraph);
    }

    return paragraphs;
  }

  getInlineTextElementTextAlign(object: fabric.Textbox) {
    if (!(object instanceof fabric.Textbox)) {
      return null;
    }

    return TextboxTextAlignMap.get(object.textAlign as TextboxTextAlign);
  }

  getInlineTextElementFontSize(object: fabric.Textbox) {
    if (!(object instanceof fabric.Textbox)) {
      return null;
    }

    return object.fontSize * SVG_VIEWBOX_SCALE;
  }

  getInlineTextElementBold(object: fabric.Textbox) {
    if (!(object instanceof fabric.Textbox)) {
      return false;
    }

    return object.fontWeight === "bold";
  }

  getInlineTextElementItalic(object: fabric.Textbox) {
    if (!(object instanceof fabric.Textbox)) {
      return false;
    }

    return object.fontStyle === "italic";
  }

  getInlineTextElementIsJustified(object: fabric.Textbox) {
    if (!(object instanceof fabric.Textbox)) {
      return false;
    }

    return object.textAlign === 'justify-left';
  }
});
