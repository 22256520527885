import { EditorAction } from '../action';
import { SELECT } from './canvas.action.types';

export class Select implements EditorAction {
  readonly type = SELECT;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>) {}
}
