import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TooltipPosition } from '@angular/material/tooltip';
import { AppState } from '../../../reducers';
import { CanvasActions } from '../../../actions';
import { CanvasElement, ElementPermission, ElementType, PageElement } from '../../../models';
import { ConfigService, GetTextService } from '../../../services';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { ToggleTargetableWhenUntargetableAction } from '../../../actions/canvas-actions';
import { DesignSet } from '../../../models/design-set';
import { Editor } from 'slate-react';
import { TextEditorService } from '../../../text-editor/text-editor.service';

@Component({
  selector: 'ed-canvas-tools',
  templateUrl: 'tools.component.html',
  styleUrls: ['tools.component.scss', '../../../shared/button/buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CanvasToolsComponent implements OnInit, OnDestroy {
  public init = false;
  public permissions = ElementPermission;
  public visiblePage: PageElement;
  public gridOn: boolean;
  public gridDataLayerId: string;
  public tooltipShowDelay$: Observable<number>;
  public tooltipAutoSaveDisabled$: Observable<boolean>;
  public tooltipPositionLeft: TooltipPosition = 'left';
  public tooltipPositionRight: TooltipPosition = 'right';
  public isMac$: Observable<boolean>;
  public untargetableElementsTargetable = false;
  public positioningTooltipText = this.getTextService.text.edit.order.tooltip;
  public showPositioningTool = false;
  public isHoveringChildElement = false;

  protected unsubscribe$ = new Subject<void>();

  selectedElement: CanvasElement;
  elementType = ElementType;

  constructor(
    private store: Store<AppState>,
    public getTextService: GetTextService,
    public configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    protected textEditorService: TextEditorService
  ) {
    this.changeDetectorRef.detach();
  }

  @Input() set designSet(designSet: DesignSet) {
    if (designSet.activeDesign) {
      const design = designSet.activeDesign;
      if (!this.init && designSet.init && design.visiblePage) {
        // enable change detection for this component only after initialization of design,
        // because initialisation of design is fast after loading and this causes ExpressionChangedAfterItHasBeenCheckedError
        this.init = true;
        this.changeDetectorRef.reattach();
      }
      this.untargetableElementsTargetable = designSet.untargetableElementsTargetable;
      this.gridOn = designSet.showGrid;
      this.gridDataLayerId = designSet.showGrid ? 'grid-off' : 'grid-on';
      this.selectedElement = design.selectedElement;
    }
  }

  ngOnInit() {
    const config$ = this.store.pipe(select(s => s.config));
    this.isMac$ = config$.pipe(map(config => config.isMac));
    this.tooltipShowDelay$ = config$.pipe(map(config => config.tooltipShowDelay));
    this.tooltipAutoSaveDisabled$ = this.store.pipe(
      select(fromPermissions.getAutoSave),
      map(autoSave => !autoSave)
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleGrid() {
    this.store.dispatch(new CanvasActions.ToggleGrid());
  }

  setTooltipShortcuts(isMac: boolean) {
    return `${this.getTextService.text.toolBar.save.tooltip} (${isMac ? 'Cmd' : 'Ctrl'} + S)`;
  }

  toggleUntargetableElementsTargetable() {
    this.store.dispatch(new ToggleTargetableWhenUntargetableAction());
  }

  removeElement() {
    if (this.selectedElement && this.selectedElement.permissions.isRemovable) {
      this.store.dispatch(new CanvasActions.DeActivateOverlay(this.selectedElement.route));
      this.store.dispatch(new CanvasActions.RemoveElement(this.selectedElement.route));
    }
  }

  get editor(): Editor {
    return this.textEditorService.editor;
  }

  focusEditor(): void {
    if (this.configService.isTouchDevice) {
      // happens on tablet landscape
      return;
    }
    if (this.selectedElement.isInlineText()) {
      this.editor.focus();
    }
  }

  togglePositioningTools() {
    if (this.selectedElement && this.selectedElement.permissions.isOrderable) {
      this.showPositioningTool = !this.showPositioningTool;
    }
  }

  toggleIsHoveringChildElement() {
    this.isHoveringChildElement = !this.isHoveringChildElement;
  }
}
