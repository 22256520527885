import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { GetTextService } from '../../services';
import { trigger, state, style, animate, transition } from '@angular/animations';

const expand = 'expand';
const collapse = 'collapse';

@Component({
  selector: 'ed-button-container-mob',
  templateUrl: 'button-container-mob.component.html',
  styleUrls: ['button-container-mob.component.scss', '../../editor/edit/edit-mob.component.scss'],
  animations: [
    trigger('expandContainer', [
      state(
        expand,
        style({
          height: '120px'
        })
      ),
      state(
        collapse,
        style({
          height: '60px'
        })
      ),
      transition(expand + ' => ' + collapse, [animate('0.2s')]),
      transition(collapse + ' => ' + expand, [animate('0.2s')])
    ])
  ]
})
export class ButtonContainerMobComponent {
  expanded = false;
  yOnTouchStart: number;

  expand = expand;
  collapse = collapse;

  @Input() buttonCount: number;

  constructor(public getTextService: GetTextService) {}

  showAllOptions() {
    this.expanded = true;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: AppTouchEvent) {
    this.yOnTouchStart = event.touches[0].screenY;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: AppTouchEvent) {
    this.expanded = event.touches[0].screenY < this.yOnTouchStart;
  }
}
