import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { formatImageSource, Material, Trim } from '../../../../models';

export type DialogItem = Trim | Material;
export const defaultPreviewImage = 'L.8de10a5a1a208eefdc3f9c9a0b5c787b.png';

export enum PreviewType {
  image,
  clipping
}

@Component({
  selector: 'ed-edit-option-dialog',
  templateUrl: 'edit-option-dialog.component.html',
  styleUrls: ['edit-option-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditOptionDialogComponent {
  title: object;
  items: DialogItem[];
  selectedItem: DialogItem;
  imageBase: string;
  clippingImage: string;
  previewType: PreviewType;
  dataLayerIdPrefix = '';

  @Output() selectItem = new EventEmitter<DialogItem>();

  constructor(
    public dialogRef: MatDialogRef<EditOptionDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.items = data.items;
    this.selectedItem = data.selectedItem;
    this.imageBase = data.imageBase;
    this.clippingImage = data.clippingImage;
    this.previewType = data.previewType;
    this.dataLayerIdPrefix = data.dataLayerIdPrefix;
  }

  close(): void {
    this.dialogRef.close();
  }

  chooseItem(item: DialogItem): void {
    if (item !== this.selectedItem) {
      this.selectedItem = item;
      this.selectItem.emit(item);
    }
  }

  getBackgroundImage(itemImage: string) {
    const image = (this.previewType === PreviewType.image ? itemImage : this.clippingImage) || defaultPreviewImage;
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageBase}${formatImageSource(image)}`);
  }
}
