import { EditorAction } from '../action';
import { SET_LABEL } from './canvas.action.types';

export class SetLabel implements EditorAction {
  readonly type = SET_LABEL;
  readonly undoable = true;
  readonly save = true;

  constructor(public value: string, public index = 0) {}
}
