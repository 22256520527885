import { EditorAction } from '../action';
import { ADD_IMAGE } from './canvas.action.types';

export class AddImage implements EditorAction {
  readonly type = ADD_IMAGE;
  readonly undoable = true;
  readonly save = true;

  constructor(
    public width: number,
    public height: number,
    public sid?: string,
    public url?: string,
    public x?: number,
    public y?: number,
    public isFoilable?: boolean
  ) {}
}
