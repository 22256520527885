import { NgModule } from '@angular/core';
import { ElementSettingsMenuComponent } from './element-settings-menu.component';
import { EditElementSettingToggleComponent } from './edit-element-setting/edit-element-setting-toggle.component';
import { EditElementSettingComponent } from './edit-element-setting/edit-element-setting.component';
import { ElementSettingsComponent } from './element-settings/element-settings.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ElementSettingDropdownComponent } from './edit-element-setting/element-setting-dropdown.component';

@NgModule({
  imports: [MatSidenavModule, CommonModule, SharedModule],
  declarations: [
    ElementSettingsComponent,
    EditElementSettingComponent,
    EditElementSettingToggleComponent,
    ElementSettingsMenuComponent,
    ElementSettingDropdownComponent
  ],
  exports: [
    ElementSettingsComponent,
    EditElementSettingComponent,
    EditElementSettingToggleComponent,
    ElementSettingsMenuComponent
  ],
  providers: []
})
export class ElementSettingsModule {}
