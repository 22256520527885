import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ed-error-dialog',
  templateUrl: 'error-dialog.component.html'
})
export class ErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; button: string }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
