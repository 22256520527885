import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CopyDialogText, DialogButtonText } from '../models/text';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import * as Save from './actions';
import { select, Store } from '@ngrx/store';
import * as fromSave from './reducer';
import { first, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientError } from '../models/client-error';
import { selectDesign } from '../selectors';

@Component({
  selector: 'ed-copy-dialog',
  templateUrl: 'copy-dialog.component.html',
  styleUrls: ['../shared/dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyDialogComponent implements OnInit {
  form = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.maxLength(70)])
  });

  pending$: Observable<boolean>;
  errors$: Observable<ClientError[]>;

  constructor(
    public dialogRef: MatDialogRef<CopyDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { text: { proposedDesignTitle: string; dialogText: CopyDialogText; buttonText: DialogButtonText } },
    public store$: Store<fromSave.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());

    this.pending$ = this.store$.pipe(
      select(fromSave.getSavePending),
      tap(pending => (pending ? this.form.disable() : this.form.enable()))
    );

    const design$ = this.store$.pipe(select(selectDesign));

    this.errors$ = this.store$.pipe(select(fromSave.getError));
    design$.pipe(take(1)).subscribe(() => this.form.controls['title'].setValue(this.data.text.proposedDesignTitle));
  }

  get title(): AbstractControl {
    return this.form.get('title');
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.store$.dispatch(new Save.CloseCopyDialog());
    this.resetErrors();
  }

  resetErrors(): void {
    this.store$.dispatch(new Save.ResetValidationErrors());
  }

  submit() {
    const errors = [];
    if (this.form.valid) {
      this.dispatch(this.form.value.title);
    } else if (this.title.errors.required) {
      errors.push({ message: this.data.text.dialogText.submitErrorNameText, code: '' });
    } else if (this.title.errors.maxlength) {
      errors.push({ message: this.data.text.dialogText.submitErrorLengthText, code: '' });
    }
    if (errors.length) {
      this.store$.dispatch(new Save.SubmitFailure(errors));
    }
  }

  dispatch(title: string) {
    this.store$.dispatch(new Save.Copy(title));
    this.close();
  }
}
