import { colorLuminance, RGB } from './color-utils';
import { ThemeItemNames } from '../data/theme.data';

export function isLightOrDarkColor(rgb: RGB) {
  if (rgb.r + rgb.g + rgb.b > 420 || rgb.g > 240) {
    return ThemeItemNames.colorTextDark;
  } else {
    return ThemeItemNames.colorTextLight;
  }
}

export function setHoverColor(color: string, lum: number) {
  return colorLuminance(color, lum);
}
