import { Font, FontStatus } from "src/app/models";

const FALLBACK_FONT = "Arial";

export const FontUtils = new (class {
  getFontFamily(fontLibrary: Font[], font: string) {
    return [font, FALLBACK_FONT]
      .map((f) => this.getFont(fontLibrary, f))
      .reduce((acc: string, f) => acc || (f?.name && `"${f?.name}"`), null)
      ?? `"${font ?? FALLBACK_FONT}"`;
  }

  getFont(fontLibrary: Font[], font: string) {
    return fontLibrary
      .find((f) => f.name === font?.replace(/['"]+/g, '') && f.status === FontStatus.active);
  }
});
