import { CanvasElement } from './canvas';

export interface EditorPermissions {
  setElementPermissions: SetElementPermissions;
  functionPermissions: FunctionPermissions;
}

export class FunctionPermissions {
  constructor(
    public importExportDesign = false,
    public saveDesignInCollectionOtherUser = false,
    public updateExistingDesign = false,
    public submitDesignForReview = false,
    public formatPages = false,
    public hideSaveContinueDialog = false,
    public canAddBox = false,
    public showFoilSafetyMarginWarning = false,
    public addImageAsPhotoFrame = false,
    public hasDimensionsInputField = false,
    public autoSave = false,
    public showGuideLines = false,
    public toggleSpreads = false,
    public canAddTag = false,
    public canAddTextInline = false,
    public canAddLabels = false,
    public canChangeBorderTrim = false,
    public canChangeMaterial = false,
    public canSave = true,
    public objectDetectionUpload = false,
    public canEditStyle = false,
    public allowCrossSessionElementCopying = false,
    public enableFoilableByDefault = true,
    public spotUvPermissionForJpgByDefault = false,
    public useChat = false,
    public resizeText = false,
    public showUpdateDialog = false,
    public canSearchImageLibrary = false,
    public canCreatePdf = false,
    public canAddSpotUv = false,
    public enableImageUpload = true,
    public setCharacterLimit = false
  ) {}
}

// permissions for elements
export class SetElementPermissions {
  constructor(
    public isLocked = false,
    public isPrintable = false,
    public isRecolorable = false,
    public isResizable = false,
    public isDuplicatable = false,
    public isRotatable = false,
    public isRemovable = false,
    public isMovable = false,
    public isCroppable = false,
    public isReplaceable = false,
    public isInstantReplaceable = false,
    public hasInstantReplaceablePlaceholder = false,
    public isUntargetable = false,
    public isFlippable = false,
    public isTopLayer = false,
    public isFoilable = false,
    public addChildElements = false,
    public adjustTransparency = false,
    public hasCoating = false,
    public isSpotUvable = false,
    public isCutThrough = false,
    public cutThroughInverted = false,
    public isKissCut = false,
    public isCrease = false,
    public isPerforation = false,
    public isFillable = false,
    public isOrderable = false,
    public adjustTrim = false,
    public changeMaterial = false,
    public isHidden = false,
    public useRichText = false,
    public shrinkToFit = false,
    public excludeFromPreviews = false,
    public isVisibleOutsideCropArea = false,
    public isExternallyEditable = false,
    public fontChangeable = false,
    public fontResizable = false,
    public textEditable = false
  ) {}
}

export type ElementPermissions = Partial<SetElementPermissions>;

export enum ElementPermission {
  isLocked = 'isLocked',
  isMovable = 'isMovable',
  isRemovable = 'isRemovable',
  isReplaceable = 'isReplaceable',
  isInstantReplaceable = 'isInstantReplaceable',
  hasInstantReplaceablePlaceholder = 'hasInstantReplaceablePlaceholder',
  isRotatable = 'isRotatable',
  isResizable = 'isResizable',
  isDuplicatable = 'isDuplicatable',
  isRecolorable = 'isRecolorable',
  isFoilable = 'isFoilable',
  isFlippable = 'isFlippable',
  isCroppable = 'isCroppable',
  isPrintable = 'isPrintable',
  isTopLayer = 'isTopLayer',
  isUntargetable = 'isUntargetable',
  addChildElements = 'addChildElements',
  adjustTransparency = 'adjustTransparency',
  hasCoating = 'hasCoating',
  isSpotUvable = 'isSpotUvable',
  isCutThrough = 'isCutThrough',
  cutThroughInverted = 'cutThroughInverted',
  isKissCut = 'isKissCut',
  isCrease = 'isCrease',
  isPerforation = 'isPerforation',
  isFillable = 'isFillable',
  isOrderable = 'isOrderable',
  adjustTrim = 'adjustTrim',
  changeMaterial = 'changeMaterial',
  isHidden = 'isHidden',
  useRichText = 'useRichText',
  shrinkToFit = 'shrinkToFit',
  excludeFromPreviews = 'excludeFromPreviews',
  isVisibleOutsideCropArea = 'isVisibleOutsideCropArea',
  isExternallyEditable = 'isExternallyEditable',
  fontChangeable = 'fontChangeable',
  fontResizable = 'fontResizable',
  textEditable = 'textEditable'
}

export enum FunctionPermission {
  importExportDesign = 'importExportDesign',
  saveDesignInCollectionOtherUser = 'saveDesignInCollectionOtherUser',
  updateExistingDesign = 'updateExistingDesign',
  submitDesignForReview = 'submitDesignForReview',
  formatPages = 'formatPages',
  hideSaveContinueDialog = 'hideSaveContinueDialog',
  canAddBox = 'canAddBox',
  showFoilSafetyMarginWarning = 'showFoilSafetyMarginWarning',
  addImageAsPhotoFrame = 'addImageAsPhotoFrame',
  renderAsSvg = 'renderAsSvg',
  hasDimensionsInputField = 'hasDimensionsInputField',
  autoSave = 'autoSave',
  showGuideLines = 'showGuideLines',
  toggleSpreads = 'toggleSpreads',
  canAddTag = 'canAddTag',
  canAddTextInline = 'canAddTextInline',
  canAddLabels = 'canAddLabels',
  canChangeBorderTrim = 'canChangeBorderTrim',
  canChangeMaterial = 'canChangeMaterial',
  canSave = 'canSave',
  objectDetectionUpload = 'objectDetectionUpload',
  canEditStyle = 'canEditStyle',
  allowCrossSessionElementCopying = 'allowCrossSessionElementCopying',
  enableFoilableByDefault = 'enableFoilableByDefault',
  spotUvPermissionForJpgByDefault = 'spotUvPermissionForJpgByDefault',
  useChat = 'useChat',
  resizeText = 'resizeText',
  canSearchImageLibrary = 'canSearchImageLibrary',
  canCreatePdf = 'canCreatePdf',
  canAddSpotUv = 'canAddSpotUv',
  enableImageUpload = 'enableImageUpload',
  setCharacterLimit = 'setCharacterLimit'
}

export type Permission = FunctionPermission | ElementPermission;

export const kcPermissionsMapping = {
  isLocked: 'locked',
  isMovable: 'is_movable',
  isRemovable: 'is_removable',
  isReplaceable: 'is_replaceable',
  isInstantReplaceable: 'is_instant_replaceable',
  hasInstantReplaceablePlaceholder: 'has_instant_replaceable_placeholder',
  isRotatable: 'is_rotatable',
  isResizable: 'is_resizable',
  isDuplicatable: 'is_duplicatable',
  isRecolorable: 'is_recolorable',
  isFoilable: 'is_foilable',
  isFlippable: 'is_flippable',
  isCroppable: 'is_croppable',
  isPrintable: 'is_printable',
  isTopLayer: 'is_top_layer',
  isUntargetable: 'is_untargetable',
  addChildElements: 'add_child_elements',
  adjustTransparency: 'adjust_transparency',
  hasCoating: 'coating',
  isSpotUvable: 'is_spot_uvable',
  isFillable: 'is_fillable',
  isOrderable: 'is_orderable',
  adjustTrim: 'adjust_trim',
  changeMaterial: 'change_material',
  useRichText: 'use_rich_text',
  shrinkToFit: 'shrink_to_fit',
  excludeFromPreviews: 'exclude_from_previews',
  isVisibleOutsideCropArea: 'is_visible_outside_crop_area',
  isExternallyEditable: 'is_externally_editable',
  fontChangeable: 'font_changeable',
  fontResizable: 'font_resizable',
  textEditable: 'text_editable'
};

export const toggleWhenIsLocked = [
  ElementPermission.isMovable,
  ElementPermission.isRemovable,
  ElementPermission.isCroppable,
  ElementPermission.isRotatable,
  ElementPermission.isResizable,
  ElementPermission.isDuplicatable,
  ElementPermission.isFlippable,
  ElementPermission.isRecolorable,
  ElementPermission.adjustTransparency,
  ElementPermission.isReplaceable,
  ElementPermission.isOrderable,
  ElementPermission.addChildElements,
  ElementPermission.fontChangeable,
  ElementPermission.fontResizable,
  ElementPermission.textEditable
];

export const disableWhenIsLocked = [
  ElementPermission.isFoilable,
  ElementPermission.isSpotUvable,
  ElementPermission.isInstantReplaceable,
  ElementPermission.hasInstantReplaceablePlaceholder
];

export const toggleWhenHasLayerType = [
  ElementPermission.adjustTransparency,
  ElementPermission.isRecolorable,
  ElementPermission.isCroppable,
  ElementPermission.isDuplicatable,
  ElementPermission.isReplaceable,
  ElementPermission.isFillable,
  ElementPermission.isOrderable,
  ElementPermission.isFoilable,
  ElementPermission.isSpotUvable
];

export const layerPermissions = [
  ElementPermission.isCutThrough,
  ElementPermission.cutThroughInverted,
  ElementPermission.isKissCut,
  ElementPermission.isCrease,
  ElementPermission.isPerforation
];

export const propertyPermissions = [
  ElementPermission.isPrintable,
  ElementPermission.isTopLayer,
  ElementPermission.isUntargetable,
  ElementPermission.isInstantReplaceable,
  ElementPermission.hasInstantReplaceablePlaceholder,
  ElementPermission.hasCoating,
  ElementPermission.isVisibleOutsideCropArea
];

export const operationsPermissions = [
  ElementPermission.isMovable,
  ElementPermission.isRotatable,
  ElementPermission.isFlippable,
  ElementPermission.isResizable,
  ElementPermission.isCroppable,
  ElementPermission.isDuplicatable,
  ElementPermission.isRemovable,
  ElementPermission.isReplaceable,
  ElementPermission.adjustTransparency,
  ElementPermission.textEditable
];

export const pagePermissions = [
  ElementPermission.addChildElements,
  ElementPermission.isLocked,
  ElementPermission.isHidden
];

export function getRouteForPermission(element: CanvasElement, permission: ElementPermission): number[] {
  if (Object.keys(element.permissions).indexOf(permission) > -1) {
    return element.route;
  } else if (element.firstChild && Object.keys(element.firstChild.permissions).indexOf(permission) > -1) {
    return element.firstChild.route;
  } else if (
    (element.parent.isBackgroundElement() || element.parent.isPhotoFrame()) &&
    Object.keys(element.parent.permissions).indexOf(permission) > -1
  ) {
    return element.parent.route;
  }
}

export const linkedPermissions = {
  isLocked: toggleWhenIsLocked
};

export const disableWhenSpecialColor = [
  ElementPermission.isTopLayer,
  ElementPermission.isInstantReplaceable,
  ElementPermission.hasInstantReplaceablePlaceholder,
  ElementPermission.isVisibleOutsideCropArea
];

const disableWhenHasLayerType = [
  ElementPermission.isFoilable,
  ElementPermission.isSpotUvable,
  ElementPermission.isTopLayer,
  ElementPermission.isInstantReplaceable,
  ElementPermission.hasInstantReplaceablePlaceholder,
  ElementPermission.isVisibleOutsideCropArea
];

const disableWhenIsVisibleOutsideCropArea = [
  ElementPermission.isFoilable,
  ElementPermission.isSpotUvable,
  ElementPermission.isTopLayer,
  ElementPermission.isInstantReplaceable,
  ElementPermission.hasInstantReplaceablePlaceholder,
  ElementPermission.isVisibleOutsideCropArea
];

// permissions that, if true for the element, cause others to be disabled
export const disablingPermissionsWhenTrue = [
  ElementPermission.isFoilable,
  ElementPermission.isSpotUvable,
  ElementPermission.isLocked,
  ElementPermission.isVisibleOutsideCropArea
];

// permissions that, if false for the element, cause others to be disabled
export const disablingPermissionsWhenFalse = [ElementPermission.isRecolorable];

export const disableWhenNotRecolorable = [ElementPermission.isFoilable, ElementPermission.isSpotUvable];

// if the key is true for the element, the permissions in the value get disabled
export const disabledPermissionsMap = {
  isSpotUvable: disableWhenSpecialColor,
  isFoilable: disableWhenSpecialColor,
  layerType: disableWhenHasLayerType,
  isLocked: disableWhenIsLocked,
  isVisibleOutsideCropArea: disableWhenIsVisibleOutsideCropArea,
  isRecolorable: disableWhenNotRecolorable
};
