import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'ed-edit-element-setting-toggle',
  styleUrls: ['edit-element-setting-toggle.component.scss'],
  templateUrl: 'edit-element-setting-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditElementSettingToggleComponent {
  @Input() label = '';
  @Input() tooltipText = '';
  @Input() tooltipShowDelay: number;
  @Input() checked: boolean;
  @Input() disabled = false;
  @Output() toggle = new EventEmitter();

  public tooltipPosition: TooltipPosition = 'below';

  change() {
    this.toggle.emit();
  }
}
