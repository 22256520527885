import { Component, Input } from '@angular/core';
import { ImageUploadMobComponent } from './image-upload-mob.component';
import { ImageUploadService } from '../../../services';

export enum UploadButtonSize {
  large = 'large',
  default = 'default'
}

@Component({
  selector: 'ed-image-upload-mob-new',
  templateUrl: 'image-upload-mob-new.component.html',
  styleUrls: ['image-upload-mob-new.component.scss', '../../../shared/mob-menu.scss'],
  providers: [ImageUploadService]
})
export class ImageUploadMobNewComponent extends ImageUploadMobComponent {
  buttonIconClass = 'mob-button-icon mb-1';

  @Input() text: string;
  @Input() set size(size: UploadButtonSize) {
    if (size === UploadButtonSize.large) {
      this.buttonIconClass = 'mob-button-icon mob-button-icon-large mb-1';
    }
  }

  @Input() disabled: boolean;
}
