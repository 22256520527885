import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import * as Recover from './actions';
import * as fromRecover from './reducer';
import { ClientError } from '../models/client-error';
import { DialogButtonText, RecoverDialogText } from '../models/text';

@Component({
  selector: 'ed-recover-dialog',
  templateUrl: 'recover-dialog.component.html',
  styleUrls: ['../shared/dialogs/modals.scss', 'recover-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoverDialogComponent implements OnInit {
  form = new FormGroup({
    recoverEmail: new FormControl('', [Validators.required, Validators.email])
  });

  pending$: Observable<boolean>;
  errors$: Observable<ClientError[]>;

  constructor(
    public dialogRef: MatDialogRef<RecoverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: { dialogText: RecoverDialogText; buttonText: DialogButtonText } },
    private store: Store<fromRecover.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());
    this.pending$ = this.store.pipe(
      select(fromRecover.getPending),
      tap(pending => (pending ? this.form.disable() : this.form.enable()))
    );
    this.errors$ = this.store.pipe(
      select(fromRecover.getError),
      tap(errors => {
        if (!errors) {
          this.dialogRef.close();
        }
      })
    );
  }

  onNoClick(): void {
    this.store.dispatch(new Recover.CloseRecoverDialog());
    this.store.dispatch(new Recover.ResetValidationErrors());
  }

  close(): void {
    this.dialogRef.close();
  }

  get recoverEmail(): AbstractControl {
    return this.form.get('recoverEmail');
  }

  submit() {
    const errors = [];
    if (this.form.valid) {
      this.store.dispatch(new Recover.Recover(this.form.getRawValue()));
    } else if (this.recoverEmail.errors.required) {
      errors.push({ message: this.data.text.dialogText.emailRequiredErrorText, code: '', field: 'emailRequired' });
    } else if (this.recoverEmail.errors.email) {
      errors.push({ message: this.data.text.dialogText.invalidEmailErrorText, code: '', field: 'emailInvalid' });
    }

    if (errors.length) {
      this.store.dispatch(new Recover.RecoverFailure(errors));
    }
  }
}
