import { CategoryImage, CatImgJson, ImageCategory, ImgCatJson } from '../models';

export enum ImageLibraryType {
  background = 'bg',
  image = 'image'
}

export class ImageLibrary {
  constructor(public categories: ImageCategory[]) {}
}

export interface ImageLibraryJSON {
  categories: ImgCatJson[];
}

export class ImageLibrarySearch {
  constructor(public images: CategoryImage[]) {}
}

export interface ImageLibrarySearchJSON {
  images: CatImgJson[];
}

export class ImageLibraryWithSearchTerm {
  constructor(public imageLibrary: ImageLibrary, public searchTerm: string) {}
}
