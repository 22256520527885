/**
 * Created by Carina on 7-11-2017.
 */
import { Component, Input, HostBinding, ChangeDetectionStrategy, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'button[ed-button], a[ed-button]',
  styleUrls: ['button.component.scss'],
  templateUrl: 'button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() set class(_class: string) {
    if (_class) {
      this._class = _class;
      this.isIconAndText = _class.includes('icon-and-text');
      this.setClasses();
    }
  }

  @Input() set size(size: string) {
    this._size = size;
    this.setClasses();
  }

  @Input() set color(color: string) {
    this._color = color;
    this.setClasses();
  }

  @Input() set selected(selected: boolean) {
    this._selected = selected;
    this.setClasses();
  }

  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled || null;
    this.setClasses();
  }

  @Input() icon: string;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() text: string;

  @HostBinding('attr.disabled') _disabled: boolean;

  _class = '';
  _size = '';
  _color = '';
  _selected = false;

  classes: Array<string> = [];

  isIconAndText: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  get selectedClass() {
    return this._selected ? 'selected' : '';
  }

  get disabledClass() {
    return this._disabled ? 'disabled' : '';
  }

  setClasses() {
    this.classes.map(_class => this.renderer.removeClass(this.elementRef.nativeElement, _class));

    this.classes = [...this._class.split(' '), this._size, this._color, this.selectedClass, this.disabledClass].filter(
      _class => !!_class
    );

    this.classes.map(_class => this.renderer.addClass(this.elementRef.nativeElement, _class));
  }
}
