import { fabric } from "fabric";
import { BaseElement } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/base-element" {
  interface BaseElement {
    createObjectAsync(object?: fabric.Object, data?: any): Promise<fabric.Object>;
    getObjectOptions(object: fabric.Object): fabric.IObjectOptions;
    getObjectControlsVisibility(object: fabric.Object): ControlsVisibility;
    getObjectOpacity(object: fabric.Object): number;
  }
}

const ControlsOrder = {
  tl: new fabric.Control(),
  tr: new fabric.Control(),
  bl: new fabric.Control(),
  br: new fabric.Control()
};

BaseElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement)) {
    return null;
  }

  if (!(object instanceof fabric.Object)) {
    return null;
  }

  return object;
}

BaseElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement) || !(object instanceof fabric.Object)) {
    return null;
  }

  setObjectControlsOrder(element);
  setObjectPosition(element);
  object.set({ opacity: element.getObjectOpacity(object) });

  return {
    flipX: element.flipHorizontal,
    flipY: element.flipVertical,
    strokeWidth: 0,
    lockScalingFlip: true,
    dirty: true,
    // @ts-expect-error
    elementType: element.type,
  };

  function setObjectPosition(element: BaseElement) {
    const angle = (element.screenRotation + 360) % 360;
    const { x: left, y: top } = fabric.util.rotatePoint(
      new fabric.Point(
        element.designX,
        element.designY
      ),
      (element.isBoxChild || element.isPhotoFrameChild)
        ? new fabric.Point(
          element.parent.designX + (element.parent.width / 2),
          element.parent.designY + (element.parent.height / 2)
        )
        : new fabric.Point(
          element.designX + (element.width / 2),
          element.designY + (element.height / 2)
        ),
      fabric.util.degreesToRadians(angle)
    );

    object.set({ left, top, angle });
  }

  function setObjectControlsOrder(element: BaseElement) {
    if (!element.isInlineText()) {
      object.controls = Object.assign(ControlsOrder, object.controls);
    }
  }
}

BaseElement.prototype.getObjectOpacity = function (object: fabric.Object): number {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement) || !(object instanceof fabric.Object)) {
    return 1;
  }

  return element.opacity || 1e-323;
}

BaseElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: BaseElement = this;

  if (!(element instanceof BaseElement) || !(object instanceof fabric.Object)) {
    return null;
  }

  return {
    tl: false,
    tr: false,
    br: false,
    bl: false,
    ml: false,
    mr: false,
    mt: false,
    mb: false,
    mtr: false,
    ml_imageReplaceControl: false,
    remove: false,
    move: false,
    moveInner: false,
    zoomInner: false,
    zoomInnerMinus: false,
    zoomInnerMinusDisabled: false,
    zoomInnerPlus: false,
    zoomInnerPlusDisabled: false,
  };
}
