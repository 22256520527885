import { Action } from '@ngrx/store';
import { State } from './reducer';

export enum ConfigActionsTypes {
  LoadConfig = '[Config] Loaded'
}

export class LoadConfig implements Action {
  readonly type = ConfigActionsTypes.LoadConfig;

  constructor(public payload: State) {}
}

export type ConfigActions = LoadConfig;
