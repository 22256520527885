import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CanvasElement, Design, PageElement, FunctionPermission, ImageElement, ElementType } from '../../models';
import { AppState } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { CanvasActions } from '../../actions';
import { ImageData, OBJECT_DETECTION_UPLOAD_URL } from '../../services/image-upload.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { Observable, Subscription } from 'rxjs';
import { GetTextService, UiService } from '../../services';
import * as fromPermissions from '../../reducers/permissions.reducer';
import { ImageLibraryService } from '../../image-library/image-library.service';
import { ToggleImageLibrary } from '../../image-library/actions';
import { TextEditorService } from '../../text-editor/text-editor.service';

export type panelName = 'text' | 'image' | 'background';

export class Panel {
  constructor(public name: panelName, public open = false) {}
}

export type Panels = { [P in panelName]: Panel };

function isImage(element: CanvasElement): element is ImageElement {
  return element.type === ElementType.image;
}

@Component({
  selector: 'ed-panels',
  templateUrl: 'panels.component.html',
  styleUrls: ['panels.component.scss']
})
export class PanelsComponent implements OnInit, OnDestroy {
  panels: Panels = {
    text: new Panel('text', true),
    image: new Panel('image'),
    background: new Panel('background')
  };

  public tooltipShowDelay$: Observable<number>;
  public tooltipPosition: TooltipPosition = 'below';
  canLockElements: boolean;
  isImage = isImage;
  objectDetectionUploadUrl = OBJECT_DETECTION_UPLOAD_URL;
  selectedElement: CanvasElement;
  visiblePage: PageElement;
  panelHeight = '34px';
  previousSelectedElementRoute: number[] = [];
  addImageAsPhotoFrame: boolean;
  permission = FunctionPermission;
  imageLibraryOpen = false;
  permissionsSubscription: Subscription;
  imageLibraryOpenSubscription: Subscription;
  useAbsoluteFontSize = false;

  showMoreTextOptions = false;
  showMoreImageOptions = false;
  showMoreBackgroundOptions = false;

  ngOnInit() {
    const config$ = this.store.pipe(select(s => s.config));
    this.tooltipShowDelay$ = config$.pipe(map(config => config.tooltipShowDelay));
    this.permissionsSubscription = this.store.pipe(select(fromPermissions.getAllPermissions)).subscribe(permissions => {
      this.canLockElements = permissions.isLocked;
      this.addImageAsPhotoFrame = permissions.addImageAsPhotoFrame;
    });
  }

  ngOnDestroy() {
    this.permissionsSubscription.unsubscribe();
    this.imageLibraryOpenSubscription.unsubscribe();
  }

  @Input() set design(design: Design) {
    if (design && design.pages.length) {
      this.useAbsoluteFontSize = design.useAbsoluteFontSize;
      const oldPageId = this.visiblePage ? this.visiblePage.id : -1;
      this.visiblePage = design.visiblePage;
      if (oldPageId !== this.visiblePage.id) {
        this.initImageLibraryOnPanelChange();
      }
      const previousElementIsImage =
        this.selectedElement && (this.selectedElement.isPhotoFrameChild || this.selectedElement.isPhotoFrame());
      this.selectedElement = design.selectedElement;
      const selectedElementRoute = this.selectedElement ? this.selectedElement.route : [];

      if (this.previousSelectedElementRoute.join() !== selectedElementRoute.join()) {
        if (this.selectedElement) {
          // only if element route is different and not when new selected element does not exist
          this.initPanels();
        } else if (previousElementIsImage && !this.imageLibraryOpen) {
          // if an image gets deselected while imagePanel is open, imageLibrary should be opened
          this.toggleImageLibrary(true);
        }
      }
      this.previousSelectedElementRoute = selectedElementRoute;
    }
  }

  constructor(
    private store: Store<AppState>,
    public getTextService: GetTextService,
    public textEditorService: TextEditorService,
    private imageLibraryService: ImageLibraryService,
    public uiService: UiService
  ) {
    this.imageLibraryOpenSubscription = imageLibraryService.imageLibraryOpen$.subscribe(
      open => (this.imageLibraryOpen = open)
    );
  }

  initPanels() {
    if (this.textElementSelected || this.inlineTextElementSelected) {
      this.openPanel(this.panels.text);
    } else if (this.imageSelected || this.boxSelected || this.photoFrameSelected) {
      this.openPanel(this.panels.image);
    } else if (this.backgroundSelected) {
      this.openPanel(this.panels.background);
    }
  }

  get backgroundIsLocked() {
    return this.visiblePage && this.visiblePage.backgroundIsLocked;
  }

  get backgroundSelected() {
    return (
      this.visiblePage.selectedElement &&
      (this.visiblePage.selectedElement.isBackgroundElement() || this.visiblePage.selectedElement.isBackgroundImage())
    );
  }

  get background() {
    return this.visiblePage ? this.visiblePage.background : undefined;
  }

  get page() {
    return this.visiblePage;
  }

  get textElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isText();
  }

  get inlineTextElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isInlineText();
  }

  get imageSelected(): boolean {
    return this.selectedElement && this.selectedElement.isImage() && !this.selectedElement.parent.isBackgroundElement();
  }

  get boxSelected(): boolean {
    return this.selectedElement && this.selectedElement.isBox();
  }

  get photoFrameSelected(): boolean {
    return this.selectedElement && this.selectedElement.isPhotoFrame();
  }

  get inCroppingMode() {
    return this.selectedElement && this.selectedElement.inCroppingMode;
  }

  get addChildElements() {
    return this.visiblePage && this.visiblePage.permissions.addChildElements;
  }

  get isResizing() {
    return this.uiService.isResizing;
  }

  openPanel(panel: Panel) {
    Object.keys(this.panels).map(name => (this.panels[name].open = name === panel.name));
    this.initImageLibraryOnPanelChange();
  }

  closePanel(panel: Panel) {
    this.panels[panel.name].open = false;
    this.initImageLibraryOnPanelChange();
  }

  initImageLibraryOnPanelChange() {
    let open = false;
    if (
      this.panels.image.open &&
      this.addChildElements &&
      (!this.selectedElement ||
        (!this.selectedElement.isPhotoFrame() &&
          !this.selectedElement.isPhotoFrameChild &&
          !this.selectedElement.isImage() &&
          !this.selectedElement.isBackgroundImage()))
    ) {
      open = true;
    }
    if (open !== this.imageLibraryOpen) {
      this.toggleImageLibrary(open);
    }
  }

  toggleImageLibrary(open: boolean) {
    this.store.dispatch(new ToggleImageLibrary(open));
  }

  addUploadedImage() {
    if (this.addImageAsPhotoFrame) {
      return (imageData: ImageData) =>
        this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(imageData.width, imageData.height, imageData.sid));
    } else {
      return (imageData: ImageData) =>
        this.store.dispatch(new CanvasActions.AddImage(imageData.width, imageData.height, imageData.sid));
    }
  }

  closeImageEdit() {
    this.store.dispatch(new CanvasActions.Deselect());
    this.toggleImageLibrary(true);
  }

  get isReplaceable() {
    if (this.imageSelected || this.photoFrameSelected) {
      const element = this.selectedElement as ImageElement;
      return element.isPhotoFrame() && element.permissions.isReplaceable && !element.foilType;
    }

    return false;
  }

  replaceImage() {
    return (imageData: ImageData) => {
      if (this.selectedElement.isPhotoFrame()) {
        this.store.dispatch(
          new CanvasActions.ReplaceImage(
            this.selectedElement.route,
            imageData.width,
            imageData.height,
            imageData.width,
            imageData.height,
            imageData.sid,
            '',
            false
          )
        );
      }
    };
  }
}
