import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren
} from '@angular/core';
import { CanvasActions } from 'src/app/actions';
import {
  BackgroundElement,
  BackgroundImageElement,
  Color,
  FunctionPermission,
  ImageElement,
  PageElement,
  ROTATION_RANGE,
  Trim,
  TrimTypes
} from 'src/app/models';
import { AppState } from '../../../../reducers';
import { select, Store } from '@ngrx/store';
import { GetTextService } from '../../../../services';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import * as fromPermissions from '../../../../reducers/permissions.reducer';
import { getShowTrimOption } from '../../../../config/reducer';
import { map } from 'rxjs/operators';
import { selectDesign } from '../../../../selectors';
import { ImageData } from '../../../../services/image-upload.service';

export type topMenuOptionName = 'fill' | 'flippable' | 'recolor' | 'backcolor' | 'rotate' | 'transparency' | 'trim';

export class TopMenuOption {
  public open = false;
  public show = true;
  constructor(public name: string) {}
}

export type TopMenuOptions = { [E in topMenuOptionName]: TopMenuOption };

@Component({
  selector: 'ed-edit-background-mob-new',
  templateUrl: 'edit-background-mob-new.component.html',
  styleUrls: ['edit-background-mob-new.component.scss', '../../../../shared/mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBackgroundMobNewComponent implements OnInit, AfterViewInit {
  topMenuOptions: TopMenuOptions = {
    fill: new TopMenuOption('fill'),
    flippable: new TopMenuOption('flippable'),
    recolor: new TopMenuOption('recolor'),
    backcolor: new TopMenuOption('backcolor'),
    rotate: new TopMenuOption('rotate'),
    transparency: new TopMenuOption('transparency'),
    trim: new TopMenuOption('trim')
  };

  _element: BackgroundImageElement;
  permissions = FunctionPermission;

  rotationRange = ROTATION_RANGE;

  @ViewChildren('menuLink') menuLinks: ElementRef[];

  @Input()
  set element(element: BackgroundImageElement) {
    this.topMenuOptions.fill.show =
      (element?.permissions.isFillable && element?.isBackgroundImage() && !this.backgroundLocked) ?? false;
    this.topMenuOptions.flippable.show =
      (element?.permissions.isFlippable && element?.isBackgroundImage() && !this.backgroundLocked) ?? false;
    this.topMenuOptions.recolor.show =
      (element?.permissions.isRecolorable && element?.isBackgroundImage() && !this.backgroundLocked) ?? false;
    this.topMenuOptions.rotate.show =
      (element?.permissions.isRotatable && element?.isBackgroundImage() && !this.backgroundLocked) ?? false;
    this.topMenuOptions.transparency.show =
      (element?.permissions.adjustTransparency && !this.backgroundLocked) ?? false;

    this._element = element;
  }

  get element() {
    return this._element;
  }

  _background: BackgroundElement | ImageElement | BackgroundImageElement;
  _visiblePage: PageElement;

  backgroundColor: Color;
  backgroundLocked: boolean;

  selectedTrimType: TrimTypes;
  trimOptions$: Observable<Trim[]>;
  showTrimOption$: Observable<boolean>;
  designSubscription$: Subscription;

  centerTopMenu$: Subject<boolean> = new Subject<boolean>();
  topMenuWidth = 0;

  @Input() set visiblePage(visiblePage: PageElement) {
    if (visiblePage) {
      this._visiblePage = visiblePage;
      const background = visiblePage.background;

      const identicalColorsBackgroundElements = visiblePage.backgroundElements.every(
        (element, index, array) => element.color === array[0].color
      );

      if (background && background.isBackgroundElement()) {
        this.backgroundColor = new Color('', background.color);
      } else if (background && background.isPageBackgroundImage()) {
        this.backgroundColor = new Color('', background.parent.color);
      } else if ((!background || background.isSpreadBackgroundImage()) && identicalColorsBackgroundElements) {
        this.backgroundColor = new Color('', visiblePage.backgroundElements[0].color);
      } else if ((!background || background.isSpreadBackgroundImage()) && !identicalColorsBackgroundElements) {
        this.backgroundColor = new Color('', '', undefined, true);
      } else {
        this.backgroundColor = new Color('', '');
      }

      this.backgroundLocked = visiblePage.backgroundIsLocked;
      this._background = background;
    }
  }

  get visiblePage() {
    return this._visiblePage;
  }

  @Input() addChildElements: boolean;

  get background() {
    return this._background;
  }

  get backgroundImage() {
    const isBgImage = this.background && this.background.isBackgroundImage();
    const isBgElement = this.background && this.background.isBackgroundElement();
    const spreadImage = this.visiblePage && this.visiblePage.spreadBackgroundImage;

    return isBgImage ? this.background : isBgElement && spreadImage ? spreadImage : undefined;
  }

  get backgroundImageColor(): Color {
    return new Color('', this.backgroundImage.color);
  }

  @Output() openBackgroundImageMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  get topMenuOptionsOpen(): boolean {
    return Object.values(this.topMenuOptions).findIndex(val => val.open === true) > -1;
  }

  constructor(private store: Store<AppState>, public getTextService: GetTextService, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.menuLinks) {
      this.menuLinks.forEach(link => (this.topMenuWidth += link.nativeElement.offsetWidth));
      if (this.topMenuWidth < this.elementRef.nativeElement.offsetWidth) {
        this.centerTopMenu$.next(true);
      } else {
        this.centerTopMenu$.next(false);
      }
    }
  }

  ngOnInit() {
    this.trimOptions$ = this.store.pipe(select(s => s.config.trimData));

    this.showTrimOption$ = combineLatest([
      this.store.pipe(select(fromPermissions.getFunctionPermissions)),
      this.store.pipe(select(getShowTrimOption))
    ]).pipe(map(([permissions, showTrim]) => permissions.canChangeBorderTrim && showTrim));

    const design$ = this.store.pipe(select(selectDesign));
    this.designSubscription$ = design$.subscribe(design => {
      this.selectedTrimType = design.trimType;
    });
  }

  openTopMenuOption(topMenuOption: TopMenuOption) {
    Object.keys(this.topMenuOptions).map(name => (this.topMenuOptions[name].open = name === topMenuOption.name));
  }

  closeAllOptions() {
    Object.keys(this.topMenuOptions).map(name => (this.topMenuOptions[name].open = false));
  }

  removeImage() {
    this.store.dispatch(new CanvasActions.DeActivateOverlay(this.element.route));
    this.store.dispatch(new CanvasActions.RemoveElement(this.element.route));
  }

  uploadBackgroundImage() {
    return (imageData: ImageData) => {
      this.store.dispatch(
        new CanvasActions.CheckDoubleBackgroundImage(imageData.width, imageData.height, imageData.sid)
      );
    };
  }

  chooseBackgroundColor(color: Color) {
    this.store.dispatch(new CanvasActions.ChangeBackgroundColor(color.colorValue));
  }

  chooseForegroundColor(color: Color) {
    this.store.dispatch(new CanvasActions.ChangeColor(this.backgroundImage.route, color));
  }

  resetForegroundColor() {
    this.store.dispatch(new CanvasActions.ResetImageColor(this.backgroundImage.route));
  }

  fillHorizontal() {
    this.store.dispatch(new CanvasActions.FillHorizontal(this.backgroundImage.route));
  }

  fillVertical() {
    this.store.dispatch(new CanvasActions.FillVertical(this.backgroundImage.route));
  }

  flipHorizontal() {
    this.store.dispatch(
      new CanvasActions.FlipHorizontal(this.backgroundImage.route, !this.backgroundImage.flipHorizontal)
    );
  }

  flipVertical() {
    this.store.dispatch(new CanvasActions.FlipVertical(this.backgroundImage.route, !this.backgroundImage.flipVertical));
  }

  rotate(rotation: number) {
    if (this.backgroundImage.rotation !== rotation) {
      this.store.dispatch(
        new CanvasActions.Rotate(
          this.backgroundImage.route,
          this.backgroundImage.screenWidth,
          this.backgroundImage.screenHeight,
          this.backgroundImage.screenX,
          this.backgroundImage.screenY,
          rotation
        )
      );
    }
  }

  changeTransparency(transparency: number) {
    this.store.dispatch(new CanvasActions.ChangeTransparency(this.backgroundImage.route, transparency));
  }

  changeTrim(trim: Trim) {
    this.store.dispatch(new CanvasActions.ChangeTrim(trim));
  }

  protected readonly ROTATION_RANGE = ROTATION_RANGE;
}
