import { NgModule } from '@angular/core';
import { NavComponent } from './nav.component';
import { NavMobComponent } from './nav-mob.component';
import { SharedModule } from '../../shared/shared.module';
import { EditModule } from '../edit/edit.module';
import { NavThumbsComponent } from './nav-thumbs.component';
import { NavMobNewComponent } from './nav-mob-new/nav-mob-new.component';
import { SwiperModule } from 'swiper/angular';
import { AddMenuComponent } from './nav-mob-new/add-menu/add-menu.component';
import { PageMenuComponent } from './nav-mob-new/page-menu/page-menu.component';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';

SwiperCore.use([Mousewheel, Navigation]);

@NgModule({
  imports: [SharedModule, EditModule, SwiperModule],
  declarations: [
    NavComponent,
    NavMobComponent,
    NavMobNewComponent,
    NavThumbsComponent,
    AddMenuComponent,
    PageMenuComponent
  ],
  exports: [NavComponent, NavMobComponent, NavMobNewComponent, NavThumbsComponent, AddMenuComponent, PageMenuComponent]
})
export class NavModule {}
