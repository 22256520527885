import { EditorAction } from '../action';
import { RESIZE } from './canvas.action.types';

export class Resize implements EditorAction {
  readonly type = RESIZE;
  readonly undoable = true;
  readonly save = true;

  constructor(
    public route: Array<number>,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public scaleToRatio: boolean = true
  ) {}
}
