import { MOVE_TO_BACK } from './canvas.action.types';
import { EditorAction } from '../action';

export class MoveToBack implements EditorAction {
  readonly type = MOVE_TO_BACK;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>) {}
}
