import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ed-shop-branding',
  templateUrl: 'shop-branding.component.html',
  styleUrls: ['shop-branding.component.scss']
})
export class ShopBrandingComponent {
  @Input() logoUrl: string;
  @Input() headerTitle: string;

  @Output() goToUrl = new EventEmitter();

  onClick() {
    this.goToUrl.emit();
  }
}
