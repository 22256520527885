import { createSelector } from '@ngrx/store';
import { Design } from '../models';
import { DesignSet } from '../models/design-set';
import { AppState } from '../reducers';

export const getDesignSet = createSelector(
  (state: AppState) => state.designSet,
  (state: DesignSet) => state
);

export const selectDesign = createSelector(
  (state: AppState) => state.designSet,
  (state: DesignSet) => state.designs.find(d => d.active)
);

export const designLoaded = createSelector(getDesignSet, (state: DesignSet) => !!state.designs.length);

export const getSelectedElement = createSelector(selectDesign, (state: Design) => state.selectedElement);

export const pageIsFoilable = createSelector(
  selectDesign,
  (state: Design) => state && state.material.isFoilable && state.visiblePage.permissions.isFoilable
);

export const pageIsSpotUvable = createSelector(
  selectDesign,
  (state: Design) => state && state.material.isSpotUvable && state.visiblePage.permissions.isSpotUvable
);
