import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ed-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['../modals.scss']
})
export class WarningDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; text: string; buttonText: string }
  ) {}

  ngOnInit() {
    // prevent all keyboard events while modal is open
    this.dialogRef.keydownEvents().subscribe(event => event.stopPropagation());
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
