import { EditorAction } from '../action';
import { ADD_CUSTOM_COLOR } from './canvas.action.types';
import { Color } from '../../models';

export class AddCustomColor implements EditorAction {
  readonly type = ADD_CUSTOM_COLOR;
  readonly undoable = false;
  readonly save = true;

  constructor(public customColor: Color) {}
}
