import { EditInlineTextComponent } from './edit-inline-text.component';
import { ConfigService, CopyService, GetTextService, UiService } from '../../../services';
import { TextEditorService } from '../../../text-editor/text-editor.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Color, InlineTextElement, MarkTypes, ROTATION_RANGE } from '../../../models';
import { Subscription } from 'rxjs';
import { CanvasActions } from '../../../actions';
import { HorizontalAlignment } from '../../../../../react-text-editor/models/text-editor.model';
import { Block } from 'slate';

export type topMenuOptionName =
  | 'edit'
  | 'font'
  | 'fontColor'
  | 'fontSize'
  | 'fontStyle'
  | 'lineHeight'
  | 'transparency'
  | 'horizontalAlign'
  | 'text'
  | 'rotate'
  | 'flippable'
  | 'order';

export class TopMenuOption {
  public open = false;
  public show = true;

  constructor(public name: string) {}
}
export type TopMenuOptions = { [E in topMenuOptionName]: TopMenuOption };

@Component({
  selector: 'ed-edit-inline-text-mob-new',
  templateUrl: './edit-inline-text-mob-new.component.html',
  styleUrls: ['../../../shared/mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInlineTextMobNewComponent extends EditInlineTextComponent implements OnInit, OnDestroy {
  topMenuOptions: TopMenuOptions = {
    edit: new TopMenuOption('edit'),
    flippable: new TopMenuOption('flippable'),
    font: new TopMenuOption('font'),
    fontColor: new TopMenuOption('fontColor'),
    fontSize: new TopMenuOption('fontSize'),
    fontStyle: new TopMenuOption('fontStyle'),
    horizontalAlign: new TopMenuOption('horizontalAlign'),
    lineHeight: new TopMenuOption('lineHeight'),
    order: new TopMenuOption('order'),
    rotate: new TopMenuOption('rotate'),
    text: new TopMenuOption('text'),
    transparency: new TopMenuOption('transparency')
  };

  elementColor: Color;

  canAddTextInlineSubscription: Subscription;
  canAddTextInline: boolean;

  rotationRange = ROTATION_RANGE;

  @ViewChild('fakeInput', { static: false }) fakeInput: ElementRef;

  @Input()
  set element(element: InlineTextElement) {
    this.topMenuOptions.flippable.show = element.permissions.isFlippable;
    this.topMenuOptions.font.show = element.permissions.fontChangeable;
    this.topMenuOptions.fontColor.show = element.permissions.isRecolorable;
    this.topMenuOptions.fontSize.show = element.permissions.fontResizable;
    this.topMenuOptions.order.show = element.permissions.isOrderable;
    this.topMenuOptions.rotate.show = element.permissions.isRotatable;
    this.editFullRange = element.editFullRange;
    this.topMenuOptions.transparency.show = !element.foilType && element.permissions.adjustTransparency;
    this._element = element;
    this._inlineTextElement = element;
    this.elementColor = new Color('', element.colors[0], element.foilType);
  }
  get element() {
    return this._element;
  }
  @Input() addChildElements: boolean;

  get topMenuOptionsOpen(): boolean {
    return Object.values(this.topMenuOptions).findIndex(val => val.open === true) > -1;
  }

  openTopMenuOption(topMenuOption: TopMenuOption) {
    Object.keys(this.topMenuOptions).map(name => (this.topMenuOptions[name].open = name === topMenuOption.name));
  }

  closeAllTopMenuOptions() {
    Object.keys(this.topMenuOptions).map(name => (this.topMenuOptions[name].open = false));
  }

  constructor(
    public getTextService: GetTextService,
    protected textEditorService: TextEditorService,
    protected store: Store<AppState>,
    protected config: ConfigService,
    public uiService: UiService,
    protected copyService: CopyService
  ) {
    super(getTextService, textEditorService, store, config, uiService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.canAddTextInlineSubscription = this.store
      .pipe(select(s => s.permissions.functionPermissions.canAddTextInline))
      .subscribe(canAddTextInline => (this.canAddTextInline = canAddTextInline));
  }

  get scrollableMenu() {
    // If all menuOptions are available menu should be scrollable
    return (
      this.canAddTextInline &&
      !this.element.permissions.isLocked &&
      this.element.permissions.isRemovable &&
      this.duplicatable &&
      this.pasteable
    );
  }

  addText() {
    this.store.dispatch(new CanvasActions.AddTextInline(this.getTextService.text.edit.textInput.placeholder));
  }

  editText() {
    // on ios the only way to manualy trigger the keyboard, is to call focus in a touchstart event handler
    this.fakeInput.nativeElement.focus();
    this.store.dispatch(new CanvasActions.SetInlineTextEditMode(this.element.route, false));
  }

  changeFontsize(value: number) {
    this._wrapOperation(false, () => {
      if (!isNaN(value)) {
        this.textEditorService.removeMark(MarkTypes.Fontsize);
        this.textEditorService.addMark(MarkTypes.Fontsize, value * this.fontSizeScale);
      }
    });
  }

  toggleFontStyle(markType: string) {
    this._wrapOperation(false, () => this.editor.toggleMark(markType));
  }

  changeHorizontalAlign(align: HorizontalAlignment) {
    this._wrapOperation(false, () => {
      if (align !== this.horizontalAlign) {
        this.textEditorService.selectedParagraphs.forEach(paragraph => {
          if (paragraph.data.get('isJustified')) {
            this.textEditorService.setNodeData(paragraph, { isJustified: false });
          }
          paragraph.nodes.forEach((line: Block) => {
            this.textEditorService.setNodeData(line, { textAlign: align, wordSpacing: undefined });
          });
        });
      }
    });
  }

  justify() {
    this._wrapOperation(false, () => {
      this.textEditorService.selectedParagraphs.forEach(paragraph => {
        this.textEditorService.setNodeData(paragraph, { isJustified: true });
      });
    });
  }

  get cuttable() {
    return this.copyService.allowCutting;
  }

  get duplicatable() {
    return this.copyService.allowDuplicating;
  }

  get pasteable() {
    return !!this.copyService.getClipboardElement() && this.copyService.allowPasting;
  }

  cut() {
    this.copyService.cut();
  }

  copy() {
    this.copyService.copy();
  }

  paste() {
    this.copyService.paste();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.canAddTextInlineSubscription.unsubscribe();
  }
}
