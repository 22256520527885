import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { CanvasElement, BorderRadius, ImageElement, PageElement } from '../../../models';
import { GetTextService } from '../../../services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ed-page-overlay',
  templateUrl: 'page-overlay.component.html',
  styleUrls: ['page-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageOverlayComponent {
  public children: Array<CanvasElement> = [];
  private _visiblePage: PageElement = new PageElement(-1);

  @Input() pageLeft: number;

  @Input() pageTop: number;

  @Input() zoom: number;

  @Input() set trim(borderRadius: BorderRadius) {
    if (borderRadius) {
      const leftTop = borderRadius.leftTop * this.zoom;
      const rightTop = borderRadius.rightTop * this.zoom;
      const rightBottom = borderRadius.rightBottom * this.zoom;
      const leftBottom = borderRadius.leftBottom * this.zoom;

      this.borderRadius = `${leftTop}px ${rightTop}px ${rightBottom}px ${leftBottom}px`;
    }
  }

  @HostBinding('style.border-radius')
  borderRadius: string;

  @Input()
  set visiblePage(visiblePage: PageElement) {
    this._visiblePage = visiblePage;

    // get children except the selected element, because this one is already in the overlay
    this.children = this.visiblePage.children.filter(el => el !== this.visiblePage.selectedElement);
  }

  get visiblePage() {
    return this._visiblePage;
  }

  constructor(private sanitizer: DomSanitizer, public getTextService: GetTextService) { }

  get bleed(): number {
    return this.visiblePage.screenBleed * this.zoom;
  }

  get safety(): number {
    return this.visiblePage.screenSafety * this.zoom;
  }

  get pageWidth(): number {
    return this.visiblePage.screenWidth * this.zoom;
  }

  get pageHeight(): number {
    return this.visiblePage.screenHeight * this.zoom;
  }

  get safetyWidth(): number {
    return this.pageWidth - this.safety * 2;
  }

  get safetyHeight(): number {
    return this.pageHeight - this.safety * 2;
  }

  get safetyMargin(): number {
    return this.bleed + this.safety;
  }

  @HostBinding('style.width.px') get width(): number {
    return this.pageWidth + this.bleed * 2;
  }

  @HostBinding('style.height.px') get height(): number {
    return this.pageHeight + this.bleed * 2;
  }

  @HostBinding('style.margin-left.px') get left(): number {
    return this.pageLeft - this.bleed;
  }

  @HostBinding('style.margin-top.px') get top(): number {
    return this.pageTop - this.bleed;
  }

  transform(element: ImageElement) {
    return `rotate(${element.rotation}deg) scale(${element.flipHorizontal ? -1 : 1} ,${element.flipVertical ? -1 : 1})`;
  }

  safeUrl(imgSource: string) {
    if (imgSource) {
      return this.sanitizer.bypassSecurityTrustUrl(imgSource);
    }
  }

  itemTrackBy(index: number, item: CanvasElement) {
    return item.route.join('');
  }
}
