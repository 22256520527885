import { State as ConfigState } from '../../app/config/reducer';

export const EnvConfig: RecursivePartial<ConfigState> = {
  googleTagManagerID: 'GTM-MOCK',
  imgBase: 'https://img.acceptatie.kaart-o-matic.nl',
  toolBarColorStart: '#bb291c',
  toolBarColorEnd: '#729f0f',
  text: {
    title: 'Editor App',
    topMenu: {
      headerTitle: ''
    }
  },
  tags: ['tag 1', 'tag 2', 'tag 3']
};
