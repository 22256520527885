import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as fromSave from '../../save/reducer';
import { SaveDesignInCollectionDialogText, SaveErrorText } from '../../models/text';
import { Observable } from 'rxjs';
import { ClientError } from '../../models/client-error';
import * as fromAdminDesigner from '../../admin-designer/reducer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DESIGN_NAME_COLLECTION_MAX_LENGTH } from '../../data/designs.data';
import * as AdminDesigner from '../../admin-designer/actions';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'ed-save-design-in-collection-dialog-component',
  templateUrl: 'save-design-in-collection-dialog.component.html',
  styleUrls: ['../dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveDesignInCollectionDialogComponent implements OnInit {
  errors$: Observable<ClientError[]>;
  pending$: Observable<boolean>;
  errors: ClientError[];
  readonly title = 'user_design_title';
  readonly email = 'user_email';

  saveToCollectionForm = new FormGroup({
    userTitle: new FormControl('', [Validators.required, Validators.maxLength(DESIGN_NAME_COLLECTION_MAX_LENGTH)]),
    emailAddress: new FormControl('', Validators.required)
  });

  constructor(
    private store: Store<fromSave.State>,
    private dialogRef: MatDialogRef<SaveDesignInCollectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: SaveDesignInCollectionDialogText; errorText: SaveErrorText }
  ) {}

  ngOnInit() {
    this.pending$ = this.store.pipe(select(fromAdminDesigner.getPending));
    this.errors$ = this.store.pipe(
      select(fromAdminDesigner.getError),
      tap((errors: ClientError[]) => (this.errors = errors))
    );
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.resetErrors());
  }

  hasInputErrors(field: string): boolean {
    if (this.errors?.length) {
      return this.errors.some(error => error.field === field);
    }
  }

  resetErrors(): void {
    this.store.dispatch(new AdminDesigner.ResetValidationErrors());
  }

  submitDesignToOtherUser() {
    const errors: ClientError[] = [];
    const titleErrors = this.saveToCollectionForm.controls.userTitle.errors;
    const emailErrors = this.saveToCollectionForm.controls.emailAddress.errors;

    if (titleErrors) {
      if (titleErrors.required) {
        errors.push({ message: this.data.errorText.nameRequired, code: '', field: this.title });
      } else if (titleErrors.maxlength) {
        errors.push({ message: this.data.errorText.maximumLengthExceeded, code: '', field: this.title });
      }
    }

    if (emailErrors && emailErrors.required) {
      errors.push({ message: this.data.errorText.emailRequired, code: '', field: this.email });
    }

    if (this.saveToCollectionForm.valid) {
      this.store.dispatch(new AdminDesigner.SubmitSaveDesignOtherUser(this.saveToCollectionForm.getRawValue()));
    } else if (errors) {
      this.store.dispatch(new AdminDesigner.SubmitDesignOtherUserFailure(errors));
    }
  }
}
