import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { Color } from '../../models';
import { first } from 'rxjs/operators';
import { colorExistsInArray } from '../../utils/color-utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ColorMenuDialogText } from '../../models/text';
import { ColorPickerDialogData } from './color-menu.service';

@Component({
  selector: 'ed-color-picker-dialog-mob',
  templateUrl: 'color-picker-dialog-mob.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerDialogMobComponent {
  @Output() selectedColor: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() customColor: EventEmitter<Color> = new EventEmitter<Color>();
  @Output() customColorMenuClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentColor$: Observable<Color>;
  customColors$: Observable<Color[]>;
  text: ColorMenuDialogText;
  colorPickerWidth: number;
  dataLayerIdPrefix: string;

  constructor(
    public dialogRef: MatDialogRef<ColorPickerDialogMobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ColorPickerDialogData
  ) {
    this.currentColor$ = data.currentColor$;
    this.customColors$ = this.data.customColors$;
    this.colorPickerWidth = this.data.colorPickerWidth;
    this.dataLayerIdPrefix = this.data.dataLayerIdPrefix;
  }

  changeColor(color: Color) {
    this.selectedColor.emit(color);
  }

  addCustomColor(color: Color) {
    this.customColor.emit(color);
  }

  closeMenu() {
    this.customColorMenuClosed.emit(true);
    this.dialogRef.close();
  }

  saveCurrentCustomColor(color: Color) {
    this.customColors$.pipe(first()).subscribe(colors => {
      const colorExist = colorExistsInArray(color, colors);
      if (!colorExist) {
        this.addCustomColor(color);
      }
    });
  }
}
