import { EditorAction } from '../action';
import { INIT_DESIGN } from './canvas.action.types';
import { DesignArea } from '../../editor/design/design.component';

export class InitDesign implements EditorAction {
  readonly type = INIT_DESIGN;
  readonly undoable = false;
  readonly save = false;

  constructor(public rectangle: DesignArea) {}
}
