import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { uniqBy } from 'lodash-es';
import { TooltipPosition } from '@angular/material/tooltip';

export class DropdownOption {
  constructor(public title: string, public value: string, public tooltip: string = '') {}
}

@Component({
  selector: 'ed-element-setting-dropdown',
  styleUrls: ['edit-element-setting.component.scss'],
  templateUrl: 'element-setting-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementSettingDropdownComponent implements OnChanges {
  @Input() options: DropdownOption[];
  @Input() selected: string;
  @Input() required = true;
  @Input() disabled = false;
  @Input() placeholderText = '';
  @Input() removeText = '';
  @Input() tooltipDelay: number;
  @Output() selectOption = new EventEmitter<string>();
  public tooltipPosition: TooltipPosition = 'below';
  public _selected: DropdownOption;

  constructor() {}

  get allOptions() {
    return this._selected ? uniqBy([...this.options, this._selected], 'value') : this.options;
  }

  chooseOption(option: string) {
    this.selectOption.emit(option);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected && this.options.length) {
      this._selected = this.options.find((opt: DropdownOption) => opt.value === this.selected);
    }
  }
}
