import { BackgroundTypes } from './page-element';
import { FoilTypes } from './color';
import { Labels, View, Fold, Material } from './design';
import { Paragraph } from './inline-text-element';
import { CanvasCoordinate } from './canvas';
import { ElementPermissions } from './permissions';
import { TrimTypes } from './trim';
import { FormatSpec } from './format-spec';

export enum KcElementType {
  photo = 'photo',
  text = 'text',
  richText = 'rich_text'
}

export enum KcClipPathType {
  photoFrame = 'photoFrame',
  box = 'box'
}

export enum flipType {
  horizontal = 'horizontal',
  vertical = 'vertical',
  both = 'both'
}

export enum KcLayerType {
  kissCut = 'kiss_cut',
  crease = 'crease',
  perforation = 'perforation',
  cutThrough = 'cut_through',
  cutThroughInverted = 'cut_through_inverted',
  foil = 'foil',
  standardRgb = 'rgb',
  spotUv = 'spot_uv',
  spotUvImage = 'spot_uv_80'
}

export const KC_LAYER_TYPE_MAPPING = {
  kissCut: KcLayerType.kissCut,
  crease: KcLayerType.crease,
  perforation: KcLayerType.perforation,
  cutThrough: KcLayerType.cutThrough,
  cutThroughInverted: KcLayerType.cutThroughInverted,
  standardRgb: KcLayerType.standardRgb,
  spotUv: KcLayerType.spotUv,
  spotUvImage: KcLayerType.spotUvImage
};

type KcPagesKey = 'p1' | 'p2' | 'p3' | 'p4' | 'p5' | 'p6' | 'p7' | 'p8';

export type KcDesign = {
  [name in KcPagesKey]?: KcPage;
} & { design_data?: KcDesignData; _version?: number };

/**
 * next to the pages, you can save design data here
 */
export class KcDesignData {
  view: View = View.pages;
  fold: Fold;
  design_images: { name: string; sid?: string; url?: string }[] = [];
  design_colors: { name: string; color_value: string }[] = [];
  labels?: Labels;
  material?: Material;
  trim?: TrimTypes;
  permissions?: ElementPermissions;
  specs?: FormatSpec;
  adjust_trim_permission_was_changed?: boolean;
}

export class KcPage {
  public images: Array<KcRichTextElement | KcImageElement | KcTextElement> = [];
  public h: number;
  public bg: KcBackground;
  public w: number;
  public route?: Array<number>;
  public bleed?: number;
  public safety?: number;
  public add_child_elements?: boolean;
  public locked?: boolean;
  public is_hidden?: boolean;
  public design_colors?: boolean; // deprecated
  public position?: number;
  public foil_enabled?: boolean;
  public spot_uv_enabled?: boolean;
}

export class KcBaseElement {
  public template_id?: number;
  public tag?: string;
  public x: number;
  public y: number;
  public trans: number;
  public type: KcElementType;
  public r: number;
  public locked: number;
  public is_printable?: boolean;
  public is_resizable?: boolean;
  public is_duplicatable?: boolean;
  public is_removable?: boolean;
  public is_movable?: boolean;
  public is_rotatable?: boolean;
  public is_croppable?: boolean;
  public is_replaceable?: boolean;
  public is_instant_replaceable?: boolean;
  public has_instant_replaceable_placeholder?: boolean;
  public is_untargetable?: boolean;
  public is_flippable?: boolean;
  public is_top_layer?: boolean;
  public is_foilable?: boolean;
  public is_spot_uvable?: boolean;
  public flip?: flipType;
  public route?: Array<number>;
  public clip_path?: CanvasCoordinate[];
  public clip_path_type?: KcClipPathType;
  public foil_type?: FoilTypes;
  public order?: number;
  public coating?: string;
  public is_cut_through?: boolean;
  public is_foilable_permission_was_changed?: boolean;
  public layer_type?: KcLayerType;
  public is_externally_editable?: boolean;
}

export class KcImageElement extends KcBaseElement {
  readonly type = KcElementType.photo;
  public fg_color: string;
  public recolor: boolean;
  public aspect: number;
  public sid?: string;
  public url?: string;
  public size: number;
  public is_bg_image: boolean;
  public is_bg_image_spread: boolean;
  public is_recolorable?: boolean;
  public is_spotuvable_for_jpg_image_permission_was_changed?: boolean;
}

export class KcTextElement extends KcBaseElement {
  readonly type = KcElementType.text;
  public va: string;
  public font: string;
  public color: string;
  public text: string;
  public markup: string;
  public s: number;
  public w: number;
  public h: number;
  public ha: string;
  public is_justify: boolean;
  public text_editable?: boolean;
  public use_absolute_font_size?: boolean;
  public font_changeable?: boolean;
  public font_resizable?: boolean;
  public is_recolorable?: boolean;
}

export class KcRichTextElement extends KcBaseElement {
  readonly type = KcElementType.richText;
  readonly font_version: number = 1;
  public text: Paragraph[];
  public w: number;
  public h: number;
  public padding: number;
  public text_editable: boolean;
  public character_limit: number;
  public font_changeable?: boolean;
  public font_resizable?: boolean;
  public is_recolorable?: boolean;
}

export class KcBackground {
  public t? = BackgroundTypes.color;
  public h: number;
  public bgc = '#ffffff';
  public w: number;
  public fgc?: string;
  public sid?: string;
  public locked?: boolean;
}
