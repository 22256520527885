import { EditorAction } from '../action';
import { CHANGE_BACKGROUND_COLOR } from './canvas.action.types';

/**
 * params
 * route: visible page route.
 */

export class ChangeBackgroundColor implements EditorAction {
  readonly type = CHANGE_BACKGROUND_COLOR;
  readonly undoable = true;
  readonly save = true;

  constructor(public color: string) {}
}
