import { Directive, HostListener } from '@angular/core';
import { Y, Z } from '@angular/cdk/keycodes';

@Directive({ selector: 'textarea, input' })
export class FocusDirective {
  hasFocus = false;

  @HostListener('focus', ['$event'])
  @HostListener('blur', ['$event'])
  focusOrBlur(event: Event) {
    this.hasFocus = event.type === 'focus';
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const ctrl = event.ctrlKey;
    const meta = event.metaKey;

    switch (event.keyCode) {
      case Y:
      case Z:
        if (ctrl || meta) {
          // prevent normal undo/redo behaviour of text input, conflicts with ours
          event.preventDefault();
          return false;
        }
        break;
    }

    if (this.hasFocus) {
      // to stop behaviour like remove element by backspace or delete keys, when editing in text-input
      event.stopPropagation();
      return;
    }
  }
}
