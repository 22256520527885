import * as FontLibActions from './actions';
import { Font, FontStatus } from '../models';
import { createFeatureSelector, createSelector, createReducer, on, Action } from '@ngrx/store';
import { canAddTextInline, selectPermissionsState } from '../reducers/permissions.reducer';
import { getDesignSet } from '../selectors';

export enum fontLibraryStatus {
  fontLibraryLoading,
  fontsLoading,
  active,
  inactive
}

export interface FontLibraryState {
  fontlibrary: Font[];
  error: string | null;
  status: fontLibraryStatus;
}

export const initialState: FontLibraryState = {
  fontlibrary: new Array<Font>(),
  error: null,
  status: fontLibraryStatus.fontLibraryLoading
};

const fontLibReducer = createReducer(
  initialState,
  on(FontLibActions.LoadFontLibrary, (state, { fontLibrary }) => ({
    ...state,
    fontlibrary: fontLibrary,
    status: fontLibraryStatus.fontsLoading
  })),
  on(FontLibActions.LoadFontLibraryFailure, (state, { payload }) => ({
    ...state,
    error: payload,
    status: fontLibraryStatus.inactive
  })),
  on(FontLibActions.LoadFont, (state, { font }) => {
    let fonts = [...state.fontlibrary];
    const oldFont = fonts.find(f => f.name === font.name);
    const newFont = Object.assign(new Font(font.name, oldFont?.screenTitle), font);

    // find all old fonts connected
    const fontIndex = fonts.findIndex(libFont => !!newFont.oldNames.find(oldName => oldName === libFont.name));
    fonts = fonts.filter(libFont => !newFont.oldNames.find(oldName => oldName === libFont.name));

    fontIndex >= 0 ? fonts.splice(fontIndex, 0, newFont) : fonts.push(newFont);

    return {
      ...state,
      fontlibrary: fonts
    };
  }),
  on(FontLibActions.SetFontLibraryStatus, (state, { status }) => ({
    ...state,
    status
  }))
);

export function reducer(state = initialState, action: Action): FontLibraryState {
  return fontLibReducer(state, action);
}

export const selectLibraryState = createFeatureSelector<FontLibraryState>('fontlibrary');

export const getError = createSelector(selectLibraryState, (state: FontLibraryState) => state.error);

export const getStatus = createSelector(selectLibraryState, (state: FontLibraryState) => state.status);

export const getFontLibrary = createSelector(selectLibraryState, (state: FontLibraryState) => state.fontlibrary);

export const getFontLibraryLoadedAndActive = createSelector(
  getStatus,
  canAddTextInline,
  (status, useInlineText) => !useInlineText || status === fontLibraryStatus.active
);

export const getVisiblePageFontsLoaded = createSelector(
  selectLibraryState,
  getDesignSet,
  selectPermissionsState,
  (state, design, permissions) => {
    if (!state.fontlibrary.length || !permissions.init || !design.init) {
      return false;
    }

    if (!design.visiblePage.fonts.length) {
      return true;
    }

    const libraryFonts = design.visiblePage.fonts.map(pageFont => {
      return state.fontlibrary.find(font => {
        return (
          font.name === pageFont.name ||
          (font.fontFaces.length && font.fontFaces.find(face => face.family === pageFont.name))
        );
      });
    });

    if (libraryFonts.filter(f => f === undefined).length) {
      // a font is not found in the fontlibrary (yet)
      return false;
    }

    const fontIsLoading = libraryFonts.find(f => f.status === FontStatus.loading);
    return !permissions.functionPermissions.canAddTextInline || !fontIsLoading;
  }
);
