import { Action } from '@ngrx/store';
import { TOGGLE_ELEMENT_PERMISSION } from './canvas.action.types';
import { ElementPermission } from '../../models';

export class ToggleElementPermission implements Action {
  readonly type = TOGGLE_ELEMENT_PERMISSION;
  undoable = false;
  readonly save = true;

  constructor(public permission: ElementPermission, public route?: Array<number>) {}
}
