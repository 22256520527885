import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ed-style-edit-header',
  templateUrl: 'style-edit-header.component.html',
  styleUrls: ['style-edit-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StyleEditHeaderComponent {
  @Input() optionActive: boolean;
  @Input() text: string;
}
