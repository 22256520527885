import { Component, HostListener, OnInit } from '@angular/core';
import { KeyEventHandlerService, UiService, ThemeService, GetTextService, ConfigService } from './services';
import { combineLatest, Observable } from 'rxjs';
import { FontLoadService } from './services/font-load.service';
import { select, Store } from '@ngrx/store';
import { AppState } from './reducers';
import { ElementTransformService } from './services/element-transform.service';
import { getDesignHasChangedSinceInit, getLastChangesAreSaved } from './save/reducer';
import { environment } from '../environments/environment';
import { getNavigationHandled } from './continue/reducer';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { BrowserStorageService } from './services/browser-storage.service';
import { UpdateService } from './services/check-for-update.service';

/**
 * This class represents the main application component. Within the @Routes annotation is the configuration of the
 * applications routes, configuring the paths for the lazy loaded components (HomeComponent, AboutComponent).
 */
@Component({
  selector: 'ed-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  unsavedChanges: boolean;
  navigationHandled: boolean;
  landscapeMode$: Observable<boolean>;
  hideForcePortrait: boolean;
  hideForcePortraitKey = 'hideForcePortraitNotification';
  isTouchDevice: boolean;

  // DO NOT REMOVE services, they are being used!
  constructor(
    private fontLoadService: FontLoadService,
    private uiService: UiService,
    private store: Store<AppState>,
    private themeService: ThemeService,
    private keyEventHandlerService: KeyEventHandlerService,
    private elementTransformService: ElementTransformService,
    private breakpointObserver: BreakpointObserver,
    protected browserStorage: BrowserStorageService,
    public getTextService: GetTextService,
    private updateService: UpdateService,
    protected configService: ConfigService
  ) {
    this.landscapeMode$ = this.breakpointObserver
      .observe(Breakpoints.TabletLandscape)
      .pipe(map(result => result.matches));
    this.isTouchDevice = this.configService.isTouchDevice;
  }

  ngOnInit() {
    const designHasChangedSinceInit$ = this.store.pipe(select(getDesignHasChangedSinceInit));
    const lastChangesAreSaved$ = this.store.pipe(select(getLastChangesAreSaved));
    combineLatest([designHasChangedSinceInit$, lastChangesAreSaved$]).subscribe(([hasChanged, isSaved]) => {
      this.unsavedChanges = hasChanged && !isSaved;
    });
    this.store.pipe(select(getNavigationHandled)).subscribe(handled => (this.navigationHandled = handled));
    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.themeService.setCssVariables(
        config.themeData,
        config.toolBarColorStart,
        config.toolBarColorEnd,
        config.toolBarTextColor
      );
    });

    this.hideForcePortrait = !!this.browserStorage.getLocal(this.hideForcePortraitKey);

    this.updateService.checkForUpdates();

    window.addEventListener('beforeunload', event => {
      if (environment.allowWindowAlerts && this.unsavedChanges && !this.navigationHandled) {
        event.preventDefault(); // firefox needs this
        event.returnValue = ''; // chrome needs this
      }
    });
  }

  removeNotification() {
    this.browserStorage.setLocal(this.hideForcePortraitKey, 'true');
    this.hideForcePortrait = true;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: AppTouchEvent) {
    // prevent pinch zoom on app
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }
}
