import { CanvasCoordinate, CanvasDimensions, CanvasElement, ImageElement } from '../models';
import { cloneDeep, round } from 'lodash-es';

export const MAX_ELEMENT_SIZE_FACTOR = 25;
export const MIN_ELEMENT_SIZE_FACTOR = 0.01;
export const OUTPUT_DECIMALS = 6;
export const INPUT_DECIMALS = OUTPUT_DECIMALS - 1;
export const fileExtensions = {
  svg: 'svg',
  png: 'png',
  jpg: 'jpg',
  jpeg: 'jpeg',
  pdf: 'pdf',
  gif: 'gif'
};

/**
 *
 * @param rotation:
 * @param adjacent: x to center
 * @param opposite: y to center
 */
export const calculateRotatedPoint = (rotation: number, adjacent: number, opposite: number): CanvasCoordinate => {
  const angleInRadians = rotation * (Math.PI / 180);
  const newX = adjacent * Math.cos(angleInRadians) + opposite * Math.sin(angleInRadians);
  const newY = -adjacent * Math.sin(angleInRadians) + opposite * Math.cos(angleInRadians);
  return { x: newX, y: newY };
};

export const roundToHalf = (value: number): number => {
  return Math.round(value * 2) / 2;
};

export const coerceNumber = (value: any, decimals = OUTPUT_DECIMALS): number => {
  return round(parseFloat(value), decimals) || 0;
};

export function cloneElement<T extends CanvasElement>(element: T): T {
  const parent = element.parent;
  if (parent) {
    element.parent = undefined;
  }

  const clone = cloneDeep(element);

  if (parent) {
    element.parent = parent;
  }

  return clone;
}

export const rotate = (
  {
    nextWidth,
    nextHeight,
    nextTop,
    nextLeft
  }: {
    nextWidth: number;
    nextHeight: number;
    nextTop: number;
    nextLeft: number;
  },
  currentPos: CanvasCoordinate,
  currentSize: CanvasDimensions,
  rotation: number
) => {
  // center of element on screen before resizing
  const center_x = currentPos.x + currentSize.width / 2;
  const center_y = currentPos.y + currentSize.height / 2;

  // rotation in radians
  const angleInRadians = -rotation * (Math.PI / 180);

  // x,y position of new top left corner relative to old center
  const relX = nextLeft - center_x;
  const relY = nextTop - center_y;

  // x,y screen position relative to center of element before resizing
  const newPoint = calculateRotatedPoint(-rotation, relX, relY);

  const screenPosX = newPoint.x + center_x;
  const screenPosY = newPoint.y + center_y;

  // calculate center of new element
  const newRadius = Math.sqrt(((nextWidth / 2) * nextWidth) / 2 + ((nextHeight / 2) * nextHeight) / 2);
  const diagonalElement = Math.atan(nextHeight / nextWidth);

  const new_rel_y = Math.sin(angleInRadians - diagonalElement) * newRadius;
  const new_rel_x = -Math.cos(angleInRadians - diagonalElement) * newRadius;

  // corner position relative to center of new element
  const newCorner = calculateRotatedPoint(rotation, new_rel_x, new_rel_y);

  // absolute screen position of new element
  nextTop = screenPosY - (new_rel_y - newCorner.y);
  nextLeft = screenPosX - (new_rel_x - newCorner.x);

  return { nextTop, nextLeft };
};

export function getCoordinatesAfterResize(element: CanvasElement, newDimensions: CanvasDimensions): CanvasCoordinate {
  let x = element.x;
  let y = element.y;

  if (element.rotation !== 0 && (element.width !== newDimensions.width || element.height !== newDimensions.height)) {
    const deltaRotate = rotate(
      {
        nextWidth: newDimensions.width,
        nextHeight: newDimensions.height,
        nextTop: 0,
        nextLeft: 0
      },
      { x: 0, y: 0 },
      { width: element.width, height: element.height },
      element.rotation
    );

    x = x + deltaRotate.nextLeft;
    y = y + deltaRotate.nextTop;
  }

  return { x, y };
}

export function getElementScreenCoordinates(
  pos: CanvasCoordinate,
  size: CanvasDimensions,
  element: CanvasElement,
  designRef: CanvasCoordinate
): CanvasCoordinate {
  // calculate center of new overlay relative to client.
  const newCenterX = pos.x + size.width / 2;
  const newCenterY = pos.y + size.height / 2;
  const rotatedRelativeToOverlayCenter = calculateRotatedPoint(
    -element.parent.screenRotation,
    pos.x - newCenterX,
    pos.y - newCenterY
  );

  const overlayLeftTopX = rotatedRelativeToOverlayCenter.x + newCenterX;
  const overlayLeftTopY = rotatedRelativeToOverlayCenter.y + newCenterY;

  const parentCenterX = designRef.x + element.parent.overlayPosX + element.parent.screenWidth / 2;
  const parentCenterY = designRef.y + element.parent.overlayPosY + element.parent.screenHeight / 2;

  // rotate left top overlay back with parent rotation
  const relativeToParentCenter = calculateRotatedPoint(
    element.parent.screenRotation,
    overlayLeftTopX - parentCenterX,
    overlayLeftTopY - parentCenterY
  );

  // x,y relative to parent x,y
  const x = relativeToParentCenter.x + element.parent.screenWidth / 2;
  const y = relativeToParentCenter.y + element.parent.screenHeight / 2;

  return { x, y };
}

export function fileExtensionIsSvg(filename: string): boolean {
  return filename.split('.').slice(-1)[0] === fileExtensions.svg;
}

export function fileExtensionIsJpg(filename: string): boolean {
  const extension = filename.split('.').slice(-1)[0];
  return extension === fileExtensions.jpg || extension === fileExtensions.jpeg;
}

/**
 * return the given set of elements and all their childElements as a flat array
 * @param elements: top layer of elements to flatten
 * @param allChildren: accumulative array
 */
export function getFlatChildren(elements: CanvasElement[], allChildren: CanvasElement[] = []): CanvasElement[] {
  elements.forEach(element => {
    allChildren.push(element);
    getFlatChildren(element.children, allChildren);
  });

  return allChildren;
}

export function elementIsJpgImage(element: CanvasElement): boolean {
  return element.firstChild
    ? (element.firstChild as ImageElement).isJpg
    : element.isImage()
    ? (element as ImageElement).isJpg
    : false;
}
