import { EditorAction } from '../action';
import { INIT } from './canvas.action.types';
import { DesignSet } from '../../models/design-set';

export class Init implements EditorAction {
  readonly type = INIT;
  readonly undoable = false;

  constructor(public designSet: DesignSet, public save = false) {}
}
