import { Injectable } from '@angular/core';
import { CanvasCoordinate, PageElement } from '../models';
import { BehaviorSubject } from 'rxjs';
import { calculateRotatedPoint } from '../utils/element.utils';
import { isEqual, round, uniqWith } from 'lodash-es';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import * as fromPermissions from '../reducers/permissions.reducer';
import { environment } from '../../environments/environment';
import { selectDesign } from '../selectors';

export const SNAP_RANGE = 10;
export const TIME_OUT_MILISECONDS = 10;

export interface Guideline {
  route: number[];
  points: CanvasCoordinate[];
  cross: boolean;
  relatesToCanvas: boolean;
  polygon: CanvasCoordinate[];
}

@Injectable({ providedIn: 'root' })
export class GuidelinesService {
  private _showGuidelines: boolean;
  private _matchingVectors$ = new BehaviorSubject<Guideline[]>([]);
  page: PageElement;
  matchingVectors$ = this._matchingVectors$.asObservable();
  activeElementPoints: CanvasCoordinate[];
  _elementGuidelines: Guideline[];

  constructor(private store: Store<AppState>) {
    store.select(fromPermissions.getShowGuidelines).subscribe(show => (this._showGuidelines = show));
    store.select(selectDesign).subscribe(design => (this.page = design.visiblePage));
  }

  updateMatchingVectors(x: number, y: number, width: number, height: number, rotation: number): CanvasCoordinate[][] {
    if (!this._showGuidelines) {
      return [];
    }

    const point = { x: x + width / 2, y: y + height / 2 };
    const center = this.page ? this.page.rotateCenter(point) : point; // todo photoframechild?

    const leftTop = this.rotatePoint({ x: -width / 2, y: -height / 2 }, center, rotation);
    const rightTop = this.rotatePoint({ x: width / 2, y: -height / 2 }, center, rotation);
    const rightBottom = this.rotatePoint({ x: width / 2, y: height / 2 }, center, rotation);
    const leftBottom = this.rotatePoint({ x: -width / 2, y: height / 2 }, center, rotation);

    const polygon = [leftTop, rightTop, rightBottom, leftBottom];
    const activeElementGuidelines = this.createActiveElementGuidelines(polygon);
    this.activeElementPoints = uniqWith(
      activeElementGuidelines.flatMap(g => g.points),
      isEqual
    );
    const matches = this.matchVectors(this._elementGuidelines, activeElementGuidelines);
    this._matchingVectors$.next(matches);

    const filteredMatches = matches.reduce((newMachtes, currentMatch) => {
      const parallelMatch = newMachtes.find(newMatch => this.areParallel(newMatch.points, currentMatch.points));
      if (parallelMatch) {
        // TODO calculate distance and replace parallelMatch if distance of current is smaller.
        return newMachtes;
      }

      // assume not parallel means perpendicular
      return [...newMachtes, currentMatch];
    }, []);

    return filteredMatches.map(m => m.points);
  }

  /**
   * creates the guidelines for the active element
   * @param polygon:
   */
  createActiveElementGuidelines(polygon: CanvasCoordinate[]): Guideline[] {
    let sideGuidelines = this.createSideGuidelines(polygon, [], false);
    const crossGuidelines = this.createCrossGuidelines(sideGuidelines);
    sideGuidelines = environment.guidelinesTestFeatures ? sideGuidelines : [];
    return [...sideGuidelines, ...crossGuidelines].map(guideline => this.getScreenLocations(guideline));
  }

  /**
   * creates the guidelines for each of the elements within the canvas, including the 'canvas' itself by means of the background element
   */
  createElementGuidelines() {
    // TODO? backgroundImage is not matched because it isn't a page child. If backgroundImage is selected, backgroundElement is not
    //  matched because their ids are the same.
    this._elementGuidelines = this.page.children
      .filter(el => el.id !== this.page.selectedElement.id)
      .filter(el => (environment.guidelinesTestFeatures ? true : el.isBackgroundElement()))
      .reduce((guidelines, el) => {
        let sideGuidelines = this.createSideGuidelines(el.polygon, el.route, el.isBackgroundElement());
        const crossGuidelines = this.createCrossGuidelines(sideGuidelines);
        if (el.isBackgroundElement()) {
          // dont include sideVectors for the canvas itself
          sideGuidelines = [];
        }
        const screenGuidelines = [...sideGuidelines, ...crossGuidelines].map(guideline =>
          this.getScreenLocations(guideline)
        );
        return [...guidelines, ...screenGuidelines];
      }, []);
  }

  /**
   * lines along the side of the element
   * returns the polygon as combinations of adjacent points, 'connect the dots'-style
   * @param polygon:
   * @param route: target element route
   * @param relatesToCanvas: whether or not the guideline relates the active element to the canvas itself.
   */
  createSideGuidelines(polygon: CanvasCoordinate[], route: number[] = [], relatesToCanvas: boolean): Guideline[] {
    return polygon.map((point, index) => {
      const points = [point, polygon[(index + 1) % polygon.length]];
      return { route, points, cross: false, relatesToCanvas, polygon: polygon.map(p => this.toScreenPoint(p)) };
    });
  }

  /**
   * lines through the center of the element
   * @param guidelines: sidevectors
   */
  createCrossGuidelines(guidelines: Guideline[]) {
    return guidelines.slice(0, guidelines.length / 2).map((guideline, index) => {
      const points = [
        this.calcultateCenterPoint(guidelines[index].points),
        this.calcultateCenterPoint(guidelines[index + guidelines.length / 2].points)
      ];

      return {
        route: guideline.route,
        points,
        cross: true,
        relatesToCanvas: guideline.relatesToCanvas,
        polygon: guideline.polygon
      };
    });
  }

  matchVectors(guidelines: Guideline[], activeElementGuidelines: Guideline[]): Guideline[] {
    return guidelines.filter(guideline => {
      return !!activeElementGuidelines.find(activeElementGuideline => {
        const elementSideToElementCenter =
          guideline.cross !== activeElementGuideline.cross && !guideline.relatesToCanvas;
        return (
          !elementSideToElementCenter &&
          this.areParallel(guideline.points, activeElementGuideline.points) &&
          this.pointOnVector(guideline.points, activeElementGuideline.points[0])
        );
      });
    });
  }

  /**
   * determine screen-relative locations
   * @param guideline:
   */
  getScreenLocations(guideline: Guideline): Guideline {
    guideline.points = [this.toScreenPoint(guideline.points[0]), this.toScreenPoint(guideline.points[1])];
    return guideline;
  }

  toScreenPoint(point: CanvasCoordinate): CanvasCoordinate {
    return this.roundedPoint({
      x: point.x + this.page.x,
      y: point.y + this.page.y
    });
  }

  roundedPoint(point: CanvasCoordinate, decimals = 5): CanvasCoordinate {
    return { x: round(point.x, decimals), y: round(point.y, decimals) };
  }

  calcultateCenterPoint(point: CanvasCoordinate[]) {
    return {
      x: point[0].x + (point[1].x - point[0].x) / 2,
      y: point[0].y + (point[1].y - point[0].y) / 2
    };
  }

  coercePointToVector(vector: CanvasCoordinate[], P: CanvasCoordinate): CanvasCoordinate {
    const AB = {
      x: vector[1].x - vector[0].x,
      y: vector[1].y - vector[0].y
    };
    const k = ((P.x - vector[0].x) * AB.x + (P.y - vector[0].y) * AB.y) / (AB.x * AB.x + AB.y * AB.y);
    return {
      x: vector[0].x + k * AB.x,
      y: vector[0].y + k * AB.y
    };
  }

  getSlope(vector: CanvasCoordinate[]) {
    const slope = (vector[0].y - vector[1].y) / (vector[0].x - vector[1].x);
    if (slope === -Infinity) {
      return Infinity;
    }
    return slope;
  }

  roundSlope(slope: number) {
    // round(Infinity) => NaN, NaN === NaN => false, so do not round Infinity
    return slope === Infinity ? Infinity : round(slope, 2);
  }

  /**
   * used to calculate de polygon/bounding-box of element,
   * rotate (total screen rotation of element and parents),
   * the corners of an element around the center coordinate of the page
   */
  rotatePoint(point: CanvasCoordinate, center: CanvasCoordinate, rotation: number): CanvasCoordinate {
    const relX = point.x;
    const relY = point.y;

    const newPoint = calculateRotatedPoint(-rotation, relX, relY);

    return {
      x: center.x + newPoint.x,
      y: center.y + newPoint.y
    };
  }

  areParallel(vector: CanvasCoordinate[], activeElementVector: CanvasCoordinate[]) {
    const slopeVector = this.getSlope(vector);
    const slopeOverlayVector = this.getSlope(activeElementVector);
    return this.roundSlope(slopeVector) === this.roundSlope(slopeOverlayVector);
  }

  pointOnVector(vector: CanvasCoordinate[], corner: CanvasCoordinate, range = SNAP_RANGE): boolean {
    return this.distance(vector, corner) < range;
  }

  distance(vector: CanvasCoordinate[], point: CanvasCoordinate) {
    return (
      Math.abs(
        (vector[1].y - vector[0].y) * point.x -
          (vector[1].x - vector[0].x) * point.y +
          vector[1].x * vector[0].y -
          vector[1].y * vector[0].x
      ) / Math.pow(Math.pow(vector[1].y - vector[0].y, 2) + Math.pow(vector[1].x - vector[0].x, 2), 0.5)
    );
  }
}
