import { PermissionActions, PermissionActionTypes } from '../actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '.';
import {
  EditorPermissions,
  FunctionPermissions,
  SetElementPermissions,
  Design,
  layerPermissions,
  PERMISSION_TO_LAYER_MAPPING,
  ElementPermission,
  LayerType
} from '../models';
import { environment } from '../../environments/environment';
import { selectDesign } from '../selectors';
import { isDesignSetSubmit } from '../models/design-submit';
import { selectConfigState } from '../config/reducer';

export interface PermissionsState {
  init: boolean;
  functionPermissions: FunctionPermissions;
  setElementPermissions: SetElementPermissions;
  userPermissions: EditorPermissions;
  designPermissions: EditorPermissions;
  configPermissions: EditorPermissions;
}

export interface State extends AppState {
  permissions: PermissionsState;
}

export const initialState: PermissionsState = {
  init: false,
  functionPermissions: new FunctionPermissions(),
  setElementPermissions: new SetElementPermissions(),
  userPermissions: {
    functionPermissions: new FunctionPermissions(),
    setElementPermissions: new SetElementPermissions()
  },
  designPermissions: {
    functionPermissions: new FunctionPermissions(),
    setElementPermissions: new SetElementPermissions()
  },
  configPermissions: {
    functionPermissions: new FunctionPermissions(),
    setElementPermissions: new SetElementPermissions()
  }
};

function mergePermissions<F>(...permissionObjects: F[]): F {
  const target = { ...permissionObjects[0] };

  Object.keys(target).forEach(p => {
    target[p] = permissionObjects.every(permObject => permObject[p]);
  });

  return target;
}

export function permissionsReducer(
  state = initialState,
  action: PermissionActions.PermissionActions
): PermissionsState {
  const envPermissions = environment.permissions;

  function updatePermissions(permState: PermissionsState) {
    return {
      ...permState,
      functionPermissions: mergePermissions(
        envPermissions.functionPermissions,
        permState.configPermissions.functionPermissions,
        permState.userPermissions.functionPermissions,
        permState.designPermissions.functionPermissions
      ),
      setElementPermissions: mergePermissions(
        envPermissions.setElementPermissions,
        permState.configPermissions.setElementPermissions,
        permState.userPermissions.setElementPermissions,
        permState.designPermissions.setElementPermissions
      )
    };
  }

  switch (action.type) {
    case PermissionActionTypes.SET_USER: {
      return updatePermissions({
        ...state,
        userPermissions: {
          functionPermissions: { ...action.functionPermissions },
          setElementPermissions: { ...action.setElementPermissions }
        }
      });
    }

    case PermissionActionTypes.SET_CONFIG: {
      return updatePermissions({
        ...state,
        configPermissions: {
          functionPermissions: { ...action.functionPermissions },
          setElementPermissions: { ...action.setElementPermissions }
        }
      });
    }

    case PermissionActionTypes.INIT_USER: {
      return updatePermissions({
        ...state,
        init: true,
        userPermissions: {
          functionPermissions: { ...action.functionPermissions },
          setElementPermissions: { ...action.setElementPermissions }
        }
      });
    }

    case PermissionActionTypes.UPDATE_DESIGN:
    case PermissionActionTypes.SET_DESIGN: {
      let newPerm: EditorPermissions;
      // take permissions of first design on init when set
      if (action.type === PermissionActionTypes.SET_DESIGN) {
        newPerm = isDesignSetSubmit(action.designOrSetSubmit)
          ? action.designOrSetSubmit.designs[0].permissions
          : action.designOrSetSubmit.permissions;
      } else {
        newPerm = action.permissions;
      }
      const allTrue = <P>(perm: P): P => Object.keys(perm).reduce((prev, key) => ({ ...prev, [key]: true }), {} as P);

      // if no permissions all permissions true for backwards compatibility
      const setElementPerm = newPerm?.setElementPermissions
        ? Object.assign(new SetElementPermissions(), newPerm?.setElementPermissions)
        : allTrue(new SetElementPermissions());

      const functionPerm = newPerm?.functionPermissions
        ? Object.assign(new FunctionPermissions(), newPerm?.functionPermissions)
        : allTrue(new FunctionPermissions());

      return updatePermissions({
        ...state,
        designPermissions: {
          functionPermissions: { ...functionPerm },
          setElementPermissions: { ...setElementPerm }
        }
      });
    }

    case PermissionActionTypes.RESET: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const selectPermissionsState = createFeatureSelector<PermissionsState>('permissions');

export const getAllPermissions = createSelector(selectPermissionsState, (state: PermissionsState) => ({
  ...state.functionPermissions,
  ...state.setElementPermissions
}));

export const getFunctionPermissions = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions
);

export const getSetElementPermissions = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions
);

export const getLockElements = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isLocked
);

export const getSetElementsMovable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isMovable
);

export const getSetElementsisExternallyEditable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isExternallyEditable
);

export const getSetElementsRotatable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isRotatable
);

export const getSetElementsFlippable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isFlippable
);

export const getSetElementsResizable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isResizable
);

export const getSetElementsRecolorable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isRecolorable
);

export const getSetElementsDuplicatable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isDuplicatable
);

export const getSetElementsRemovable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isRemovable
);

export const getSetElementsAsFoilable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isFoilable
);

export const getSetElementHasCoating = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.hasCoating
);

export const getSetElementsCroppable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isCroppable
);

export const getSetElementsReplaceable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isReplaceable
);

export const getSetElementsIsCutThrough = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isCutThrough
);

export const getSetAddChildElements = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.addChildElements
);

export const showFoilSafetyMarginWarning = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.showFoilSafetyMarginWarning
);

export const getSetElementsPrintable = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isPrintable
);

export const getSetElementPermissionIsHidden = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.setElementPermissions.isHidden
);

export const getImportExportDesign = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.importExportDesign
);

export const getSaveDesignInCollectionOtherUser = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.saveDesignInCollectionOtherUser
);

export const getUpdateExistingDesign = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.updateExistingDesign
);

export const getSubmitDesignForReview = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.submitDesignForReview
);

export const getFormatPages = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.formatPages
);

export const getHideSaveContinueDialog = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.hideSaveContinueDialog
);

export const getHasExtraOptions = createSelector(
  selectPermissionsState,
  (state: PermissionsState) =>
    state.functionPermissions.updateExistingDesign ||
    state.functionPermissions.submitDesignForReview ||
    state.functionPermissions.saveDesignInCollectionOtherUser ||
    state.functionPermissions.formatPages ||
    state.functionPermissions.importExportDesign ||
    state.functionPermissions.canCreatePdf
);

export const getCanAddTag = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canAddTag
);

export const getCanAddLabels = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canAddLabels
);

export const canAddTextInline = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canAddTextInline
);

export const canAddSpotUv = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canAddSpotUv
);

export const canEditElementSettings = createSelector(selectPermissionsState, (state: PermissionsState) =>
  Object.values(state.setElementPermissions).find(permission => permission === true)
);

export const getPermittedLayerTypes = createSelector(
  selectPermissionsState,
  selectConfigState,
  (state: PermissionsState) => {
    return [
      ...layerPermissions
        .filter(layerPermission => {
          return Object.keys(state.setElementPermissions).find(setElementPermission => {
            return (
              layerPermission === setElementPermission && state.setElementPermissions[setElementPermission] === true
            );
          });
        })
        .map((permission: ElementPermission) => PERMISSION_TO_LAYER_MAPPING[permission]),
      LayerType.standardRgb
    ];
  }
);

export const getHasDimensionsInputField = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.hasDimensionsInputField
);

export const getAutoSave = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.autoSave
);

export const getCanSave = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canSave
);

export const getCanEditStyle = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canEditStyle
);

export const getAllowCrossSessionElementCopying = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.allowCrossSessionElementCopying
);

export const getEnableFoilableByDefault = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.enableFoilableByDefault
);

export const getEnableSpotUvByDefault = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.spotUvPermissionForJpgByDefault
);

export const getShowGuidelines = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.showGuideLines
);

export const getAddImageAsPhotoFrame = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.addImageAsPhotoFrame
);

export const selectUseRichText = createSelector(selectDesign, (design: Design) => design.permissions.useRichText);

export const getUseChat = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.useChat
);

export const getResizeText = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.resizeText
);

export const getShowUpdateDialog = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.showUpdateDialog
);

export const getCanSearchImageLibrary = createSelector(
  selectPermissionsState,
  (state: PermissionsState) => state.functionPermissions.canSearchImageLibrary
);
