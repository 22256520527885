import { CanvasCoordinate } from '../models';

/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 *
 * @param a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
export function doPolygonsIntersect(a: Array<CanvasCoordinate>, b: Array<CanvasCoordinate>) {
  const polygons = [a, b];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates the two shapes
    const polygon = polygons[i];
    for (let i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      const i2 = (i1 + 1) % polygon.length;
      const p1 = polygon[i1];
      const p2 = polygon[i2];

      // find the line perpendicular to this edge
      const normal = { x: p2.y - p1.y, y: p1.x - p2.x };

      const [minA, maxA] = getMinMaxVertex(a, normal);
      const [minB, maxB] = getMinMaxVertex(b, normal);

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA <= minB || maxB <= minA) {
        return false;
      }
    }
  }
  return true;
}

function getMinMaxVertex(vertex: Array<CanvasCoordinate>, normal: CanvasCoordinate) {
  let min: number;
  let max: number;
  const isUndefined = (value: any) => value === undefined;

  // for each vertex in the first shape, project it onto the line perpendicular to the edge
  // and keep track of the min and max of these values
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let j = 0; j < vertex.length; j++) {
    const projected = normal.x * vertex[j].x + normal.y * vertex[j].y;
    if (isUndefined(min) || projected < min) {
      min = projected;
    }
    if (isUndefined(max) || projected > max) {
      max = projected;
    }
  }
  return [min, max];
}

/**
 * ray-casting algorithm based on
 * http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
 */
export function pointInPolygon(point: CanvasCoordinate, polygon: CanvasCoordinate[]) {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x;
    const yi = polygon[i].y;
    const xj = polygon[j].x;
    const yj = polygon[j].y;

    const intersect = yi > point.y !== yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;

    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}
