import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ColorMenuService } from '../color-menu.service';
import { ColorMenuComponent } from '../color-menu.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Color, FoilTypes } from '../../../models';

export type colorTabNames = 'design' | 'foil' | 'default' | 'custom';

export class ColorTab {
  public enabled = true;
  constructor(public name: string) {}
}

export type ColorTabs = { [E in colorTabNames]: ColorTab };

@Component({
  selector: 'ed-color-menu-mob',
  templateUrl: 'color-menu-mob.component.html',
  styleUrls: ['color-menu-mob.component.scss'],
  providers: [ColorMenuService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorMenuMobComponent extends ColorMenuComponent implements OnInit, OnDestroy {
  colorTabs: ColorTabs = {
    design: new ColorTab('design'),
    foil: new ColorTab('foil'),
    default: new ColorTab('default'),
    custom: new ColorTab('custom')
  };

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    public colorMenuService: ColorMenuService,
    @Inject(DOCUMENT) protected document: Document
  ) {
    super(cdr, sanitizer, document);
  }

  ngOnInit() {
    this.colorMenuService.designColors$.pipe(takeUntil(this.unsubscribe$)).subscribe(color => {
      this.colorTabs.design.enabled = color.length > 0;
    });
    this.colorMenuService.customColors$.pipe(takeUntil(this.unsubscribe$)).subscribe(color => {
      this.colorTabs.custom.enabled = color.length > 0;
    });

    this.colorTabs.foil.enabled = this.showFoilPanel;

    this.allowAddingExtraColors = this.colorMenuService.allowAddingExtraColors;
  }

  openColorPickerDialog() {
    this.colorMenuService.openColorPickerDialog(this.elementColor$, this.dataLayerIdPrefix);
  }

  getFoilChipBackground(foilColor: Color): string | SafeUrl {
    if (foilColor.foil === FoilTypes.rainbow) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`url(${this.rainbowBackgroundImage})`);
    } else {
      return 'linear-gradient(to top right, ' + foilColor.colorValue + ', white)';
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
