import { AdminDesignerActionTypes, AdminDesignerActions } from './actions';
import { ClientError } from '../models/client-error';
import { AppState } from '../reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AdminDesignerState {
  error: ClientError[];
  pending: boolean;
}

export interface State extends AppState {
  adminDesigner: AdminDesignerState;
}

export const initialState: AdminDesignerState = {
  error: [],
  pending: false
};

export function reducer(state = initialState, action: AdminDesignerActions): AdminDesignerState {
  switch (action.type) {
    case AdminDesignerActionTypes.SaveTitleDescription: {
      return {
        ...state,
        pending: true
      };
    }

    case AdminDesignerActionTypes.SaveTitleDescriptionFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case AdminDesignerActionTypes.SaveTitleDescriptionSuccess: {
      return {
        ...state,
        error: null,
        pending: false
      };
    }

    case AdminDesignerActionTypes.SubmitSaveDesignForReview: {
      return {
        ...state,
        pending: true
      };
    }

    case AdminDesignerActionTypes.SubmitDesignForReviewFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case AdminDesignerActionTypes.SubmitDesignForReviewSuccess: {
      return {
        ...state,
        error: null,
        pending: false
      };
    }

    case AdminDesignerActionTypes.SubmitSaveDesignOtherUser: {
      return {
        ...state,
        error: null,
        pending: true
      };
    }

    case AdminDesignerActionTypes.SubmitDesignOtherUserSuccess: {
      return {
        ...state,
        error: null,
        pending: false
      };
    }

    case AdminDesignerActionTypes.SubmitDesignOtherUserFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case AdminDesignerActionTypes.ResetValidationErrors: {
      return {
        ...state,
        error: null
      };
    }

    default: {
      return state;
    }
  }
}

export const selectAdminDesignerState = createFeatureSelector<AdminDesignerState>('adminDesigner');

export const getError = createSelector(selectAdminDesignerState, (state: AdminDesignerState) => state.error);

export const getPending = createSelector(selectAdminDesignerState, (state: AdminDesignerState) => state.pending);

export const getSuccess = createSelector(
  selectAdminDesignerState,
  (state: AdminDesignerState) => !state.pending && !state.error
);
