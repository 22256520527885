import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EditImageMobComponent } from './edit-image-mob.component';
import { Color, ImageElement, PhotoFrameElement, ROTATION_RANGE } from '../../../models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService, CopyService, GetTextService } from '../../../services';

export type topMenuOptionName = 'fill' | 'flippable' | 'order' | 'recolor' | 'effect' | 'rotate' | 'transparency' | 'zoom';

export class TopMenuOption {
  public open = false;
  public show = true;
  constructor(public name: string) {}
}

export type TopMenuOptions = { [E in topMenuOptionName]: TopMenuOption };

@Component({
  selector: 'ed-edit-image-mob-new',
  templateUrl: 'edit-image-mob-new.component.html',
  styleUrls: [
    '../../../shared/mob-menu.scss',
    '../../../shared/button/buttons.scss',
    './edit-image-mob-new.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditImageMobNewComponent extends EditImageMobComponent {
  topMenuOptions: TopMenuOptions = {
    fill: new TopMenuOption('fill'),
    flippable: new TopMenuOption('flippable'),
    order: new TopMenuOption('order'),
    recolor: new TopMenuOption('recolor'),
    effect: new TopMenuOption('effect'),
    rotate: new TopMenuOption('rotate'),
    transparency: new TopMenuOption('transparency'),
    zoom: new TopMenuOption('zoom'),
  };

  @Input()
  set element(element: ImageElement | PhotoFrameElement) {
    const imageElement = element.firstChild
      ? (element.firstChild as ImageElement)
      : element.isImage()
      ? (element as ImageElement)
      : undefined;

    this.topMenuOptions.fill.show = element.combinedPermissions.isFillable;
    this.topMenuOptions.flippable.show = element.combinedPermissions.isFlippable;
    this.topMenuOptions.order.show = element.combinedPermissions.isOrderable;
    this.topMenuOptions.recolor.show = element.combinedPermissions.isRecolorable;
    this.topMenuOptions.effect.show = element.permissions.isSpotUvable && imageElement.isJpg;
    this.topMenuOptions.rotate.show = element.combinedPermissions.isRotatable;
    this.topMenuOptions.transparency.show = element.combinedPermissions.adjustTransparency && !element.foilType;
    this.topMenuOptions.zoom.show = element.combinedPermissions.isCroppable;

    this._element = element;
    this.elementColor = new Color('', this.imageElement.color, this.imageElement.foilType);
  }

  get element() {
    return this._element;
  }

  @Input() addChildElements: boolean;

  @Output() openImageLibraryMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  get topMenuOptionsOpen(): boolean {
    return Object.values(this.topMenuOptions).findIndex(val => val.open === true) > -1;
  }

  rotationRange = ROTATION_RANGE;

  constructor(
    protected store: Store<AppState>,
    protected sanitizer: DomSanitizer,
    public getTextService: GetTextService,
    protected configService: ConfigService,
    protected copyService: CopyService
  ) {
    super(store, sanitizer, getTextService, configService);
  }

  openTopMenuOption(topMenuOption: TopMenuOption) {
    Object.keys(this.topMenuOptions).map(name => (this.topMenuOptions[name].open = name === topMenuOption.name));
  }

  closeAllTopMenuOptions() {
    Object.keys(this.topMenuOptions).map(name => (this.topMenuOptions[name].open = false));
  }

  openImageLibrary() {
    this.openImageLibraryMenu.emit();
  }

  openCropMenu() {
    this.toggleCroppingMode();
  }

  get cutable() {
    return this.copyService.allowCutting;
  }

  get duplicatable() {
    return this.copyService.allowDuplicating;
  }

  get pasteable() {
    return !!this.copyService.getClipboardElement() && this.copyService.allowPasting;
  }

  cut() {
    this.copyService.cut();
  }

  copy() {
    this.copyService.copy();
  }

  paste() {
    this.copyService.paste();
  }
}
