import { Directive, Input, Renderer2, ElementRef } from '@angular/core';
import { ConfigService } from '../services';

declare let dataLayer: Array<any>;

@Directive({ selector: '[dataLayerId]' })
export class DatalayerDirective {
  dataLayer: Array<any> = [];
  private event: string;
  private destroyEventListener: () => void;

  @Input() dataLayerId = '';

  @Input() set dataLayerEvent(event: string) {
    if (this.event !== event) {
      if (this.destroyEventListener) {
        this.destroyEventListener();
      }
      this.event = event;
      this.destroyEventListener = this.addEventListener(event);
    }
  }

  constructor(private configService: ConfigService, private renderer: Renderer2, private elementRef: ElementRef) {
    // The dataLayer needs to be initialized
    if (typeof dataLayer !== 'undefined') {
      this.dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];
      this.addEventListener('mousedown');
    } else {
      console.warn('dataLayer is undefined');
    }
  }

  addEventListener(event: string): () => void {
    return this.renderer.listen(this.elementRef.nativeElement, event, this.onEvent);
  }

  get deviceSuffix(): string {
    return this.configService.isMobile ? '-mob' : '';
  }

  onEvent = (event: MouseEvent) => {
    const datalayerEvent = this.elementRef.nativeElement.getAttribute('datalayerevent');
    // don't push datalayerid if it's fired by a different event than the one provided for the target element if it has one
    if (!!datalayerEvent && datalayerEvent !== event.type) {
      return;
    }

    if (typeof this.dataLayerId !== 'undefined') {
      this.dataLayer.push({
        Edclicks: `${this.dataLayerId}${this.deviceSuffix}`,
        event: 'EditorClick'
      });
    } else {
      console.warn('dataLayerId is undefined');
    }
  };
}
