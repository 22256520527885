import { EditorAction } from '../action';
import { SET_CHARACTER_LIMIT } from './canvas.action.types';

export class SetCharacterLimit implements EditorAction {
  readonly type = SET_CHARACTER_LIMIT;
  readonly undoable = false;
  readonly save = true;

  constructor(public route: Array<number>, public characterLimit: number) {}
}
