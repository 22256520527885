import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  arrowDownKey,
  arrowLeftKey,
  arrowRightKey,
  arrowUpKey,
  backspaceKey,
  cKey,
  deleteKey,
  vKey,
  xKey,
  yKey,
  zKey
} from '../data';
import { CanvasActions, UndoActions } from '../actions';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { CopyService } from './copy.service';
import { ElementTransformService } from './element-transform.service';
import { LEFT, RIGHT } from '../data/directions';

@Injectable({
  providedIn: 'root'
})
export class KeyEventHandlerService {
  renderer: Renderer2;

  /**
   * By passing null to renderer factory you get a hold of the default DOMrenderer
   * and can therefore listen to window events.
   * https://stackoverflow.com/questions/39592972/is-it-possible-to-use-hostlistener-in-a-service-or-how-to-use-dom-events-in-an
   */
  constructor(
    private store: Store<AppState>,
    private rendererFactory: RendererFactory2,
    private copyService: CopyService,
    private elementTransformService: ElementTransformService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.renderer.listen('document', 'keydown', this.onKeydown.bind(this));
  }

  onKeydown(event: KeyboardEvent) {
    const key = event.key;
    const ctrl = event.ctrlKey;
    const meta = event.metaKey;
    const shift = event.shiftKey;
    const alt = event.altKey;

    if (alt) {
      event.preventDefault();
    }

    switch (key.toLowerCase()) {
      case yKey:
        if (ctrl || meta) {
          event.preventDefault();
          this.store.dispatch(new UndoActions.Redo());
        }
        break;
      case zKey:
        if (ctrl || meta) {
          if (shift) {
            this.store.dispatch(new UndoActions.Redo());
          } else {
            this.store.dispatch(new UndoActions.Undo());
          }
        }
        break;
      case xKey:
        if (ctrl || meta) {
          this.copyService.cut();
        }
        break;
      case cKey:
        if (ctrl || meta) {
          this.copyService.copy();
        }
        break;
      case vKey:
        if (ctrl || meta) {
          this.copyService.paste();
        }
        break;
      case arrowUpKey.toLowerCase():
        if ((ctrl && shift) || (meta && shift)) {
          this.store.dispatch(new CanvasActions.MoveToFront(this.elementTransformService.selectedElement.route));
        }
        break;
      case arrowDownKey.toLowerCase():
        if ((ctrl && shift) || (meta && shift)) {
          this.store.dispatch(new CanvasActions.MoveToBack(this.elementTransformService.selectedElement.route));
        }
        break;
      case arrowRightKey.toLowerCase():
        if (alt) {
          this.elementTransformService.rotate(RIGHT, shift);
        }
        break;
      case arrowLeftKey.toLowerCase():
        if (alt) {
          this.elementTransformService.rotate(LEFT, shift);
        }
        break;
      case deleteKey.toLowerCase():
      case backspaceKey.toLowerCase():
        this.elementTransformService.remove();
        break;
      default:
        break;
    }
  }
}
