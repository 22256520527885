import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { tap, map, switchMap, catchError } from 'rxjs/operators';

import { RegisterActionTypes, Register, RegisterSuccess, RegisterFailure } from './actions';
import { LoginSuccess } from '../auth/actions';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User, RegisterUser } from '../models/user';
import { RegisterSuccessDialogComponent } from './reg-success-dialog.component';
import { RegisterService } from './register.service';
import * as fromRegister from './reducer';
import { CustomFormField } from '../models';
import { GetTextService } from '../services';
import { sha256 } from 'js-sha256';

@Injectable()
export class RegisterEffects {
  @Effect()
  register$ = this.actions$.pipe(
    ofType(RegisterActionTypes.Register),
    map((action: Register) => action.payload),
    switchMap((regUser: RegisterUser) =>
      this.registerService.register(regUser).pipe(
        map(
          responseUser =>
            new RegisterSuccess(
              new User(
                responseUser.Username,
                responseUser.Shop,
                responseUser.Email,
                responseUser.BasketItems,
                responseUser.FunctionPermissions,
                responseUser.SetElementPermissions,
                true
              )
            )
        ),
        catchError(error => of(new RegisterFailure(error.error)))
      )
    )
  );

  @Effect({ dispatch: false })
  pushRegistrationEventToDataLayer$ = this.actions$.pipe(
    ofType(RegisterActionTypes.RegisterSuccess),
    tap((action: RegisterSuccess) => {
      const dataLayer = (window as any).dataLayer || [];

      dataLayer.push({
        event: 'registrationEvent',
        email_hash: sha256(action.user.email),
        method: 'editor'
      });
    })
  );

  @Effect()
  openRegisterSuccessModal$ = this.actions$.pipe(
    ofType(RegisterActionTypes.RegisterSuccess),
    map((action: RegisterSuccess) => new LoginSuccess(action.user)),
    tap(
      () =>
        (this.registerSuccessDialog = this.dialog.open(RegisterSuccessDialogComponent, {
          id: 'register-success-dialog',
          data: {
            title: this.getTextService.text.dialog.registerSuccess.title,
            message: this.getTextService.text.dialog.registerSuccess.message,
            buttonText: this.getTextService.text.dialog.registerSuccess.buttonText
          }
        }))
    )
  );

  @Effect({ dispatch: false })
  closeRegisterSuccessModal$ = this.actions$.pipe(
    ofType(RegisterActionTypes.CloseRegisterSuccessDialog),
    tap(() => this.registerSuccessDialog.close())
  );

  registerSuccessDialog: MatDialogRef<RegisterSuccessDialogComponent>;
  formFields: CustomFormField[];
  configSubscription: Subscription;

  constructor(
    private registerService: RegisterService,
    private dialog: MatDialog,
    private actions$: Actions,
    private store: Store<fromRegister.State>,
    private getTextService: GetTextService
  ) {
    this.configSubscription = this.store.pipe(select(s => s.config)).subscribe(config => {
      this.formFields = config.registrationFormFields;
    });
  }
}
