import { Design, View } from "src/app/models";

declare module "src/app/models/design" {
  interface Design {
    setBackSidePageIds(): void;
  }
}

Design.prototype.setBackSidePageIds = function () {
  const design: Design = this;

  if (!(design instanceof Design)) {
    return;
  }

  if (!design.spreadView && design.pages.length === 2) {
    design.pages[0].backSidePageId = design.pages[1].id;
    design.pages[1].backSidePageId = design.pages[0].id;
  }

  if (!design.specs) {
    if (!design.spreadView && design.pages.length === 4) {
      // assume double card, hack so double card shows card behind in kc
      design.getPageElement(1).backSidePageId = 2;
      design.getPageElement(2).backSidePageId = 1;
      design.getPageElement(3).backSidePageId = 4;
      design.getPageElement(4).backSidePageId = 3;

      design.getPageElement(1).pageBehindId = 3;
      design.getPageElement(4).pageBehindId = 2;
    }

    // hard coded hack for cut through in spreadview for kc
    if (design.view === View.userSpreads && design.spreads[0].pages.length === 2) {
      design.getPageElement(5).backSidePageId = 6;
      design.getPageElement(6).backSidePageId = 5;
    }

    // hard coded hack for cut through in cardview for kc
    if (design.view === View.card && design.spreads[1].pages.length === 2) {
      design.getPageElement(6).backSidePageId = 5;
    }
  }
}
