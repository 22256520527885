import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigService, GetTextService } from '../../services';
import { ColorMenuDialogComponent } from './color-menu-dialog.component';
import { CanvasElement, Color, DIALOG_VALUES_DT, DIALOG_VALUES_MOB, FoilTypes } from '../../models';
import { combineLatest, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { CanvasActions } from '../../actions';
import { createNewColor } from '../../utils/color-utils';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { getSelectedElement, selectDesign } from '../../selectors';
import { ColorPickerDialogMobComponent } from './color-picker-dialog-mob.component';

export interface DialogHeightValues {
  plusHeight: number;
  timeoutValue: number;
}

export interface ColorPickerDialogData {
  currentColor$: Observable<Color>;
  customColors$: Observable<Color[]>;
  colorPickerWidth: number;
  dataLayerIdPrefix: string;
}

@Injectable()
export class ColorMenuService {
  customColors$: Observable<Color[]>;
  designColors$: Observable<Color[]>;
  allowAddingExtraColors: boolean;
  colorData: Color[];
  foilColorData: Color[];
  spotUvColorData: Color[];
  rainbowBackgroundImage: string;

  foilInfoUrl: string;
  spotUvInfoUrl: string;

  colorMenuDialogText = this.getTextService.text.edit.colorMenu;

  currentCustomColor: Color;
  selectedColor$ = new Subject<Color>();
  originalColor$ = new Subject<boolean>();
  dialogOpen$ = new Subject<boolean>();
  dialogClosed$ = new Subject<boolean>();

  dialogRef: MatDialogRef<ColorMenuDialogComponent>;
  colorPickerDialogRef: MatDialogRef<ColorPickerDialogMobComponent>;

  tooltipShowDelay: number;

  isMobile: boolean;
  designHasFoiledElement$: Observable<boolean>;
  designHasSpotUvElement$: Observable<boolean>;
  selectedElement: CanvasElement;

  get dialogValues() {
    return this.configService.isMobile ? DIALOG_VALUES_MOB : DIALOG_VALUES_DT;
  }

  constructor(
    public dialog: MatDialog,
    public getTextService: GetTextService,
    public store: Store<AppState>,
    public configService: ConfigService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const config$ = this.store.pipe(select(s => s.config));
    config$.subscribe(config => {
      this.allowAddingExtraColors = config.allowAddingExtraColors;
      this.foilColorData = config.foilColorData;
      this.spotUvColorData = config.spotUvColorData;
      this.tooltipShowDelay = config.tooltipShowDelay;
      this.foilInfoUrl = config.foilInfoUrl;
      this.spotUvInfoUrl = config.spotUvInfoUrl;
      this.rainbowBackgroundImage = config.rainbowColorChipUrl;
    });

    this.customColors$ = this.store
      .pipe(select(selectDesign))
      .pipe(map(design => design.customColors.map(color => createNewColor(color.colorValue, color.spotUv))));
    this.designColors$ = this.store.pipe(
      select(selectDesign),
      map(d => d.designColors)
    );

    const design$ = this.store.pipe(select(selectDesign));
    this.designHasFoiledElement$ = design$.pipe(map(d => d.foilElements.length > 0));
    this.designHasSpotUvElement$ = design$.pipe(map(d => d.spotUvElements.length > 0));
    this.store.pipe(select(getSelectedElement)).subscribe(element => (this.selectedElement = element));

    combineLatest([design$, config$]).subscribe(([design, config]) => {
      this.colorData = config.colorData;
      if (design.material.disabledColors && design.material.disabledColors.length > 0) {
        this.colorData = config.colorData.filter(
          color =>
            !design.material.disabledColors.find(disabledColor => {
              return disabledColor === color.colorValue;
            })
        );
      }
    });
    this.isMobile = this.configService.isMobile;
  }

  openColorDialog(
    currentColor$: Observable<Color>,
    showFoilPanel: boolean,
    showSpotUvColors: boolean,
    originalColorOption: boolean,
    dataLayerIdPrefix: string
  ) {
    this.dialogRef = this.dialog.open(ColorMenuDialogComponent, {
      width: this.dialogValues.dialogWidth,
      maxWidth: this.dialogValues.dialogMaxWidth,
      position: this.dialogValues.dialogPosition,
      id: 'color-menu-dialog',
      backdropClass: 'transparent',
      data: {
        currentColor$,
        allowAddingExtraColors: this.allowAddingExtraColors,
        colorData: this.colorData,
        customColors$: this.customColors$,
        designColors$: this.designColors$,
        spotUvColorData: this.spotUvColorData,
        foilColorData: this.foilColorData,
        rainbowBackgroundImage: this.rainbowBackgroundImage,
        showFoilPanel,
        showSpotUvColors,
        originalColorOption,
        text: this.colorMenuDialogText,
        colorPickerWidth: this.setColorPickerWidth(),
        tooltipShowDelay: this.tooltipShowDelay,
        foilInfoUrl: this.foilInfoUrl,
        spotUvInfoUrl: this.spotUvInfoUrl,
        dataLayerIdPrefix,
        isMobile: this.isMobile,
        designHasFoiledElement$: this.designHasFoiledElement$,
        designHasSpotUvElement$: this.designHasSpotUvElement$
      }
    });

    this.dialogRef.componentInstance.selectedColor.subscribe((color: Color) => {
      this.selectedColor$.next(color);
    });

    this.dialogRef.componentInstance.customColor.subscribe((color: Color) => {
      this.currentCustomColor = color;
    });

    this.dialogRef.componentInstance.customColorMenuClosed.subscribe((closed: boolean) => {
      if (this.currentCustomColor) {
        this.addCustomColor(this.currentCustomColor);
        // Set currentCustomColor to undefined to prevent it will be added again on dialog close.
        this.currentCustomColor = undefined;
      }
    });

    this.dialogRef.componentInstance.originalColor.subscribe((resetColor: boolean) => {
      this.originalColor$.next(resetColor);
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.dialogOpen$.next(true);
    });

    this.dialogRef.beforeClosed().subscribe(() => {
      // If custom color picker is open and the user clicks next to the dialogue the color
      // picker and the color menu will be closed at once. CustomColorMenuClosed will not be
      // triggered at that moment. Therefore currentCustomColor needs te be added on dialog close.
      if (this.currentCustomColor) {
        this.addCustomColor(this.currentCustomColor);
      }
      this.dialogOpen$.next(false);
      this.dialogClosed$.next(true);
    });

    this.dialogRef.componentInstance.updateAllSpecialColorElements.subscribe((event: Color) => {
      this.store.dispatch(new CanvasActions.UpdateAllSpecialColorElements(this.selectedElement.route, event));
    });

    this.dialogRef.componentInstance.resetAllSpotUvElements.subscribe(() => {
      this.store.dispatch(new CanvasActions.ResetAllSpotUvElements());
    });
  }

  // mobile color picker
  openColorPickerDialog(currentColor$: Observable<Color>, dataLayerIdPrefix: string) {
    this.colorPickerDialogRef = this.dialog.open(ColorPickerDialogMobComponent, {
      width: this.dialogValues.dialogWidth,
      maxWidth: this.dialogValues.dialogMaxWidth,
      position: this.dialogValues.dialogPosition,
      id: 'color-picker-dialog-mob',
      backdropClass: 'transparent',
      data: {
        currentColor$,
        customColors$: this.customColors$,
        colorPickerWidth: this.setColorPickerWidth(),
        dataLayerIdPrefix
      }
    });

    this.colorPickerDialogRef.componentInstance.selectedColor.subscribe((color: Color) => {
      this.store.dispatch(new CanvasActions.ChangeColor(this.selectedElement.route, color));
    });

    this.colorPickerDialogRef.componentInstance.customColor.subscribe((color: Color) => {
      this.currentCustomColor = color;
    });

    this.colorPickerDialogRef.componentInstance.customColorMenuClosed.subscribe(() => {
      if (this.currentCustomColor) {
        this.addCustomColor(this.currentCustomColor);
        this.currentCustomColor = undefined;
      }
    });

    this.colorPickerDialogRef.beforeClosed().subscribe(() => {
      if (this.currentCustomColor) {
        this.addCustomColor(this.currentCustomColor);
      }
    });
  }

  addCustomColor(color: Color) {
    this.store.dispatch(new CanvasActions.AddCustomColor(color));
  }

  getSwatchStyle(color: string, foilType: FoilTypes) {
    if (foilType) {
      const foilColor = this.foilColorData.find(colorData => colorData.foil === foilType);
      if (foilColor && foilColor.swatchColors) {
        if (foilType === FoilTypes.rainbow) {
          return `background-image: url(${this.rainbowBackgroundImage}); background-size: cover`;
        } else {
          const swatchColors = foilColor.swatchColors;
          return `background-image: linear-gradient(to top right, ${swatchColors[0]}, ${swatchColors[1]})`;
        }
      }
    }
    return 'background-color: ' + color;
  }

  setColorPickerWidth() {
    if (this.configService.isMobile) {
      return this.document.documentElement.offsetWidth - 30;
    } else {
      return DIALOG_VALUES_DT.dialogWidthValue - 30;
    }
  }
}
