import { EditorAction } from '../action';
import { ALIGN_TEXT } from './canvas.action.types';

export class AlignText implements EditorAction {
  readonly type = ALIGN_TEXT;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public ha: string, public is_justify: boolean) {}
}
