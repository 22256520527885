import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanvasActions } from '../../../actions';
import { Translate } from '../../../actions/canvas-actions';
import {
  CanvasCoordinate,
  CanvasDimensions,
  Color,
  FunctionPermission,
  ImageElement,
  PhotoFrameElement
} from '../../../models';
import { AppState } from '../../../reducers';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { ConfigService, GetTextService } from '../../../services';
import { ImageData } from '../../../services/image-upload.service';
import {
  MAX_ELEMENT_SIZE_FACTOR,
  MIN_ELEMENT_SIZE_FACTOR,
  getCoordinatesAfterResize
} from '../../../utils/element.utils';
import * as FabricCanvasActions from "../../design/canvas/actions";

@Component({
  selector: 'ed-edit-image',
  templateUrl: 'edit-image.component.html',
  styleUrls: ['../edit.component.scss', '../../../shared/button/buttons.scss', './edit-image.component.scss']
})
export class EditImageComponent implements OnInit {
  _element: ImageElement | PhotoFrameElement;
  isUploading = false;
  elementColor: Color;
  tooltipShowDelay$: Observable<number>;
  tooltipPosition: TooltipPosition = 'below';
  showDimensionsInputField$: Observable<boolean>;
  canEditSettings$: Observable<boolean>;
  positioningTooltipText = this.getTextService.text.edit.order.tooltip;
  permissions = FunctionPermission;

  @Input()
  set element(element: ImageElement | PhotoFrameElement) {
    this._element = element;
    this.elementColor = new Color('', this.imageElement.color, this.imageElement.foilType, this.imageElement.spotUv);
  }

  get element() {
    return this._element;
  }

  get imageElement(): ImageElement {
    return this.element.firstChild
      ? (this.element.firstChild as ImageElement)
      : this.element.isImage()
        ? (this.element as ImageElement)
        : undefined;
  }

  @Input() pageWidth: number;
  @Input() pageHeight: number;

  @Input() showMoreOptions = false;
  @Output() showMoreOptionsChange = new EventEmitter<boolean>();

  readonly maxElementSizeFactor = MAX_ELEMENT_SIZE_FACTOR;
  readonly minElementSizeFactor = MIN_ELEMENT_SIZE_FACTOR;

  constructor(
    protected store: Store<AppState>,
    protected sanitizer: DomSanitizer,
    public getTextService: GetTextService,
    protected configService: ConfigService
  ) { }

  get imageSrc() {
    return this.imageElement.imgSource ? this.sanitizer.bypassSecurityTrustUrl(this.imageElement.imgSource) : '';
  }

  ngOnInit() {
    const config$ = this.store.pipe(select(s => s.config));
    this.tooltipShowDelay$ = config$.pipe(map(config => config.tooltipShowDelay));
    this.showDimensionsInputField$ = this.store.pipe(select(fromPermissions.getHasDimensionsInputField));
    this.canEditSettings$ = this.store.pipe(select(fromPermissions.canEditElementSettings));
  }

  onChooseColor(color: Color) {
    if (color.foil || color.spotUv) {
      this.store.dispatch(new CanvasActions.CheckSpecialColor(this.imageElement.route, color));
    } else {
      this.store.dispatch(new CanvasActions.ChangeColor(this.imageElement.route, color));
    }
  }

  resetColor() {
    this.store.dispatch(new CanvasActions.ResetImageColor(this.imageElement.route));
  }

  fillHorizontal() {
    this.store.dispatch(new CanvasActions.FillHorizontal(this.element.route));
  }

  fillVertical() {
    this.store.dispatch(new CanvasActions.FillVertical(this.element.route));
  }

  rotate(rotation: number) {
    if (this.element.rotation !== rotation) {
      this.store.dispatch(
        new CanvasActions.Rotate(
          this.element.route,
          this.element.screenWidth,
          this.element.screenHeight,
          this.element.screenX,
          this.element.screenY,
          rotation
        )
      );
    }
  }

  flipHorizontal() {
    this.store.dispatch(new CanvasActions.FlipHorizontal(this.imageElement.route, !this.imageElement.flipHorizontal));
  }

  flipVertical() {
    this.store.dispatch(new CanvasActions.FlipVertical(this.imageElement.route, !this.imageElement.flipVertical));
  }

  replaceImage() {
    return (imageData: ImageData) => {
      if (this.element.isPhotoFrame()) {
        this.store.dispatch(
          new CanvasActions.ReplaceImage(
            this.element.route,
            imageData.width,
            imageData.height,
            imageData.width,
            imageData.height,
            imageData.sid,
            '',
            false
          )
        );
      }
    };
  }

  removeImage() {
    this.store.dispatch(new CanvasActions.DeActivateOverlay(this.element.route));
    this.store.dispatch(new CanvasActions.RemoveElement(this.element.route));
  }

  changeTransparency(transparency: number) {
    this.store.dispatch(new CanvasActions.ChangeTransparency(this.imageElement.route, transparency));
  }

  changeZoom(zoomLevel: number) {
    this.store.dispatch(new FabricCanvasActions.ChangePhotoFrameZoom(this.element.route, zoomLevel));
  }

  onUploading(event: boolean) {
    this.isUploading = event;
  }

  toggleCroppingMode() {
    this.store.dispatch(new CanvasActions.ToggleCroppingMode(this.imageElement.parent.route));
  }

  translate(event: CanvasCoordinate) {
    const screenX = this.element.x + event.x;
    const screenY = this.element.y + event.y;

    this.store.dispatch(
      new Translate(this.element.route, this.element.screenWidth, this.element.screenHeight, screenX, screenY)
    );
  }

  resize(event: CanvasDimensions) {
    const leftTop = getCoordinatesAfterResize(this.element, event);

    this.store.dispatch(
      new CanvasActions.Resize(
        this.element.route,
        event.width,
        event.height,
        leftTop.x,
        leftTop.y
      )
    );
  }

  toggleSpotUv(spotUv: boolean): void {
    const color = new Color('', '', undefined, spotUv);
    this.store.dispatch(new CanvasActions.CheckSpecialColor(this.imageElement.route, color));
  }

  toggleMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
    this.showMoreOptionsChange.emit(this.showMoreOptions);
  }

  hasMoreOptions() {
    return this._element.permissions.isFlippable
      || this._element.permissions.isResizable
      || (this._element.permissions.adjustTransparency && !this._element.foilType)
      || this._element.permissions.isRotatable;
  }
}
