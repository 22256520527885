import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CategoryImage } from '../../../../models';
import { ConfigService } from '../../../../services';
import { DOCUMENT } from '@angular/common';
import { fileExtensionIsSvg } from '../../../../utils/element.utils';
import { ImageLibraryType } from '../../../../image-library/image-library';

const enlarge = 'enlarge';
const decrease = 'decrease';

@Component({
  selector: 'ed-image-thumb',
  templateUrl: './image-thumb.component.html',
  styleUrls: ['./image-thumb.component.scss'],
  animations: [
    trigger('enlargeImage', [
      state(
        enlarge,
        style({
          maxWidth: '110px',
          maxHeight: '110px'
        })
      ),
      state(
        decrease,
        style({
          maxWidth: '100px',
          maxHeight: '100px'
        })
      ),
      transition(enlarge + ' => ' + decrease, [animate('0.25s')]),
      transition(decrease + ' => ' + enlarge, [animate('0.15s')])
    ])
  ]
})
export class ImageThumbComponent {
  @Input() image: CategoryImage;
  @Input() imageSource: string;
  @Input() imageType: string;
  @Input() dataLayerIdPrefix: string;
  @Input() imageLibraryType: string;

  @Output() chooseImage: EventEmitter<object> = new EventEmitter();

  animatingImage = false;
  enlarge = enlarge;
  decrease = decrease;

  constructor(private config: ConfigService, @Inject(DOCUMENT) private _document: Document) {}

  onChooseImage(image: CategoryImage, thumbElement: HTMLImageElement) {
    this.getImageDimensions(thumbElement, image).then(dimensions => {
      this.chooseImage.emit({
        sid: image.sid,
        url: image.url,
        imageType: this.imageType,
        width: dimensions.width,
        height: dimensions.height,
        isFoilable: image.isFoilable
      });
    });
  }

  toggleAnimateImage() {
    this.animatingImage = !this.animatingImage;
  }

  getImageSrc() {
    return this.config.imgBase + this.imageSource;
  }

  drag(image: CategoryImage, thumbElement: HTMLImageElement, ev: DragEvent) {
    this.getImageDimensions(thumbElement, image).then(dimensions => {
      const data = {
        sid: image.sid,
        url: image.url,
        height: dimensions.height,
        width: dimensions.width,
        libraryType: this.imageLibraryType,
        droppable: 'droppable',
        isFoilable: image.isFoilable,
        isSvg: fileExtensionIsSvg(image.sid || image.url)
      };

      if (this.imageLibraryType === ImageLibraryType.background) {
        ev.dataTransfer.effectAllowed = 'copy';
      }

      ev.dataTransfer.setData('text', JSON.stringify(data));
    });
  }

  getImageDimensions(thumbElement: HTMLImageElement, image: CategoryImage): Promise<{ width: number; height: number }> {
    if (image.width && image.height) {
      return new Promise((resolve, reject) => resolve({ width: image.width, height: image.height }));
    }

    let height = thumbElement.naturalHeight;
    let width = thumbElement.naturalWidth;

    if (!width || !height) {
      // internet explorer doesn't have naturalWidth/height with svg images
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = thumbElement.src;
        img.onload = () => {
          this._document.body.appendChild(img);
          width = img.offsetWidth;
          height = img.offsetHeight;
          this._document.body.removeChild(img);
          return resolve({ width, height });
        };
      });
    } else {
      return new Promise((resolve, reject) => resolve({ width, height }));
    }
  }
}
