import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ed-ab-tests-script',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbTestsScriptComponent implements OnInit {
  @Input() abTestsScript: string;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.injectScript(this.abTestsScript);
  }

  private injectScript(htmlString: string): void {
    const container = document.createElement('div');
    container.innerHTML = htmlString;

    Array.from(container.querySelectorAll('script')).forEach(({ type = 'text/javascript', src, textContent }) => {
      const script = document.createElement('script');
      script.type = type;
      src ? (script.src = src) : (script.textContent = textContent);

      document.head.appendChild(script);
    });

    document.head.appendChild(container);
  }
}
