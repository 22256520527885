import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from './button/button.component';
import { PositioningToolsComponent } from './positioning-tools/positioning-tools.component';
import { PermissionsDirective } from './permissions.directive';
import { MediaIfDirective } from './media-breakpoint.directive';
import { SliderComponent } from './slider/slider.component';
import { SliderMobComponent } from './slider/slider-mob/slider-mob.component';
import { SliderButtonComponent } from './slider/slider-mob/slider-button.component';
import { CopyPasteComponent } from './copy-paste/copy-paste.component';
import { CopyPasteMobComponent } from './copy-paste/copy-paste-mob.component';
import { CounterComponent } from './counter/counter.component';
import { CounterButtonComponent } from './counter/counter-button.component';
import { FontListComponent } from './font-menu/font-list.component';
import { ColorButtonComponent } from './color-menu/color-button/color-button.component';
import { ColorButtonMobComponent } from './color-menu/color-button/color-button-mob.component';
import { ColorMenuComponent } from './color-menu/color-menu.component';
import { ColorMenuMobComponent } from './color-menu/color-menu-mob/color-menu-mob.component';
import { ColorMenuDialogComponent } from './color-menu/color-menu-dialog.component';
import { ColorHueSelectorComponent } from './color-menu/color-hue-selector/color-hue-selector.component';
import { ColorPickerComponent } from './color-menu/color-picker/color-picker.component';
import { ColorSliderComponent } from './color-menu/color-slider/color-slider.component';
import { FocusDirective } from './focus.directive';
import { CustomFormComponent } from './form-fields/custom-form-field.component';
import { NavigationButtonsComponent } from './navigation-buttons/navigation-buttons.component';
import { NavigationButtonsMobComponent } from './navigation-buttons/navigation-buttons-mob.component';
import { ChangeFormatDialogComponent } from './change-format-dialog/change-format.component';
import { UpdateExistingDesignDialogComponent } from './update-existing-design-dialog/update-existing-design-dialog.component';
import { DatalayerDirective } from './datalayer.directive';
import { FontSelectComponent } from './font-menu/font-select.component';
import { ErrorDialogComponent, ConfirmDialogComponent, WarningDialogComponent } from './dialogs';
import { DisableContextMenuDirective } from './disable-context-menu.directive';
import { PageThumbComponent } from './thumbs/page-thumb.component';
import { TriptychZigzagThumbComponent } from './thumbs/triptych-zigzag-thumb.component';
import { LabelComponent } from './label/label.component';
import { BorderTrimMobComponent } from './border-trim/border-trim-mob.component';
import { BorderTrimMobNewComponent } from './border-trim/border-trim-mob-new.component';
import { CoatingComponent } from './coating/coating.component';
import { ContextMenuDialogComponent } from './context-menu/context-menu-dialog.component';
import { ContextMenuService } from './context-menu/context-menu.service';
import { ButtonContainerMobComponent } from './button-container-mobile/button-container-mob.component';
import { CanvasComponent } from '../editor/design/canvas/canvas.component';
import { NewDesignDialogComponent } from './new-design-dialog/new-design-dialog.component';
import { SaveDesignInCollectionDialogComponent } from './save-design-in-collection-dialog/save-design-in-collection-dialog.component';
import { ImportExportJsonDialogComponent } from './import-export-json-dialog/import-export-json-dialog.component';
import { PositioningToolsMobComponent } from './positioning-tools/positioning-tools-mob.component';
import { ColorPickerDialogMobComponent } from './color-menu/color-picker-dialog-mob.component';
import { FontListMobComponent } from './font-menu/font-list-mob.component';
import { FontAwesomeModule } from './font-awesome/font-awesome.module';

const components = [
  DatalayerDirective,
  DisableContextMenuDirective,
  ButtonComponent,
  BorderTrimMobComponent,
  BorderTrimMobNewComponent,
  FontListComponent,
  FontListMobComponent,
  FontSelectComponent,
  ColorButtonComponent,
  ColorButtonMobComponent,
  ColorMenuComponent,
  ColorMenuMobComponent,
  ColorHueSelectorComponent,
  ColorPickerComponent,
  ColorSliderComponent,
  PositioningToolsComponent,
  PositioningToolsMobComponent,
  PermissionsDirective,
  MediaIfDirective,
  FocusDirective,
  CustomFormComponent,
  SliderComponent,
  SliderMobComponent,
  SliderButtonComponent,
  CopyPasteComponent,
  CopyPasteMobComponent,
  CounterComponent,
  CounterButtonComponent,
  NavigationButtonsComponent,
  NavigationButtonsMobComponent,
  ErrorDialogComponent,
  ChangeFormatDialogComponent,
  UpdateExistingDesignDialogComponent,
  NewDesignDialogComponent,
  SaveDesignInCollectionDialogComponent,
  ImportExportJsonDialogComponent,
  ConfirmDialogComponent,
  WarningDialogComponent,
  PageThumbComponent,
  TriptychZigzagThumbComponent,
  LabelComponent,
  CoatingComponent,
  ContextMenuDialogComponent,
  ButtonContainerMobComponent,
  CanvasComponent
];

const material = [
  MatSliderModule,
  MatSlideToggleModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatRadioModule,
  MatExpansionModule,
  MatMenuModule,
  MatButtonModule,
  MatTooltipModule,
  MatDialogModule,
  MatDividerModule,
  MatProgressBarModule,
  MatSelectModule
];

@NgModule({
  declarations: [ColorMenuDialogComponent, ColorPickerDialogMobComponent, ...components],
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule, ...material],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ...components,
    ...material
  ],
  providers: [ContextMenuService]
})
export class SharedModule {}
