import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PageElement, Spread, Thumb, ThumbSize } from '../../models';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DesignSet } from 'src/app/models/design-set';

@Component({
  selector: 'ed-triptych-zigzag-thumb',
  templateUrl: 'triptych-zigzag-thumb.component.html',
  styleUrls: ['triptych-zigzag-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TriptychZigzagThumbComponent {
  triptychThumb: Thumb;
  thumbSize = ThumbSize.small;
  thumbWidth: number;
  skewY: number;
  translateY = 10;
  translateXFactor = 0.3;

  get translateX() {
    return this.thumbWidth * this.translateXFactor;
  }

  get scaleX() {
    return 1 - this.translateXFactor;
  }

  @Input() designSet: DesignSet;

  @Input() set spread(spread: Spread) {
    if (spread) {
      this.triptychThumb = this.createTriptychThumb(spread);
    }
  }

  /*
    function to create triptychthumb takes 2 sides of a triptych: the front side with pages 1, 2 and 3, and the back side with pages
    4, 5 and 6.
  */
  createTriptychThumb(spread: Spread) {
    const spreadPageFront = spread.spreadPage;
    const spreadPageBack = spread.backSpread.spreadPage;

    if (spreadPageFront.backgroundElements.length === 3) {
      const page = spreadPageFront.backgroundElements[0];
      this.thumbWidth = (page.width / page.height) * this.thumbSize; // page ratio * thumbSize
      this.skewY = this.calculateSkewY(this.thumbWidth, this.translateY);

      // all pages take the whole side of the spread as content. Based on css different parts of the spreadPage are shown in each page
      const page1 = spreadPageFront;
      const page2 = spreadPageBack;
      const page3 = spreadPageFront;

      const thumb = new Thumb(1, this.designSet, [page1, page2, page3]);

      return thumb;
    }
  }

  calculateSkewY(width: number, height: number) {
    const adjacent = width;
    const opposing = height;

    const angleInRadians = Math.atan(opposing / adjacent);
    const angleInDegrees = angleInRadians / (Math.PI / 180);

    return -angleInDegrees;
  }

  transform(index: number) {
    if (index === 0) {
      // back page
      return null;
    } else if (index === 1) {
      // middle page
      return this.transformPage1;
    } else if (index === 2) {
      // front page
      return this.transformPage2;
    }
  }

  get transformPage1(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      'translateY(' +
        this.translateY +
        'px) ' +
        'translateX(' +
        this.translateX +
        'px) scaleX(' +
        this.scaleX +
        ') skewY(' +
        this.skewY +
        'deg)'
    );
  }

  get transformPage2(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      'translateY(' + this.translateY + 'px) translateX(' + this.translateX + 'px)'
    );
  }

  marginLeft(index: number) {
    if (index === 0) {
      return 0;
    } else if (index === 1) {
      return '-' + this.thumbWidth;
    } else if (index === 2) {
      return '-' + this.thumbWidth * 2;
    }
  }

  itemTrackBy(index: number, item: PageElement) {
    return item.id;
  }

  constructor(private sanitizer: DomSanitizer) {}
}
