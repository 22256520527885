import { EditorAction } from '../action';
import { ADD_TEXT } from './canvas.action.types';

export class AddText implements EditorAction {
  readonly type = ADD_TEXT;
  readonly undoable = true;
  readonly save = true;

  constructor(public text = '', public useAbsoluteFontSize = false) {}
}
