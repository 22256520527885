import { FunctionPermissions, SetElementPermissions } from './permissions';

export interface Authenticate {
  username: string;
  password: string;
}

export interface AuthenticateEmail {
  recoverEmail: string;
}

export class User {
  constructor(
    public username: string,
    public shop: string,
    public email: string = '',
    public basketItems: number = 0,
    public functionPermissions = new FunctionPermissions(),
    public setElementPermissions = new SetElementPermissions(),
    public isAuthenticated: boolean = true
  ) {}
}

export class ResponseUser {
  IsAuthenticated: boolean;
  Username: string;
  Shop: string;
  Email: string;
  BasketItems: number;
  FunctionPermissions: FunctionPermissions;
  SetElementPermissions: SetElementPermissions;
}

export class RegisterUser {
  Name: string;
  Email: string;
  Password: string;
}

export class RecoverUser {
  constructor(public email: string) {}
}
