import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import * as Auth from './actions';
import { Store } from '@ngrx/store';
import * as fromAuth from './reducer';
import { first } from 'rxjs/operators';
import { AuthDialogText, CustomFormText, DialogButtonText, LoginFormText, RegisterFormText } from '../models/text';
import { CustomFormField } from '../models';

export enum TabName {
  login = 'login',
  register = 'register'
}

export class TabOption {
  constructor(public name: string, public open: boolean) {}
}

export type TabOptions = { [E in TabName]: TabOption };

@Component({
  selector: 'ed-auth-dialog',
  templateUrl: 'auth-dialog.component.html',
  styleUrls: ['../shared/dialogs/modals.scss', './auth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthDialogComponent implements OnInit {
  tabOptions: TabOptions = {
    login: new TabOption(TabName.login, true),
    register: new TabOption(TabName.register, false)
  };

  tabName = TabName;

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      loginForSave: boolean;
      formFields: CustomFormField[];
      text: {
        dialogText: AuthDialogText;
        loginText: LoginFormText;
        registerText: RegisterFormText;
        customFormText: CustomFormText;
        buttonText: DialogButtonText;
      };
    },
    private store: Store<fromAuth.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancelOrPreviousClick() {
    this.store.dispatch(new Auth.CloseLoginDialog());
  }

  openTab(tabOption: TabOption) {
    Object.keys(this.tabOptions).map(name => (this.tabOptions[name].open = name === tabOption.name));
  }

  get tabSelected() {
    return Object.keys(this.tabOptions).some(name => this.tabOptions[name].open);
  }

  get cancelOrPreviousButtonText() {
    return this.data.loginForSave ? this.data.text.buttonText.previous : this.data.text.buttonText.cancel;
  }
}
