import { ImageCategory, ImgCatJson } from '../models';

export function categoryByPath(path: number[], category: ImageCategory): ImageCategory {
  if (category.path[0] !== path[0]) {
    return category;
  }
  path = path.slice(1); // path[0] refers to the category itself

  while (path.length) {
    category = category.subcategories[path[0]];
    path = path.slice(1);
  }
  return category;
}

export function mergeCategories(categories: ImgCatJson[]): ImgCatJson[] {
  return categories.reduce((cats, category) => {
    const index = cats.findIndex(c => c.name === category.name);
    if (index > -1) {
      category = { ...cats[index], images: [...cats[index].images, ...category.images] };
      return [...cats.slice(0, index), category, ...cats.slice(index + 1)];
    }
    return [...cats, category];
  }, []);
}
