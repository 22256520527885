import { fabric } from "fabric";

const size = 38;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="420" height="430" viewBox="0 0 42 43" fill="none">
    <rect x="1.2" y="20" width="28" height="28" rx="14" transform="rotate(-45 1.2 20)" fill="#FFF" shape-rendering="geometricPrecision" stroke="#0008" stroke-width="1" stroke-opacity="0.7" />
    <path
      d="M15.984 20.0221C16.0061 20.7955 16.1607 21.5247 16.4922 22.1655C16.7353 22.6737 16.5143 23.2925 16.0281 23.5134C15.5199 23.7565 14.9233 23.5576 14.6802 23.0494C14.2383 22.1213 13.9952 21.0828 13.9952 20.0221C13.9952 16.1772 17.1109 13.0615 20.9558 13.0173V11.1612C20.9558 10.896 21.1547 10.6087 21.4198 10.4762C21.7071 10.3657 22.0386 10.432 22.2374 10.6308L25.0659 13.4593C25.3752 13.7686 25.3531 14.2327 25.0659 14.5199L24.867 14.7188L22.2374 17.3483C22.0165 17.5693 21.7071 17.6577 21.4198 17.5472C21.1547 17.4146 20.9558 17.1274 20.9558 16.818V15.0061C18.2158 15.0503 16.0061 17.26 15.984 20.0221ZM16.9341 26.5407C16.6469 26.2535 16.6248 25.7894 16.9341 25.4801L17.0888 25.3254L17.1109 25.3033L19.7626 22.6517C19.9614 22.4528 20.2929 22.3865 20.5801 22.497C20.8453 22.6296 21.0442 22.9168 21.0442 23.182V25.0381C23.7842 24.9939 26.016 22.7621 26.016 20.0221C25.9939 19.2487 25.8393 18.5195 25.5299 17.8566C25.2647 17.3704 25.4857 16.7517 25.9718 16.5308C26.4801 16.2877 27.0767 16.4866 27.3198 16.9948C27.7617 17.9229 27.9827 18.9393 28.0048 20.0221C28.0048 23.867 24.8891 26.9827 21.0442 27.0269V28.8388C21.0442 29.1482 20.8453 29.4355 20.5801 29.568C20.2929 29.6785 19.9835 29.5901 19.7626 29.3692L17.133 26.7396L17.1109 26.7175L16.9341 26.5407Z"
      fill="#292929" />
  </svg>
`);

const mtr: Partial<fabric.Control> = {
  x: 0,
  y: -.5,
  offsetX: 0,
  offsetY: -24,
  sizeX: 32,
  sizeY: 32,
  cursorStyle: 'pointer',
  withConnection: false,
  render,
};

Object.assign(fabric.Object.prototype.controls.mtr, mtr);
Object.assign(fabric.Textbox.prototype.controls.mtr, mtr);

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}
