import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Trim, TrimTypes } from '../../models';

@Component({
  selector: 'ed-border-trim-mob',
  templateUrl: './border-trim-mob.component.html',
  styleUrls: ['./border-trim-mob.component.scss', '../button/preview-button-mob.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorderTrimMobComponent {
  @Input() trim: TrimTypes;
  @Input() trimData: Trim[];

  @Output() changeTrim = new EventEmitter<Trim>();

  chooseTrimOption(trimOption: Trim) {
    this.trim = trimOption.type;
    this.changeTrim.emit(trimOption);
  }
}
