import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { round } from 'lodash-es';
import { ButtonFunctions } from './counter-button.component';

@Component({
  selector: 'ed-counter',
  templateUrl: 'counter.component.html',
  styleUrls: ['counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent {
  @Input() dataLayerIdPrefix: string;
  @Input() showDegreesSymbol = false;
  @Input() changeImmediately = true;
  @Input() roundToPrecision = 0;

  @Input()
  set count(count: number) {
    this.disableButtons = count === undefined;
    const rounded = round(count, this.roundToPrecision);
    this._count = rounded;
    this.counterValue = rounded;
    this.setSymbolDistance(rounded);
  }

  get count() {
    return this._count;
  }

  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() step = 1;
  @Input() period: number;

  @Output() changeValue = new EventEmitter<number>();

  buttonFunctions = ButtonFunctions;
  symbolDistances = ['one', 'two', 'three', 'four'];
  symbolDistance = this.symbolDistances[0];

  counterValue: number;
  private _count = 0;

  disableButtons = false;

  setCountValue(value: number) {
    this.counterValue = this.boundCount(value);
    this.disableButtons = false;
    this.setSymbolDistance(value);
  }

  setNewValue(value: number) {
    this.count = this.boundCount(value);
    this.changeValue.emit(this.count);
  }

  setSymbolDistance(value: number) {
    const length = value.toString().replace('.', '').length;
    this.symbolDistance = this.symbolDistances[length - 1] || this.symbolDistances[this.symbolDistances.length - 1];
  }

  boundCount(count: number) {
    return Math.min(Math.max(count, this.min), this.max);
  }

  onTextInput(value: string) {
    const newValue = this.boundCount(coerceNumberProperty(value, this.count));
    this.setCountValue(newValue);
    this.changeValue.emit(newValue);
  }
}
