import { MOVE_STEP_FORWARD } from './canvas.action.types';
import { EditorAction } from '../action';

export class MoveStepForward implements EditorAction {
  readonly type = MOVE_STEP_FORWARD;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>) {}
}
