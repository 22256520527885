import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetTextService } from '../../../services';

@Component({
  selector: 'ed-remove-element-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['../modals.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public getTextService: GetTextService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; message: string; confirmButtonText: string; cancelButtonText: string }
  ) {}

  ngOnInit() {
    // prevent all keyboard events while modal is open
    this.dialogRef.keydownEvents().subscribe(event => event.stopPropagation());
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ok() {
    this.dialogRef.close(true);
  }
}
