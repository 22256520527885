import { EditorAction } from '../action';
import { ADD_BACKGROUND_IMAGE } from './canvas.action.types';

export class AddBackgroundImage implements EditorAction {
  readonly type = ADD_BACKGROUND_IMAGE;
  readonly undoable = true;
  readonly save = true;

  constructor(public width: number, public height: number, public sid?: string, public url?: string) {}
}
