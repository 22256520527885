import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { catchError, filter, first, map, mergeMap, share } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { load as loadFonts } from 'webfontloader';
import { cloneDeep, isEqual, unionBy } from 'lodash-es';

import { AppState } from '../reducers';
import { ConfigService } from './config-service';
import { DEFAULT_FONTS, Font, FontFace, FontStatus, ImgBaseFunc } from '../models';
import { LoadFont, SetFontLibraryStatus } from '../font-library/actions';
import { fontLibraryStatus, getVisiblePageFontsLoaded } from '../font-library/reducer';
import { getDesignSet } from '../selectors';

const FONT_FACES_NODE_ID = 'custom-font-faces';
const FontsWithUnnecessaryInactiveStatus = ['tweed.'];

@Injectable()
export class FontLoadService {
  protected subscriptions: Subscription[] = [];
  protected designSetFontsLastValue: Font[];
  fontsToShowInLibrary: Font[];
  constructor(
    public store: Store<AppState>,
    private config: ConfigService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store.pipe(
      select(s => s.designSet),
      filter(designSet => designSet.init),
      map(designSet => designSet.designSetFonts),
    ).subscribe((designSetFonts) => {
      if (!isEqual(designSetFonts, this.designSetFontsLastValue)) {
        this.designSetFontsLastValue = designSetFonts;
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.load();
      }
    });
  }

  load() {
    const visiblePageFonts$ = this.store.pipe(
      select(s => s.designSet),
      filter(design => design.init),
      map(designSet => designSet.visiblePage.fonts),
      first()
    );
    const designFonts$ = this.store.pipe(
      select(s => s.designSet),
      filter(design => design.init),
      map(design => design.designSetFonts)
    );
    const fontLibraryFonts$ = this.store.pipe(
      select(s => s.fontlibrary.fontlibrary),
      filter(fonts => !!fonts.length)
    );
    const permissions$ = this.store.pipe(
      select(s => s.permissions),
      filter(perm => perm.init)
    );
    const visiblePageFontsLoaded$ = this.store.pipe(
      select(getVisiblePageFontsLoaded),
      filter(l => l),
      first()
    );
    const visiblePageImagesLoaded$ = this.store.pipe(
      select(getDesignSet),
      map(d => d.initialPageImagesLoaded),
      filter(l => l),
      first()
    );

    const fontLocationRequest$ = combineLatest([designFonts$, fontLibraryFonts$, permissions$]).pipe(
      first(),
      filter(([a, b, permissions]) => permissions.functionPermissions.canAddTextInline),
      mergeMap(([designFonts, libraryFonts, c]) => {
        const defaultFonts = DEFAULT_FONTS.map(f => new Font(f));
        this.fontsToShowInLibrary = [...designFonts, ...libraryFonts]; // exclude defaultFonts from shown unless requested
        return this.getFontLocations(unionBy(designFonts, libraryFonts, defaultFonts, 'name'));
      }),
      filter(fontsWithLocation => !!fontsWithLocation.length),
      share()
    );

    const findFontInArray = (font: Font, fontsArray: Font[]): Font => {
      return fontsArray.find(
        f => f.name === font.name || (font.fontFaces.length && font.fontFaces.find(face => face.family === f.name))
      );
    };

    this.subscriptions.push(forkJoin([fontLocationRequest$, visiblePageFonts$])
      .pipe(
        map(([fontsWithLocation, visiblePageFonts]) => {
          const visiblePageFontsWithoutLocation = visiblePageFonts.filter(
            (font: Font) => !findFontInArray(font, fontsWithLocation)
          );
          return [
            ...fontsWithLocation.filter((fwl: Font) => findFontInArray(fwl, visiblePageFonts)),
            ...visiblePageFontsWithoutLocation
          ];
        }),
        filter(fonts => !!fonts.length)
      )
      .subscribe((visiblePageFonts: Font[]) => this.handleFonts(visiblePageFonts)));

    this.subscriptions.push(forkJoin([visiblePageFontsLoaded$, visiblePageImagesLoaded$, fontLocationRequest$, visiblePageFonts$])
      .pipe(
        map(([a, b, fontsWithLocation, visiblePageFonts]) =>
          fontsWithLocation.filter(fwl => !findFontInArray(fwl, visiblePageFonts))
        )
      )
      .subscribe(allRemainingFonts => this.handleFonts(allRemainingFonts)));
  }

  getFontLocations(fonts: Font[]): Observable<Font[]> {
    return this.http.post(this.config.imgBase + ImgBaseFunc.get_font_locations, { fonts }).pipe(
      map((response: { fonts: Font[] }) => response.fonts),
      catchError(e => {
        console.error(e);
        return of([]);
      })
    );
  }

  handleFonts(fonts: Font[]) {
    const fontsWithoutUrl = fonts.filter(font => font.fontFaces.length < 1);
    if (fontsWithoutUrl.length) {
      fontsWithoutUrl.forEach(font => {
        font = cloneDeep(font);
        font.status = FontStatus.inactive;
        font.oldNames.push(font.name);
        this.store.dispatch(LoadFont({ font }));
      });
    }

    const fontsWithUrl = fonts.filter(font => font.fontFaces.length > 0);
    this.createFontNode(fontsWithUrl);
    const fontFamiliesWithFvd = fontsWithUrl.map(font => {
      const fvds = font.fontFaces.map(fontFace => {
        const weight = this.isBold(fontFace) ? '7' : '4';
        const slant = this.isItalic(fontFace) ? 'i' : 'n';
        return slant + weight;
      });
      return font.name + ':' + fvds.join(',');
    });
    loadFonts({
      timeout: 10000,
      // sometimes font load takes up to 10sec!!! on development environment,
      // this means editor waits this long for inline text conversion, which is way to long
      custom: { families: fontFamiliesWithFvd },
      classes: false,
      fontactive: (familyName: string, fvd: string) => this.disPatchFont(familyName, fontsWithUrl, FontStatus.active),
      fontinactive: (familyName: string, fvd: string) =>
        this.disPatchFont(familyName, fontsWithUrl, FontStatus.inactive),
      active: () => this.store.dispatch(SetFontLibraryStatus({ status: fontLibraryStatus.active })),
      inactive: () => this.store.dispatch(SetFontLibraryStatus({ status: fontLibraryStatus.inactive }))
    });
  }

  disPatchFont(familyName: string, fonts: Font[], status: FontStatus) {
    const fontOfFonts = fonts.find(f => f.name == familyName);
    if (!this.fontsToShowInLibrary.find(f => fontOfFonts.oldNames.includes(f.name))) {
      return;
    }

    const font = fonts.find(
      (f: Font) => f.name === familyName && f.fontFaces.find(fontFace => fontFace.status === undefined)
    );
    // kind of hacky, just count font variants and if the same number variants are activated, activate font
    const loadingFontFace = font.fontFaces.find(fontFace => fontFace.status === undefined);

    loadingFontFace.status = status;
    const allFontsActivated = !font.fontFaces.find(fontFace => fontFace.status === undefined);

    if (allFontsActivated) {
      if (FontsWithUnnecessaryInactiveStatus.includes(font.name) && status === FontStatus.inactive) {
        status = FontStatus.active;
      }

      font.status = status;
      this.store.dispatch(LoadFont({ font }));
    }
  }

  isBold = (fontFace: FontFace) => fontFace.styles.find(style => style === 'bold');

  isItalic = (fontFace: FontFace) => fontFace.styles.find(style => style === 'italic');

  createFontNode(fonts: Font[]) {
    let fontFaceNodeString = '';
    fonts.forEach(font => {
      font.fontFaces.forEach(fontFace => {
        const fontUrl = `${this.config.imgBase}/${fontFace.url}`;
        // use font-display block else chrome will use fallback font when detecting slow network
        fontFaceNodeString += `
        @font-face {
          font-display: block;
          font-family: "${font.name}";
          src: url('${encodeURI(fontUrl)}');
          font-weight: ${this.isBold(fontFace) ? 'bold' : 'normal'};
          font-style: ${this.isItalic(fontFace) ? 'italic' : 'normal'};
        }`;
      });
    });

    const fontFacesNode = document.createElement('style');
    fontFacesNode.setAttribute('id', FONT_FACES_NODE_ID);
    document.body.appendChild(fontFacesNode);
    fontFacesNode.innerHTML = fontFaceNodeString;
  }
}
