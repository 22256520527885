import { EditorAction } from '../action';
import { CHANGE_FONTSIZE } from './canvas.action.types';

export class ChangeFontsize implements EditorAction {
  readonly type = CHANGE_FONTSIZE;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public fontsize: number) {}
}
