import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromSave from '../../save/reducer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImportExportJsonDialogText } from '../../models/text';
import { getDesignSet, selectDesign } from '../../selectors';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { OutputService, TextService } from '../../services';
import { Observable, of } from 'rxjs';
import { getFontLibraryLoadedAndActive } from '../../font-library/reducer';
import { environment } from '../../../environments/environment';
import { Design } from '../../models';
import { DesignSet } from '../../models/design-set';
import { CanvasActions } from '../../actions';
import { HttpClient } from '@angular/common/http';
import { TransformService } from '../../save/transform.service';

export type jsonName = 'import' | 'export';

export class JsonOption {
  constructor(public name: jsonName, public open = false) {}
}

export type JsonOptions = { [P in jsonName]: JsonOption };

@Component({
  selector: 'ed-import-export-json',
  templateUrl: 'import-export-json-dialog.component.html',
  styleUrls: ['../dialogs/modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportExportJsonDialogComponent implements OnInit {
  output = '';
  public notAllFontsLoaded$: Observable<boolean>;

  jsonOptions: JsonOptions = {
    import: new JsonOption('import', true),
    export: new JsonOption('export')
  };

  constructor(
    private http: HttpClient,
    private transform: TransformService,
    private store: Store<fromSave.State>,
    private outputService: OutputService,
    private textService: TextService,
    private dialogRef: MatDialogRef<ImportExportJsonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: ImportExportJsonDialogText }
  ) {}

  get selectedJsonOption() {
    return Object.keys(this.jsonOptions).find(name => this.jsonOptions[name].open);
  }

  ngOnInit(): void {
    this.notAllFontsLoaded$ = this.store.pipe(
      select(getFontLibraryLoadedAndActive),
      map(loaded => !loaded)
    );
  }

  showJsonOption(jsonOption: JsonOption) {
    Object.keys(this.jsonOptions).map(name => (this.jsonOptions[name].open = name === jsonOption.name));
  }

  exportJson() {
    this.showJsonOption(this.jsonOptions['export']);
    this.store.pipe(select(selectDesign), first()).subscribe(design => {
      this.output = JSON.stringify(this.outputService.transformToKC(design));
    });
  }

  savePages(value: string) {
    const designJson$ = value
      ? of(JSON.parse(value))
      : environment.demoCanvas
      ? this.http.get('assets/double-square.json')
      : this.http.get('assets/empty-square.json');

    designJson$
      .pipe(
        first(),
        map(cardData => this.transform.transformKcPages(cardData)),
        this.textService.designTextConversion(),
        withLatestFrom(
          this.store.pipe(
            select(getDesignSet),
            filter(set => set.init)
          )
        )
      )
      .subscribe(([design, set]: [Design, DesignSet]) => {
        const designSet = new DesignSet();
        designSet.version = set.version;
        designSet.designs = [design];
        if (set.version > 0) {
          design.id = set.designs[0].id;
        }
        this.store.dispatch(new CanvasActions.Init(designSet, true));
        this.dialogRef.close();
      });
  }
}
