import { CanvasElement, Design, Fold, ImageElement, PageElement, Spread, TextElement, View } from '../models';
import { round } from 'lodash-es';

export function getSpreads(design: Design, view: View): Spread[] {
  const nrOfPages = design.pages.length;
  const startId = calculateStartId(design.pages);
  const foldHorizontal = design.foldType === Fold.horizontal;

  // assume brochure or double card when true;
  const nrOfPagesIsMultipleOfFour = nrOfPages >= 4 && nrOfPages % 4 === 0;

  if (view === View.card && nrOfPagesIsMultipleOfFour) {
    return getCardSpreads(design.pages, design.labels.card, nrOfPages, startId);
  } else if (view === View.userSpreads && nrOfPagesIsMultipleOfFour) {
    return getUserSpreads(design.pages, design.labels.userSpreads, nrOfPages, startId);
  } else if (view === View.productionSpreads && nrOfPagesIsMultipleOfFour) {
    return getProductionSpreads(design.pages, nrOfPages, startId);
  } else if ((view === View.card || view === View.userSpreads) && nrOfPages === 6) {
    // assume '3-luik'
    return getUserSpreadsZigZag3(design.pages, design.labels.userSpreads, startId);
  }

  // assume every page is a spread
  return getPageSpreads(design.pages, nrOfPages, nrOfPagesIsMultipleOfFour, foldHorizontal);
}

function getCardSpreads(
  pages: PageElement[],
  cardLabels: Array<string[]>,
  nrOfPages: number,
  startId: number
): Spread[] {
  const spreads: Spread[] = [];

  const frontLabel = cardLabels[0] || [''];
  const front = new Spread(startId, [pages[0]], [], frontLabel);
  front.spreadPage.borderRadius.leftTop = 0;
  front.spreadPage.borderRadius.leftBottom = 0;
  spreads.push(front);

  for (let i = 1; i < nrOfPages - 1; i = i + 2) {
    const cardLabelIndex = (i + 1) / 2;
    const cardLabel = cardLabels[cardLabelIndex] || [''];
    const spread = new Spread(i + startId, [pages[i], pages[i + 1]], ['back'], cardLabel);
    spreads.push(spread);
  }

  const backLabel = cardLabels[cardLabels.length - 1] || [''];
  const back = new Spread(startId + (nrOfPages - 1), [pages[nrOfPages - 1]], [], backLabel);
  back.spreadPage.borderRadius.rightTop = 0;
  back.spreadPage.borderRadius.rightBottom = 0;
  spreads.push(back);

  return spreads;
}

function getUserSpreads(
  pages: PageElement[],
  spreadLabels: Array<string[]>,
  nrOfPages: number,
  startId: number
): Spread[] {
  const spreads: Spread[] = [];

  const coverLabel = spreadLabels[0] || [''];
  const cover = new Spread(startId, [pages[nrOfPages - 1], pages[0]], ['back'], coverLabel);
  spreads.push(cover);

  for (let i = 1; i < nrOfPages - 1; i = i + 2) {
    const spreadLabelIndex = (i + 1) / 2;
    const insideLabel = spreadLabels[spreadLabelIndex] || [''];
    const spread = new Spread(i + startId, [pages[i], pages[i + 1]], ['front'], insideLabel);
    spreads.push(spread);
  }
  return spreads;
}

function getUserSpreadsZigZag3(pages: PageElement[], spreadLabels: Array<string[]>, startId: number): Spread[] {
  const spreads: Spread[] = [];
  const frontLabel = spreadLabels[0] || [''];
  const backLabel = spreadLabels[1] || [''];
  const front = new Spread(startId, [pages[0], pages[1], pages[2]], ['back', 'front'], frontLabel);
  const back = new Spread(1 + startId, [pages[3], pages[4], pages[5]], ['front', 'back'], backLabel);
  front.backSpread = back;
  spreads.push(front);
  spreads.push(back);

  return spreads;
}

function getProductionSpreads(pages: PageElement[], nrOfPages: number, startId: number): Spread[] {
  const spreads: Spread[] = [];
  for (let i = 0; i < nrOfPages / 4; i++) {
    const front = new Spread(i * 2 + startId, [pages[nrOfPages - (i * 2 + 1)], pages[i * 2]], ['back']);
    const back = new Spread(i * 2 + 1 + startId, [pages[i * 2 + 1], pages[nrOfPages - (i * 2 + 2)]], ['front']);
    front.backSpread = back; // not used yet
    spreads.push(front);
    spreads.push(back);
  }
  return spreads;
}

function getPageSpreads(
  pages: PageElement[],
  startId: number,
  nrOfPagesIsMultipleOfFour: boolean,
  foldHorizontal: boolean
): Spread[] {
  return pages.map((page, index) => {
    if (nrOfPagesIsMultipleOfFour) {
      if (foldHorizontal) {
        if (index !== 1) {
          page.borderRadius.leftTop = 0;
          page.borderRadius.rightTop = 0;
        } else {
          page.borderRadius.leftBottom = 0;
          page.borderRadius.rightBottom = 0;
        }
      } else {
        if (index % 2 === 0) {
          // gives odd page; note that index of array starts at 0
          page.borderRadius.leftTop = 0;
          page.borderRadius.leftBottom = 0;
        } else {
          page.borderRadius.rightTop = 0;
          page.borderRadius.rightBottom = 0;
        }
      }
    }
    return new Spread(index + startId, [page]);
  });
}

function calculateStartId(pages: PageElement[]): number {
  return pages.reduce((maxId, page) => Math.max(maxId, page.id), 0) + 1;
}

export function getVisiblePageSpread(design: Design, view: View) {
  return getSpreads(design, view).find(spread => !!spread.pages.find(page => !!page.visible));
}

export function elementsAreEqual(newElement: CanvasElement, oldElement: CanvasElement, newX: number): boolean {
  const decimals = 0;
  let isEqual =
    newElement.type === oldElement.type &&
    round(newX, decimals) === round(oldElement.x, decimals) &&
    round(newElement.rotation, decimals) === round(oldElement.rotation, decimals) &&
    round(newElement.y, decimals) === round(oldElement.y, decimals) &&
    round(newElement.width, decimals) === round(oldElement.width, decimals) &&
    round(newElement.height, decimals) === round(oldElement.height, decimals);

  const commonTextImagePropertiesAreEqual = (first: ImageElement | TextElement, second: ImageElement | TextElement) =>
    first.color === second.color &&
    first.foilType === second.foilType &&
    first.transparency === second.transparency &&
    first.flipHorizontal === second.flipHorizontal &&
    first.flipVertical === second.flipVertical;

  const textIsEqual = (first: TextElement, second: TextElement) =>
    commonTextImagePropertiesAreEqual(first, second) &&
    first.text === second.text &&
    first.font === second.font &&
    first.fontSize === second.fontSize &&
    first.useAbsoluteFontSize === second.useAbsoluteFontSize &&
    first.ha === second.ha;

  const imageIsEqual = (first: ImageElement, second: ImageElement) =>
    commonTextImagePropertiesAreEqual(first, second) && first.sid === second.sid && first.url === second.url;

  if (newElement.isImage()) {
    isEqual = isEqual && imageIsEqual(newElement as ImageElement, oldElement as ImageElement);
  }

  if (newElement.isText()) {
    // TODO: inline text
    isEqual = isEqual && textIsEqual(newElement as TextElement, oldElement as TextElement);
  }

  if (newElement.isPhotoFrame() || newElement.isBox()) {
    isEqual =
      isEqual &&
      newElement.children.length === oldElement.children.length &&
      newElement.children.reduce(
        (prev, current, currentIndex) =>
          prev && elementsAreEqual(current, oldElement.children[currentIndex], current.x),
        true
      );
  }

  return isEqual;
}
