import { EditorAction } from '../action';
import { RESIZE_TEXT_INLINE } from './canvas.action.types';
import { EditorSelection, Paragraph } from '../../models';

export class ResizeTextInline implements EditorAction {
  readonly type = RESIZE_TEXT_INLINE;
  readonly undoable = false;
  readonly save = true;

  constructor(
    public route: Array<number>,
    public text: Paragraph[],
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public selection?: EditorSelection
  ) {}
}
