import { ImgCatJson } from '../models';

export const imageLibrary: { categories: ImgCatJson[] } = {
  categories: [
    {
      imageType: 'image',
      name: 'Afbeeldingen',
      sid: 'L.1bd3d0eecfd6220316b86c0e44a81bc3.jpg',
      subcategories: [],
      images: [
        {
          name: 'Party animals',
          sid: 'None.a035f467444fd639b748c7567187e32d.jpg',
          width: 1600,
          height: 1200
        },
        {
          name: 'Bergen',
          sid: 'None.5301b486712aed7fabce880609637c29.jpg'
        },
        {
          name: 'Beer',
          sid: 'None.18eb87714899358b5b43b695afd2b091.jpg'
        },
        {
          name: 'New Background',
          sid: 'L.dd3e8dbf38e475b1a6a85fce2e58393e.jpg'
        },
        {
          name: 'Eforce',
          url: 'https://img.kaart-o-matic.nl/img/?sid=None.c59529ba3bee94746ffa8fb1f0511979.png&maxsize=800'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Iconen',
      sid: 'None.2f18ff75508a6c3f007925d7c4a53344.svg',
      subcategories: [
        {
          imageType: 'image',
          name: 'Kaders',
          subcategories: [
            {
              imageType: 'image',
              name: 'Cirkels',
              subcategories: [],
              images: [
                {
                  name: 'Cirkel1',
                  sid: 'None.7d13ce41f088ac81cea3a49061103348.svg',
                  width: 500,
                  height: 500,
                  isFoilable: true
                }
              ]
            }
          ],
          images: [
            {
              name: 'Kader1',
              sid: 'None.b94fbdfeec52abbdeb4cb0c581e40704.svg',
              width: 500,
              height: 500,
              isFoilable: true
            }
          ]
        },
        {
          imageType: 'image',
          name: 'Cirkels',
          subcategories: [],
          images: [
            {
              name: 'Cirkel1',
              sid: 'None.7d13ce41f088ac81cea3a49061103348.svg',
              width: 500,
              height: 500,
              isFoilable: true
            }
          ]
        },
        {
          imageType: 'image',
          name: 'Multi-media',
          sid: 'None.28a6c538a948b6e37a406394e0d55332.svg',
          subcategories: [],
          images: []
        },
        {
          imageType: 'image',
          name: 'Bloemen',
          sid: 'None.28a6c538a948b6e37a406394e0d55332.svg',
          subcategories: [],
          images: []
        },
        {
          imageType: 'image',
          name: 'Hartjes',
          subcategories: [],
          images: [
            {
              name: 'Hartje1',
              sid: 'None.359a1c3f4a3c2d8af7dddce605461214.svg'
            },
            {
              name: 'Hartje2',
              sid: 'None.a9c97d88b0b539543236b7632bb9ee2c.svg'
            },
            {
              name: 'Hartje3',
              sid: 'None.953611af2435bbce90564445c35182de.svg'
            },
            {
              name: 'Hartje4',
              sid: 'None.2f18ff75508a6c3f007925d7c4a53344.svg'
            },
            {
              name: 'Hartje5',
              sid: 'None.3f0ad9367e3507cf88de86c8a03e2395.svg'
            }
          ]
        },
        {
          imageType: 'image',
          name: 'Sterren',
          sid: 'None.28a6c538a948b6e37a406394e0d55332.svg',
          subcategories: [],
          images: []
        }
      ],
      images: [
        {
          name: 'Lijn',
          url: 'https://img.kaart-o-matic.nl/img/L.06215cee6968b6791ee16d6ad64c39fe.png'
        },
        {
          name: 'Lijn2',
          sid: 'None.a26095e0a5770b90051a7b0918dbe1c6.svg'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Vormen',
      sid: 'None.b0c63c577df60e31c7329daa8863cd0b.svg',
      subcategories: [],
      images: []
    },
    {
      imageType: 'image',
      name: 'Mijn fotos',
      sid: 'None.6486b43f99e4ec0839f53018f9030764.jpg'
    },
    {
      imageType: 'image',
      name: 'Recent',
      subcategories: [],
      images: [
        {
          name: 'Recent1',
          sid: 'None.e02c753607f1c2cb8116bb5d85c565f8.svg'
        },
        {
          name: 'Recent2',
          sid: 'None.09330b5836cab1d551c195f19932255e.png'
        },
        {
          name: 'Recent5',
          sid: 'None.68ccfa7d7de423a82ca449c074df2530.png'
        }
      ]
    },
    {
      imageType: 'backgroundImage',
      name: 'Patronen',
      sid: 'None.e923689ee15a5852de621ce5b8865dfd.svg',
      subcategories: [
        {
          imageType: 'backgroundImage',
          name: 'Lijnen',
          sid: 'None.e923689ee15a5852de621ce5b8865dfd.svg',
          subcategories: [],
          images: [
            {
              name: 'Lijnen',
              sid: 'None.e923689ee15a5852de621ce5b8865dfd.svg'
            }
          ]
        },
        {
          imageType: 'backgroundImage',
          name: 'Hartjes',
          sid: 'None.89ce79cdf7e27abe24199599e598e984.svg',
          subcategories: [],
          images: [
            {
              name: 'Hartjes',
              sid: 'None.89ce79cdf7e27abe24199599e598e984.svg'
            }
          ]
        },
        {
          imageType: 'backgroundImage',
          name: 'Bloemen',
          sid: 'None.085cbcceda946027493009d2202130a6.svg',
          subcategories: [],
          images: [
            {
              name: 'Bloemen',
              sid: 'None.085cbcceda946027493009d2202130a6.svg'
            }
          ]
        },
        {
          imageType: 'backgroundImage',
          name: 'Sterren',
          sid: 'None.6c25fe968cda5319eb24fce4d5d0328a.svg',
          subcategories: [],
          images: [
            {
              name: 'Sterren',
              sid: 'None.6c25fe968cda5319eb24fce4d5d0328a.svg'
            }
          ]
        }
      ],
      images: []
    },
    {
      imageType: 'image',
      name: 'Leuke diertjes',
      subcategories: [],
      images: [
        {
          name: 'Leuke tijger',
          sid: 'L.2bc9e495a8e6672a47b4a5c6c5b2b9fe.png'
        },
        {
          name: 'Leuke diertjes',
          sid: 'L.688e54355f16f1aa374466c5479c89d8.png'
        },
        {
          name: 'Leuk takje',
          sid: 'L.ae70aadedbd1348c3873e38ee86d5404.png'
        },
        {
          name: 'Leuke leeuw',
          sid: 'None.794cfde223e580054f22e214a909f342.png'
        },
        {
          name: 'Leuk blaadje',
          sid: 'L.4b230dff6c70f7433e26b8617730a9b8.png'
        },
        {
          name: 'Leuke papegaaiduiker',
          sid: 'L.7879f4eb7aae208597b46d6e60cf11b4.png'
        },
        {
          name: 'Leuke luiaard',
          sid: 'L.ac6819a26f6980716ee92cfb6020197a.png'
        },
        {
          name: 'Leuke bloem',
          sid: 'L.19194ea3ab9fdc4a0fe600054fe018ab.png'
        },
        {
          name: 'Leuke diertjes 2',
          sid: 'None.52db298d2d672482e7672d481ddb58a0.png'
        },
        {
          name: 'Leuke luipaard',
          sid: 'L.43142288f030ef6817a868cbc307d376.png'
        },
        {
          name: 'Leuke tak',
          sid: 'L.120a7f329b8fc8aec9dbca4b65b35387.png'
        },
        {
          name: 'Leuke walvis',
          sid: 'L.773123e4ab9290660198b47651c38f18.png'
        },
        {
          name: 'Leuke bloemen',
          sid: 'L.857d44a74fde9ba4837de137d770ff1d.png'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Foxy',
      subcategories: [],
      images: [
        {
          name: 'Old Foxy',
          sid: 'None.c6a7b86d4584784385f3febba4157cd0.svg'
        },
        {
          name: 'New Foxy',
          url: 'https://dumielauxepices.net/sites/default/files/red-fox-clipart-transparent-background-736874-484930.png'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Pinguin',
      subcategories: [],
      images: [
        {
          name: 'Pinguin',
          sid: 'None.e2b8fdd7f61a5d98320f51ea0f327343.svg'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Uiltjes',
      subcategories: [],
      images: [
        {
          name: 'Uil1',
          sid: 'None.feebbb5a296fdfae4b283609725bb563.svg'
        },
        {
          name: 'Uil2',
          sid: 'None.a1dd3eeb1c96a7763ffe544d7a8f9007.svg'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Bloemen',
      subcategories: [],
      images: [
        {
          name: 'Bloem',
          sid: 'None.02d3708f0c3f7034f341d0cd239990bf.png'
        },
        {
          name: 'Bloem',
          sid: 'None.71bec3d58d5dd86cbff97d9e913fc95e.png'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Letters',
      subcategories: [],
      images: [
        {
          name: 'A',
          sid: 'None.67e25e8eda615eeb4fed05c3d1155f7f.png'
        },
        {
          name: 'B',
          sid: 'None.376b22d66a15c22a8780dffdb91787f2.png'
        },
        {
          name: 'C',
          sid: 'None.fee7dfc9af273c73e50426123a2ed456.png'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Silhouet',
      subcategories: [],
      images: [
        {
          name: 'Silhouet',
          sid: 'None.f7f7034b718cf47e9c2619a6175cb35b.png'
        },
        {
          name: 'Silhouet',
          sid: 'None.9dbd6304449b63d9361790c715be88f8.png'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Stempels',
      subcategories: [],
      images: [
        {
          name: 'Stempel',
          sid: 'None.3dd716cab46271a0e960507d00a37240.svg'
        },
        {
          name: 'Stempel',
          sid: 'None.6980ab43fe697279fa6f3ee62ff032b1.svg'
        },
        {
          name: 'Stempel',
          sid: 'None.d891d2ab3cd93782c22ea5ded0cc8e9e.svg'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Icoontjes',
      subcategories: [],
      images: [
        {
          name: 'Icoontje',
          sid: 'None.168726080e388ec91688fb49824542d9.svg'
        },
        {
          name: 'Icoontje',
          sid: 'None.b0683edac00d0c7c40495875cf88b2f4.svg'
        }
      ]
    }
  ]
};

export const imageLibrary2: { categories: ImgCatJson[] } = {
  categories: [
    {
      imageType: 'image',
      name: 'Leuke diertjes',
      subcategories: [],
      images: [
        {
          name: 'Leuke tijger',
          sid: 'L.2bc9e495a8e6672a47b4a5c6c5b2b9fe.png'
        },
        {
          name: 'Leuke diertjes',
          sid: 'L.688e54355f16f1aa374466c5479c89d8.png'
        },
        {
          name: 'Leuk takje',
          sid: 'L.ae70aadedbd1348c3873e38ee86d5404.png'
        },
        {
          name: 'Leuke leeuw',
          sid: 'None.794cfde223e580054f22e214a909f342.png'
        },
        {
          name: 'Leuk blaadje',
          sid: 'L.4b230dff6c70f7433e26b8617730a9b8.png'
        },
        {
          name: 'Leuke papegaaiduiker',
          sid: 'L.7879f4eb7aae208597b46d6e60cf11b4.png'
        },
        {
          name: 'Leuke luiaard',
          sid: 'L.ac6819a26f6980716ee92cfb6020197a.png'
        },
        {
          name: 'Leuke bloem',
          sid: 'L.19194ea3ab9fdc4a0fe600054fe018ab.png'
        },
        {
          name: 'Leuke diertjes 2',
          sid: 'None.52db298d2d672482e7672d481ddb58a0.png'
        },
        {
          name: 'Leuke luipaard',
          sid: 'L.43142288f030ef6817a868cbc307d376.png'
        },
        {
          name: 'Leuke tak',
          sid: 'L.120a7f329b8fc8aec9dbca4b65b35387.png'
        }
      ]
    },
    {
      imageType: 'image',
      name: 'Iconen',
      sid: 'None.2f18ff75508a6c3f007925d7c4a53344.svg',
      subcategories: [
        {
          imageType: 'image',
          name: 'Cirkels',
          subcategories: [],
          images: [
            {
              name: 'Cirkel1',
              sid: 'None.7d13ce41f088ac81cea3a49061103348.svg',
              isFoilable: true
            }
          ]
        },
        {
          imageType: 'image',
          name: 'Multi-media',
          sid: 'None.28a6c538a948b6e37a406394e0d55332.svg',
          subcategories: [],
          images: []
        },
        {
          imageType: 'image',
          name: 'Bloemen',
          sid: 'None.28a6c538a948b6e37a406394e0d55332.svg',
          subcategories: [],
          images: []
        },
        {
          imageType: 'image',
          name: 'Hartjes',
          subcategories: [],
          images: [
            {
              name: 'Hartje1',
              sid: 'None.359a1c3f4a3c2d8af7dddce605461214.svg'
            },
            {
              name: 'Hartje2',
              sid: 'None.a9c97d88b0b539543236b7632bb9ee2c.svg'
            },
            {
              name: 'Hartje3',
              sid: 'None.953611af2435bbce90564445c35182de.svg'
            },
            {
              name: 'Hartje4',
              sid: 'None.2f18ff75508a6c3f007925d7c4a53344.svg'
            },
            {
              name: 'Hartje5',
              sid: 'None.3f0ad9367e3507cf88de86c8a03e2395.svg'
            }
          ]
        },
        {
          imageType: 'image',
          name: 'Sterren',
          sid: 'None.28a6c538a948b6e37a406394e0d55332.svg',
          subcategories: [],
          images: []
        }
      ],
      images: [
        {
          name: 'Lijn',
          url: 'https://img.kaart-o-matic.nl/img/L.06215cee6968b6791ee16d6ad64c39fe.png'
        },
        {
          name: 'Lijn2',
          sid: 'None.a26095e0a5770b90051a7b0918dbe1c6.svg'
        }
      ]
    }
  ]
};

export const emptyImageLibrary: { categories: ImgCatJson[] } = {
  categories: []
};
