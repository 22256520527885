import { Component, Input, Output, EventEmitter, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Subscription, timer } from 'rxjs';

export enum ButtonFunctions {
  increase = 'increase',
  decrease = 'decrease'
}

@Component({
  selector: 'ed-counter-button',
  templateUrl: 'counter-button.component.html',
  styleUrls: ['../../shared/button/buttons.scss', 'counter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterButtonComponent {
  @Input() dataLayerIdPrefix: string;
  @Input() count: number;
  @Input() step = 1;
  @Input() changeImmediately: boolean;
  @Input() disable: boolean;

  @Input() set period(period: number) {
    this.timer = timer(50, period);
  }

  @Input() set buttonFunction(buttonFunction: ButtonFunctions) {
    this.icon = buttonFunction === ButtonFunctions.decrease ? 'ed-icon-remove' : 'ed-icon-add';
    this.stepValue = buttonFunction === ButtonFunctions.decrease ? -this.step : this.step;
    this._buttonFunction = buttonFunction;
  }

  get buttonFunction() {
    return this._buttonFunction;
  }

  @Output() changeCountValue = new EventEmitter<number>();
  @Output() changeValue = new EventEmitter<number>();

  protected timeoutHandler: Subscription;
  private timer = timer(50, 100);

  _buttonFunction: ButtonFunctions;
  icon: string;
  stepValue: number;
  newCount: number;

  onMouseDown(event: MouseEvent, delta: number) {
    if (event.button !== undefined && event.button !== 0) {
      // only respond on left mouse button or on touch event
      return;
    }

    this.newCount = this.count + delta;
    this.changeValue.emit(this.newCount);
    this.startTimer(delta);
  }

  onTouchStart(event: TouchEvent, delta: number) {
    event.preventDefault();
    this.newCount = this.count + delta;
    this.changeValue.emit(this.newCount);
    this.startTimer(delta);
  }

  startTimer(delta: number) {
    this.timeoutHandler = this.timer.subscribe(x => {
      const changeValue = x * delta;
      this.newCount = this.count + changeValue;
      this.changeCountValue.emit(this.newCount);
      if (this.changeImmediately) {
        this.changeValue.emit(this.newCount);
      }
    });
  }

  @HostListener('window:mouseup')
  @HostListener('window:touchend')
  onMouseUp() {
    if (this.timeoutHandler) {
      this.timeoutHandler.unsubscribe();
      this.timeoutHandler = undefined;
      this.changeValue.emit(this.newCount);
    }
  }
}
