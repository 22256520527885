import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { roundToHalf } from '../../../../utils/element.utils';
import { GetTextService } from '../../../../services';
import { CanvasCoordinate } from '../../../../models';

@Component({
  selector: 'ed-coordinates',
  templateUrl: 'coordinates.component.html',
  styleUrls: ['../coordinates-and-dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoordinatesComponent {
  @Input() x: number;

  @Input() y: number;

  @Output() changeValue = new EventEmitter<CanvasCoordinate>();

  @Input() @HostBinding('class.height-adjusted-for-input') showInputFields: boolean;

  get roundX() {
    return roundToHalf(this.x);
  }

  get roundY() {
    return roundToHalf(this.y);
  }

  constructor(public getTextService: GetTextService) {}

  onEnterX(value: string) {
    const newLeftTop = { x: Number(value), y: this.roundY };
    this.emitDelta(newLeftTop);
  }

  onEnterY(value: string) {
    const newLeftTop = { x: this.roundX, y: Number(value) };
    this.emitDelta(newLeftTop);
  }

  emitDelta(newLeftTop: CanvasCoordinate) {
    const deltaX = newLeftTop.x - this.roundX;
    const deltaY = newLeftTop.y - this.roundY;
    const delta = { x: deltaX, y: deltaY };
    this.changeValue.emit(delta);
  }
}
