import { EditorAction } from '../action';
import { CHANGE_TRANSPARENCY } from './canvas.action.types';

export class ChangeTransparency implements EditorAction {
  readonly type = CHANGE_TRANSPARENCY;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public transparency: number) {}
}
