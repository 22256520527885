import { Action } from '@ngrx/store';
import { AuthenticateEmail, RecoverUser } from '../models/user';
import { ClientError } from '../models/client-error';

export enum RecoverActionTypes {
  OpenRecoverDialog = '[Recover] Open Recover Dialog',
  CloseRecoverDialog = '[Recover] Close Recover Dialog',
  Recover = '[Recover] Recover Password',
  RecoverSuccess = '[Recover] Recover Success',
  RecoverFailure = '[Recover] Recover Failure',
  CloseRecoverSuccessDialog = '[Recover] Close Recover Success Dialog',
  ResetValidationErrors = '[Recover] Reset Validation Errors'
}

export class OpenRecoverDialog implements Action {
  readonly type = RecoverActionTypes.OpenRecoverDialog;
}

export class CloseRecoverDialog implements Action {
  readonly type = RecoverActionTypes.CloseRecoverDialog;
}

export class Recover implements Action {
  readonly type = RecoverActionTypes.Recover;

  constructor(public payload: AuthenticateEmail) {}
}

export class RecoverSuccess implements Action {
  readonly type = RecoverActionTypes.RecoverSuccess;

  constructor(public user: RecoverUser) {}
}

export class RecoverFailure implements Action {
  readonly type = RecoverActionTypes.RecoverFailure;

  constructor(public payload: ClientError[]) {}
}

export class CloseRecoverSuccessDialog implements Action {
  readonly type = RecoverActionTypes.CloseRecoverSuccessDialog;
}

export class ResetValidationErrors implements Action {
  readonly type = RecoverActionTypes.ResetValidationErrors;
}

export type RecoverActions =
  | OpenRecoverDialog
  | CloseRecoverDialog
  | Recover
  | RecoverSuccess
  | RecoverFailure
  | CloseRecoverSuccessDialog
  | ResetValidationErrors;
