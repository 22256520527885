import * as CanvasActions from '../actions/canvas-actions';
import { CanvasElement, Color, ElementPermission } from '../models';

export function replaceSpecialColorInText(text: string, defaultText: string, color?: Color) {
  const replaceText = color && color.name ? color.name : defaultText;
  const regex = /{{([^}]+)}}/g;
  const curMatch = regex.exec(text);
  if (curMatch && curMatch[1].trim() === 'colorName') {
    text = text.replace(curMatch[0], replaceText);
  }
  return text;
}

export function resetSpecialColorAction(
  element: CanvasElement,
  permission: ElementPermission
): CanvasActions.ChangeColor | CanvasActions.ResetImageColor {
  const imageElement = element.isImage() ? element : element.isPhotoFrame() ? element.firstChild : undefined;
  if (
    element.isText() ||
    element.isInlineText() ||
    permission === ElementPermission.isSpotUvable // any element type
  ) {
    const colorValue = permission === ElementPermission.isSpotUvable && element.color || ((element.isText() || element.isInlineText()) && "#000000") || undefined;
    const color = new Color('', colorValue, undefined, false);
    const recolorRoute = imageElement ? imageElement.route : element.route;
    return new CanvasActions.ChangeColor(recolorRoute, color, false);
  } else if (imageElement) {
    return new CanvasActions.ResetImageColor(imageElement.route, false);
  }
}
