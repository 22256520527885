import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { OutputService } from '../services';
import { CanvasActions, UiActions } from '../actions';
import { ChangeFormatPreset, FunctionPermission } from '../models';
import { ChangeFormatDialogComponent } from '../shared/change-format-dialog/change-format.component';
import * as fromAuth from '../auth/reducer';
import * as Save from '../save/actions';
import * as fromSave from '../save/reducer';
import * as fromPermissions from '../reducers/permissions.reducer';
import { environment } from '../../environments/environment';
import * as Continue from '../continue/actions';
import { GetTextService } from '../services';
import { getDesignSet } from '../selectors';
import { getDemoDesignSet } from '../data/demo-canvas.data';
import { UpdateExistingDesignDialogComponent } from '../shared/update-existing-design-dialog/update-existing-design-dialog.component';
import { NewDesignDialogComponent } from '../shared/new-design-dialog/new-design-dialog.component';
import { SaveDesignInCollectionDialogComponent } from '../shared/save-design-in-collection-dialog/save-design-in-collection-dialog.component';
import { ImportExportJsonDialogComponent } from '../shared/import-export-json-dialog/import-export-json-dialog.component';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'ed-main-menu',
  templateUrl: 'main-menu.component.html',
  styleUrls: ['main-menu.component.scss', '../toolbar/toolbar.scss', '../shared/button/buttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit, OnDestroy {
  menuOpened = false;

  permissions = FunctionPermission;

  output = '';
  demoCanvas = environment.demoCanvas;

  public hasNotMultipleDesigns$: Observable<boolean>;
  public existingDesign$: Observable<boolean>;
  public canCopyDesign$: Observable<boolean>;
  public canSaveSubscription$: Subscription;
  public canSave: boolean;
  public isProposedDesign$: Observable<boolean>;
  public designDescription$: Observable<string>;
  public showFormatPages$: Observable<boolean>;
  public collectionUrl: string;
  public closeUrl: string;
  public convertDesignUrl: string;
  public createPdfUrl: string;
  private changeFormatPresets: ChangeFormatPreset[];
  public dataLayerIdPrefix = 'menu-';
  public gridOn$: Observable<boolean>;

  isLoggedIn$: Observable<boolean>;
  hasExtraOptions$: Observable<boolean>;
  faEllipsis = faEllipsis;

  constructor(
    private store: Store<fromSave.State>,
    private outputService: OutputService,
    private dialog: MatDialog,
    public getTextService: GetTextService
  ) {}

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  copyJson() {
    // TODO: create a function to copy input-content to clipboard
  }

  ngOnInit(): void {
    const designSet$ = this.store.pipe(
      select(getDesignSet),
      filter(set => set.init)
    );

    this.hasNotMultipleDesigns$ = designSet$.pipe(map(set => set.designs.length === 1));
    this.existingDesign$ = designSet$.pipe(map(designSet => designSet.id && !designSet.isProposedDesign));
    const existingUserDesign$ = designSet$.pipe(map(designSet => !!designSet.userCollectionId));
    this.isLoggedIn$ = this.store.pipe(select(fromAuth.isLoggedIn));
    this.canCopyDesign$ = combineLatest([existingUserDesign$, this.isLoggedIn$]).pipe(
      map(([userDesignExists, loggedIn]) => userDesignExists && loggedIn)
    );
    this.isProposedDesign$ = designSet$.pipe(map(design => design.isProposedDesign));
    this.designDescription$ = designSet$.pipe(map(design => design.description || ''));
    this.showFormatPages$ = this.store.pipe(select(fromPermissions.getFormatPages));
    this.canSaveSubscription$ = this.store
      .pipe(select(fromPermissions.getCanSave))
      .subscribe(permission => (this.canSave = permission));
    this.hasExtraOptions$ = this.store.pipe(select(fromPermissions.getHasExtraOptions));
    this.gridOn$ = designSet$.pipe(map(design => design.showGrid));

    this.store.pipe(select(s => s.config)).subscribe(config => {
      this.closeUrl = config.closeUrl;
      this.collectionUrl = config.collectionUrl;
      this.changeFormatPresets = config.changeFormatPresets;
      this.convertDesignUrl = config.convertDesignUrl;
      this.createPdfUrl = config.createPdfUrl;
    });
  }

  loadDemoCanvas() {
    this.store.dispatch(new CanvasActions.Init(getDemoDesignSet(), false));
  }

  save() {
    if (this.isLoggedIn$ && this.canSave) {
      this.store.dispatch(new Save.Save());
    } else {
      this.openSaveModal();
    }
  }

  openCopyModal() {
    this.store.dispatch(new Save.OpenCopyDialog());
  }

  openUpdateExistingDesignModal() {
    this.dialog.open(UpdateExistingDesignDialogComponent, {
      id: 'update-existing-design-dialog',
      width: '400px',
      data: {
        text: this.getTextService.text.dialog.updateExistingDesignDialogText,
        errorText: this.getTextService.text.dialog.saveErrorText
      }
    });
  }

  openNewDesignDialog() {
    this.dialog.open(NewDesignDialogComponent, {
      id: 'new-design-dialog',
      width: '400px',
      data: {
        text: this.getTextService.text.dialog.newDesignDialogText,
        errorText: this.getTextService.text.dialog.saveErrorText
      }
    });
  }

  openSaveDesignInCollectionDialog() {
    this.dialog.open(SaveDesignInCollectionDialogComponent, {
      id: 'save-design-in-collection-dialog',
      width: '400px',
      data: {
        text: this.getTextService.text.dialog.saveDesignInCollectionDialogText,
        errorText: this.getTextService.text.dialog.saveErrorText
      }
    });
  }

  openCopyJsonModal() {
    this.dialog.open(ImportExportJsonDialogComponent, {
      id: 'copy-json-dialog',
      width: '400px',
      data: {
        text: this.getTextService.text.dialog.importExportJsonDialogText
      }
    });
  }

  createPdf() {
    this.goToUrl(this.createPdfUrl, true);
  }

  openSaveModal() {
    this.store.dispatch(new Save.OpenSaveDialog());
  }

  openChangeFormatModal() {
    const dialogRef = this.dialog.open(ChangeFormatDialogComponent, {
      id: 'change-format-dialog',
      width: '500px',
      data: {
        text: this.getTextService.text.dialog.changeFormat,
        presets: this.changeFormatPresets,
        convertDesignUrl: this.convertDesignUrl
      }
    });
  }

  goToUrl(url: string, retainParams = false) {
    this.store.dispatch(new Continue.Navigate(url, retainParams));
  }

  ngOnDestroy() {
    this.canSaveSubscription$.unsubscribe();
  }

  toggleGrid() {
    this.store.dispatch(new CanvasActions.ToggleGrid());
  }
}
