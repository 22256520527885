import { RegisterActions, RegisterActionTypes } from './actions';
import { User } from '../models/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { ClientError } from '../models/client-error';

export interface RegisterState {
  user: User | null;
  error: ClientError[];
  pending: boolean;
  dialogOpen: boolean;
  successDialogOpen: boolean;
}

export interface State extends AppState {
  register: RegisterState;
}

export const initialState: RegisterState = {
  user: null,
  error: [],
  pending: false,
  dialogOpen: false,
  successDialogOpen: false
};

export function reducer(state = initialState, action: RegisterActions): RegisterState {
  switch (action.type) {
    case RegisterActionTypes.Register: {
      return {
        ...state,
        error: null,
        pending: true
      };
    }

    case RegisterActionTypes.RegisterFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case RegisterActionTypes.RegisterSuccess: {
      return {
        ...state,
        user: action.user,
        error: null,
        pending: false,
        dialogOpen: false,
        successDialogOpen: true
      };
    }

    case RegisterActionTypes.CloseRegisterSuccessDialog: {
      return {
        ...state,
        error: null,
        successDialogOpen: false
      };
    }

    case RegisterActionTypes.ResetValidationErrors: {
      return {
        ...state,
        error: null
      };
    }

    default: {
      return state;
    }
  }
}

export const selectRegisterState = createFeatureSelector<RegisterState>('register');

export const getError = createSelector(selectRegisterState, (state: RegisterState) => state.error);

export const getPending = createSelector(selectRegisterState, (state: RegisterState) => state.pending);

export const getDialogOpen = createSelector(selectRegisterState, (state: RegisterState) => state.dialogOpen);

export const getSuccessDialogOpen = createSelector(
  selectRegisterState,
  (state: RegisterState) => state.successDialogOpen
);
