import { BaseElement } from './base-element';
import { ElementType, CanvasElement } from './canvas';
import { ElementPermissions } from './permissions';
import { ImageElement } from './image-element';

export class BoxElement extends BaseElement {
  readonly type = ElementType.box;
  name = 'box';

  permissions: ElementPermissions = {
    isLocked: false,
    isMovable: true,
    isRemovable: true,
    isRotatable: true,
    isResizable: true,
    isDuplicatable: true,
    isFlippable: true,
    isCroppable: true,
    isTopLayer: false,
    isUntargetable: false,
    excludeFromPreviews: false
  };

  addElement(element: CanvasElement, id?: number) {
    element.id = id !== undefined ? id : this.newId;
    element.parent = this;
    element.order = this.newOrder;
    this.children.push(element);
    this.sortChildren();
  }

  constructor(id = -1, { width = 400, height = 500, x = 10, y = 10 } = {}) {
    super(id);
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }
}

export class BackgroundElement extends BaseElement {
  readonly type = ElementType.background;
  name = 'background';
  x = 0;
  y = 0;
  bleed = 10;

  permissions: ElementPermissions = {
    isLocked: false
  };

  get firstChild() {
    return this.children[0] as ImageElement;
  }

  get combinedPermissions() {
    return this.firstChild ? { ...this.firstChild.permissions, ...this.permissions } : this.permissions;
  }

  addElement(element: ImageElement, id?: number) {
    element.id = id !== undefined ? id : this.newId;
    element.parent = this;
    this.children = [element];
  }

  constructor({ width = 400, height = 500, color = '#ffffff', bleed = 0 } = {}) {
    super(0);
    this.width = width;
    this.height = height;
    this.color = color;
    this.bleed = bleed;
  }
}
