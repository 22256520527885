import {
  BackgroundTypes,
  defaultMaterialType,
  Fold,
  FunctionPermissions,
  KcDesign,
  KcElementType,
  KcLayerType,
  SetElementPermissions,
  TrimTypes,
  View
} from '../models';
import sluitsticker from '../../assets/sluitsticker-sample.json';
import { FakeDesign, FakeDesignSet } from '../models/fake-design';

export const allTrue = <P>(perm: P): P => Object.keys(perm).reduce((prev, key) => ({ ...prev, [key]: true }), {} as P);

export const emptyBusinessCard: FakeDesign = {
  design_id: 'emptyBusinessCard',
  is_proposed_design: false,
  design_title: 'Visitekaartje - Blanco',
  design_description: '',
  user_design_id: 34,
  user_design_title: 'visite kaartje',
  use_absolute_font_size: false,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.none,
      material: {
        type: defaultMaterialType,
        needsCoating: false,
        isFoilable: true,
        isFoldable: true,
        isSpotUvable: true,
        name: 'Papier'
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [],
      h: 55,
      bg: { h: 55, bgc: '#afd5db', w: 85 },
      w: 85,
      bleed: 3,
      safety: 3,
      position: 1
    },
    p2: {
      images: [],
      h: 55,
      bg: { h: 55, bgc: '#afd5db', w: 85 },
      w: 85,
      bleed: 3,
      safety: 3,
      position: 2
    }
  }
};

export const emptyDesignAbsoluteFontSize: FakeDesign = {
  design_id: null,
  is_proposed_design: false,
  design_title: 'Visitekaartje - Blanco',
  design_description: '',
  user_design_id: undefined,
  user_design_title: undefined,
  use_absolute_font_size: true,
  permissions: {
    setElementPermissions: allTrue(new SetElementPermissions()),
    functionPermissions: allTrue(new FunctionPermissions())
  },
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.none,
      material: {
        type: defaultMaterialType,
        needsCoating: false,
        isFoilable: true,
        isFoldable: true,
        isSpotUvable: true,
        name: 'Papier'
      },
      design_images: [],
      design_colors: []
    },
    p2: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#ffffff', w: 1000 },
      w: 1000,
      position: 1
    },
    p1: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#ffffff', w: 1000 },
      w: 1000,
      position: 2
    }
  }
};

export const design2: FakeDesign = {
  design_id: '2',
  is_proposed_design: false,
  design_title: 'Kaartje - Design 2',
  design_description: '',
  user_design_id: 1,
  user_design_title: '2',
  use_absolute_font_size: false,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.none,
      trim: TrimTypes.borderRound,
      permissions: {
        adjustTrim: true
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.photo,
          x: 6.8851343527764834,
          trans: 0,
          locked: 0,
          is_printable: true,
          fg_color: '',
          recolor: false,
          aspect: 1.25,
          sid: 'None.09330b5836cab1d551c195f19932255e.png',
          y: -11.338582677,
          r: 0,
          is_bg_image: true,
          is_bg_image_spread: false,
          size: 1022.677165354,
          layer_type: KcLayerType.standardRgb
        },
        {
          type: KcElementType.text,
          va: 'N',
          r: 0,
          font: 'CabinSketch',
          locked: 0,
          is_printable: true,
          color: '#98c836',
          text: 'Design 2',
          markup: '',
          trans: 0,
          s: 100,
          w: 530.8985292300085,
          h: 260,
          y: 715.4496610455483,
          x: 462.97016204775855,
          ha: 'C',
          is_justify: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 1000,
      w: 1000,
      bg: {
        h: 1000,
        bgc: '#ffffff',
        t: BackgroundTypes.image,
        w: 1000,
        fgc: '#000000'
      },
      position: 1
    },
    p2: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#cccccc', w: 1000 },
      w: 1000,
      position: 2
    }
  }
};

export const design3: FakeDesign = {
  design_id: '3',
  is_proposed_design: false,
  design_title: 'Kaartje - Design 3',
  design_description: '',
  user_design_id: 1,
  user_design_title: '3',
  use_absolute_font_size: false,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.horizontal,
      labels: {
        userSpreads: [['Buitenkant'], ['Binnenkant']]
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.photo,
          x: 6.8851343527764834,
          trans: 0,
          locked: 0,
          is_printable: true,
          fg_color: '',
          recolor: false,
          aspect: 1.25,
          sid: 'None.09330b5836cab1d551c195f19932255e.png',
          y: -11.338582677,
          r: 0,
          is_bg_image: true,
          is_bg_image_spread: false,
          size: 1022.677165354,
          layer_type: KcLayerType.standardRgb
        },
        {
          type: KcElementType.text,
          va: 'N',
          r: 0,
          font: 'CabinSketch',
          locked: 0,
          is_printable: true,
          color: '#98c836',
          text: 'Design 2',
          markup: '',
          trans: 0,
          s: 100,
          w: 530.8985292300085,
          h: 260,
          y: 715.4496610455483,
          x: 462.97016204775855,
          ha: 'C',
          is_justify: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 1000,
      w: 1000,
      bg: {
        h: 1000,
        bgc: '#ffffff',
        t: BackgroundTypes.image,
        w: 1000,
        fgc: '#000000'
      },
      position: 1
    },
    p2: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#cccccc', w: 1000 },
      w: 1000,
      position: 2
    },
    p3: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#cccccc', w: 1000 },
      w: 1000,
      position: 3
    },
    p4: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#cccccc', w: 1000 },
      w: 1000,
      position: 4
    }
  }
};

export const design4: FakeDesign = {
  design_id: '4',
  is_proposed_design: true,
  design_title: 'Kaartje - Design 4',
  design_description: '',
  user_design_id: 2,
  user_design_title: '4',
  use_absolute_font_size: false,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.none,
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.photo,
          x: -319.45285685416667,
          trans: 0,
          locked: 0,
          is_printable: true,
          fg_color: '',
          recolor: false,
          aspect: 0.624,
          sid: 'L.9f2276f431b25fa05e91e69dda27a74d.jpg',
          y: -11.338582677,
          r: 0,
          is_bg_image: true,
          is_bg_image_spread: false,
          size: 1638.9057137083335,
          layer_type: KcLayerType.standardRgb
        },
        {
          type: KcElementType.text,
          va: 'N',
          r: 0,
          font: 'Annie Use Your Telescope Bold',
          locked: 0,
          is_printable: true,
          color: '#fda069',
          text: 'Design 4',
          markup: '',
          trans: 0,
          s: 100,
          w: 600,
          h: 147,
          y: 130,
          x: 200,
          ha: 'C',
          is_justify: false,
          layer_type: KcLayerType.standardRgb
        },
        {
          type: KcElementType.photo,
          x: -23.37041156840934,
          trans: 0,
          locked: 0,
          is_printable: true,
          fg_color: '',
          recolor: false,
          aspect: 1,
          sid: 'None.e2b8fdd7f61a5d98320f51ea0f327343.svg',
          y: 459.88258558892596,
          r: 0,
          is_bg_image: false,
          is_bg_image_spread: false,
          size: 500,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 1000,
      w: 1000,
      bg: {
        h: 1000,
        bgc: '#ffffff',
        t: BackgroundTypes.image,
        w: 1000,
        fgc: '#000000'
      },
      position: 1
    },
    p2: {
      images: [],
      h: 1000,
      bg: { h: 1000, bgc: '#cccccc', w: 1000 },
      w: 1000,
      position: 2
    }
  }
};

export const luikSquare: FakeDesign = {
  design_id: 'luik-square',
  is_proposed_design: false,
  design_title: 'KC - 3-luik-square - Blanco',
  design_description: 'dit is een 3-luik',
  user_design_id: null,
  user_design_title: 'luik-square',
  use_absolute_font_size: true,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.zigzag,
      labels: {
        pages: [['Pagina 3'], ['Pagina 2'], ['Pagina 1'], ['Pagina 4'], ['Pagina 5'], ['Pagina 6']],
        userSpreads: [
          ['', '', 'Voorkant'],
          ['', '', 'Achterkant']
        ]
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '3',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 1],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 1
    },
    p2: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '2',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 2],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 2
    },
    p3: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '1',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 3],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 3
    },
    p4: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '4',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 4],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 4
    },
    p5: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '5',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 5],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 5
    },
    p6: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '6',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 6],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 6
    }
  }
};

export const luikPortrait: FakeDesign = {
  design_id: 'luik-portrait',
  is_proposed_design: false,
  design_title: 'KC - 3-luik-portrait - Blanco',
  design_description: 'dit is een 3-luik-portrait',
  user_design_id: null,
  user_design_title: 'luik-portrait',
  use_absolute_font_size: true,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.zigzag,
      labels: {
        pages: [['Pagina 3'], ['Pagina 2'], ['Pagina 1'], ['Pagina 4'], ['Pagina 5'], ['Pagina 6']],
        userSpreads: [
          ['', '', 'Voorkant'],
          ['', '', 'Achterkant']
        ]
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '3',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 248,
          x: 26,
          route: [1, 1],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 600,
      bg: { h: 600, bgc: '#ffffff', w: 200 },
      w: 200,
      bleed: 10,
      safety: 10,
      position: 1
    },
    p2: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '2',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 248,
          x: 26,
          route: [1, 2],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 600,
      bg: { h: 600, bgc: '#ffffff', w: 200 },
      w: 200,
      bleed: 10,
      safety: 10,
      position: 2
    },
    p3: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '1',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 248,
          x: 26,
          route: [1, 3],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 600,
      bg: { h: 600, bgc: '#ffffff', w: 200 },
      w: 200,
      bleed: 10,
      safety: 10,
      position: 3
    },
    p4: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '4',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 248,
          x: 26,
          route: [1, 4],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 600,
      bg: { h: 600, bgc: '#ffffff', w: 200 },
      w: 200,
      bleed: 10,
      safety: 10,
      position: 4
    },
    p5: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '5',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 248,
          x: 26,
          route: [1, 5],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 600,
      bg: { h: 600, bgc: '#ffffff', w: 200 },
      w: 200,
      bleed: 10,
      safety: 10,
      position: 5
    },
    p6: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '6',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 248,
          x: 26,
          route: [1, 6],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 600,
      bg: { h: 600, bgc: '#ffffff', w: 200 },
      w: 200,
      bleed: 10,
      safety: 10,
      position: 6
    }
  }
};

export const luikLandscape: FakeDesign = {
  design_id: 'luik-landscape',
  is_proposed_design: false,
  design_title: 'KC - 3-luik-landscape - Blanco',
  design_description: 'dit is een 3-luik-landscape',
  user_design_id: null,
  user_design_title: 'luik-landscape',
  use_absolute_font_size: true,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.zigzag,
      labels: {
        pages: [['Pagina 3'], ['Pagina 2'], ['Pagina 1'], ['Pagina 4'], ['Pagina 5'], ['Pagina 6']],
        userSpreads: [
          ['', '', 'Voorkant'],
          ['', '', 'Achterkant']
        ]
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '3',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 90.5,
          x: 174.5,
          route: [1, 3],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 280,
      bg: { h: 280, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 1
    },
    p2: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '2',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 90.5,
          x: 174.5,
          route: [1, 2],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 280,
      bg: { h: 280, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 2
    },
    p3: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '1',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 90.5,
          x: 174.5,
          route: [1, 1],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 280,
      bg: { h: 280, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 3
    },
    p4: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '4',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 90.5,
          x: 174.5,
          route: [1, 6],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 280,
      bg: { h: 280, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 4
    },
    p5: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '5',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 90.5,
          x: 174.5,
          route: [1, 5],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 280,
      bg: { h: 280, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 5
    },
    p6: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '6',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 90.5,
          x: 174.5,
          route: [1, 4],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 280,
      bg: { h: 280, bgc: '#ffffff', w: 480 },
      w: 480,
      bleed: 10,
      safety: 10,
      position: 6
    }
  }
};

export const brochure: FakeDesign = {
  design_id: 'brochure',
  is_proposed_design: false,
  design_title: 'Print - Brochure - Blanco',
  design_description: 'dit is een brochure',
  user_design_id: null,
  user_design_title: 'brochure',
  use_absolute_font_size: true,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.vertical,
      labels: {
        pages: [
          ['Pagina 1'],
          ['Pagina 2'],
          ['Pagina 3'],
          ['Pagina 4'],
          ['Pagina 5'],
          ['Pagina 6'],
          ['Pagina 7'],
          ['Pagina 8']
        ],
        userSpreads: [['Achterkant', 'Voorkant'], ['Pagina 2, 3'], ['Pagina 4, 5'], ['Pagina 6, 7']]
      },
      design_images: [],
      design_colors: []
    },
    p1: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '1',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 1],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 1
    },
    p2: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '2',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 2],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 2
    },
    p3: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '3',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 3],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 3
    },
    p4: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '4',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 4],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 4
    },
    p5: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '5',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 5],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 5
    },
    p6: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '6',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 6],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 6
    },
    p7: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '7',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 7],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 7
    },
    p8: {
      images: [
        {
          type: KcElementType.text,
          va: 'N',
          font: 'Arial',
          color: '#000000',
          text: '8',
          markup: '',
          s: 100,
          ha: 'C',
          is_justify: false,
          w: 144,
          h: 107,
          use_absolute_font_size: false,
          r: 0,
          trans: 0,
          tag: '',
          y: 193.5,
          x: 174.5,
          route: [1, 8],
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb
        }
      ],
      h: 480,
      bg: { h: 480, bgc: '#ffffff', w: 320 },
      w: 320,
      bleed: 10,
      safety: 10,
      position: 8
    }
  }
};

export const inline: FakeDesign = {
  design_id: 'inline',
  is_proposed_design: false,
  design_title: 'Inline Text Preview',
  design_description: 'Inline text preview',
  user_design_id: null,
  user_design_title: 'inline-text',
  use_absolute_font_size: true,
  pages: {
    design_data: {
      view: View.pages,
      fold: Fold.none,
      labels: {
        pages: [['Voorkant'], ['Achterkant']],
        userSpreads: [['Voorkant'], ['Achterkant']],
        card: [['Voorkant'], ['Achterkant']]
      },
      trim: TrimTypes.borderStraight,
      permissions: {},
      design_images: [],
      design_colors: [
        { name: '', color_value: '#ffffff' },
        { name: '', color_value: '#000000' }
      ]
    },
    p1: {
      images: [
        {
          type: KcElementType.richText,
          font_version: 2,
          w: 319.166667,
          h: 138.415629,
          padding: 5,
          text: [
            {
              textAlign: 'middle',
              lineHeight: 1,
              isJustified: false,
              lines: [
                {
                  y: 94.6764705882353,
                  wordSpacing: undefined,
                  textSpans: [
                    {
                      text: 'Spaghetti ',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'beetroot ',
                      fontSize: 55,
                      font: 'CabinSketch',
                      color: '#ffffff',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'spinach ',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'pea ',
                      fontSize: 50,
                      font: 'Special Elite',
                      color: '#98c836',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    }
                  ]
                },
                {
                  y: 151.1764705882353,
                  wordSpacing: undefined,
                  textSpans: [
                    {
                      text: 'courgette',
                      fontSize: 50,
                      font: 'Special Elite',
                      color: '#98c836',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: '.  Aubergine ',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'meatballs ',
                      fontSize: 40,
                      font: 'Architects Daughter',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'spring ',
                      fontSize: 40,
                      font: 'Chelsea Market',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    }
                  ]
                },
                {
                  y: 207.1764705882353,
                  wordSpacing: undefined,
                  textSpans: [
                    {
                      text: 'onion bush ',
                      fontSize: 40,
                      font: 'Chelsea Market',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },

                    {
                      text: 'tomato',
                      fontSize: 40,
                      font: 'Advetime',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: ' turnip.',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    }
                  ]
                }
              ]
            },
            {
              textAlign: 'middle',
              lineHeight: 1,
              isJustified: false,
              lines: [
                {
                  y: 259.1764705882353,
                  wordSpacing: undefined,
                  textSpans: [
                    {
                      text: 'Dandelion ',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'zucchini',
                      fontSize: 40,
                      font: 'Chelsea Market',
                      color: '000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: ' green ',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'soybeans',
                      fontSize: 40,
                      font: 'Architects Daughter',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: ' broccoli ',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    }
                  ]
                },
                {
                  y: 329.1764705882353,
                  wordSpacing: undefined,
                  textSpans: [
                    {
                      text: 'radish ',
                      fontSize: 60,
                      font: 'Fineliner Script',
                      color: '#b7f7dc',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: 'bacon',
                      fontSize: 60,
                      font: 'Fineliner Script',
                      color: '#b7f7dc',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    },
                    {
                      text: '.',
                      fontSize: 40,
                      font: 'Arial',
                      color: '#000000',
                      bold: false,
                      italic: false,
                      underlined: false,
                      opacity: 1,
                      foil: undefined,
                      spotUv: false,
                      spanParts: []
                    }
                  ]
                }
              ]
            }
          ],
          r: 0,
          trans: 0,
          tag: '',
          y: 147.5,
          x: 90,
          route: [2, 1],
          order: 2,
          locked: 0,
          is_movable: true,
          is_removable: true,
          is_rotatable: true,
          is_resizable: true,
          is_duplicatable: true,
          is_foilable: false,
          is_flippable: true,
          is_printable: true,
          is_top_layer: false,
          is_untargetable: false,
          layer_type: KcLayerType.standardRgb,
          text_editable: true,
          character_limit: null
        }
      ],
      add_child_elements: true,
      h: 480,
      w: 480,
      bleed: 10,
      safety: 10,
      bg: {
        t: BackgroundTypes.color,
        bgc: '#006d7d',
        h: 480,
        w: 480,
        locked: false
      },
      position: 1
    }
  }
};

const functionPermissions = allTrue(new FunctionPermissions());
const setElementPermissions = allTrue(new SetElementPermissions());
setElementPermissions.isFoilable = false;
setElementPermissions.isSpotUvable = false;
functionPermissions.enableFoilableByDefault = false;
functionPermissions.spotUvPermissionForJpgByDefault = false;
functionPermissions.canAddSpotUv = false;
functionPermissions.enableImageUpload = false;

export const designSet: FakeDesignSet = {
  version: 1,
  design_id: 'design_set',
  is_proposed_design: false,
  design_title: 'kaart + sluitsticker',
  design_description: 'kaart + sluitsticker',
  user_design_id: null,
  user_design_title: 'kaart + sluitsticker',
  use_absolute_font_size: true,
  designs: [
    emptyDesignAbsoluteFontSize,
    {
      pages: sluitsticker as KcDesign,
      permissions: {
        functionPermissions,
        setElementPermissions
      }
    }
  ]
};

export const DESIGN_NAME_MAX_LENGTH = 70;
export const DESIGN_NAME_COLLECTION_MAX_LENGTH = 100;
