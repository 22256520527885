export const LIGHTEN_COLOR_PERCENTAGE = 10;
export const DARKEN_COLOR_PERCENTAGE = -10;

export class ThemeItem {
  constructor(
    public name: ThemeItemNames,
    public title: string,
    public cssPrefix: CssPrefixes,
    public type: ThemeTypes,
    public value: string,
    public numValue?: number,
    public numUnit?: string,
    public basedOn?: ThemeItemNames
  ) {}
}

export interface CssVariables {
  [name: string]: string;
}

export enum ThemeTypes {
  color = 'color',
  number = 'number',
  font = 'font'
}

export enum ThemeItemNames {
  colorTextDark = 'colorTextDark',
  colorTextLight = 'colorTextLight',
  colorToolbarText = 'colorToolbarText',
  colorToolbarStart = 'colorToolbarStart',
  colorToolbarEnd = 'colorToolbarEnd',
  colorBrandPrimary = 'colorBrandPrimary',
  colorBrandSecondary = 'colorBrandSecondary',
  colorBasePrimary = 'colorBasePrimary',
  colorBaseSecondary = 'colorBaseSecondary',
  colorBaseSuccess = 'colorBaseSuccess',
  colorBaseWarning = 'colorBaseWarning',
  colorBaseError = 'colorBaseError',
  colorBaseInfo = 'colorBaseInfo',
  colorBaseDefault = 'colorBaseDefault',
  borderRadiusSmall = 'borderRadiusSmall',
  borderRadiusMedium = 'borderRadiusMedium',
  borderRadiusLarge = 'borderRadiusLarge',
  font = 'font'
}

export const FontNames: string[] = ['Roboto', 'Lora', 'Raleway', 'Bitter'];

export enum CssPrefixes {
  colorTextDark = '--color-text-dark',
  colorTextLight = '--color-text-light',
  colorBrandPrimary = '--color-brand-primary',
  colorBrandSecondary = '--color-brand-secondary',
  colorBasePrimary = '--color-base-primary',
  colorBaseSecondary = '--color-base-secondary',
  colorBaseSuccess = '--color-base-success',
  colorBaseWarning = '--color-base-warning',
  colorBaseError = '--color-base-error',
  colorBaseInfo = '--color-base-info',
  colorBaseDefault = '--color-base-default',
  colorToolbarText = '--color-toolbar-text',
  colorToolbarStart = '--color-toolbar-start',
  colorToolbarEnd = '--color-toolbar-end',
  borderRadiusSmall = '--border-radius-small',
  borderRadiusMedium = '--border-radius-medium',
  borderRadiusLarge = '--border-radius-large',
  fontName = '--font-name'
}

export const ThemeDataTextAndIconsColors: ThemeItem[] = [
  {
    name: ThemeItemNames.colorTextDark,
    title: 'Color Text Dark',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorTextDark,
    value: '#444c5f'
  },
  {
    name: ThemeItemNames.colorTextLight,
    title: 'Color Text Light',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorTextLight,
    value: '#ffffff'
  }
];

export const ThemeDataToolbarColors: ThemeItem[] = [
  {
    name: ThemeItemNames.colorToolbarStart,
    title: 'Color Toolbar Start',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorToolbarStart,
    value: '#11457f'
  },
  {
    name: ThemeItemNames.colorToolbarEnd,
    title: 'Color Toolbar End',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorToolbarEnd,
    value: '#00b5ed'
  },
  {
    name: ThemeItemNames.colorToolbarText,
    title: 'Color Toolbar Text',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorToolbarText,
    value: '#ffffff',
    basedOn: ThemeItemNames.colorTextLight
  }
];

export const ThemeDataBrandColors: ThemeItem[] = [
  {
    name: ThemeItemNames.colorBrandPrimary,
    title: 'Color Brand Primary',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBrandPrimary,
    value: '#444c5f'
  },
  {
    name: ThemeItemNames.colorBrandSecondary,
    title: 'Color Brand Secondary',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBrandSecondary,
    value: '#444c5f'
  },
  {
    name: ThemeItemNames.colorToolbarText,
    title: 'Toolbar Text Color',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorToolbarText,
    value: '#ffffff'
  }
];

export const ThemeDataBaseColors: ThemeItem[] = [
  {
    name: ThemeItemNames.colorBasePrimary,
    title: 'Color Base Primary',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBasePrimary,
    value: '#2990cc'
  },
  {
    name: ThemeItemNames.colorBaseSecondary,
    title: 'Color Base Secondary',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBaseSecondary,
    value: '#444c5f'
  },
  {
    name: ThemeItemNames.colorBaseSuccess,
    title: 'Color Base Success',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBaseSuccess,
    value: '#37b149'
  },
  {
    name: ThemeItemNames.colorBaseWarning,
    title: 'Color Base Warning',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBaseWarning,
    value: '#f6971b'
  },
  {
    name: ThemeItemNames.colorBaseError,
    title: 'Color Base Error',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBaseError,
    value: '#ff0000'
  },
  {
    name: ThemeItemNames.colorBaseInfo,
    title: 'Color Base Info',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBaseInfo,
    value: '#1298b3'
  },
  {
    name: ThemeItemNames.colorBaseDefault,
    title: 'Color Button Default',
    type: ThemeTypes.color,
    cssPrefix: CssPrefixes.colorBaseDefault,
    value: '#f5f5f5'
  }
];

export const ThemeDataBorderRadii: ThemeItem[] = [
  {
    name: ThemeItemNames.borderRadiusSmall,
    title: 'Border Radius Small',
    cssPrefix: CssPrefixes.borderRadiusSmall,
    type: ThemeTypes.number,
    value: '2px'
  },
  {
    name: ThemeItemNames.borderRadiusMedium,
    title: 'Border Radius Medium',
    cssPrefix: CssPrefixes.borderRadiusMedium,
    type: ThemeTypes.number,
    value: '5px'
  },
  {
    name: ThemeItemNames.borderRadiusLarge,
    title: 'Border Radius Large',
    cssPrefix: CssPrefixes.borderRadiusLarge,
    type: ThemeTypes.number,
    value: '50%'
  }
];

export const THEME_ITEMS: ThemeItem[] = [
  ...ThemeDataTextAndIconsColors,
  ...ThemeDataToolbarColors,
  ...ThemeDataBrandColors,
  ...ThemeDataBaseColors,
  ...ThemeDataBorderRadii,
  {
    name: ThemeItemNames.font,
    title: 'Font',
    type: ThemeTypes.font,
    value: 'Roboto',
    cssPrefix: CssPrefixes.fontName
  }
];
