import { Action } from '@ngrx/store';
import { User, Authenticate } from '../models/user';
import { ClientError } from '../models/client-error';

export enum AuthActionTypes {
  OpenLoginDialog = '[Auth] Open Login Dialog',
  OpenLoginDialogFromRecoverDialog = '[Auth] Open Login Dialog From Recover Dialog',
  OpenLoginDialogFromRegisterDialog = '[Auth] Open Login Dialog From Register Dialog',
  OpenLoginDialogAfterRecover = '[Auth] Open Login Dialog After Recover',
  OpenLoginDialogFromSave = '[Auth] Open Login Dialog From Save',
  OpenLoginDialogFromContinue = '[Auth] Open Login Dialog From Continue',
  CloseLoginDialog = '[Auth] Close Login Dialog',
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginErrorReset = '[Auth] Login Error Reset',
  AuthInitSuccess = '[Auth] Init Succes',
  AuthInitFailure = '[Auth] Init Failure'
}

export class AuthInitSuccess implements Action {
  readonly type = AuthActionTypes.AuthInitSuccess;

  constructor(public loggedIn = false, public user: User) {}
}

export class AuthInitFailure implements Action {
  readonly type = AuthActionTypes.AuthInitFailure;

  constructor(public user: User) {}
}

export class OpenLoginDialog implements Action {
  readonly type = AuthActionTypes.OpenLoginDialog;
}

export class OpenLoginDialogFromRecoverDialog implements Action {
  readonly type = AuthActionTypes.OpenLoginDialogFromRecoverDialog;
}

export class OpenLoginDialogFromRegisterDialog implements Action {
  readonly type = AuthActionTypes.OpenLoginDialogFromRegisterDialog;
}

export class OpenLoginDialogAfterRecover implements Action {
  readonly type = AuthActionTypes.OpenLoginDialogAfterRecover;
}

export class OpenLoginDialogFromSave implements Action {
  readonly type = AuthActionTypes.OpenLoginDialogFromSave;
}

export class OpenLoginDialogFromContinue implements Action {
  readonly type = AuthActionTypes.OpenLoginDialogFromContinue;
}

export class CloseLoginDialog implements Action {
  readonly type = AuthActionTypes.CloseLoginDialog;
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: Authenticate) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public user: User) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: ClientError[]) {}
}

export class LoginErrorReset implements Action {
  readonly type = AuthActionTypes.LoginErrorReset;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export type AuthActions =
  | AuthInitSuccess
  | AuthInitFailure
  | CloseLoginDialog
  | OpenLoginDialog
  | OpenLoginDialogFromRecoverDialog
  | OpenLoginDialogFromRegisterDialog
  | OpenLoginDialogAfterRecover
  | OpenLoginDialogFromSave
  | OpenLoginDialogFromContinue
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginErrorReset
  | Logout;
