import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ImageCategory } from '../../../../models';
import { ConfigService } from '../../../../services';

@Component({
  selector: 'ed-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesComponent implements OnChanges, AfterViewChecked {
  @Input() category: ImageCategory;
  @Input() dataLayerIdPrefix: string;
  @Output() chooseCategory: EventEmitter<ImageCategory> = new EventEmitter();

  previousCategory: ImageCategory;
  @ViewChild('previousCategory', { static: false }) previousCategoryElementRef: ElementRef;

  constructor(private config: ConfigService) {}

  onChooseCategory(category: ImageCategory) {
    this.chooseCategory.emit(category);
  }

  ngOnChanges(changes: SimpleChanges) {
    const categoryChange = changes['category'];
    if (categoryChange) {
      this.previousCategory = categoryChange.previousValue;
    }
  }

  ngAfterViewChecked() {
    if (this.previousCategoryElementRef) {
      this.previousCategoryElementRef.nativeElement.scrollIntoView({
        behavior: 'instant',
        block: 'center',
        inline: 'nearest'
      });
      this.previousCategoryElementRef = null;
    }
  }

  getImageSrc(category: ImageCategory) {
    return this.config.imgBase + category.imageSource;
  }
}
