import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

const designVersion = 1;

@Injectable()
export class ParamsService {
  public httpParams: HttpParams;

  constructor(@Inject(DOCUMENT) document: Document) {
    const queryString = document.location.search;
    let params = new HttpParams({
      fromString: queryString ? queryString.substr(1) : ''
    });
    if (!params.has('expected_version')) {
      params = params.append('expected_version', designVersion);
    }
    this.httpParams = params;
  }
}
