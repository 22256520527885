import { CategoryImage, CatImgJson, formatImageSource } from './category-image';

export interface ImgCatJson {
  imageType: string;
  name?: string;
  sid?: string;
  url?: string;
  subcategories?: ImgCatJson[];
  images?: CatImgJson[];
}

export class ImageCategory {
  imageType: string;
  name: string;
  sid: string;
  url: string;
  subcategories: ImageCategory[] = [];
  images: CategoryImage[] = [];
  path: number[];

  constructor(category: ImgCatJson, path: number[]) {
    const { imageType, name, sid, url, subcategories, images } = category;
    this.imageType = imageType;
    this.name = name;
    this.sid = sid;
    this.url = url;
    this.path = path;

    this.subcategories = (subcategories || [])
      .filter(cat => (cat.images && cat.images.length > 0) || (cat.subcategories && cat.subcategories.length > 0))
      .map((cat, i) => new ImageCategory(cat, [...path, i]));

    this.images = (images || []).map(
      img => new CategoryImage(img.name, img.sid, img.url, img.isFoilable, img.width, img.height)
    );
  }

  get imageSource() {
    let imgSrc = formatImageSource(this.sid, this.url);

    if (!imgSrc) {
      if (this.images.length > 0) {
        imgSrc = this.images[0].imageSource;
      } else if (this.subcategories.length > 0) {
        imgSrc = this.subcategories[0].imageSource;
      }
    }
    return imgSrc;
  }
}
