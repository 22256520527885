import { EditBackgroundComponent } from './edit-background.component';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CanvasActions } from '../../../../actions';
import { BackgroundImageElement, ImageElement, Trim } from '../../../../models';

export type editOptionName =
  | 'backgroundColor'
  | 'color'
  | 'fill'
  | 'flippable'
  | 'replace'
  | 'rotate'
  | 'transparency'
  | 'trim';

export class EditOption {
  public open = false;
  public enabled = true;

  constructor(public name: string) {}
}

export type EditOptions = { [E in editOptionName]: EditOption };

@Component({
  selector: 'ed-edit-background-mob',
  templateUrl: 'edit-background-mob.component.html',
  styleUrls: [
    'edit-background-mob.component.scss',
    '../../edit-mob.component.scss',
    '../../../../shared/button/preview-button-mob.scss'
  ]
})
export class EditBackgroundMobComponent extends EditBackgroundComponent {
  buttonCount: number;

  editOptions: EditOptions = {
    backgroundColor: new EditOption('backgroundColor'),
    color: new EditOption('color'),
    fill: new EditOption('fill'),
    flippable: new EditOption('flippable'),
    replace: new EditOption('replace'),
    rotate: new EditOption('rotate'),
    transparency: new EditOption('transparency'),
    trim: new EditOption('trim')
  };

  @Output() openImageLibrary: EventEmitter<boolean> = new EventEmitter();
  @Output() displayAddMenu: EventEmitter<boolean> = new EventEmitter();

  _backgroundImage: ImageElement | BackgroundImageElement;

  @Input() backgroundLocked: boolean;

  @Input() set backgroundImage(backgroundImage: ImageElement | BackgroundImageElement) {
    this.editOptions.replace.enabled = backgroundImage.permissions.isReplaceable && !this.backgroundLocked;
    this.editOptions.fill.enabled = backgroundImage.permissions.isResizable && !this.backgroundLocked;
    this.editOptions.flippable.enabled = backgroundImage.permissions.isFlippable && !this.backgroundLocked;
    this.editOptions.rotate.enabled = backgroundImage.permissions.isRotatable && !this.backgroundLocked;
    this.editOptions.transparency.enabled = backgroundImage.permissions.adjustTransparency && !this.backgroundLocked;

    this.buttonCount = Object.keys(this.editOptions).filter(option => this.editOptions[option].enabled).length;

    this._backgroundImage = backgroundImage;
  }

  get backgroundImage() {
    return this._backgroundImage;
  }

  @HostBinding('class.hide-edit-menu') showEditMenu = false;

  get showAlternativeInputField(): boolean {
    return this.configService.showAlternativeInputField;
  }

  get imageSrc() {
    return this.backgroundImage.imgSource
      ? this.sanitizer.bypassSecurityTrustStyle('url(' + this.backgroundImage.imgSource + ')')
      : '';
  }

  get optionSelected() {
    return Object.keys(this.editOptions).some(name => this.editOptions[name].open);
  }

  openEditOption(editOption: EditOption) {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = name === editOption.name));
  }

  closeAllEditOptions() {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = false));
  }

  activateImageLibrary() {
    this.openImageLibrary.emit(true);
  }

  changeTrim(trim: Trim) {
    this.store.dispatch(new CanvasActions.ChangeTrim(trim));
  }
}
