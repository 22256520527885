import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of, zip } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../index';
import {
  CacheService,
  ConfigService,
  ElementsIntersectService,
  GetTextService,
  UiService,
} from '../../../services';
import * as CanvasActionTypes from '../../../actions/canvas-actions/canvas.action.types';
import {
  CHECK_DOUBLE_BACKGROUND_IMAGE,
  RESIZE,
  ROTATE,
  TRANSLATE,
  UPDATE_IMAGE
} from '../../../actions/canvas-actions/canvas.action.types';
import { CanvasActions } from '../../../actions';
import { InitSuccess, SaveActionTypes } from '../../../save/actions';
import { ConfirmDialogComponent } from '../../../shared/dialogs';
import { RemoveElement, Resize, Rotate, Translate, UpdateImageAction } from '../../../actions/canvas-actions';
import { Undo } from '../../../actions/undo-actions';
import { FunctionPermissions, View } from '../../../models';
import { getVisiblePageSpread } from '../../../utils/spreads.utils';
import { TextEditorService } from '../../../text-editor/text-editor.service';
import * as ImageLibraryActions from '../../../image-library/actions';
import { getPending } from '../../../image-library/reducer';
import { ImageLibraryActionsTypes } from '../../../image-library/actions';
import { getDesignSet, selectDesign } from '../../../selectors';
import { UpdateDesign } from '../../../actions/permission-actions';
import { EditorAction } from '../../../actions/action';
import { DesignSet } from '../../../models/design-set';

@Injectable()
export class CanvasEffects {
  design$ = this.store$.pipe(select(selectDesign));
  designSet$ = this.store$.pipe(select(getDesignSet));

  constructor(
    protected cacheService: CacheService,
    protected actions$: Actions,
    protected store$: Store<AppState>,
    protected dialog: MatDialog,
    protected getTextService: GetTextService,
    protected textEditorService: TextEditorService,
    protected elementsIntersectService: ElementsIntersectService,
    protected config: ConfigService,
    protected uiService: UiService,
  ) {}

  @Effect({ dispatch: true })
  initCanvas$ = this.actions$.pipe(
    ofType(SaveActionTypes.InitSuccess),
    tap((action: InitSuccess) => {
      const dataLayer = (window as any).dataLayer || [];
      action.designSet.designs.forEach(d => dataLayer.push({ designid: d.id }));
    }),
    map((action: InitSuccess) => new CanvasActions.Init(action.designSet, false))
  );

  @Effect({ dispatch: true })
  initVisiblePageImages$ = this.actions$.pipe(
    ofType(CanvasActionTypes.INIT_DESIGN, CanvasActionTypes.INIT_VISIBLE_PAGE_IMAGES),
    withLatestFrom(this.designSet$, this.store$.pipe(select(s => s.permissions.functionPermissions))),
    mergeMap(([action, designSet, permissions]: [EditorAction, DesignSet, FunctionPermissions]) => {
      const initVisiblePage = action.type === CanvasActionTypes.INIT_DESIGN;
      const page = initVisiblePage
        ? designSet.designs.find(d => d.active).visiblePage
        : designSet.designs.flatMap(d => d.pages)?.find(p => !p.init);
      if (page) {
        const updateImageActions = this.cacheService.initPageImages(
          page,
          permissions.canAddTextInline,
          initVisiblePage ? 500 : undefined
        );
        const init = zip(...updateImageActions).pipe(
          map(() => {
            return new CanvasActions.InitVisiblePageImages(page.route);
          })
        );
        if (!updateImageActions.length) {
          // page contains no images
          return [of(new CanvasActions.InitVisiblePageImages(page.route))];
        }
        return [...updateImageActions, init];
      } else {
        return EMPTY;
      }
    }),
    mergeMap(obs => obs)
  );

  @Effect({ dispatch: true })
  updatePosition$ = this.actions$.pipe(
    ofType(ROTATE, RESIZE, TRANSLATE, UPDATE_IMAGE),
    map((action: Rotate | Resize | Translate | UpdateImageAction) => action),
    // filter out page-elements (route.length > 1)
    filter(action => action.route.length > 2 && !this.elementsIntersectService.intersectsWithParent(action.route)),
    exhaustMap(action => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: this.getTextService.text.dialog.confirm.title,
          message: this.getTextService.text.dialog.confirm.message,
          confirmButtonText: this.getTextService.text.dialog.button.delete,
          cancelButtonText: this.getTextService.text.dialog.button.cancel
        }
      });
      return dialogRef.afterClosed().pipe(map(result => [result, action]));
    }),
    map(([result, action]) => {
      if (result) {
        // use an not undoable remove action, because else you can still get not visible elements by undoing the remove
        return new RemoveElement(action.route, false);
      }
      return new Undo();
    })
  );

  @Effect({ dispatch: true })
  doubleBackgroundImage$ = this.actions$.pipe(
    ofType(CHECK_DOUBLE_BACKGROUND_IMAGE),
    withLatestFrom(this.design$),
    exhaustMap(([action, design]) => {
      const spread = getVisiblePageSpread(design, View.userSpreads);
      const showDialog = !!spread.pages.find(
        page => !page.visible && !!page.children.find(el => el.isSpreadBackgroundImage())
      );
      if (showDialog && design.view === View.pages) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            confirmButtonText: this.getTextService.text.dialog.button.yes,
            cancelButtonText: this.getTextService.text.dialog.button.no,
            message: this.getTextService.text.dialog.doubleBackgroundImage.message,
            title: this.getTextService.text.dialog.confirm.title
          }
        });
        return dialogRef.afterClosed().pipe(map(result => [result, action, design]));
      } else {
        return of([true, action, design]);
      }
    }),
    map(([result, action, design]) => {
      if (result) {
        const hasBackgroundImage = design.visiblePage.background && design.visiblePage.background.isBackgroundImage();
        return hasBackgroundImage
          ? new CanvasActions.ChangeBackgroundImage(action.width, action.height, action.sid, action.url)
          : new CanvasActions.AddBackgroundImage(action.width, action.height, action.sid, action.url);
      }
    }),
    filter(action => !!action)
  );

  @Effect()
  updateImageLibrary$ = this.actions$.pipe(
    ofType(CanvasActionTypes.INIT, CanvasActionTypes.UPDATE_DESIGN_IMAGES, ImageLibraryActionsTypes.LoadImageLibrary),
    withLatestFrom(
      this.store$.pipe(
        select(getPending),
        filter(pending => !pending)
      )
    ),
    withLatestFrom(this.designSet$),
    map(([[action, imageLibInit], designSet]) => {
      const categoryTitle = this.getTextService.text.edit.imageLibrary.designImagesTitle;
      return new ImageLibraryActions.Update('image', categoryTitle, designSet?.designSetImages || []);
    })
  );

  @Effect()
  updateDesignPermissions$ = this.actions$.pipe(
    ofType(CanvasActionTypes.TOGGLE_PAGE),
    withLatestFrom(this.design$),
    map(([action, design]) => {
      return new UpdateDesign(design.editorPermissions);
    })
  );
}
