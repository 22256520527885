import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, BrowserModule, HammerModule } from '@angular/platform-browser';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppState, initialState, reducers } from './reducers';
import { EditorModule } from './editor/editor.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { environment } from '../environments/environment';
import { MyHammerConfig } from './hammer.config';
import { AppComponent } from './app.component';
import {
  TextService,
  ErrorInterceptor,
  CustomFormControlService,
  CacheService,
  ConfigService,
  ElementsIntersectService,
  SvgService,
  GetTextService,
  UiService,
  KeyEventHandlerService,
  ThemeService,
  CopyService
} from './services';
import { MainMenuModule } from './main-menu/main-menu.module';
import { Configuration, init } from './config/config.service';
import { AuthModule } from './auth/auth.module';
import { RegisterModule } from './register/register.module';
import { RecoverModule } from './recover/recover.module';
import { SaveModule } from './save/save.module';
import { ContinueModule } from './continue/continue.module';
import { AdminDesignerModule } from './admin-designer/admin-designer.module';
import { TransformService } from './save/transform.service';
import { SharedModule } from './shared/shared.module';
import { ParamsService } from './services/params.service';
import { FontLoadService } from './services/font-load.service';
import { UndoEffects } from './reducers/meta-reducers/undo.effects';
import { canvasEffects } from './reducers/canvas-reducer/effects';
import { ElementTransformService } from './services/element-transform.service';
import { BrowserStorageService } from './services/browser-storage.service';
import { ChatService } from './services/chat.service';
import { UpdateService } from './services/check-for-update.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { AbTestsScriptComponent } from './ab-tests-script/ab-tests-script.component';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

// In this function dynamic state slices, if they exist, will overwrite static state at runtime.
export function getInitialState(): AppState {
  return initialState;
}

export function getReducers(): ActionReducerMap<AppState> {
  return reducers;
}

@NgModule({
  imports: [
    BrowserModule,
    StoreModule.forRoot(REDUCER_TOKEN, {
      initialState: getInitialState,
      runtimeChecks: environment.ngrxRuntimeChecks
    }),
    EffectsModule.forRoot([...canvasEffects, UndoEffects]),
    ...environment.appModuleImports,
    EditorModule,
    ToolbarModule,
    MainMenuModule,
    MatSnackBarModule,
    MatSidenavModule,
    AuthModule,
    RegisterModule,
    RecoverModule,
    SaveModule,
    ContinueModule,
    AdminDesignerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker }),
    SharedModule,
    HammerModule
  ],
  declarations: [AppComponent, AbTestsScriptComponent],
  providers: [
    ...environment.providers,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [Configuration],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: { disabled: true }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true }
    },
    BrowserStorageService,
    Configuration,
    TransformService,
    CustomFormControlService,
    TextService,
    CacheService,
    ElementsIntersectService,
    ConfigService,
    ParamsService,
    SvgService,
    GetTextService,
    FontLoadService,
    UiService,
    ThemeService,
    KeyEventHandlerService,
    ElementTransformService,
    CopyService,
    ChatService,
    UpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
