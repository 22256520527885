import { EditorAction } from '../action';
import { LayerType } from '../../models';
import { SET_LAYER_TYPE } from './canvas.action.types';

export class SetLayerType implements EditorAction {
  readonly type = SET_LAYER_TYPE;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: number[], public layerType: LayerType) {}
}
