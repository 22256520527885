import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import * as Register from './actions';
import { Store } from '@ngrx/store';
import * as fromRegister from './reducer';
import { first } from 'rxjs/operators';

@Component({
  selector: 'ed-reg-success-dialog',
  templateUrl: 'reg-success-dialog.component.html',
  styleUrls: ['reg-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterSuccessDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RegisterSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; buttonText: string },
    private store: Store<fromRegister.State>
  ) {}

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onNoClick());
  }

  onNoClick(): void {
    this.store.dispatch(new Register.CloseRegisterSuccessDialog());
  }

  close(): void {
    this.dialogRef.close();
  }
}
