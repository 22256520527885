import { PageElement } from './page-element';
import { Fold } from './design';
import { DesignSet } from './design-set';

export class Thumb {
  thumbRatio: number;
  hasVisiblePage: boolean;

  constructor(
    public id: number,
    public designSet: DesignSet,
    public pages: PageElement[],
    public blur = false,
    public foldType?: Fold,
    public thumbSize?: ThumbSize,
    public active?: boolean
  ) {
    if ((this.foldType === Fold.vertical && id === 2) || (this.foldType === Fold.vertical && id === 3)) {
      this.thumbRatio = thumbSize / pages[0].ratio + (thumbSize / pages[0].ratio) * 0.5;
    } else {
      this.thumbRatio = thumbSize / pages[0].ratio;
    }

    this.hasVisiblePage = active && pages.some(page => page.visible && page.id === id);
  }
}

export enum ThumbSize {
  extraSmall = 40,
  small = 50,
  large = 120
}
