import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { createEffect, Effect, ofType } from '@ngrx/effects';
import { SET_LAYER_TYPE, TOGGLE_ELEMENT_PRESET } from '../../../actions/canvas-actions/canvas.action.types';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SetLayerType, ToggleElementPermission, ToggleElementPreset } from '../../../actions/canvas-actions';
import * as fromPermission from '../../permissions.reducer';
import {
  ElementPermission,
  getRouteForPermission,
  LayerType,
  linkedPermissions,
  PageElement,
  SPOT_LAYERS,
  toggleWhenHasLayerType
} from '../../../models';
import { CanvasEffects } from './canvas.effects';

@Injectable()
export class ElementSettingsEffects extends CanvasEffects {
  @Effect({ dispatch: true })
  toggleElementPreset$ = this.actions$.pipe(
    ofType(TOGGLE_ELEMENT_PRESET),
    map((action: ToggleElementPreset) => action),
    withLatestFrom(this.design$, this.store$.pipe(select(fromPermission.getSetElementPermissions))),
    mergeMap(([action, design, setElementPermissions]) => {
      const element = design.getElement(action.route);
      const permissions = linkedPermissions[action.preset] || [];
      const currentValue = element.permissions[action.preset];

      let route;
      return permissions
        .filter((p: ElementPermission) => {
          route = getRouteForPermission(element, p);
          return route && currentValue === design.getElement(route).permissions[p] && setElementPermissions[p];
        })
        .map((p: ElementPermission) => {
          route = getRouteForPermission(element, p);
          return new ToggleElementPermission(p, route);
        });
    }),
    filter(action => !!action)
  );

  toggleLayerLinkedPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LAYER_TYPE),
      map((action: SetLayerType) => action),
      withLatestFrom(this.design$, this.store$.pipe(select(fromPermission.getSetElementPermissions))),
      mergeMap(([action, design, setElementPermissions]) => {
        const element = design.getElement(action.route);

        return toggleWhenHasLayerType
          .filter((p: ElementPermission) => {
            const route = getRouteForPermission(element, p);
            return (
              route &&
              SPOT_LAYERS.includes(action.layerType) === design.getElement(route).permissions[p] &&
              setElementPermissions[p]
            );
          })
          .map((p: ElementPermission) => new ToggleElementPermission(p, getRouteForPermission(element, p)));
      })
    )
  );

  toggleInvertedLayerLinkedPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LAYER_TYPE),
      map((action: SetLayerType) => action),
      withLatestFrom(this.design$, this.store$.pipe(select(fromPermission.getSetElementPermissions))),
      mergeMap(([action, design, setElementPermissions]) => {
        const element = design.getElement(action.route);
        const p = ElementPermission.isUntargetable;
        const route = getRouteForPermission(element, p);

        if (!setElementPermissions[p]) {
          return [];
        }

        const spotLayersWithoutCutthrough = SPOT_LAYERS.filter(sl => sl !== LayerType.cutThrough); // cutthrough elements should be targetable
        if (spotLayersWithoutCutthrough.includes(action.layerType) !== design.getElement(route).permissions[p]) {
          return [new ToggleElementPermission(p, getRouteForPermission(element, p))];
        }

        return [];
      })
    )
  );

  @Effect({ dispatch: true })
  togglePageLocked$ = this.actions$.pipe(
    ofType(TOGGLE_ELEMENT_PRESET),
    filter((action: ToggleElementPreset) => action.route.length === 1),
    filter(action => action.preset === ElementPermission.isLocked),
    withLatestFrom(this.design$),
    mergeMap(([action, design]) => {
      const page = design.getElement(action.route) as PageElement;
      const currentValue = page.permissions[action.preset];
      return page.children
        .filter(el => currentValue !== el.permissions.isLocked)
        .map(el => new ToggleElementPreset(el.route, action.preset));
    }),
    filter(action => !!action)
  );
}
