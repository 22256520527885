import { EditorAction } from '../action';
import { CHANGE_FONT } from './canvas.action.types';

export class ChangeFont implements EditorAction {
  readonly type = CHANGE_FONT;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public font: string) {}
}
