import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Color, FoilTypes } from '../../../models';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ColorMenuService } from '../color-menu.service';

@Component({
  selector: 'ed-color-button',
  templateUrl: './color-button.component.html',
  styleUrls: ['./color-button.component.scss'],
  providers: [ColorMenuService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorButtonComponent implements OnInit, OnDestroy {
  colorSubscription$: Subscription;
  colorDialogOpenSubscription$: Subscription;
  colorDialogClosedSubscription$: Subscription;
  originalColorSubscription$: Subscription;

  @Input() set elementColor(color: Color) {
    this._elementColor = color;
    this.elementColor$.next(color);
  }

  get elementColor() {
    return this._elementColor;
  }

  private _elementColor: Color;
  elementColor$ = new BehaviorSubject<Color>(new Color('', '#fff'));

  @Input() showFoilPanel = false;
  @Input() showSpotUvColors = false;
  @Input() selectedElementHasSpotUv = false;
  @Input() originalColorOption = false;
  @Input() dataLayerIdPrefix: string;

  @Output() changeColor = new EventEmitter<Color>();
  @Output() colorDialogOpen = new EventEmitter<boolean>();
  @Output() colorDialogClosed = new EventEmitter<boolean>();
  @Output() resetOriginalColor = new EventEmitter<boolean>();

  get hasColor() {
    return this.elementColor.colorValue !== '';
  }

  constructor(public colorMenuService: ColorMenuService, private sanitizer: DomSanitizer) {}

  getColorSwatch(color: string, foilType: FoilTypes) {
    const style = this.colorMenuService.getSwatchStyle(color, foilType);
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  openColorDialog() {
    this.colorMenuService.openColorDialog(
      this.elementColor$,
      this.showFoilPanel,
      this.showSpotUvColors,
      this.originalColorOption,
      this.dataLayerIdPrefix
    );
  }

  ngOnInit() {
    this.colorSubscription$ = this.colorMenuService.selectedColor$.subscribe((color: Color) =>
      this.changeColor.emit(color)
    );

    this.colorDialogOpenSubscription$ = this.colorMenuService.dialogOpen$.subscribe(isOpen =>
      this.colorDialogOpen.emit(isOpen)
    );

    this.colorDialogClosedSubscription$ = this.colorMenuService.dialogClosed$.subscribe(isClosed =>
      this.colorDialogClosed.emit(isClosed)
    );

    this.originalColorSubscription$ = this.colorMenuService.originalColor$.subscribe(reset =>
      this.resetOriginalColor.emit(reset)
    );
  }

  ngOnDestroy() {
    this.colorSubscription$.unsubscribe();
    this.colorDialogOpenSubscription$.unsubscribe();
    this.originalColorSubscription$.unsubscribe();
    this.colorDialogClosedSubscription$.unsubscribe();
  }
}
