import { fabric } from "fabric";

fabric.Object.NUM_FRACTION_DIGITS = 100;

fabric.Object.prototype.isCacheDirty = function (skipCanvas?: boolean) {
  if (this.isNotVisible()) {
    return false;
  }
  if (this._cacheCanvas && this._cacheContext && !skipCanvas && this._updateCacheCanvas()) {
    // in this case the context is already cleared.
    return true;
  }
  else {
    if (this.dirty ||
      (this.clipPath && this.clipPath.absolutePositioned && this.clipPath.dirty) ||
      (this.statefullCache && this.hasStateChanged('cacheProperties'))
    ) {
      if (this._cacheCanvas && this._cacheContext && !skipCanvas) {
        var width = this.cacheWidth / this.zoomX;
        var height = this.cacheHeight / this.zoomY;
        this._cacheContext.clearRect(-width / 2, -height / 2, width, height);
      }
      return true;
    }
  }
  return false;
}
