import { EditorAction } from '../action';
import { CHANGE_TEXT } from './canvas.action.types';

export class ChangeText implements EditorAction {
  readonly type = CHANGE_TEXT;
  readonly undoable = true;
  readonly save = true;

  constructor(public route: Array<number>, public text: string) {}
}
