import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../reducers';
import { CanvasElement, Design, FunctionPermission, PageElement } from '../../../models';
import { CanvasActions } from '../../../actions';
import { ImageData, OBJECT_DETECTION_UPLOAD_URL } from '../../../services/image-upload.service';
import { GetTextService, UiService } from '../../../services';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { ImageLibraryService } from '../../../image-library/image-library.service';
import { ToggleImageLibrary } from '../../../image-library/actions';
import { DesignSet } from '../../../models/design-set';

export type panelName = 'addTextMenu' | 'addImageMenu' | 'editBackgroundMenu';

export class Panel {
  public open = false;

  constructor(public name: panelName) {}
}

export type Panels = { [P in panelName]: Panel };

@Component({
  selector: 'ed-panels-mobile',
  templateUrl: 'panels-mobile.component.html',
  styleUrls: ['panels-mobile.component.scss', '../../nav/nav-buttons.component.scss']
})
export class PanelsMobileComponent implements OnInit, OnDestroy {
  @Output() adjustDesignTopMargin: EventEmitter<boolean> = new EventEmitter();
  @Input() designLoaded: boolean;

  panels: Panels = {
    addTextMenu: new Panel('addTextMenu'),
    addImageMenu: new Panel('addImageMenu'),
    editBackgroundMenu: new Panel('editBackgroundMenu')
  };

  editMenuOpen = false;
  selectedElement: CanvasElement;
  visiblePage: PageElement;
  previousSelectedElementRoute: number[];
  canLockElements: boolean;
  lockMessage = false;
  functionPermission = FunctionPermission;
  permissions$: Subscription;
  imageLibraryOpenSubscription: Subscription;
  imageLibraryOpen = false;
  uploadUrlObjectDetection = OBJECT_DETECTION_UPLOAD_URL;

  shortEditing$: Observable<boolean>;

  textEditing: boolean;

  _design: Design;
  _designSet: DesignSet;

  showNewMobileMenu = false;

  ngOnInit() {
    this.permissions$ = this.store
      .pipe(select(fromPermissions.getSetElementPermissions))
      .subscribe(getSetElementPermissions => {
        this.canLockElements = getSetElementPermissions.isLocked;

        // close all panels after logging in / logging out, in order to prevent that user can use functionalities without permission
        if (!this.selectedElement) {
          this.closeAllPanels();
        }
      });
    this.shortEditing$ = this.uiService.shortEditingInlineText$;
  }

  ngOnDestroy() {
    this.permissions$.unsubscribe();
    this.imageLibraryOpenSubscription.unsubscribe();
  }

  adjustDesignMargin(adjust: boolean) {
    this.adjustDesignTopMargin.emit(adjust);
  }

  @Input()
  set designSet(designSet: DesignSet) {
    this._designSet = designSet;
    this._design = designSet.activeDesign;
    if (this._design && this._design.visiblePage) {
      this.visiblePage = this._design.visiblePage;
      this.selectedElement = this._design.selectedElement;
      const selectedElementRoute = this.selectedElement ? this.selectedElement.route : null;

      // close all panels after deselecting an element
      // panels stay open when orientation of device changes
      if (!selectedElementRoute && this.previousSelectedElementRoute) {
        this.closeAllPanels();
      }
      if (selectedElementRoute !== null && this.previousSelectedElementRoute?.join() !== selectedElementRoute.join()) {
        this.initPanels();
      }
      this.previousSelectedElementRoute = selectedElementRoute;
    }
  }

  get designSet() {
    return this._designSet;
  }

  get design() {
    return this._design;
  }

  /* editmode get value true when AddTextMenu, AddImageMenu, UploadImageMenu or EditBackgroundMenu
     is opened. When the edit-menu in edit-text-component, ed-image-component or ed-background-mob-component
     is closed by closeEditMenu, editmode is set to false. Then showPageSelector get value true.
   */

  get moveAddButtonToLeft() {
    return this.selectedElement || this.panels.editBackgroundMenu.open;
  }

  get backgroundLocked() {
    return this.visiblePage && this.visiblePage.backgroundIsLocked;
  }

  onTextEditing(editing: boolean) {
    this.textEditing = editing;
  }

  updateEditMode() {
    this.closeAllPanels();
    this.deselect();
  }

  toggleEditMenu() {
    if (this.optionSelected) {
      this.deselect();
    }
    this.editMenuOpen = !this.editMenuOpen;
  }

  deselect() {
    this.store.dispatch(new CanvasActions.Deselect());
  }

  constructor(
    protected store: Store<AppState>,
    public getTextService: GetTextService,
    public imageLibraryService: ImageLibraryService,
    public uiService: UiService
  ) {
    this.imageLibraryOpenSubscription = imageLibraryService.imageLibraryOpen$.subscribe(
      open => (this.imageLibraryOpen = open)
    );
  }

  get textElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isText();
  }

  get inlineTextElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isInlineText();
  }

  get imageElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isImage() && !this.selectedElement.parent.isBackgroundElement();
  }

  get boxElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isBox();
  }

  get photoFrameElementSelected(): boolean {
    return this.selectedElement && this.selectedElement.isPhotoFrame();
  }

  get background() {
    if (this.visiblePage) {
      return this.visiblePage.background;
    }
  }

  get addChildElements() {
    return this.visiblePage && this.visiblePage.permissions.addChildElements;
  }

  initPanels() {
    if (this.textElementSelected || this.inlineTextElementSelected) {
      this.openPanel(this.panels.addTextMenu);
    } else if (this.imageElementSelected || this.boxElementSelected || this.photoFrameElementSelected) {
      this.openPanel(this.panels.addImageMenu);
    } else if (
      this.background &&
      (this.background.selected || (this.background.isPageBackgroundImage() && this.background.parent.selected))
    ) {
      this.openPanel(this.panels.editBackgroundMenu);
    }
  }

  openPanel(panel: Panel) {
    this.editMenuOpen = false;
    Object.keys(this.panels).map(name => (this.panels[name].open = name === panel.name));
    this.initImageLibraryOnPanelChange();
  }

  get optionSelected() {
    return Object.keys(this.panels).some(name => this.panels[name].open);
  }

  closeAllPanels() {
    Object.keys(this.panels).map(name => (this.panels[name].open = false));
    this.initImageLibraryOnPanelChange();
  }

  initImageLibraryOnPanelChange() {
    let open = false;
    if (this.panels.addImageMenu.open && !this.selectedElement && this.addChildElements) {
      open = true;
    }
    if (open !== this.imageLibraryOpen) {
      this.toggleImageLibrary(open);
    }
  }

  toggleImageLibrary(open: boolean) {
    this.store.dispatch(new ToggleImageLibrary(open));
  }

  addUploadedImage() {
    return (imageData: ImageData) =>
      this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(imageData.width, imageData.height, imageData.sid));
  }
}
