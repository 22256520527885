import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { Observable } from 'rxjs';
import * as fromConfig from '../config/reducer';
import { filter, mergeMap, map } from 'rxjs/operators';
import { Font, FontResponse } from '../models';
import { ParamsService } from '../services/params.service';
import { FontLibraryState } from './reducer';

@Injectable()
export class FontLibraryService {
  fontsUrl$: Observable<string>;
  fontLibrary$: Observable<FontLibraryState>;

  constructor(private http: HttpClient, private store$: Store<AppState>, private paramsService: ParamsService) {
    this.fontsUrl$ = this.store$.pipe(select(fromConfig.getFontsUrl));
    this.fontLibrary$ = this.store$.pipe(
      select(s => s.fontlibrary),
      filter(lib => !!lib.fontlibrary.length)
    );
  }

  load(): Observable<any> {
    return this.fontsUrl$.pipe(
      mergeMap(fontsUrl => this.http.get(fontsUrl, { params: this.paramsService.httpParams })),
      map((fontList: FontResponse[]) => fontList.map(font => new Font(font.name, font.screen_title))),
      map((fonts: Font[]) => fonts.sort((a, b) => a.screenTitle.localeCompare(b.screenTitle)))
    );
  }
}
