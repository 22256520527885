import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PositioningToolsComponent } from './positioning-tools.component';
import { PositioningOptionsText, PositioningTooltipText } from '../../models/text';

@Component({
  selector: 'ed-positioning-tools-mob',
  templateUrl: 'positioning-tools-mob.component.html',
  styleUrls: ['../mob-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositioningToolsMobComponent extends PositioningToolsComponent {
  @Input() buttonText: PositioningOptionsText;
}
