import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, throttleTime, filter, withLatestFrom } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { ImageElement, ImgBaseFunc, TextElement, InlineTextElement, ElementType, PageElement } from '../models';
import { CanvasActions } from '../actions';
import { ConfigService } from './config-service';
import { SvgService } from './svg.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

export type CacheElement = ImageElement | TextElement | InlineTextElement;

export interface ImageResponse {
  url: string;
  height: number;
  width: number;
  isVectorImage: boolean; // svg images with embedded images are not foilable
}

interface CacheItem {
  maxSize: number;
  requestParams: Record<string, unknown>;
  response: ReplaySubject<ImageResponse>;
}

export class ElementCachingService {
  public element: ReplaySubject<CacheElement> = new ReplaySubject(1);
  public image$: Observable<ImageResponse>;

  constructor(private cacheService: CacheService, private store: Store<AppState>) {
    this.image$ = this.element.pipe(
      withLatestFrom(this.store.select(s => s.designSet)),
      filter(([element, design]) => !!design.initialPageImagesLoaded),
      map(([element, design]) => element),
      throttleTime(100, undefined, { leading: true, trailing: true }),
      switchMap((element: CacheElement) => this.cacheService.getImage(element)),
      filter(cacheItem => !!cacheItem)
    );
  }
}

@Injectable()
export class CacheService {
  public cache: CacheItem[] = [];
  private urlCreator = window.URL;

  constructor(private http: HttpClient, private config: ConfigService, private svgService: SvgService) {}

  getCachedImage(element: CacheElement, maxSize: number): CacheItem {
    // find cache element with same params
    const itemsWithSameParams = this.cache.filter(item => isEqual(element.imgParams, item.requestParams));

    if (!itemsWithSameParams.length) {
      return undefined;
    }

    // if text or svg return cachedItem
    if (element.isText() || (element.isSvg && this.config.renderAsSvg)) {
      if (itemsWithSameParams.length > 1) {
        console.warn('same item multipe times in cache');
      }
      return itemsWithSameParams[0];
    }

    // if png/jpg or not RenderAsSvg check if maxsize >= maxsize of element
    const highestCachedMaxSizeItem = itemsWithSameParams.reduce((prev, current) =>
      prev.maxSize > current.maxSize ? prev : current
    );

    if (highestCachedMaxSizeItem.maxSize >= maxSize) {
      return highestCachedMaxSizeItem;
    }

    return undefined;
  }

  removeCachedImage(image: CacheItem) {
    this.cache.splice(this.cache.indexOf(image), 1);
  }

  createCacheImage(element: CacheElement, maxSize: number): CacheItem {
    return {
      maxSize,
      requestParams: element.imgParams,
      response: new ReplaySubject<ImageResponse>(1)
    };
  }

  getImage(element: CacheElement, maxSize?: number): ReplaySubject<ImageResponse> {
    maxSize = maxSize || element.maxSize;

    maxSize = maxSize < element.maxSize ? maxSize : element.maxSize;

    let image = this.getCachedImage(element, maxSize);

    if (image) {
      return image.response;
    }
    image = this.createCacheImage(element, maxSize);

    this.request(element, maxSize)
      .pipe(
        map(([data, newHeight, newWidth, isVectorImage]) => {
          const url = this.urlCreator.createObjectURL(data);
          return { url, height: newHeight, width: newWidth, isVectorImage };
        }),
        catchError(e => {
          console.warn(e);
          this.removeCachedImage(image);
          return of(undefined);
        })
      )
      .subscribe((response: ImageResponse) => image.response.next(response));

    this.cache.push(image);

    return image.response;
  }

  request(element: CacheElement, maxSize: number): Observable<[Blob, number, number, boolean]> {
    if (ElementType.inlineText === element.type) {
      const url = this.config.imgBase + ImgBaseFunc.inlineTxt;
      return this.http
        .post(url, element.imgParams, { responseType: 'text' })
        .pipe(map(data => this.svgService.parseSVG(data, element)));
    } else if (element.isText()) {
      const url = this.config.imgBase + ImgBaseFunc.txt;
      return this.http
        .post(url, element.imgParams, { responseType: 'text' })
        .pipe(map(data => this.svgService.parseSVG(data, element)));
    } else if (element.isSvg && this.config.renderAsSvg) {
      const url = this.config.imgBase + ImgBaseFunc.svg + this.encodeParams(element.imgParams);
      return this.http.get(url, { responseType: 'text' }).pipe(map(data => this.svgService.parseSVG(data, element)));
    } else {
      // Disable img maxSize
      const imgParams = Object.assign(element.imgParams);
      const url = this.config.imgBase + ImgBaseFunc.img + this.encodeParams(imgParams);
      return this.http
        .get(url, { responseType: 'blob' })
        .pipe(map(data => [data, 0, 0, false] as [Blob, number, number, boolean]));
    }
  }

  encodeParams(params: Record<string, string | number>): string {
    return (
      '?' +
      Object.keys(params)
        .filter(param => !!params[param])
        .map(param => param + '=' + encodeURIComponent(params[param]))
        .join('&')
    );
  }

  initPageImages(
    page: PageElement,
    use_inline_text: boolean,
    maxSize?: number
  ): Observable<CanvasActions.UpdateImageAction>[] {
    return this.getPageElements(page, use_inline_text).map(canvasElement => {
      return this.getImage(canvasElement, maxSize).pipe(map(item => this.getUpdateImageAction(canvasElement, item)));
    });
  }

  getPageElements(page: PageElement, use_inline_text: boolean): CacheElement[] {
    return page.children
      .reduce((acc, val) => acc.concat([val, ...val.children]), [])
      .filter(element => {
        return (
          element.isImage() ||
          element.isBackgroundImage() ||
          element.isInlineText() ||
          (element.isText() && !use_inline_text)
        );
      });
  }

  getUpdateImageAction(element: CacheElement, image: ImageResponse) {
    return new CanvasActions.UpdateImageAction(element.route, element, image.url, image.isVectorImage);
  }
}

const elementCachingServiceFactory = (cacheService: CacheService, store: Store<AppState>) => {
  return new ElementCachingService(cacheService, store);
};

export const elementCachingServiceProvider = {
  provide: ElementCachingService,
  useFactory: elementCachingServiceFactory,
  deps: [CacheService, Store]
};
