import { fabric } from 'fabric';
import { isSmallObject } from '../../utils/object.utils';

const size = 38;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 40 40" fill="none">
    <rect x="6" y="4" width="28" height="28" rx="14" fill="white" shape-rendering="geometricPrecision" stroke="#0008" stroke-width="1" stroke-opacity="0.7" />
    <path
      d="M20.6875 10.3125L22.6875 12.3125C22.9688 12.5938 23.0625 13.0312 22.9062 13.4062C22.75 13.7812 22.375 14 22 14H21V17.0312H24V16.0312C24 15.625 24.2188 15.25 24.5938 15.0938C24.9688 14.9375 25.4062 15.0312 25.6875 15.3125L27.6875 17.3125C28.0938 17.6875 28.0938 18.3438 27.6875 18.7188L25.6875 20.7188C25.4062 21 24.9688 21.0938 24.5938 20.9375C24.2188 20.7812 24 20.4062 24 20V19H21V22H21.9688C22.375 22 22.75 22.25 22.9062 22.625C23.0625 23 22.9688 23.4375 22.6875 23.7188L20.6875 25.7188C20.3125 26.125 19.6562 26.125 19.2812 25.7188L17.2812 23.7188C17 23.4375 16.9062 23 17.0625 22.625C17.2188 22.25 17.5938 22 18 22H19V19H16V20C16 20.4062 15.75 20.7812 15.375 20.9375C15 21.0938 14.5625 21 14.2812 20.7188L12.2812 18.7188C11.875 18.3438 11.875 17.6875 12.2812 17.3125L14.2812 15.3125C14.5625 15.0312 15 14.9375 15.375 15.0938C15.75 15.25 16 15.625 16 16V17.0312H19V14.0312H18C17.5938 14.0312 17.2188 13.7812 17.0625 13.4062C16.9062 13.0312 17 12.5938 17.2812 12.3125L19.2812 10.3125C19.6562 9.90625 20.3125 9.90625 20.6875 10.3125Z"
      fill="#292929" />
  </svg>
`);

const move = new fabric.Control({
  actionName: 'move',
  x: .5,
  y: 0,
  offsetX: 24,
  offsetY: 0,
  cursorStyle: 'pointer',
  visible: false,
  getVisibility,
  render,
  actionHandler,
  mouseDownHandler,
  mouseUpHandler,
});

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return isSmallObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}

function render(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object): void {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function actionHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  const originalPointer: fabric.Point = transform.target.controls.move['#pointer'];
  const options = getOptions(e, transform, x, y, originalPointer);

  transform.target.canvas.fire('object:moving', options);
  transform.target.fire('moving', options);
  return true;
}

function mouseDownHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  transform.target.controls.move['#pointer'] = new fabric.Point(x, y);
  return true;
}

function mouseUpHandler(e: MouseEvent, transform: fabric.Transform, x: number, y: number): boolean {
  const originalPointer: fabric.Point = transform.target.controls.move['#pointer'];
  const options = getOptions(e, transform, x, y, originalPointer);

  transform.target.canvas.fire('object:modified', options);
  transform.target.fire('modified', options);

  transform.target.controls.move['#pointer'] = null;
  return true;
}

function getOptions(e: MouseEvent, transform: fabric.Transform, x: number, y: number, originalPointer: fabric.Point) {
  const target = transform.target;
  const pointer = new fabric.Point(x, y);
  const dx = x - originalPointer.x;
  const dy = y - originalPointer.y;

  target.set({
    left: transform.original.left + dx,
    top: transform.original.top + dy,
  });

  target.setCoords();

  return {
    e,
    transform,
    target,
    pointer,
    action: 'drag',
  };
}

fabric.Object.prototype.controls.move = move;
fabric.Textbox.prototype.controls.move = move;
