import { EditorAction } from '../action';
import { SET_INLINE_TEXT_EDIT_MODE } from './canvas.action.types';

export class SetInlineTextEditMode implements EditorAction {
  readonly type = SET_INLINE_TEXT_EDIT_MODE;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>, public editFullRange: boolean) {}
}
