import { RecoverActions, RecoverActionTypes } from './actions';
import { RecoverUser } from '../models/user';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { ClientError } from '../models/client-error';

export interface RecoverState {
  user: RecoverUser | null;
  error: ClientError[];
  pending: boolean;
  dialogOpen: boolean;
  successDialogOpen: boolean;
}

export interface State extends AppState {
  recover: RecoverState;
}

export const initialState: RecoverState = {
  user: null,
  error: [],
  pending: false,
  dialogOpen: false,
  successDialogOpen: false
};

export function reducer(state = initialState, action: RecoverActions): RecoverState {
  switch (action.type) {
    case RecoverActionTypes.OpenRecoverDialog: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case RecoverActionTypes.CloseRecoverDialog: {
      return {
        ...state,
        dialogOpen: false
      };
    }

    case RecoverActionTypes.Recover: {
      return {
        ...state,
        pending: true
      };
    }

    case RecoverActionTypes.RecoverFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case RecoverActionTypes.RecoverSuccess: {
      return {
        ...state,
        user: action.user,
        error: [],
        pending: false,
        dialogOpen: false,
        successDialogOpen: true
      };
    }

    case RecoverActionTypes.CloseRecoverSuccessDialog: {
      return {
        ...state,
        error: [],
        successDialogOpen: false
      };
    }

    case RecoverActionTypes.ResetValidationErrors: {
      return {
        ...state,
        error: []
      };
    }

    default: {
      return state;
    }
  }
}

export const selectRecoverState = createFeatureSelector<RecoverState>('recover');

export const getError = createSelector(selectRecoverState, (state: RecoverState) => state.error);

export const getPending = createSelector(selectRecoverState, (state: RecoverState) => state.pending);

export const getUser = createSelector(selectRecoverState, (state: RecoverState) => state.user);

export const getDialogOpen = createSelector(selectRecoverState, (state: RecoverState) => state.dialogOpen);
