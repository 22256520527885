import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UndoActions, UndoActionTypes } from '../../actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { Design, InlineTextElement } from '../../models';
import { AppState } from '../index';
import { TextEditorService } from '../../text-editor/text-editor.service';
import { selectDesign } from '../../selectors';

@Injectable()
export class UndoEffects {
  @Effect({ dispatch: false })
  undo$ = this.actions$.pipe(
    ofType(UndoActionTypes.UNDO, UndoActionTypes.REDO),
    withLatestFrom(this.store$.pipe(select(selectDesign))),
    map(([action, design]: [UndoActions.Undo | UndoActions.Redo, Design]) => {
      if (design.selectedElement && design.selectedElement.isInlineText()) {
        const newInlineText = design.selectedElement as InlineTextElement;
        this.textEditorService.updateInput(newInlineText);
      }
    })
  );

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private textEditorService: TextEditorService
  ) {}
}
