import { AuthActions, AuthActionTypes } from './actions';
import { User } from '../models/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { ClientError } from '../models/client-error';

export interface AuthState {
  loggedIn: boolean;
  user: User | null;
  error: ClientError[];
  pending: boolean;
  dialogOpen: boolean;
}

export interface State extends AppState {
  auth: AuthState;
}

export const initialState: AuthState = {
  loggedIn: false,
  user: new User('', ''),
  error: [],
  pending: true,
  dialogOpen: false
};

export function reducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.OpenLoginDialog: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case AuthActionTypes.OpenLoginDialogFromRecoverDialog: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case AuthActionTypes.OpenLoginDialogFromRegisterDialog: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case AuthActionTypes.OpenLoginDialogAfterRecover: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case AuthActionTypes.OpenLoginDialogFromSave: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case AuthActionTypes.OpenLoginDialogFromContinue: {
      return {
        ...state,
        dialogOpen: true
      };
    }

    case AuthActionTypes.CloseLoginDialog: {
      return {
        ...state,
        error: null,
        dialogOpen: false
      };
    }

    case AuthActionTypes.Login: {
      return {
        ...state,
        error: null,
        pending: true
      };
    }

    case AuthActionTypes.LoginFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        loggedIn: action.user.isAuthenticated,
        user: action.user,
        error: null,
        pending: false,
        dialogOpen: false
      };
    }

    case AuthActionTypes.LoginErrorReset: {
      return {
        ...state,
        error: null
      };
    }

    case AuthActionTypes.AuthInitSuccess: {
      return {
        ...state,
        loggedIn: action.user.isAuthenticated,
        user: action.user,
        error: null,
        pending: false
      };
    }

    case AuthActionTypes.AuthInitFailure: {
      return {
        ...state,
        loggedIn: false,
        user: action.user,
        error: null,
        pending: false
      };
    }

    case AuthActionTypes.Logout: {
      return {
        ...initialState,
        pending: false
      };
    }

    default: {
      return state;
    }
  }
}

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const getError = createSelector(selectAuthState, (state: AuthState) => state.error);

export const getPending = createSelector(selectAuthState, (state: AuthState) => state.pending);

export const getUser = createSelector(selectAuthState, (state: AuthState) => state.user);

export const getDialogOpen = createSelector(selectAuthState, (state: AuthState) => state.dialogOpen);

export const isLoggedIn = createSelector(selectAuthState, (state: AuthState) => state.loggedIn);
