import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FunctionPermission, ImageCategory } from '../../../../models';
import { ConfigService, GetTextService } from '../../../../services';
import { AppState } from '../../../../reducers';
import { Store } from '@ngrx/store';
import * as ImageLibraryActions from '../../../../image-library/actions';
import { categoryByPath } from '../../../../image-library/image-library.utils';
import { ImageLibraryService } from 'src/app/image-library/image-library.service';
import { debounceTime, distinctUntilChanged, EMPTY, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { UploadCallbackFunction } from '../../../../services/image-upload.service';

@Component({
  selector: 'ed-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss', '../../../../shared/button/buttons.scss']
})
export class ImageLibraryComponent implements OnInit, OnDestroy {
  category: ImageCategory;

  @Input() mainCategory: ImageCategory;
  @Input() savedLocation: number[];
  @Input() dataLayerIdPrefix: string;
  @Input() headerTitle: string;
  @Input() image: boolean;
  @Input() background: boolean;
  @Input() addImageAsPhotoFrame: boolean;
  @Input() uploadImageCallback: UploadCallbackFunction;
  @Output() closeImageLibrary = new EventEmitter();
  @Output() chooseImage: EventEmitter<object> = new EventEmitter();

  permissions = FunctionPermission;

  private searchImageLibrarySubscription: Subscription;
  private savedImageLibrarySubscription: Subscription;
  public searchText$ = new Subject<string>();
  searchTerm$: Observable<string>;

  constructor(
    public getTextService: GetTextService,
    public configService: ConfigService,
    public store: Store<AppState>,
    public libraryService: ImageLibraryService
  ) {}

  ngOnInit(): void {
    this.searchTerm$ = this.libraryService.savedSearchTerm$;

    this.savedImageLibrarySubscription = this.libraryService.savedImagelibrary$.subscribe(imageLibrary => {
      if (imageLibrary) {
        this.category = imageLibrary.categories[0];
      } else {
        this.category = categoryByPath(this.savedLocation, this.mainCategory);
      }
    });

    this.searchImageLibrarySubscription = this.searchText$
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(text => (text ? this.libraryService.search(text, this.dataLayerIdPrefix + 'search') : EMPTY))
      )
      .subscribe(response => {
        this.store.dispatch(new ImageLibraryActions.SaveSearch(response.searchTerm, response.imageLibrary));
      });
  }

  getButtonSize() {
    return this.configService.isMobile ? 'xsmall' : 'small';
  }

  goBack(category: ImageCategory): void {
    const newCategory = categoryByPath(category.path.slice(0, -1), this.mainCategory);
    this.selectCategory(newCategory);
  }

  onCloseImageLibrary() {
    this.selectCategory(this.mainCategory);
    this.closeImageLibrary.emit();
  }

  selectCategory(category: ImageCategory) {
    this.saveLocation(category.path);
    this.category = category;
  }

  onChooseImage(event: any) {
    this.chooseImage.emit({
      sid: event.sid,
      url: event.url,
      imageType: event.imageType,
      width: event.width,
      height: event.height,
      isFoilable: event.isFoilable
    });
  }

  saveLocation(path: number[]): void {
    this.store.dispatch(new ImageLibraryActions.SaveLocation(path));
  }

  getValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  onSearch(value: string) {
    this.searchText$.next(value);

    if (!value) {
      this.selectCategory(this.mainCategory);
      this.store.dispatch(new ImageLibraryActions.SaveSearch('', null));
    }
  }

  ngOnDestroy() {
    this.searchImageLibrarySubscription.unsubscribe();
    this.savedImageLibrarySubscription.unsubscribe();
  }
}
