import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { EditModule } from '../edit/edit.module';
import { PanelsComponent } from './panels.component';
import { PanelsMobileComponent } from './panels-mobile/panels-mobile.component';
import { PanelsMobileNewComponent } from './panels-mobile-new/panels-mobile-new.component';
import { NavModule } from '../nav/nav.module';

@NgModule({
  imports: [SharedModule, EditModule, NavModule],
  declarations: [PanelsComponent, PanelsMobileComponent, PanelsMobileNewComponent],
  exports: [PanelsComponent, PanelsMobileComponent, PanelsMobileNewComponent],
  providers: []
})
export class PanelsModule {}
