import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class CanvasIdService {
  private ids: string[] = [];

  generate(): string {
    let tempId = '';
    let isUnique = false;

    while (!isUnique) {
      tempId = this.generator();
      if (this.isUnique(tempId)) {
        isUnique = true;
        this.ids.push(tempId);
      }
    }

    return tempId;
  }

  remove(id: string) {
    const index = this.ids.indexOf(id);
    if (index !== -1) {
      this.ids.splice(index, 1)
    }
  }

  private generator(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);

      return v.toString(16);
    });
  }

  private isUnique(id: string): boolean {
    return !this.ids.includes(id);
  }
}
