import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable, of, throwError, zip } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, mergeMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash-es';

import { Design } from '../models';
import { AppState } from '../reducers';
import { DesignSetSubmit, DesignSubmit, DesignTitleResponse, isDesignSetSubmit } from '../models/design-submit';
import { TransformService } from './transform.service';
import * as fromConfig from '../config/reducer';
import { GetTextService, OutputService, TextService } from '../services';
import { TitleAndEmail } from '../admin-designer/actions';
import { ParamsService } from '../services/params.service';
import { getDesignSet } from '../selectors';
import { DesignSet } from '../models/design-set';

@Injectable()
export class SaveService {
  designUrl$: Observable<string>;
  designOtherUserUrl$: Observable<string>;
  updateExistingDesignUrl$: Observable<string>;
  designToReviewUrl$: Observable<string>;
  designSet$: Observable<DesignSet>;

  constructor(
    private http: HttpClient,
    private store$: Store<AppState>,
    private transformService: TransformService,
    private outputService: OutputService,
    private paramsService: ParamsService,
    private getTextService: GetTextService,
    private textService: TextService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.designUrl$ = this.store$.pipe(select(fromConfig.getDesignUrl));
    this.designOtherUserUrl$ = this.store$.pipe(select(fromConfig.getDesignOtherUserUrl));
    this.updateExistingDesignUrl$ = this.store$.pipe(select(fromConfig.getUpdateExistingDesignUrl));
    this.designToReviewUrl$ = this.store$.pipe(select(fromConfig.getDesignToReviewUrl));
    this.designSet$ = this.store$.pipe(select(getDesignSet));
  }

  init(): Observable<DesignSubmit> {
    return this.designUrl$.pipe(
      mergeMap(designUrl =>
        this.http.get<DesignSubmit>(designUrl, { params: this.paramsService.httpParams }).pipe(
          catchError(error => {
            // if coid is unknown backend returns a 404 (not found), so redirect to homepage because there is nog design
            if (error.status === 404) {
              this.document.location.href = '/';
            }
            return throwError(error);
          })
        )
      )
    );
  }

  transformDesignAndAdjustText(designSetSubmit: DesignSetSubmit | DesignSubmit): Observable<DesignSet> {
    return of(this.transformToDesignSet(cloneDeep(designSetSubmit))).pipe(
      mergeMap((designSet: DesignSet) => this.textService.adjustTextSet(designSet))
    );
  }

  saveDesign(title: string, save: boolean): Observable<DesignSet> {
    return zip(this.designUrl$, this.designSet$).pipe(
      map(([designUrl, designSet]) => {
        const setOrDesignSubmit = this.transformToKc(designSet);
        const newTitle =
          title || designSet.userCollectionTitle || designSet.title || this.getTextService.text.untitledText;
        this.setColTitle(setOrDesignSubmit, newTitle);
        setOrDesignSubmit.save = save;
        return { designUrl, setOrDesignSubmit };
      }),
      mergeMap(({ designUrl, setOrDesignSubmit }) =>
        this.http.post(designUrl, setOrDesignSubmit, { params: this.paramsService.httpParams })
      ),
      map((response: DesignSubmit | DesignSetSubmit) => this.transformToDesignSet(response))
    );
  }

  saveDesignInCollectionOtherUser(payload: TitleAndEmail): Observable<DesignSubmit> {
    return this.designOtherUserUrl$.pipe(
      withLatestFrom(this.store$.pipe(select(getDesignSet))),
      map(([designOtherUserUrl, designSet]) => {
        const setOrDesignSubmit = this.transformToKc(designSet);
        this.setColTitle(setOrDesignSubmit, payload.userTitle);
        setOrDesignSubmit.email_user = payload.emailAddress;
        return { designOtherUserUrl, setOrDesignSubmit };
      }),
      mergeMap(({ designOtherUserUrl, setOrDesignSubmit }) =>
        this.http.post<DesignSubmit>(designOtherUserUrl, setOrDesignSubmit, { params: this.paramsService.httpParams })
      )
    );
  }

  makeCopy(designSubmit: DesignSubmit | DesignSetSubmit): Observable<DesignSet> {
    return this.designUrl$.pipe(
      map((designUrl: string) => {
        designSubmit.make_copy = true;
        designSubmit.save = true;
        return designUrl;
      }),
      switchMap(
        designUrl =>
          this.http.post(designUrl, designSubmit as DesignSubmit, {
            params: this.paramsService.httpParams
          }) as Observable<DesignSubmit>
      ),
      map((response: DesignSubmit | DesignSetSubmit) => this.transformToDesignSet(response))
    );
  }

  saveTitleDescriptionDesign(title: string, description: string, url: Observable<string>, overwrite?: boolean) {
    return zip(url, this.designSet$).pipe(
      map(([titleDescriptionUrl, design]) => {
        const designSubmit = this.transformToKc(design, true);
        if (isDesignSetSubmit(designSubmit)) {
          designSubmit.title = title;
          designSubmit.description = description;
        } else {
          designSubmit.design_title = title;
          designSubmit.design_description = description;
        }
        designSubmit.overwrite_existing_design = overwrite;
        return { titleDescriptionUrl, designSubmit };
      }),
      mergeMap(({ titleDescriptionUrl, designSubmit }) =>
        this.http.post<DesignSubmit>(titleDescriptionUrl, designSubmit as DesignSubmit, {
          params: this.paramsService.httpParams
        })
      )
    );
  }

  saveTitleDescription(title: string, description: string, overwrite: boolean): Observable<DesignTitleResponse> {
    return this.saveTitleDescriptionDesign(title, description, this.updateExistingDesignUrl$, overwrite);
  }

  saveDesignForReview(title: string, description: string, requestOverwrite: boolean): Observable<DesignTitleResponse> {
    return this.saveTitleDescriptionDesign(title, description, this.designToReviewUrl$, requestOverwrite);
  }

  transformToDesignSet(response: DesignSubmit | DesignSetSubmit): DesignSet {
    const designSet = new DesignSet();
    if (isDesignSetSubmit(response)) {
      designSet.designs = response.designs.map((d, i) => this.transformToDesign(d, i));
      designSet.version = response.version;
      designSet.id = response.id;
      designSet.title = response.title;
      designSet.description = response.description;
      designSet.useAbsoluteFontSize = response.use_absolute_font_size;
      designSet.isProposedDesign = response.is_proposed;
      designSet.userCollectionId = response.user_collection_id;
      designSet.userCollectionTitle = response.user_collection_title;
    } else {
      designSet.designs = [this.transformToDesign(response)];
      designSet.version = 0;
      designSet.id = response.design_id;
      designSet.title = response.design_title;
      designSet.description = response.design_description;
      designSet.useAbsoluteFontSize = response.use_absolute_font_size;
      designSet.isProposedDesign = response.is_proposed_design;
      designSet.userCollectionId = response.user_design_id;
      designSet.userCollectionTitle = response.user_design_title;
    }
    return designSet;
  }

  transformToDesign(response: DesignSubmit, index = 0): Design {
    const design = this.transformService.transformKcPages(
      response.pages,
      response.use_absolute_font_size,
      response.permissions
    );
    design.user_design_id = response.user_design_id;
    design.user_design_title = response.user_design_title;
    design.isProposedDesign = response.is_proposed_design;
    design.title = response.design_title;
    design.description = response.design_description;
    design.id = response.design_id;
    design.useAbsoluteFontSize = response.use_absolute_font_size;
    design.active = index === 0;
    design.setId = index;
    return design;
  }

  setColTitle(setOrDesignSubmit: DesignSetSubmit | DesignSubmit, newTitle: string): void {
    if (isDesignSetSubmit(setOrDesignSubmit)) {
      setOrDesignSubmit.user_collection_title = newTitle;
    } else {
      setOrDesignSubmit.user_design_title = newTitle;
    }
  }

  transformToKc(designSet: DesignSet, updateDesignAssets?: boolean): DesignSetSubmit | DesignSubmit {
    if (designSet.version > 0) {
      return this.createDesignSetSubmit(designSet, updateDesignAssets);
    } else {
      return this.createDesignSubmitFromSet(designSet, updateDesignAssets);
    }
  }

  createDesignSubmitFromSet(designSet: DesignSet, updateDesignAssets?: boolean): DesignSubmit {
    const designSubmit = new DesignSubmit();
    designSubmit.pages = this.outputService.transformToKC(designSet.designs[0], updateDesignAssets);
    designSubmit.is_proposed_design = designSet.isProposedDesign;
    designSubmit.design_id = designSet.id;
    designSubmit.design_title = designSet.title;
    designSubmit.user_design_id = designSet.userCollectionId;
    designSubmit.user_design_title = designSet.userCollectionTitle;
    designSubmit.use_absolute_font_size = designSet.useAbsoluteFontSize;
    return designSubmit;
  }

  createDesignSubmit(design: Design, updateDesignAssets?: boolean): DesignSubmit {
    const designSubmit = new DesignSubmit();
    designSubmit.pages = this.outputService.transformToKC(design, updateDesignAssets);
    designSubmit.is_proposed_design = design.isProposedDesign;
    designSubmit.design_id = design.id;
    designSubmit.design_title = design.title;
    designSubmit.user_design_id = design.user_design_id;
    designSubmit.user_design_title = design.user_design_title;
    designSubmit.use_absolute_font_size = design.useAbsoluteFontSize;
    return designSubmit;
  }

  createDesignSetSubmit(designSet: DesignSet, updateDesignAssets?: boolean): DesignSetSubmit {
    const setSubmit = new DesignSetSubmit();
    setSubmit.designs = designSet.designs.map(design => this.createDesignSubmit(design, updateDesignAssets));
    setSubmit.is_proposed = designSet.isProposedDesign;
    setSubmit.id = designSet.id;
    setSubmit.title = designSet.title;
    setSubmit.user_collection_id = designSet.userCollectionId;
    setSubmit.user_collection_title = designSet.userCollectionTitle;
    setSubmit.use_absolute_font_size = designSet.useAbsoluteFontSize;
    setSubmit.version = designSet.version;
    return setSubmit;
  }

  designHasChanges(design: DesignSet, lastSavedDesign: DesignSet) {
    if (!lastSavedDesign) {
      return true; // if not saved before, design has changed
    }
    const designSubmit = this.transformToKc(design);
    const lastSavedDesignSubmit = this.transformToKc(lastSavedDesign);

    const pages = isDesignSetSubmit(designSubmit) ? designSubmit.designs.flatMap(d => d.pages) : designSubmit.pages;
    const oldPages = isDesignSetSubmit(lastSavedDesignSubmit)
      ? lastSavedDesignSubmit.designs.flatMap(d => d.pages)
      : lastSavedDesignSubmit.pages;

    // JSON.stringify sees order of permissions as diff;
    return !isEqual(pages, oldPages);
  }
}
