import { EditorAction } from '../action';
import { OPEN_MULTIPLE_SPECIAL_COLORS_DIALOG } from './canvas.action.types';
import { Color } from '../../models';

export class OpenMultipleSpecialColorsDialog implements EditorAction {
  readonly type = OPEN_MULTIPLE_SPECIAL_COLORS_DIALOG;
  readonly undoable = false;
  readonly save = false;

  constructor(public route: Array<number>, public color: Color, public message = '') {}
}
