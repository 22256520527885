import { Paragraph } from '../models';

export const defaultText: Paragraph[] = [
  {
    textAlign: 'middle',
    lineHeight: 1,
    isJustified: false,
    lines: [
      {
        wordSpacing: undefined,
        y: 12.4346,
        textSpans: [
          {
            text: 'Alles',
            fontSize: 7,
            font: 'Arial',
            color: '#000000',
            italic: true,
            bold: true,
            underlined: true,
            opacity: 0.6,
            spanParts: [],
            foil: undefined,
            spotUv: false
          },
          {
            text: '*',
            fontSize: 7,
            font: 'Arial',
            color: '#000000',
            italic: false,
            bold: true,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          },
          {
            text: ' binnen',
            fontSize: 5,
            font: 'Arial',
            color: '#0000ff',
            italic: false,
            bold: false,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          },
          {
            text: ' een ',
            fontSize: 5,
            font: 'A Little Pot',
            color: '#ff0000',
            italic: true,
            bold: false,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          },
          {
            text: 'tekstelement',
            fontSize: 5,
            font: 'Arial',
            color: '#ff00ff',
            italic: false,
            bold: false,
            underlined: true,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          },
          {
            text: ' op selectie ',
            fontSize: 5,
            font: 'Arial',
            color: '#000000',
            italic: false,
            bold: false,
            underlined: false,
            opacity: 0.2,
            spanParts: [],
            foil: undefined,
            spotUv: false
          }
        ]
      },
      {
        wordSpacing: undefined,
        y: 25.0346,
        textSpans: [
          {
            text: 'flexibel',
            fontSize: 12,
            font: 'Advetime',
            color: '#ff00ff',
            italic: true,
            bold: false,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          },
          {
            text: ' kunnen bewerken',
            fontSize: 10,
            font: 'Arial',
            color: '#000000',
            italic: false,
            bold: false,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          }
        ]
      }
    ]
  },
  {
    textAlign: 'start',
    lineHeight: 1,
    isJustified: false,
    lines: [
      {
        wordSpacing: undefined,
        y: 31.0346,
        textSpans: [
          {
            text: 'Ook de uitlijning  van een nieuwe paragraaf :-)',
            fontSize: 4,
            font: 'Arial Black',
            color: '#000000',
            italic: false,
            bold: false,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          }
        ]
      }
    ]
  },
  {
    textAlign: 'end',
    lineHeight: 1,
    isJustified: false,
    lines: [
      {
        wordSpacing: undefined,
        y: 35.4346,
        textSpans: [
          {
            text: 'Ook bij deze regel...',
            fontSize: 4,
            font: 'Arial',
            color: '#000000',
            italic: false,
            bold: false,
            underlined: false,
            opacity: 1,
            spanParts: [],
            foil: undefined,
            spotUv: false
          }
        ]
      }
    ]
  }
];
